import {useEffect, useState, useContext} from 'react';
import {ArtifactStyled} from './ArtifactStyled'
import { drizzleReactHooks } from '@drizzle/react-plugin';
import {useApi} from './apis/api'
import LocalShips from './LocalShips'
import {ReactComponent as VoxIcon} from './assets/vox_crystal_icon.svg'
import {Button} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import SalvageArtifactButton from './SalvageArtifactButton'
import {UniverseContext} from './context/UniverseContext'
import {formatBalance} from './libs/utils'

const Salvage = (props) => {
  const api = useApi();
  const theme = useTheme();
  const artifactId = props.artifactId;
  const {selectedShip} = useContext(UniverseContext);
  const { drizzle } = drizzleReactHooks.useDrizzle();


  const [ships, setShips] = useState();
  const [trustedSalvage, setTrustedSalvage] = useState();

  useEffect(()=>{
    if(selectedShip){
      console.log(selectedShip.token)
      const run = async () => {
        const trustedSalvage = await api.trustedSigner.getSalvageSignature(artifactId, selectedShip.token);
        setTrustedSalvage(trustedSalvage);
      }
      run();

    }
  },[selectedShip]);

  const shipsCallback = (ships) =>{
    setShips(ships);
  }


  if(ships && ships.length === 0){
    return (
      <div className="no-ships">
        You don't have any ships in the vicinity.
      </div>
    )
  }
  return (
    <div className="artifact-salvage">
      {!selectedShip && <h3>Engage a local ship from your fleet to salvage this artifact.</h3>}
      {trustedSalvage && selectedShip?.token &&
        <>
          <div className="salvage-value">
            <VoxIcon className="vox-icon" fill={theme.palette.info.main}/>
            {formatBalance(trustedSalvage.amount.hex, drizzle)}
          </div>
          <SalvageArtifactButton shipTokenId={selectedShip.token} trustedSalvage={trustedSalvage}/>
        </>
      }
    </div>
  )
}
export default function Artifact(props){
  const { drizzle, useCacheCall } = drizzleReactHooks.useDrizzle();
  const {image, description, id: artifactId} = props;
  const {selectedShip} = useContext(UniverseContext);

  useEffect(()=>{
    console.log("IMAGE = ", image);
  }, [image]);

  return (
    <ArtifactStyled>
      {image && <img className="artifact-image" src={image} />}
      <div className="artifact-description">
        <p>{description}</p>
      </div>
      <Salvage artifactId={artifactId} selectedShip={selectedShip}/>

    </ArtifactStyled>
  );

}