import './Header.css'

import {useEffect, useState, useContext} from 'react'
import {useLocation} from 'react-router-dom'
import {Button, Link} from '@mui/material'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import ContextDrawer from './ContextDrawer'
import PlanetDrawerContents from './PlanetDrawerContents'
import SolarSystemDrawerContents from './SolarSystemDrawerContents'
import GalaxyDrawerContents from './GalaxyDrawerContents'
import WalletDialog from './WalletDialog'
import {AuthContext} from './Web3Container'
import DisplayItem from './DisplayItem';
import InfoIcon from '@mui/icons-material/Info';
import TwitterIcon from '@mui/icons-material/Twitter';
import {description} from './constants/localContent'

export default function Header(params) {
  const {web3OptedIn, account} = useContext(AuthContext);
  const [walletIsOpen, setWalletIsOpen] = useState(false);
  const [info, setInfo] = useState(null);
  const [showLogo, setShowLogo] = useState(params.showLogo !== false)

  const handleWalletClick = () => {
     setWalletIsOpen(true);
  }
  const BreadCrumb = (params) => {

    const name = (planet)=>{
      if(planet.getName().includes("Station")){
        return planet.getName();
      }
      return "Planet " + planet.getName()
    }
    if(params.solarSystem){
      return (<div className="breadCrumb"><div className="divider">{"<"}</div><a href={web3OptedIn ? `/app/solarsystems/${params.solarSystem.getId()}` : `/home/solarsystems/${params.solarSystem.getId()}`}>{params.solarSystem.getName() + " System"}</a></div>);
    }
    if(params.planet){
      return (<div className="breadCrumb"><div className="divider">{"<"}</div> <a href={web3OptedIn ? `/app/planets/${params.planet.getId()}` : `/home/planets/${params.planet.getId()}`}>{name(params.planet)}</a></div>);
    }
    if(params.galaxy){
      return (<div className="breadCrumb"><a href={web3OptedIn ? `/app/galaxy` :  `/home/galaxy`}>{ "The Galaxy"}</a></div>);
    }
    return null;
  }

  useEffect(()=>{
    setShowLogo(params.showLogo !== false)
  }, [params.showLogo])

  console.log("PARAMS", params?.planet)
  return (
    <div id="header" className="Header">
        <a href="/"><img id="smallLogo" className={"smallLogo" + (showLogo ? " show" : " hide")} src="/inline_logo.png" /></a>
        <div className="breadcrumbs">
          <BreadCrumb galaxy={true}/>
          <BreadCrumb solarSystem={params.solarSystem} />
          <BreadCrumb planet={params.planet?.proto} />
        </div>
        {
                  web3OptedIn ?
                  <Button className="wallet" onClick={handleWalletClick}><AccountBalanceWalletIcon color="info"/></Button>:
                  <div className="right-buttons">
                    <Link className="twitter-icon" target="_blank" href="//twitter.com/galaxyhustle"><TwitterIcon/></Link>
                    <Button className="info" color="info" onClick={()=>{setInfo({information:{description}})}}><InfoIcon/></Button>
                  </div>
                  //<Button href={params?.planet ? `/app/planets/${params?.planet?.proto?.getId()}` : (params?.solarSystem ? `/app/solarsystems/${params?.solarSystem?.proto?.getId()}` : `/app/galaxy`)} variant="contained" className="sign-in" disabled>Coming Soon</Button>
        }
        {/*
          //Don't show the context drawer.
          params?.planet?.proto?.getId() != '3b6d2d5b-29d9-4456-a9b8-2552c853ac0c' && <ContextDrawer {...params}/>
        */}


        <DisplayItem {...info}  onDismiss={() => setInfo(null)}/>
        <WalletDialog universe={params.universe} open={walletIsOpen} onClose={() => setWalletIsOpen(false)}/>


    </div>
  );
}