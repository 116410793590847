
export const COMMAND_NONE = 0;
export const COMMAND_HEAT = 1;
export const COMMAND_DIG = 2;
export const COMMAND_FILL = 3;
export const COMMAND_SIMULATE = 6;

export const VOXEL_NONE = 0;
export const VOXEL_WATER = 2;
export const VOXEL_ROCK = 3;
export const VOXEL_PLANT = 4;

export class EditTool{


  set commandType(value){
    this._commandType = value;
    this.commandArg2 = 0;
    this.commandArg3 = 0;
  }

  get commandType(){
    return this._commandType;
  }

  constructor(canvas, raycaster, localArea, mouse, camera){
    this._commandType = COMMAND_NONE;
    this.commandArg1 = 10.0;
    this.commandArg2 = 0;
    this.commandArg3 = 0;

    this.mousePos = [0,0];
    this.isDragging = false;
    this.intersection = false;
    this.target_intersection = null;
    this.beamIsFiring = false;
    this.raycaster = raycaster;
    this.localArea = localArea;
    this.mouse = mouse;
    this.camera = camera;

  }

  _onActivate(mousePos) {
    if(this.commandType > 0){
      this.raycaster.setFromCamera( this.mouse, this.camera );
      this.intersection = this.raycaster.intersectObjects([this.localArea.volumeObject], true);
      this.target_intersection = null;
      this.beamIsFiring = this.intersection?.length > 0;
    } else {
      this.beamIsFiring = false;
    }
    return this.beamIsFiring;
  };

  _onDeactivate() {
    this.beamIsFiring = false;
  }

  _onFire(mousePos) {
    this.raycaster.setFromCamera( this.mouse, this.camera );
    this.intersection = this.raycaster.intersectObjects([this.localArea.volumeObject], true);
    this.target_intersection = null;
    console.log("Setting intersection")


  }

  _onTarget(mousePos){
    //this.raycaster.setFromCamera( this.mouse, this.camera );
    //target_intersection = raycaster.intersectObjects([volumeSphere], true);
    //console.log("Setting target_intersection")
  };

  handleTouchStart(event) {
    if(event.touches.length == 1){
      this.mousePos = [event.touches[0].clientX, event.touches[0].clientY];
      if((!this._onActivate) || (this._onActivate(this.mousePos) && this.commandType > COMMAND_NONE)){
        event.preventDefault();
        this.isDragging = true;
      }

    }
  }

  handleTouchEnd(event){
    if(event.touches.length == 1){
      this.isDragging = false;
      this._onDeactivate && this._onDeactivate();

    }
  }

  handleTouchMove(event) {
    this.mousePos = [event.touches[0].clientX, event.touches[0].clientY];
    if(this.isDragging && this.commandType > COMMAND_NONE){
      event.preventDefault();
      this._onFire && this._onFire(this.mousePos)
    }
  }

  handleMouseDown(event){
    if(event.button === 0){
      this.mousePos = [event.clientX, event.clientY];
      if((!this._onActivate) || (this._onActivate(this.mousePos) && this.commandType > COMMAND_NONE)){
        event.preventDefault();
        this.isDragging = true;
      }
    }
  }

  handleMouseUp(event) {
    if(event.button === 0){
      this.isDragging = false;
      this._onDeactivate && this._onDeactivate();
    }
  }

  handleMouseMove(event){
    this.mousePos = [event.clientX, event.clientY];
    if(this.isDragging && this.commandType > COMMAND_NONE){
      event.preventDefault();
      this._onFire && this._onFire(this.mousePos)
    } else if(this.commandType > COMMAND_NONE){
      this._onTarget && this._onTarget(this.mousePos)
    }
  }

  handleZoom (event) {

  }


  registerCallbacks(canvas, document){
    this.boundHandleTouchStart ||= this.handleTouchStart.bind(this);
    this.boundHandleTouchEnd ||= this.handleTouchEnd.bind(this);
    this.boundHandleTouchMove ||= this.handleTouchMove.bind(this);
    this.boundHandleMouseDown ||= this.handleMouseDown.bind(this);
    this.boundHandleMouseUp ||= this.handleMouseUp.bind(this);
    this.boundHandleMouseMove ||= this.handleMouseMove.bind(this);
    this.boundHandleZoom ||= this.handleZoom.bind(this);

    canvas.addEventListener("touchstart",this.boundHandleTouchStart );
    canvas.addEventListener("mousedown", this.boundHandleMouseDown);

    document.addEventListener("mouseleave", this.boundHandleMouseUp);
    document.documentElement.addEventListener("mouseup", this.boundHandleMouseUp);
    document.documentElement.addEventListener("touchend", this.boundHandleTouchEnd);


    document.addEventListener("touchmove", this.boundHandleTouchMove);
    document.addEventListener("mousemove", this.boundHandleMouseMove);
    canvas.addEventListener("wheel", this.boundHandleZoom);
  }

  removeCallbacks(canvas, document){
    canvas.removeEventListener("touchstart", this.boundHandleTouchStart);
    canvas.removeEventListener("mousedown", this.boundHandleMouseDown);

    document.removeEventListener("mouseleave", this.boundHandleMouseUp);
    document.documentElement.removeEventListener("mouseup", this.boundHandleMouseUp);
    document.documentElement.removeEventListener("touchend", this.boundHandleTouchEnd);


    document.removeEventListener("touchmove", this.boundHandleTouchMove);
    document.removeEventListener("mousemove", this.boundHandleMouseMove);
    canvas.removeEventListener("wheel", this.boundHandleZoom);
  }
}