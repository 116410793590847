import './Team.css'
import {Link} from '@mui/material'
import { useTheme } from '@mui/material/styles';

export default function Team(){
  const theme = useTheme();
  return (
    <div className="Team">
      <h1>Team? What Team??</h1>
      <p>I did it <em style={{color: theme.palette.secondary.main}}>ALL</em> dammit. Me! <em style={{color: theme.palette.info.main}}>Whistleman.</em></p>

      <h1>Who is Whistleman?</h1>
      <div style={{display: "grid", justifyContent:"center", marginTop:"50px", marginBottom:"20px"}}>
        <img src="whistleman.jpeg" style={{width: "256px", height: "256px", borderRadius:"128px"}}/>
        <h5><span style={{color: theme.palette.primary.main}}>fig 1.</span> <span>Whistleman</span></h5>
      </div>

      <p>A lonely solo-dev, Whistleman began building the engine
      that powers the single-player, quest driven version of Galaxy Hustle in May 2013. Much work remains.</p>

      <p>
      Suffice it to say, building a
      game is a lot of work, and Whistleman's primary optimization technique for running full volume planet simulations in real time was waiting for Moore's law to fix the problem for him.</p>

      <p>He is a lazy bastard.</p>

      <p>Regardless, the work <em style={{color: theme.palette.secondary.main}}>continues</em>, for this is a labor of love.</p>

      <p>Tweet complaints at me <Link href="https://twitter.com/whistlemangames">@whistlemangames</Link></p>

    </div>
  );
}