import {InformationStyled} from './InformationStyled'

export default function Information(props){

  const description = props.description;

  return (
    <InformationStyled>
      <div className="information-description">
        {description}
      </div>
    </InformationStyled>
  );
}