import { useState, useEffect } from 'react';
export const AUTH_KEY = 'auth';

export const useSessionStorage = (keyName, defaultValue) => {
  const [value, setValue] = useState(() => {
    try {
      const value = window.sessionStorage.getItem(keyName);

      if (value) {
        return JSON.parse(value);
      } else {
        window.sessionStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  useEffect(()=>{
    window.sessionStorage.setItem(keyName, JSON.stringify(value));
  }, [value])

  return [value, setValue];
};