import {OperatorStyled} from './OperatorStyled'
import {useState, useEffect, memo} from 'react';
import Pilot from './Pilot'
import Contract from 'web3-eth-contract'
import ERC721 from'./contracts/ERC721.json';
import { drizzleReactHooks } from '@drizzle/react-plugin';
import networkMap from './constants/networkMap'
import {metadataMap} from './constants/metadataMap'
import axios from 'axios'
import Web3 from 'web3'
import {ipfsToGateway} from './libs/uriTools'
import * as proto from './protos/universe_pb'
import Panel from './Panel'

function Operator(props) {

    const { drizzle, useCacheCall } = drizzleReactHooks.useDrizzle();
    const operator = props.operator;
    const [pilotToken, setPilotToken] = useState();
    const [pilotUri, setPilotUri] = useState();

    useEffect(()=>{
      let mounted = true;
      const run = async () => {

        switch(operator.operatorContract){
          case drizzle.contracts.Eyeguy.address:
            setPilotToken(operator.operatorTokenId);
            return;
          default:
            var contractAddr = operator.operatorContract;
            if(!(contractAddr in drizzle.contracts)){

              const connectedChain = await drizzle.web3.eth.getChainId();
              if(!mounted){
                return;
              }
              var contract = new Contract(ERC721.abi, contractAddr);

              if(operator.operatorChainId == connectedChain){
                contract.setProvider(drizzle.web3.currentProvider)
              } else {

                const network = networkMap[operator.operatorChainId]
                if(!network?.providerUrls?.http){
                  throw new Error(`No provider url for chain ${operator.operatorChainId}`)
                }
                contract.setProvider(new Web3(network?.providerUrls?.http));
              }

              var contractConfig = {
                contractName: contractAddr,
                web3Contract: contract,
              }
              drizzle.addContract(contractConfig);
            }

            var metadataUri = await drizzle.contracts[contractAddr].methods.tokenURI(operator.operatorTokenId).call();
            if(!mounted){
              return;
            }
            metadataUri = ipfsToGateway(metadataUri)
                            .replace("https://nft.galaxyhustle.xyz/", "http://localhost:9001/");

            const { data: pilotMetadata } = await axios.get(metadataUri)
            if(!mounted){
              return;
            }
            var image = ipfsToGateway(pilotMetadata.image)
                          .replace("https://nft.galaxyhustle.xyz/", "http://localhost:9001/");

            setPilotUri(image);
            return
        }
      }
      if(operator){
        run()
      }

      return () => {
        mounted = false;
      }
    },[]);

    if(!operator){
      return null;
    }

    if(pilotToken){
      return  (
        <OperatorStyled>
          <Pilot className={props.className} species="eyeguy" id={pilotToken} animated={props.animated} showName={props.showName}/>
        </OperatorStyled>
      );
    }

    if(pilotUri){
      return (
      <OperatorStyled>
          <img className={"pilotImage " + props.className + " " + (props.animated ? 'animated' : 'frozen')} src={pilotUri}/>
      </OperatorStyled>);
    }

    return null;
  }

export default memo(Operator, (a,b)=>{
  return a.operator.operatorChainId === b.operator.operatorChainId &&
         a.operator.operatorContract === b.operator.operatorContract &&
         a.operator.operatorTokenId === b.operator.operatorTokenId;

});