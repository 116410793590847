import * as THREE from "three";
import {NoiseTexture} from './libs/noiseTexture'

function fetchFile(path) {
  return fetch(path)
              .then((r) => {return r.text()})
              .then(t  => {
                return t;
              });
}

export class ParticleSystem {

  constructor( renderer,
               textureWidth,
               maxParticles,
               positionTextures,
               stateTexture,
               colorTexture,
               particlePositionMaterial,
               particleRenderObject ){
    this.currentTime = 0.0;
    this.renderer = renderer;
    this.textureWidth = textureWidth;
    this.screenDimensions = new THREE.Vector2();
    renderer.getSize(this.screenDimensions);
    this.maxParticles = maxParticles;
    this.positionTextureA = positionTextures[0];
    this.positionTextureB = positionTextures[1];
    this.positionTextureC = positionTextures[2];
    this.stateTexture = stateTexture;
    this.colorTexture = colorTexture;
    //this.particlePositionRenderTarget = new THREE.WebGLRenderTarget(textureWidth, textureWidth);
    //this.particlePositionRenderTarget.texture = this.positionTextureA;
    this.particlePositionMaterial = particlePositionMaterial;
    this.particleRenderObject = particleRenderObject;

    /*this.positionTextureMaterial1 = new THREE.MeshStandardMaterial({map:this.positionTextureA });
    this.positionSprite1 = new THREE.Mesh( new THREE.PlaneGeometry( 1, 1 ), this.positionTextureMaterial1);
    this.positionSprite1.position.set(-1,1,0)
    this.positionSprite1.renderOrder=10;

    this.positionTextureMaterial2 = new THREE.MeshStandardMaterial({map:this.positionTextureB });

    this.positionSprite2 = new THREE.Mesh( new THREE.PlaneGeometry( 1, 1 ), this.positionTextureMaterial2);
    this.positionSprite2.position.set(0,1,0)
    this.positionSprite2.renderOrder=10;

    this.positionTextureMaterial3 = new THREE.MeshStandardMaterial({map:this.positionTextureC });

    this.positionSprite3 = new THREE.Mesh( new THREE.PlaneGeometry( 1, 1 ), this.positionTextureMaterial3);
    this.positionSprite3.position.set(1,1,0)
    this.positionSprite3.renderOrder=10;*/


    this.particlePositionPlane = new THREE.Mesh( new THREE.PlaneGeometry( 2, 2 ), particlePositionMaterial);
    this.particlePositionScene = new THREE.Scene();
    this.particlePositionScene.add(this.particlePositionPlane);
    this.particlePositionCamera = new THREE.Camera();

    this.stateData = [];
    this.addedQueue = [];

    this.writeCursor = 0;
    for(var i = 0; i < this.maxParticles; i++){

      this.stateData.push([0,0,0,0]);
    }

  }

  updatePosition(elapsedTime){



    this.particlePositionMaterial.uniforms.currentTime.value = this.currentTime;
    this.particlePositionMaterial.uniforms.elapsedTime.value = elapsedTime;

    var renderTarget = new THREE.WebGLRenderTarget(this.textureWidth, this.textureWidth);
    renderTarget.texture.dispose();
    renderTarget.texture = this.positionTextureA;

    this.particlePositionMaterial.uniforms.positionTextureB.value = this.positionTextureB;
    this.particlePositionMaterial.uniforms.positionTextureC.value = this.positionTextureC;




    const currentRenderTarget = this.renderer.getRenderTarget();
    this.renderer.setRenderTarget(renderTarget);
    this.renderer.clear();
    this.renderer.render(this.particlePositionScene, this.particlePositionCamera);
    this.renderer.setRenderTarget(currentRenderTarget);




    var tmp = this.positionTextureC;
    this.positionTextureC = this.positionTextureB;
    this.positionTextureB =  this.positionTextureA;
    this.positionTextureA= tmp;

  }
  think(elapsedTime, camera){
    this.currentTime += elapsedTime;

    while (this.addedQueue.length > 0){
      var particle = this.addedQueue.shift();
      var index = this.writeCursor;
      this.writeParticle(index, particle, elapsedTime);
      this.writeCursor = (this.writeCursor + 1) % this.maxParticles;

    }

    this.particleRenderObject.material.uniforms.currentTime.value = this.currentTime;

    this.updatePosition(elapsedTime);
    this.particleRenderObject.material.uniforms.positionTexture.value = this.positionTextureA;


  }

  add(particle) {
    //allow maximum 5000 particles added per frame
    if (this.addedQueue.length < 4999) {
      this.addedQueue.push(particle);
    }
  }

  /***********
  *
  *  particle - an object with the following properties
  *    - Vector3 position
  *    - Vector3 velocity
  *    - float lifetime
  *    - float size
  *    - float type
  *      #define TYPE_NONE 0.0
  *      #define TYPE_SPARK 1.0
  *      #define TYPE_BLAST 2.0
  *      #define TYPE_MASS_SPARK 3.0
  *      #define TYPE_CONFETTI 4.0
  *    - float[4] color
  *
  ***********/
  writeParticle(index, particle, elapsedTime){

    //write particle
    //https://bitbucket.org/tryba/planettycoon/src/2d1e2b6448001f04e6abbbd7728ae479bd64e87c/GalaxyHustle/ParticleSystem.cpp#lines-91
    if(this.currentTime < 0){
      return;
    }
    const gl = this.renderer.getContext();

    elapsedTime = Math.min(0.03, elapsedTime);
    var x = Math.floor(index % this.textureWidth);
    var y = Math.floor(index / this.textureWidth);
    var state = [
      this.currentTime,
      particle.lifetime,
      particle.size,
      particle.type
    ];
    this.stateData[index] = state;
    const stateTextureId = this.renderer.properties.get( this.stateTexture ).__webglTexture;
    gl.bindTexture(gl.TEXTURE_2D, stateTextureId);
    gl.texSubImage2D(gl.TEXTURE_2D, 0, x, y, 1, 1, gl.RGBA, gl.FLOAT, new Float32Array(state), 0);

    const colorTextureId = this.renderer.properties.get( this.colorTexture ).__webglTexture;
    gl.bindTexture(gl.TEXTURE_2D, colorTextureId);
    gl.texSubImage2D(gl.TEXTURE_2D, 0, x, y, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, new Uint8Array(particle.color), 0);

    //var position = [particle.position.x, particle.position.y, particle.position.z, 0.0];
    //var positionTextureId = this.renderer.properties.get( this.positionTextureA ).__webglTexture;
    //gl.bindTexture(gl.TEXTURE_2D, positionTextureId);
    //gl.texSubImage2D(gl.TEXTURE_2D, 0, x, y, 1, 1, gl.RGBA, gl.FLOAT, new Float32Array(position), 0);

    var deltaPos = particle.velocity.clone().multiplyScalar(elapsedTime);
    var pos = particle.position.clone().sub(deltaPos);
    var position = [pos.x, pos.y, pos.z, 0.0];
    var positionTextureId = this.renderer.properties.get( this.positionTextureB ).__webglTexture;
    gl.bindTexture(gl.TEXTURE_2D, positionTextureId);
    gl.texSubImage2D(gl.TEXTURE_2D, 0, x, y, 1, 1, gl.RGBA, gl.FLOAT, new Float32Array(position), 0);

    pos.sub(deltaPos);
    position = [pos.x, pos.y, pos.z, 0.0];
    positionTextureId = this.renderer.properties.get( this.positionTextureC ).__webglTexture;
    gl.bindTexture(gl.TEXTURE_2D, positionTextureId);
    gl.texSubImage2D(gl.TEXTURE_2D, 0, x, y, 1, 1, gl.RGBA, gl.FLOAT, new Float32Array(position), 0);
    gl.bindTexture(gl.TEXTURE_2D, null);


  }

  static initializeSquareTexture(width, type=THREE.FloatType){
     var data = null;
     if(type == THREE.FloatType){
      data = new Float32Array(4 * width * width);
     } else if (type == THREE.UnsignedByteType){
      data = new Uint8Array( 4 * width * width );
     }

     const texture = new THREE.DataTexture( data, width, width, THREE.RGBAFormat, type );

     texture.minFilter = THREE.NearestFilter;
     texture.magFilter = THREE.NearestFilter;
     texture.needsUpdate = true;
     return texture;
  }

  static async build(renderer, maxParticles){
    const textureWidth = Math.ceil(Math.sqrt(maxParticles));

    const randomTexture = new NoiseTexture().generate(128, 128);

    const positionTextures = [
      this.initializeSquareTexture(textureWidth),
      this.initializeSquareTexture(textureWidth),
      this.initializeSquareTexture(textureWidth),
    ];

    for(var tex of positionTextures){
      renderer.initTexture(tex);
    }

    const stateTexture = this.initializeSquareTexture(textureWidth);
    renderer.initTexture(stateTexture);
    const colorTexture = this.initializeSquareTexture(textureWidth, THREE.UnsignedByteType);
    renderer.initTexture(colorTexture);
    const velocityTexture = this.initializeSquareTexture(textureWidth);
    renderer.initTexture(velocityTexture);

    const particleTextures = new THREE.TextureLoader().load("/particle_textures.png");

    const particlePositionMaterial = new THREE.ShaderMaterial( {
        uniforms: {
            volumeMass: {value: 0.1},
            textureWidth: {value: textureWidth},
            textureHeight: {value: textureWidth},
            currentTime: {value: 0.0},
            elapsedTime: {value: 0.0},
            volumePosition: {value: [0.0, 0.0, 0.0]},
            positionTextureB: {value: positionTextures[1]},
            positionTextureC: {value: positionTextures[2]},
            //velocityTexture: {value: velocityTexture}
            stateTexture: {value: stateTexture},
            randomTexture: {value: randomTexture},
        },
        vertexShader: await fetchFile("/shaders/particlePosition.vertex"),
        fragmentShader: await fetchFile("/shaders/particlePosition.fragment")
    } );

      const particleRenderVertexShader = await fetchFile("/shaders/particleRender.vertex");
      const particleRenderFragmentShader = await fetchFile("/shaders/particleRender.fragment");

      const spark = new THREE.TextureLoader().load( '/particle_textures.png');
      var screenDimensions = new THREE.Vector2();
      renderer.getSize(screenDimensions);
      const particleRenderMaterial = new THREE.ShaderMaterial( {

      uniforms: {
        pointTexture: { value: spark },
        positionTexture: {value: positionTextures[1]},
        colorTexture: {value: colorTexture},
        stateTexture: {value: stateTexture},
        currentTime: {value: 0.0},
        screenWidth: {value: screenDimensions.x},
        screenHeight: {value: screenDimensions.y}

      },
      vertexShader: particleRenderVertexShader,
      fragmentShader: particleRenderFragmentShader,

      blending: THREE.AdditiveBlending,
      depthTest: true,
      depthWrite: false,
      transparent: true,
      side: THREE.DoubleSide,

    } );


    const radius = 20;

    var geometry = new THREE.BufferGeometry();


    const sizes = [];


    var particles = 10000;
    const references = new THREE.BufferAttribute(new Float32Array(particles * 2), 2);
    for ( let i = 0; i < particles; i ++ ) {
      sizes.push( Math.random() * 2.0 );

      var x = (i%textureWidth)/textureWidth;
      var y = Math.floor(i/textureWidth)/textureWidth;
      references.array[i*2] = x;
      references.array[i*2+1] = y;

    }

    geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( new Float32Array(particles * 3), 3 ) );
    geometry.setAttribute( 'size', new THREE.Float32BufferAttribute( sizes, 1 ).setUsage( THREE.DynamicDrawUsage ) );
    geometry.setAttribute( 'reference', references);

    var particleRenderObject = new THREE.Points( geometry, particleRenderMaterial );
    particleRenderObject.frustumCulled = false

    const result = new ParticleSystem(renderer, textureWidth, maxParticles, positionTextures, stateTexture, colorTexture, particlePositionMaterial, particleRenderObject);
    return result;
  }
};