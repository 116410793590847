import styled from 'styled-components';

export const MintDialogStyled = styled.div`

  padding: 10px;
  width: 65vmin;
  height: 65vmin;
  max-width: 300px;
  max-height: 300px;
  border-radius: 10px 10px 10px 10px;
  border: 2px solid #0f0;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .vox-icon {
    width: 1rem;
  }
  .matic-icon {
    width: 1rem;
  }
`;