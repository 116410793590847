import * as THREE from 'three';
import {fetchFile, disposeObject} from './libs/utils'

export class Gate extends THREE.Mesh {
  constructor(object, position, size, solarSystem){
    super();
    this.copy(object);
    disposeObject(object);
    this.position.set(...position).multiplyScalar(20);
    this.pos = position;
    this.scale.set(size, size, size);
    this.size = size;
    this.baseSize = size;
    this.solarSystem = solarSystem;

    this.isHovering = false;
    this.hoverActive = false;
    this.frustrumCulled = false;
    this.isInteractable = true
    this.name = "Gate";
  }

  async click(mouse, api){
    return {
      navigateItem: {
        url: `/app/solarsystems/${this.solarSystem.getId()}`
      }
    };
  }

  think(deltaTime, currentTime, camera){
    if(this.isHovering && this.size < this.baseSize * 4.0){
      this.size = this.size + this.baseSize * 2.0 * deltaTime;
      this.scale.set(this.size, this.size, this.size);
    } else if(!this.isHovering && this.size > this.baseSize){
      this.size = this.size - this.baseSize * 4.0 * deltaTime;
      this.scale.set(this.size, this.size, this.size);
    }

    this.material.uniforms.time.value = currentTime;
    this.quaternion.copy(camera.quaternion);

  }

  static async buildFromJson(defn, params){

    const {destination, canvas} = params;
    const vertexShader = await fetchFile("/shaders/gate.vertex");
    const fragmentShader = await fetchFile("/shaders/gate.fragment");

    var geometry = new THREE.PlaneGeometry( 2, 2 );
    var material = new THREE.ShaderMaterial( {
         uniforms: {
           lightColor: {value: [1,1,1,1]},
           screenWidth: {value: canvas.clientWidth},
           screenHeight: {value: canvas.clientHeight},
           hover: {value: false},
           time: {value: 0.0},
         },
         vertexShader: vertexShader,
         fragmentShader: fragmentShader
     } );

    material.blending = THREE.CustomBlending;
    material.blendEquation = THREE.AddEquation; //default
    material.blendSrc = THREE.SrcAlphaFactor; //default
    material.blendDst = THREE.OneMinusSrcAlphaFactor;
    material.depthWrite = false;
    material.transparent = true;
    const object = new THREE.Mesh(geometry, material);

    return new Gate(object, defn.position, defn.size, destination);
  }
}

