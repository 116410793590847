import {ShipyardStyled} from './ShipyardStyled'
import {MintDialogStyled} from './MintDialogStyled'
import {MintedDialogStyled} from './MintedDialogStyled'
import {ReactComponent as VoxIcon} from './assets/vox_crystal_icon.svg'
import {ReactComponent as MaticIcon} from './assets/polygon_token.svg'
import { drizzleReactHooks } from '@drizzle/react-plugin';
import {Button, Box, Link, Dialog, DialogContent, DialogActions} from '@mui/material'
import {useState, useEffect} from 'react'
import InfoIcon from '@mui/icons-material/Info';
import ReportIcon from '@mui/icons-material/Report';
import { useTheme } from '@mui/material/styles';
import {onAction} from './hooks/useTransactionListener'
import {api} from './apis/api'
import web3 from 'web3'
import Ship from './Ship'
import { call, put, take, takeLatest } from 'redux-saga/effects'


const toBN = (val) => val && web3.utils.toBN(val);

export default function Shipyard(props){

  const { drizzle, useCacheCall, useCacheSend } = drizzleReactHooks.useDrizzle();
  const [shipTokenId, setShipTokenId] = useState();

  const [shipMetadata, setShipMetadata] = useState();
  const [mintDialogOpen, setMintDialogOpen] = useState(false);
  const [mintedDialogOpen, setMintedDialogOpen] = useState(false);

  if(!mintedDialogOpen){
    console.log("WILL NOT DISPLAY MINTED DIALOG!!!")
  }

  const drizzleState =  drizzle?.store?.getState?.();
  const account = drizzleState?.accounts?.[0];
  const maticBalance = toBN(drizzleState.accountBalances?.[account]);
  const voxBalance = toBN(useCacheCall("VOXToken", "balanceOf", account));
  const voxCost = toBN(useCacheCall("Fleet", "getMintCostVox"));
  const maticCost = toBN(useCacheCall("Fleet", "getMintCost"));
  const { send: mintNext, TXObjects: mintNextTx} = useCacheSend('Fleet', 'mintNext');
  const [mintCounter, setMintCounter] = useState(0);

  const weiToEth = amount => amount ? Math.trunc(drizzle.web3.utils.fromWei(amount) * 1000)/1000 : '-';

  const sufficientVox =  voxCost && voxBalance?.gte(voxCost);
  const sufficientMatic = maticCost && maticBalance?.gte(maticCost);
  const sufficientFunds = sufficientMatic || sufficientVox;

  const theme = useTheme();
  useEffect(()=>{

    if(mintNextTx.length > mintCounter){
      const txResult = mintNextTx[mintNextTx.length - 1];
      if(txResult){
        console.log("MINT NEXT TX", txResult);
        if(txResult.status === "success" ){
          setShipTokenId(txResult.receipt.events.Transfer[0].returnValues.tokenId);
          setMintedDialogOpen(true);
          setMintCounter(mintCounter +1)
          console.log("DISPLAY MINTED DIALOG!!!")
        }
      }
    }
  },[mintNextTx]);

  useEffect(()=>{
    if(shipTokenId){
      const run = async () => {
        const meta = await api.ships.get(shipTokenId);
        const hue = meta.attributes.find((x) => {return x.trait_type == "Space Hue"});
        //setSpaceColor(`hsl(${Math.floor(hue.value)},100%,50%)`)
        setShipMetadata(meta);
      };
      run();
    }
  },[shipTokenId])

  const mintShipVox = async () =>{
    mintNext(account);
  };
  const mintShipMatic = async () =>{
    mintNext(account, {value: maticCost});
  };


  return (
    <ShipyardStyled>
      <div className={"row " + (shipTokenId && "select-operator")}>
        <Box className="ship-container" bgcolor="info.main" >
          <img className="ship-avatar" src={shipMetadata ? shipMetadata.image : "/ships.gif"}/>
        </Box>
        <Button className="mint-button" variant="contained" color="info" onClick={() => setMintDialogOpen(true)}>Purchase Refurbished Ship</Button>
      </div>

      <Dialog open={mintDialogOpen} className="mint-dialog" PaperComponent="div" scroll="paper" fullWidth={true} onClick={() => setMintDialogOpen(false)}>
        <DialogContent style={{justifyContent: "center", display: "flex"}}>
          <MintDialogStyled>
            {
              sufficientFunds ?
              <><h2>Refurbish a Salvage Ship</h2><p><InfoIcon color="info"/>Ships may be financed in either VOX or MATIC</p></> :
              <><h2><ReportIcon color="error"/>Insufficient funds.</h2><p> Move either MATIC or VOX into your account to purchase a ship.</p></>
            }
            <Button variant='contained' disabled={!sufficientVox} onClick={mintShipVox}>Pay {weiToEth(voxCost)} VOX</Button>
            <Button variant='contained'disabled={!sufficientMatic} onClick={mintShipMatic}>Pay {weiToEth(maticCost)} MATIC</Button>
          </MintDialogStyled>
        </DialogContent>
      </Dialog>

      <Dialog open={mintedDialogOpen} className="minted-dialog" PaperComponent="div" scroll="paper" onClick={() => {
        setMintedDialogOpen(false);
        console.log("DO NOT DISPLAY MINTED DIALOG!!!")

      }} PaperProps={{

         style: {
           width: '80%',
           maxWidth: '1080px',
           position: 'absolute',
           top: 0,
           height: '100%',
         },
      }} >
        <DialogContent style={{display:'flex', justifyContent:'center'}}>
          <MintedDialogStyled>
            <h2>Refurbished Ship Purchased!</h2>
            <p>Would you look at that? This ship's as good as new!</p>
            <p>
              (Just so we're clear, from a legal perspective, I'm only implying that this ship is "emotionally" as good as new.
            </p>
            <p>
               In reality, this ship is quite old, and your warranty clearly states that this establishment, Slig's Salvage Ships, is
               released from any and all liability up to and including the violent loss of the lives of yourself and your crew).</p>
            <Ship id={shipTokenId}/>
          </MintedDialogStyled>
        </DialogContent>
      </Dialog>
    </ShipyardStyled>
  );

}