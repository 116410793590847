import axios from 'axios'
import * as planets from './planets'
import * as universe from './universe'
import * as pilots from './pilots'
import * as ships from './ships'
import {TrustedSigner} from './trustedSigner'
import {LocalAreas} from './localAreas'
import {Characters} from './characters'
import {SolarSystems} from './solarSystems'
import {Artifacts} from './artifacts'
import {AuthContext} from '../Web3Container'
import {useContext} from 'react'

export const api = {
  planets,
  universe,
  pilots,
  ships,
  trustedSigner: new TrustedSigner(),
  localAreas: new LocalAreas(),
  characters: new Characters(),
  solarSystems: new SolarSystems(),
  artifacts: new Artifacts(),
}

export const useApi = () => {
  const authContext = useContext(AuthContext);

  return {
    planets,
    universe,
    pilots,
    ships,
    trustedSigner: new TrustedSigner(authContext),
    localAreas: new LocalAreas(authContext),
    characters: new Characters(authContext),
    solarSystems: new SolarSystems(authContext),
    artifacts: new Artifacts(authContext),
  };
}
