
import {Link} from '@mui/material'
import TwitterIcon from '@mui/icons-material/Twitter';

const LOCAL_CHARACTERS = {
  '3b6d2d5b-29d9-4456-a9b8-2552c853ac0c': {
    characters: [{
      "id": "slig",
      "modelFilename": "models/portShip/mesh.obj",
      "textureFilename": "models/portShip/albedo2.png",
      "orbit": {
       "center": [0.0, 0.0, 0.0],
       "speed": [-0.05, -0.05, 0.0],
       "offset": [4.0, 4.0, 0.0],
       "size": [11.0, 11.0, 0.0]
      },
      "nozzles": [{
        "position": [1.3, -0.15, -0.15],
        "velocity": [0.0, -1.5, 0.0],
        "color": [1.0, 0.0, 1.0, 1.0],
        "size": 0.45,
        "lifetime": 0.3,
        "period": 0.1
      },{
        "position": [-1.35, -0.15, -0.15],
        "velocity": [0.0, -1.5, 0.0],
        "color": [1.0, 0.0, 1.0, 1.0],
        "size": 0.45,
        "lifetime": 0.3,
        "period": 0.1
      }]
    }]
  },
  '90c7c855-8354-48b5-a203-1926803b9e5d':{
       "characters": [{
         "id": "bushka",
         "modelFilename": "models/flyingSaucer/flying_saucer.obj",
         "textureFilename": "models/flyingSaucer/flying_saucer.png",
         "orbit": {
           "center": [0, 0, 0],
           "speed": [0.1, 0.1, 0.1],
           "offset": [0,0,0],
           "size": [10, 10, 0]
         },
         "nozzles": [{
           "position": [0.9, 0.0, -0.5],
           "velocity": [0.0, 0.0, -1.0],
           "color": [1.0, 0.0, 1.0, 1.0],
           "size": 0.25,
           "lifetime": 0.3,
           "period": 0.1
         },{
           "position": [-0.9, 0.0, -0.5],
           "velocity": [0.0, 0.0, -1.0],
           "color": [1.0, 0.0, 1.0, 1.0],
           "size": 0.25,
           "lifetime": 0.3,
           "period": 0.1
         },{
           "position": [0.0, 0.9, -0.5],
           "velocity": [0.0, 0.0, -1.0],
           "color": [1.0, 0.0, 1.0, 1.0],
           "size": 0.25,
           "lifetime": 0.3,
           "period": 0.1
         },{
           "position": [0.0, -0.9, -0.5],
           "velocity": [0.0, 0.0, -1.0],
           "color": [1.0, 0.0, 1.0, 1.0],
           "size": 0.25,
           "lifetime": 0.3,
           "period": 0.1
         }]
       }]
     }

};
const BUSHKA_ATTRIBUTES = [{
   "trait_type": "Species",
   "value": "Caterpillar"
 },
 {
   "trait_type": "Bioreactor",
   "value": "Bushka"
 },

 {
    "trait_type": "Manipulators",
    "value": "Bushka"
 },
 {
    "trait_type": "Communications",
    "value": "Bushka"
 },
 {
    "trait_type": "Processing Unit",
    "value": "Bushka"
 },

 {
    "trait_type": "Aural Hue",
    "value": 120
 },
];

const SLIG_ATTRIBUTES = [{
   "trait_type": "Species",
   "value": "Wormhair"
 },
 {
   "trait_type": "Bioreactor",
   "value": "Slig"
 },
 {
   "trait_type": "Processing Unit",
   "value": "Slig"
 },
 {
   "trait_type": "Optical Sensors",
   "value": "Slig"
 },
 {
   "trait_type": "Communications",
   "value": "Slig"
 },
 {
   "trait_type": "Aural Hue",
   "value": 180
 },
];
const LOCAL_INTERACTIONS = {
  slig: {
    displayItem:{
      speech: {
        headshots: {
          name: "Slig",
          slig:{
            attributes: SLIG_ATTRIBUTES,
          }
        },
        paragraphs: [
          {
            headshot: 'slig',
            text: "Welcome to my humble shipyard! You look like someone who's ready to make the best business investment of your life."
          },
          {
            headshot: 'slig',
            text: "Planetary Salvage Professionals know that the universe is full of riches just waiting to be exploited."
          },
          {
             headshot: 'slig',
             text: ". . . "
          },
          {
              headshot: 'slig',
              text: 'I mean, "developed!"'
          },
          {
             headshot: 'slig',
             text: 'Sure, some people wind up destitute, indentured, or dead, but entrepreneurship isn\'t for everyone.'
          },
          {
            headshot: 'slig',
            text: "Unfortunately, our stock isn't what it used to be. Supply chain issues, inflation. I'm sure you get it. We can get one of these classic refurbished ships . . ."
          },
          {
            headshot: 'slig',
            text: "Eventually . . ."
          },
          {
            headshot: 'slig',
            text: "Check back in and we'll get you up and running. I'm sure you'll be directing a whole fleet in no time!"
          },
        ]
      }

    }
  },
  bushka: {
    displayItem:{
      speech: {
        headshots: {
          name: "Bushka",
          bushka:{
            attributes: BUSHKA_ATTRIBUTES,
          }
        },
        paragraphs: [
          {
            headshot: "bushka",
            text: "I've got BIG PROBLEMS. And I've got LOTS of them!"
          },
          {
            headshot: "bushka",
            text: "You might not know this, but I have 200 feet. Not one. Not two. TWO HUNDRED!"
          },
          {
            headshot: "bushka",
            text: "What's wrong with 200 feet you ask? Well, you might also not know that I have poor circulation. That means my little toesie-wosies get sooo cold! I need to wear sockie-wockies on each and every footsie-wootsie if I want to be warm and cozy."
          },
          {
            headshot: "bushka",
            text: "Yesterday, I walked right through a puddle, and all of my FUZZY-WUZZIE sockie-wockies got wet. So, I did what anyone with wet socks would do. I put them in the dryer."
          },
          {
            headshot: "bushka",
            text: "This is where my BIG PROBLEM started. I use a Wormhole brand dryer. It uses the fabric of space time to make sure the fabric of my sockie-wockies are all dry and toasty."
          },
          {
            headshot: "bushka",
            text: "But something went TERRIBLY WRONG! My dryer devoured my socks! My guess is that they're travelling through a wormhole RIGHT NOW."
          },
          {
            headshot: "bushka",
            text: "I'm miserable without my sockie-wockies. I can't bear to live with these chilly toesies for long..."
          },
          {
            headshot: "bushka",
            text: "I hope they pop out somewhere soon!"
          }


        ]
      }

    }
  }
};

const description = <>
  <p>Galaxy Hustle is a game about salvaging what you can.</p>
  <p>Manage your salvage ship and its resources wisely. Terraform living, evolving planets. Defend yourself from space gerbils, na'er-do-wells, and corporate behemoths. Be helpful, make interesting friends, and move up in the world.</p>
  <p>Or, asphyxiate and die alone in the cold darkness of the Galaxy.</p>
  <p>If you stay alive long enough, you might find that everyone's got bigger problems than just paying the bills.</p>

  <p>Available on PC and Macintosh computers.</p>
  <Link target="_blank" href="//twitter.com/galaxyhustle"><TwitterIcon/></Link>
</>;

export {
  LOCAL_CHARACTERS,
  LOCAL_INTERACTIONS,
  description,
};