import {TextField, Button, Stack} from "@mui/material";
import {useState} from "react";
import { useTheme } from '@mui/material/styles';

function SubmitButton(props) {
    const enabled = props.enabled || false;
    const handleClick = props.handleClick;
    const buttonText = props.buttonText || "Submit";

    const getClasses = () => {
        let classes = "submit btnBlock ";
        classes += (enabled) ? "enabled" : "disabled";
        return classes;
    }

    const opts = {};
    if (!enabled) {
        opts['disabled'] = true;
    }

    return (
        <Button
            variant="contained"
            type="submit"
            className={getClasses()}
            onClick={handleClick}
            {...opts}
        >{buttonText}</Button>
    )
}

function NewsletterInputs(props) {
    const theme = useTheme();
    const email = props.email;
    const inputName = props.inputName;
    const submitEnabled = props.submitEnabled;
    const handleChange = props.handleChange;
    const handleSubmit = props.handleSubmit;
    const isSubmitting = props.isSubmitting;


    return (
        <div style={{marginTop:"50px", marginBottom:"50px"}}>
            <h2 style={{color: theme.palette.secondary.main}}>Join the genesis mintlist</h2>

            <Stack alignItems="center" direction="row" spacing={2} className="newsletterInputs">
                <TextField
                    style={{width:"70%", maxWidth:"512px"}}
                    name={inputName}
                    placeholder="email address"
                    value={email}
                    onChange={handleChange}
                />

                <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                    <input type="text"
                           name="b_4b5e01746364774b906bc6e11_4dfcd92c5d"
                           value=""
                           readOnly='readOnly'
                           tabIndex="-1"
                    />
                </div>

                <div className="optionalParent">
                    <div className="clear foot">

                        <SubmitButton
                            enabled={submitEnabled}
                            isSubmitting={isSubmitting}
                            buttonText="Sign up"
                            handleClick={() => {
                                if (submitEnabled) {
                                    handleSubmit();
                                }
                            }}
                        />
                    </div>
                </div>
            </Stack>
        </div>
    )
}

function ThankYouMessage() {
    return (
        <div>
            <h3>Thank you</h3>
            <p>Stay tuned for updates.</p>
        </div>
    )
}


export function EmailSignup() {
    const formId = "emailSignup";
    const [email, setEmail] = useState('');
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const inputName = "EMAIL";

    const setFormState = (enabled) => {
        setSubmitEnabled(enabled);
    }

    const onEmailChange = (event) => {
        const val = event.target.value;
        setEmail(val);
        const isEmailValid = /\S+@\S+\.\S+/.test(val);
        setFormState(isEmailValid);
    }

    const onSubmit = () => {
        setIsSubmitting(true);
        setSubmitSuccess(true);
        document.getElementById(formId).submit();
    }

    return (
        <div id="mc_embed_signup">
            <form
                action="https://xyz.us14.list-manage.com/subscribe/post?u=4b5e01746364774b906bc6e11&amp;id=4dfcd92c5d"
                method="post"
                target="_blank"
                id={formId}
            >
                {!submitSuccess
                    ? <NewsletterInputs
                        email={email}
                        inputName={inputName}
                        handleChange={onEmailChange}
                        submitEnabled={submitEnabled}
                        isSubmitting={isSubmitting}
                        handleSubmit={onSubmit}
                    />
                    : <ThankYouMessage/>
                }
            </form>
        </div>
    )
}
