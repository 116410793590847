import './SolarSystem.css'
import { useNavigate, useOutletContext } from "react-router-dom";
import * as proto from './protos/universe_pb'
import {api} from './apis/api'
import {useState, useEffect, useRef, useContext} from 'react';
import * as THREE from "three";
import {SkyTexture} from './libs/skyTexture'
import {Interactable} from './Interactable'
import {LocalArea} from './LocalArea.js'
import {AuthContext} from './Web3Container'

const raycaster = new THREE.Raycaster();

export default function SolarSystem(props) {
  const {web3OptedIn} = useContext(AuthContext);
  const navigate = useNavigate();
  const {
    universe,
    solarSystem,
    localAreas,
    skyTexture,
    scene,
    sky,
    sunSphere,
    camera,
    canvas,
    mouse,
    renderer,
    particleSystem,
    composer,
  } = useOutletContext();

  var hoverId = null;
  const cameraPosition = new THREE.Vector3(0,0,5);
  const cameraStartDistance = cameraPosition.length();

  const frameId = useRef();
  const prepareFrame = useRef();

  useEffect(()=>{
    if(!canvas || !camera || !sunSphere || !renderer ){
      return;
    }
    console.log("SOLAR SYSTEM biggie")

    var mousePos = [0,0];
    var isDragging = false;
    var orientation = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1,0,0), Math.PI * 0.5 );
    const removeInteractableCallbacks = Interactable(canvas, orientation, cameraPosition, mouse, function(mousePos){
      const hoverList = scene.children.filter(x => x.isHovering);
      if(hoverList.length > 0){
        hoverList[0].click(mousePos, api).then(interactionResult => {
          /*if( interactionResult?.displayItem ){
            setCharacterDisplay(interactionResult.displayItem);
            cameraFocusObject = hoverList[0];
          } else */if(interactionResult?.navigateItem){
            if(web3OptedIn){
              navigate(interactionResult.navigateItem.url)
            } else {
              navigate(interactionResult.navigateItem.url.replace("app", "home"))
            }
          }
        });
      }
      return true;
    }, function(event, ratio){

      cameraPosition.z = cameraPosition.z * ratio;
      if(cameraPosition.z < 1){
          cameraPosition.z = 1;
      }
      else if(cameraPosition.z > 15){
          cameraPosition.z = 15;
      } else {
        event.preventDefault();
      }
    });

    const clock = new THREE.Clock();
    var animate = function () {
      var deltaTime = clock.getDelta();
      var currentTime = clock.getElapsedTime();
      frameId.current = requestAnimationFrame( animate );
      //volumeSphere.rotation.y += 0.005;
      //sunDirection.applyAxisAngle(up, -0.005);

      camera.position.copy(cameraPosition);
      camera.position.applyQuaternion(orientation);
      camera.setRotationFromQuaternion(orientation);
      sunSphere.quaternion.copy( orientation );
      if(sky){
        sky.position.copy(camera.position);
      }

      raycaster.setFromCamera( mouse, camera );

      const interactable = scene.children.filter(x => x.isInteractable);
      const hoverList = raycaster.intersectObjects( interactable );

      for(let obj of interactable){
        obj.isHovering = false;
      }
      //setCurrentGate(null);

      var interactableHovering = false;
      if(hoverList.length > 0){
        document.body.style.cursor = "pointer";

        if(hoverList[0].object.isInteractable){
          hoverList[0].object.isHovering = true;
          if(hoverList[0].object.name == "Gate"){
            //setCurrentGate(hoverList[0].object);
          }
        } else if(hoverList[0].object.parent.isInteractable){
          hoverList[0].object.parent.isHovering = true;
        }

        interactableHovering = true;
      } else {
        document.body.style.cursor = "default";
      }

      for(let i = 0; i < scene.children.length; i ++ ){
        const child = scene.children[i];
        child.think && child.think(deltaTime, currentTime, camera );
      }


      renderer.render( scene, camera );
    };
    animate();
    return () =>{
      removeInteractableCallbacks();
      if(frameId){
        console.log("SOLAR SYSTEM Cancel animation frame!")
        cancelAnimationFrame(frameId.current);
      }
    }
  },[canvas, renderer, camera, sunSphere]);

  return (
    <div className="SolarSystem">

    </div>
  );
}
