import * as proto from './protos/universe_pb'
import * as THREE from "three";
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader'
import {OrbitingEntity} from './OrbitingEntity'



export class Asteroid extends OrbitingEntity {

  emitParticles(elapsedTime, fwd, up, right){
    if(this._particleSystem){
      var backward = fwd.clone().multiplyScalar(-1.0 * elapsedTime);
      for(let i = 0; i < 10; i++){
      var displacement = new THREE.Vector3(Math.random()-0.5,Math.random()-0.5, Math.random()-0.5).normalize();
              displacement.multiplyScalar(Math.random()*0.08);

        var vel = backward.clone().multiplyScalar(Math.random() * 2.0).sub(displacement);

        var pos = this.position.clone().add(displacement);
        this._particleSystem.add({
          position: pos,
          velocity: vel,
          lifetime: 0.3 + Math.random() * 0.7,
          size: 0.4 + Math.random() * 0.4,
          type: 3.0,
          color: [200 + Math.random()*55, Math.random()*64.0, Math.random()*255, 255.0],
        });
      }


    }
  }

  static async build(proto, params={}){

    console.log("Building Asteroid");
    const object = await this.buildObject(proto);
    const result = new Asteroid(object, proto, params);

    return result;
  }

}