import * as THREE from 'three';
import {fetchFile, disposeObject} from './libs/utils'

export const ALIGNMENT_FRIENDLY = 0;
export const ALIGNMENT_NEUTRAL = 1;
export const ALIGNMENT_HOSTILE = 2;

export class Fleet extends THREE.Points {
  constructor(object, alignment, count, particleAxes, particleSpeeds){
    super();
    this.copy(object);
    disposeObject(object);

    this.name = "Gate";
    this.alignment = alignment;
    this.count = count;
    this.axes = particleAxes;
    this.speeds = particleSpeeds;
    this.frustumCulled = false;
    this.isInteractable = true;
    this.isHovering = false;
  }

  async click(mouse, api){

  }

  think(deltaTime, currentTime, camera){

    if(deltaTime <= 0){
      return;
    }
    this.quaternion.multiply(new THREE.Quaternion().setFromAxisAngle( new THREE.Vector3(0,1,0), 3.1415 * deltaTime/120.0))

    const positions = this.geometry.attributes.position.array;
    const velocity = this.geometry.attributes.velocity.array;
    for(let i = 0; i < this.count * 3; i+=3){

      velocity[i] = Math.sin(currentTime * this.speeds[ i ])  * this.axes[ i ];
      velocity[i + 1] = Math.sin(currentTime * this.speeds[ i + 1 ]) * this.axes[ i + 1 ];
      velocity[i+2] = Math.cos(currentTime * this.speeds[ i + 2 ]) * this.axes[ i + 2 ];
      positions[ i ] += velocity[i] * 0.0005;
      positions[ i + 1 ] += velocity[i+1] * 0.0005;
      positions[ i + 2 ] += velocity[i+2]* 0.0005;

    }
    this.geometry.attributes.position.needsUpdate = true;
    this.geometry.attributes.velocity.needsUpdate = true;

  }

  static async build(alignment, count){

    if(!count || count <= 0){
      return null;
    }

    var axis = new THREE.Vector3(0,-1,0);
    var color = new THREE.Color(0.3, 0.3, 0.3);

    switch(alignment){
      case ALIGNMENT_FRIENDLY:
         axis = new THREE.Vector3(1,0,0);
         color = new THREE.Color(0, 1, 0);
      break;
      case ALIGNMENT_NEUTRAL:
        axis = new THREE.Vector3(0,1,0);
        color = new THREE.Color(0, 1, 1);
      break;
      case ALIGNMENT_HOSTILE:
        axis = new THREE.Vector3(-1,0,0);
        color = new THREE.Color(1, 0, 1);
      break;
      default:
        console.warn("Unrecognized alignment")
    }
    const geometry = new THREE.BufferGeometry();
    const vertices = [];
    const particleAxes = [];
    const speeds = [];
    const velocities = []
    for(let i = 0; i < count; i++){
      const width = Math.sqrt(count/10000);
      if(alignment === ALIGNMENT_FRIENDLY || alignment === ALIGNMENT_HOSTILE){

        const yAngle = (Math.random() - 0.5)*Math.PI*0.8*width;
        const yRatio = Math.sqrt(1.0-(Math.abs(yAngle)/(Math.PI*0.5*0.8)));
        const euler = new THREE.Euler( 0, yAngle, (Math.random() - 0.5)*Math.PI*0.3 * yRatio * width);
        const direction = axis.clone().applyEuler(euler)
        const pos = direction.multiplyScalar(0.75 + Math.random() * yRatio * width);
        vertices.push(pos.x, pos.y, pos.z)
      } else {
        const euler = new THREE.Euler( (Math.random() - 0.5)*Math.PI*0.5*width, 0, (Math.random() - 0.5)*Math.PI*0.5*width );
        const direction = axis.clone().applyEuler(euler)
        const pos = direction.multiplyScalar(0.75 + Math.random() * 0.2 * width);
        vertices.push(pos.x, pos.y, pos.z)
      }

      const particleAxis = 4.0 * (Math.random() - 0.5);
      const particleSpeed = 0.6 + Math.random();
      particleAxes.push(particleAxis, 2.0 * (Math.random() - 0.5), particleAxis);
      speeds.push(particleSpeed, 0.4 + Math.random() , particleSpeed);
      velocities.push(0, 0, particleAxis);

    }
    geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );
    geometry.setAttribute( 'velocity', new THREE.Float32BufferAttribute( velocities, 3 ) );


    const vertexShader = await fetchFile('/shaders/ships.vertex')
    const fragmentShader = await fetchFile('/shaders/ships.fragment')
    const shipTexture = new THREE.TextureLoader().load( '/ship_sprite.png' );
    shipTexture.transparent = true;
    var material = new THREE.ShaderMaterial( {
            uniforms: {
              color: {value: [color.r, color.g, color.b, 1]},
              pointTexture: {value: shipTexture},
            },
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,

            transparent: true,
        } );
    /*var material = new THREE.PointsMaterial({
      color,
      transparent: true,
      //blending: THREE.AdditiveBlending,
      size: 0.01,
    });*/


    const object = new THREE.Points(geometry, material);
    //object.translateOnAxis(axis, 1.0);
    object.renderOrder = 10;

    return new Fleet(object, alignment, count, particleAxes, speeds);
  }
}

