import {PanelStyled} from './PanelStyled';
import {useState, useEffect} from 'react';
import {Box} from '@mui/material'

export default function Panel(props){
  console.log(props)
  return (
    <PanelStyled className={(props.className || '')}width={props.width} height={props.height}>
      <Box className="panel " >
        <div className="glitch-effect" />
        <div className="panel-wrapper">
          {props.children}
        </div>
      </Box>
    </PanelStyled>
  )
}