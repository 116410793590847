import styled from 'styled-components';

export const VoxBalanceRowStyled = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  .vox-icon {
    height: 2rem;
    margin: 0 1rem;

  }

  .vox-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;