import styled from 'styled-components';

export const ShipyardStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;


  .row {
    width: 50%;
    min-width: 300px;
    border-radius: 50vw 0 100vw 50vw;
    border: 2px solid #0f0;
    background-color: #030;
    overflow: hidden;
    display: flex;
    flex-direction: row;

    &.select-operator{
     background-color: #0f0;
   }

    .ship-container {

    }
    .ship-avatar {
      border-radius: 50%;
      height: 6em;
    }

    .mint-button, .operator-button {
      border-radius: 0 0 10em 0;
      height: 100%;
      width: 100%;
      box-shadow: none;
    }

    .mint-button:disabled, .operator-button:disabled{
      background-color: #030;
    }

    .operator-button {
      width: 50%;
    }
  }
  .vox-icon {
    height: 2rem;
    margin: 0 1rem;

  }
`;