
import { useTheme } from '@mui/material/styles';

export default function AwaitingMetamask(props) {
  const status = props.status;
  const theme = useTheme();

  if(status === "initializing"){
    return <div/>
  }

  return <div>
    <div style={{padding:"20px"}}>
      <h2 style={{color: theme.palette.primary.main}}>Complete Wallet Connection</h2>
      <p>Please use the Metamask interface to complete the login process.</p>
      <p>You may need to click on the Metamask plugin icon if it does not display automatically.</p>
    </div>
  </div>
}