import './PlanetMinter.css'
import {Link} from '@mui/material'
import {EmailSignup} from './EmailSignup'
import SpeechDialog from './SpeechDialog'
export default function PlanetMinter(){
  const paragraphs = [
    "Welcome to Quantum Realty and Wealth Services! Do you take your water sparkling or still? Perhaps you'd prefer a methane vapor?",
    "Before we begin, I want you to know that all of our agents exercise the utmost discretion in all of your affairs. We aim to be trusted stewards of your dominion, and that includes issues that may prove too... complex... for other firms.",
    "Our focus is your galactic expansion, and we will stop at nothing to ensure that your holdings are secure and, of course, growing at all costs.",
    "You may have heard of our work in the Bessotian genocide. It was controversial, to be sure, but you can't argue with the results.",
    "I must warn you, however, that the current supply of Planets is... limited. In fact, there are exactly zero celestial bodies available for purchase at this particular moment.",
    "But please — do not fret! If you leave your information, I will contact you promptly when minting begins."
  ];

    return (
      <>

        <SpeechDialog src="quantumbot.png" name="KP-1425G" paragraphs={paragraphs}/>
        <div className="PlanetMinter">
          <h1>Quantum Realty and Wealth Services</h1>
          <p>Our discerning autonomous agents will optimize your assets with no regard for ethics or morality. Relentless acquisition is our sole function.</p>
          <p>Unfortunately, planet minting has not yet begun.</p>
          <p>In the meantime, <Link href="/app/galaxy">explore</Link> the Galaxy.</p>
          <EmailSignup/>
        </div>
      </>
    );
}