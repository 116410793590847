import {useState, useEffect, createContext, useCallback, useRef} from 'react';
import { drizzleReactHooks } from '@drizzle/react-plugin';
import { Drizzle, generateStore, EventActions} from '@drizzle/store';
import drizzleOptions from './constants/drizzleOptions';
import {Button} from '@mui/material'
import AwaitingMetamask from './AwaitingMetamask'

import { useTheme } from '@mui/material/styles';
import { signAuthMessage } from './libs/signer';
import { useSessionStorage, AUTH_KEY } from './hooks/useSessionStorage'
import Web3 from 'web3'
import {setAction} from './hooks/useTransactionListener'

export const AuthContext = createContext({
  account: null,
  auth: null,
  invalidateAuth: () => {},
});

const Web3Container = (props) => {
  const [isConnected, setIsConnected] = useState();
  const drizzleRef = useRef();
  const [drizzle, setDrizzle] = useState();
  const [account, setAccount] = useState();
  const [auth, setAuth] = useSessionStorage(AUTH_KEY, null);
  const theme = useTheme();
  useEffect(()=>{
    window.ethereum.request({ method: 'eth_accounts' }).then(accounts =>{
      if(accounts.length > 0){
        setIsConnected(true);
        setAccount(Web3.utils.toChecksumAddress(accounts[0]));
      } else {
        setIsConnected(false);
      }
    });
  },[]);

  const syncingCallback = useCallback((action, store) => {
    if(action.contract.contractName === 'Fleet'){
      store.dispatch({
        type: "CONTRACT_SYNCING",
        contract: drizzleRef.current.contracts['Galaxy']
      })

    } else if(action.contract.contractName === 'Galaxy'){
      store.dispatch({
        type: "CONTRACT_SYNCING",
        contract: drizzleRef.current.contracts['VOXToken']
      })
    }
  }, [drizzle]);

  useEffect(()=>{
    if(isConnected && !drizzle) {
      //connect to metamask
      const contractErrorNotifier = store => next => action => {
        return next(action)
      }

      const contractEventNotifier = store => next => action => {

        if (action.type === 'CONTRACT_SYNCING'){
          console.log("ACTION SYNCING!!!!!!", store)

          syncingCallback(action, store);
        }
        if (action.type === 'TX_SUCCESSFUL') {
          console.log("TX_SUCCESSFUL")
          setAction(action)
        }

        if (action.type === 'ERROR_CONTRACT_VAR') {
          console.log("ERROR_CONTRACT_VAR")
          const contract = action.name
          const method = action.variable
          const errorMessage = action.error.message
          const display = `${contract}(${method}): ${errorMessage}`

          // interact with your service
          console.log('Contract error fired', display)
        }

        if (action.type === 'WEB3_FAILED') {
          setIsConnected(false);
          setDrizzle(null);
        }
        return next(action)
      }

      const appMiddlewares = [ contractEventNotifier ]

      // create the store
      const store = generateStore({
        drizzleOptions,
        appMiddlewares,
      });

      drizzleRef.current = new Drizzle(drizzleOptions, store);
      setDrizzle(drizzleRef.current);
    }
  }, [isConnected, drizzle]);

  const SignatureProvider = (props) => {
    useEffect(() => {
       if( !auth) {
         const timestamp = new Date().toISOString();
         signAuthMessage(drizzle.web3.currentProvider, timestamp).then( signature => {
          setAuth({ timestamp, signature });
         });
       }
    }, [auth]);

    return null;
  };

  if( isConnected === undefined){
    return <AwaitingMetamask status="initializing"/>;
  }
  if(!isConnected){
    return <div>
            <div style={{padding:"20px"}}>
              <h2 style={{color: theme.palette.primary.main}}>ATTN: Salvage Professionals</h2>
              <p style={{marginBottom: "20px"}}>Galaxy Hustle uses your account on the Polygon network to store game data. Please connect your wallet to continue.</p>
              <p>You may need to click on the Metamask plugin icon if it does not display automatically.</p>
              <Button variant="contained" color="secondary" onClick={()=>{setIsConnected("loading")}}>Connect Wallet</Button>
            </div>
           </div>
  }

  if(!drizzle){
    return <AwaitingMetamask status="initializing"/>;
  }

  return (
    <drizzleReactHooks.DrizzleProvider drizzle={drizzle}>
      <drizzleReactHooks.Initializer
        // Optional `node` to render on errors. Defaults to `'Error.'`.
        error="Error"
        // Optional `node` to render while loading contracts and accounts. Defaults to `'Loading contracts and accounts.'`.
        //loadingContractsAndAccounts="Also still loading."
        // Optional `node`` to render while loading `web3. Defaults to `'Loading web3.'`.
        loadingWeb3={<AwaitingMetamask status={isConnected === true ? "initializing" : "connecting"}/>}
      >
        <SignatureProvider />
        <AuthContext.Provider value={{
          web3OptedIn: true,
          account: account,
          auth: auth,
          invalidateAuth: () => {
            console.log("Invalidating AUTH");
            setAuth(null);
          },
        }}>
          {props.children}
        </AuthContext.Provider>
      </drizzleReactHooks.Initializer>
    </drizzleReactHooks.DrizzleProvider>
  )
}

export default Web3Container;