import './GalaxyMap2d.css'
import {useState, useEffect} from 'react';
import * as proto from './protos/universe_pb'
import {api} from './apis/api'
import {useNavigate} from 'react-router-dom'
import * as THREE from "three";
import {webgl2Support} from "./libs/webglSupport"
import {fetchFile} from "./libs/utils"


export function GalaxyMap2d() {
  const [universe, setUniverse] = useState();
  const [scene, setScene] = useState(new THREE.Scene());

  useEffect( async ()=> {
    const u = await api.universe.get();
    setUniverse(proto.Universe.deserializeBinary(u));
  }, []);

  const SolarSystem = (props) => {
    const [isHovering, setIsHovering] = useState(false);
    const navigate = useNavigate();
    const position = props.solarSystem.getPosition();
    const minDimension = props.dimensions[0];
    const maxDimension = props.dimensions[1];
    const maxSunWidth = 100;
    const systemWidth = getSolarSystemWidth(props.solarSystem);
    const areaWidth = (maxDimension[0] + maxSunWidth -minDimension[0]);

    const sunWidth = Math.min(systemWidth/25, maxSunWidth);
    const halfWidth = sunWidth * 0.5;


    const style = {
      top: 100.0*((position.getY() ) - minDimension[1]) / ((maxDimension[1] + 75 + sunWidth)-minDimension[1]) + "%",
      left: 100.0* ((position.getX()) - minDimension[0]) / (maxDimension[0] + sunWidth -minDimension[0]) + "%",
      width: sunWidth,
      height:sunWidth
    };

    function drawStar(ctx, cx,cy,spikes,outerRadius,innerRadius){
      var rot=Math.PI/2*3;
      var x=cx;
      var y=cy;
      var step=Math.PI/spikes;

      ctx.beginPath();
      ctx.moveTo(cx,cy-outerRadius)
      for(let i=0;i<spikes;i++){
        x=cx+Math.cos(rot)*outerRadius;
        y=cy+Math.sin(rot)*outerRadius;
        ctx.lineTo(x,y)
        rot+=step

        x=cx+Math.cos(rot)*innerRadius;
        y=cy+Math.sin(rot)*innerRadius;
        ctx.lineTo(x,y)
        rot+=step
      }
      ctx.lineTo(cx,cy-outerRadius);
      ctx.closePath();

    }

    function getSolarSystemWidth(solarSystem){

      const localAreas = solarSystem.getLocalareasList();
      const distances = localAreas.map(l=>{return universe.getLocalareamapMap().get(l.getId()).getDistancefromsun()});
      const maxDistance = Math.max.apply(Math, distances);
      const minDistance = Math.min.apply(Math,distances);
      return maxDistance - minDistance;
    }
    function handleClick(){
      navigate(`/app/solarsystems/${props.solarSystem.getId()}`);
    }

    useEffect(()=>{

      const sunColor = props.solarSystem.getSuncolor();

      const canvasElement = document.getElementById(props.solarSystem.getId());
      canvasElement.height = canvasElement.width;
      const centerX = canvasElement.width / 2;
      const centerY = canvasElement.height / 2;
      const radius = centerY;
      var shrinkFactor = 0.5;


      const context = canvasElement.getContext("2d");
      var rgb = `#${Math.floor(sunColor.getR()*255).toString(16)}${Math.floor(sunColor.getG()*255).toString(16)}${Math.floor(sunColor.getB()*255).toString(16)}`;

      if(isHovering){
        rgb = "#ffffff";
        shrinkFactor = 0.7;
      }
      context.beginPath();
      context.arc(centerX, centerY, radius*shrinkFactor*shrinkFactor, 0, 2 * Math.PI, false);
      context.fillStyle = `${rgb}ff`;
      context.fill();


      drawStar(context, centerX, centerY, Math.max(4, Math.floor(sunWidth/3)), radius, radius * shrinkFactor);
      context.clip();

      context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
      context.fillStyle = `${rgb}33`;
      context.fill();

      context.beginPath();
      context.arc(centerX, centerY, radius*shrinkFactor*0.9, 0, 2 * Math.PI, false);
      context.fillStyle = `${rgb}55`;
      context.fill();

    },[isHovering]);

    return (<div key={props.solarSystem.getId()} className="solarSystem" style={style}><canvas onClick={handleClick} onMouseOver={()=>{setIsHovering(true)}} onMouseOut={()=>{setIsHovering(false)}} id={props.solarSystem.getId()}/> </div>)
  }

  const Galaxy = () => {

    if(!universe){
      return null;
    }

    const id = universe.getGalaxymapMap().keys().next().value;
    const galaxy = universe.getGalaxymapMap().get(id);

    const solarSystems = galaxy.getSolarsystemsList();
    const positions = solarSystems.map(s=>{return universe.getSolarsystemmapMap().get(s.getId()).getPosition()});
    const maxX = Math.max.apply(Math, positions.map(x => {return x.getX()}));
    const maxY = Math.max.apply(Math, positions.map(x => {return x.getY()}));
    const minX = Math.min.apply(Math, positions.map(x => {return x.getX()}));
    const minY = Math.min.apply(Math, positions.map(x => {return x.getY()}));

    const dimensions = [[minX, minY], [maxX, maxY]];

    return solarSystems.map(s => {
      const solarSystem = universe.getSolarsystemmapMap().get(s.getId());
      return (<SolarSystem solarSystem={solarSystem} dimensions={dimensions} />);
    });

  }


  return (
    <div id="galaxyMap" className="GalaxyMap2d" style={{width: window.innerHeight-55, height: window.innerHeight-55}}>
      <Galaxy />
    </div>
  );
}