import {GateTravelDialogStyled} from './GateTravelDialogStyled'
import {useState, useEffect, useContext} from 'react';
import {Dialog, DialogContent, List, ListItem, Button} from '@mui/material'
import {UniverseContext} from './context/UniverseContext'
import {LocalArea} from './LocalArea'

export default function GateTravelDialog(props) {
  const [gate, setGate] = useState(props.gate);
  const [localAreas, setLocalAreas] = useState();
  const {universe} = useContext(UniverseContext);
  const onClick = props.onClick || (()=>{});

  useEffect(()=>{
    setGate(props.gate);

  },[props.gate]);

  useEffect(()=>{
    if(gate){
      const solarSystem = gate.solarSystem;
      const localAreas = solarSystem.getLocalareasList().map(l=>{
        return universe.getLocalareamapMap().get(l.getId());
      }).sort((a,b)=>{
        return a.getName().localeCompare(b.getName());
      });

      setLocalAreas(localAreas);
    }
  },[gate]);
  return (

    <Dialog open={Boolean(gate)} onBackdropClick={()=>{setGate(null)}}>
      <DialogContent>
        <GateTravelDialogStyled>
          {gate?.solarSystem?.getName()}
          <List>
            {localAreas && localAreas.map((x,idx) => <ListItem><Button onClick={async()=>{
              const l = await LocalArea.build(x, {sunColor: gate.solarSystem.getSuncolor(), renderOrder: idx + 1});
              onClick(l)
              setGate(null)
            }}>{x.getName()}</Button></ListItem>)}
          </List>
        </GateTravelDialogStyled>
      </DialogContent>

    </Dialog>

  )
}