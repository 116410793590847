import { useState } from 'react';
export const AUTH_KEY = 'auth';


const callbackMap = {}

export const onAction = (txHash, fn) => {
  if( callbackMap[txHash]){
    callbackMap[txHash].push(fn)
  }
  else{
    callbackMap[txHash] = [fn]
  }
}

export const setAction = (val) =>{
  const callbackList = callbackMap[val.txHash];

  if(callbackList){
    for(let fn of callbackList){
      fn(val);
    }

    delete callbackMap[val.txHash];
  }
}



