import './Speech.css'
import {useState, useEffect} from 'react';
import {Button, Paper} from '@mui/material'
import HeadShot from './HeadShot'
import Panel from './Panel'

export default function Speech(props){

  const headshots = props.headshots;
  const paragraphs = props.paragraphs.map(x => x.text ? x : {text: x});
  const src = props.src;
  const name = props.name;
  const onComplete = props.onComplete;
  const onDismiss = props.onDismiss;
  const dismissOnComplete = props.dismissOnComplete;


  const [speechDisplay, setSpeechDisplay] = useState(true);

  const [charIdx, setCharIdx] = useState(0);
  const [paragraphIdx, setParagraphIdx] = useState(0);
  const [currentHeadshot, setCurrentHeadshot] = useState(headshots ? headshots[paragraphs[0]?.headshot] : null);
  const [currentParagraph, setCurrentParagraph] = useState("");
  const [paragraphComplete, setParagraphComplete] = useState(false);
  var typeWriterTimeoutId = null;
  const typeWriter = () => {


    var paragraph = paragraphs[paragraphIdx].text;
    if (charIdx < paragraph.length) {
      var char = paragraph[charIdx];
      setCurrentParagraph(currentParagraph + char);
      setCharIdx(charIdx+1);


    } else {
      setParagraphComplete(true);
      if(paragraphIdx >= paragraphs.length-1){
        setSpeechDisplay(false);
      }
    }
  }


  const skipParagraph = (e) =>{
    if(e){
      e.preventDefault();
    }
    if(paragraphComplete){
      if(paragraphIdx < paragraphs.length-1){
        setCharIdx(0);
        const idx = paragraphIdx+1;
        setParagraphIdx(idx);
        setCurrentHeadshot(headshots ? headshots[paragraphs[idx].headshot] : null)
        setCurrentParagraph("");
        setParagraphComplete(false);
      } else {
        setSpeechDisplay(false);
        dismissOnComplete && onDismiss && onDismiss();
      }
    } else {
      if(typeWriterTimeoutId){
        clearTimeout(typeWriterTimeoutId);
        typeWriterTimeoutId = null;
      }
      setCurrentParagraph(paragraphs[paragraphIdx].text);
      setCurrentHeadshot(headshots ? headshots[paragraphs[paragraphIdx].headshot] : null)
      setCharIdx(paragraphs[paragraphIdx].length);
    }
  }



  useEffect(()=>{
    typeWriterTimeoutId = setTimeout(typeWriter, 35);
    var element = document.getElementById("speechParagraph");
    if(element){
      element.scrollTop = element.scrollHeight;
    }
  }, [charIdx, paragraphIdx]);



  return (

    <div className="Speech" onClick={skipParagraph}>
      <div className="content">
      {
        currentHeadshot ?
        (<div className="speaker"><HeadShot metadata={currentHeadshot} name={currentHeadshot.name} animated={true} showName={true}/></div>) :
        (<div className="speaker">

            <img src={src} />
          <h2 className="speakerName">{name}</h2>
        </div>
        )
      }



        <div className="paragraphWrapper">
          <div id="speechParagraph" className="paragraph">
            {currentParagraph}

          </div>
          {(speechDisplay || dismissOnComplete) && <div className="pressAnyKey">Click to continue</div>}
        </div>

      </div>
    </div>
  );
};