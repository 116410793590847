import styled from 'styled-components';

export const ShipRowStyled = styled.div`
  & {


    height: 6em;
    display: flex;
    width: 100%;

    align-items: center;
    justify-content: space-between;
    margin-top: 1vh;
    margin-bottom: 1.9rem;

    border-radius: 50vw 0 100vw 50vw;
    border: 2px solid #0f0;
    background-color: #0305;
    overflow: hidden;

      .ship-operator {
        display: flex;
        width: 9em;
        height: 6em;
      }
      .ship-details {
        min-width: 65%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .salvage-button {
          border-radius: 10em 0 0 0;
          height: 6em;
          width: 8em;
          margin: 0;
          box-shadow: none;
          &.cooling {
            font-family: 'Courier New', monospace;
          }
        }
      }
      .ship-level {
        position:absolute;
        border: 2px solid #0f0;
        border-radius: 100%;
        width: 1rem;
        aspect-ratio: 1;
        justify-contents: center;
        text-align: center;
        top: 2px;


      }
      .ship-avatar {
        border-radius: 50%;
        height: 6em;
      }
      .salvage {

      }


    .operator-avatar {
      border: 2px solid #f0f;
      border-radius: 50%;
      overflow:hidden;
      height: 3em;
      width: 3em;
      position: absolute;
      top:1.5em;
      left:-1em;

    }

    .ship-controls {
      position: absolute;
      width: 12rem;
      left: 5rem;
      display: flex;
      justify-content: space-between;
      bottom: 0;

      .ship-control {
         border-radius: 0 0 50% 50%;
         border-width: 2px;
         height: 2rem;
         padding: 0;
         box-shadow: none;
      }

      &.hidden {

        .ship-control {
          display: none;
        }
      }
    }

  }

`;