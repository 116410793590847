import './PlanetEditor.css'
import {useState} from 'react';
import { useOutletContext, useNavigate, useLocation} from "react-router-dom";
import {
  COMMAND_NONE,
  COMMAND_HEAT,
  COMMAND_DIG,
  COMMAND_FILL,
  COMMAND_SIMULATE,
  VOXEL_NONE,
  VOXEL_WATER,
  VOXEL_ROCK,
  VOXEL_PLANT
} from './libs/editTool'
import {ToggleButton, ToggleButtonGroup, Slider, FormControlLabel} from '@mui/material'

export default function PlanetEditor(props) {

  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const location = useLocation();
  const planet = outletContext.planet;
  const editTool = outletContext.editTool;
  const [selectedCommand, setSelectedCommand] = useState(COMMAND_NONE);

  const handleChange = (event, value) => {
    value = value || COMMAND_NONE;
    setSelectedCommand(value);
    editTool.current.commandType = value;
  }

  if(!planet){
    return null;
  }

  const EditorOptions = (props) => {

    const command = props.command;
    const [selectedVoxelType, setSelectedVoxelType] = useState(VOXEL_NONE);
    const [temperature, setTemperature] = useState(128);
    const [size, setSize] = useState(10);

    if(command == COMMAND_HEAT){

      const handleTemperatureChange = (event, value) => {
        setTemperature(value);
        editTool.current.commandArg2 = value;
      }

      const handleSizeChange = (event, value) => {
        setSize(value);
        editTool.current.commandArg1 = value;
      }

      return (
        <div className="EditorOptions">

          <div className="label">temp</div>
          <div className="content">
            <Slider
              className="slider"
              onChange={handleTemperatureChange}
              value={temperature}
              valueLabelDisplay="auto"
              step={1}
              min={0}
              max={255}
            />
          </div>

           <div className="label">size</div>
           <div className="content">
             <Slider
               className="slider"
               onChange={handleSizeChange}
               value={size}
               valueLabelDisplay="auto"
               step={1}
               min={1}
               max={128}
             />
           </div>

        </div>
      )
    }

    if(command == COMMAND_FILL){
      const handleVoxelTypeChange = (event, value) => {
        value = value || VOXEL_NONE;
        setSelectedVoxelType(value);
        editTool.current.commandArg2 = value;
      }

      const handleTemperatureChange = (event, value) => {
        setTemperature(value);
        editTool.current.commandArg3 = value;
      }

      const handleSizeChange = (event, value) => {
        setSize(value);
        editTool.current.commandArg1 = value;
      }

      return (
        <div className="EditorOptions">
             <div className="label">type</div>
             <div className="content">
               <ToggleButtonGroup className="voxelTypeSelector" exclusive value={selectedVoxelType} onChange={handleVoxelTypeChange}>
                 <ToggleButton value={VOXEL_ROCK} className="button">Rock</ToggleButton>
                 <ToggleButton value={VOXEL_WATER} className="button">Water</ToggleButton>
                 <ToggleButton value={VOXEL_PLANT} className="button">Plant</ToggleButton>
               </ToggleButtonGroup>
             </div>
             <div className="label">temp</div>
             <div className="content">
                <Slider
                  className="slider"
                  onChange={handleTemperatureChange}
                  value={temperature}
                  valueLabelDisplay="auto"
                  step={1}
                  min={0}
                  max={255}
                />
             </div>

             <div className="label">size</div>
             <div className="content">
               <Slider
                 className="slider"
                 onChange={handleSizeChange}
                 value={size}
                 valueLabelDisplay="auto"
                 step={1}
                 min={1}
                 max={128}
               />
             </div>



        </div>
      )
    }

    if(command == COMMAND_DIG){
      const handleSizeChange = (event, value) => {
          setSize(value);
          editTool.current.commandArg1 = value;
        }

        return (
          <div className="EditorOptions">
            <div className="label">size</div>
            <div className="content">
              <Slider
                className="slider"
                onChange={handleSizeChange}
                value={size}
                valueLabelDisplay="auto"
                step={1}
                min={1}
                max={128}
              />
            </div>
          </div>
      );
    }

    if(command == COMMAND_SIMULATE){
      const handleSizeChange = (event, value) => {
          setSize(value);
          editTool.current.commandArg1 = value;
        }

        return (
          <div className="EditorOptions">
             <div className="label">size</div>
             <div className="content">
              <Slider
                className="slider"
                onChange={handleSizeChange}
                value={size}
                valueLabelDisplay="auto"
                step={1}
                min={1}
                max={128}
              />
            </div>
          </div>
      );
    }

    return null;
  };


  return (
    <div className="PlanetEditor">
      <div className="container">
        <EditorOptions command={selectedCommand} />
        <ToggleButtonGroup exclusive value={selectedCommand} onChange={handleChange}>
          <ToggleButton id="heat_button" value={COMMAND_HEAT} className="button"><img src="/heat.svg" /></ToggleButton>
          <ToggleButton id="draw_button" value={COMMAND_FILL} className="button"><img src="/draw.svg" /></ToggleButton>
          <ToggleButton id="dig_button" value={COMMAND_DIG} className="button"><img src="/dig.svg" /></ToggleButton>
          <ToggleButton id="simulate_button" value={COMMAND_SIMULATE} className="button"><img src="/time.svg" /></ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  );
}