import './ShipMinter.css'
import {useState} from 'react';
import {Link} from '@mui/material'
import {EmailSignup} from './EmailSignup'
import SpeechDialog from './SpeechDialog'

export default function ShipMinter(){
  const paragraphs = [
    "Ah! A discerning customer!",
    "You're a pilot of superior taste, I'm sure. If I had to guess, a specimen like you has no trouble copulating with other members of your species. (Not that I have any clue which of your disgusting appendages or orifices you might use for that unspeakable act...)",
    "I'd love nothing more than to send you home with a brand spankin' new Salvage Ship. Believe me, I only sell the best, and when the day comes, you're gonna fly away happy.",
    "Unfortunately, that day ain't today. You know, supply chain problems and whatnot. Labor shortages. Trade wars. It's all a mess.",
    ". . .",
    "But don't worry! We've got it under control, friend!",
    "Well . . . we will. Soon . . . (Better be soon, or we're screwed.)",
    "And when we do, we'll get you something nice. You've got my word, and my word's the best in the system. (And if you believe that, I've got a pile of shitcoins in Dicora to sell you.)",
    "Just put your name on the list, and I'll send you a message when ships are ready for minting."
  ];

  return (
    <>

      <SpeechDialog src="wormhair.png" name="Slig" paragraphs={paragraphs} metadata={{
        attributes: [{
            "trait_type": "Species",
            "value": "Eyeguy"
          },{
            "trait_type": "Aural Hue",
            "value": 180
          },
          {
            "trait_type": "Optical Sensors",
            "value": "Brown Eyed"
          },
          {
            "trait_type": "Locomotion",
            "value": "Knobby Knees"
          },
          {
            "trait_type": "Manipulators",
            "value": "Chicken Wing"
          },
          {
            "trait_type": "Bioreactor",
            "value": "Pickle"
          },
          {
            "trait_type": "Processing Unit",
            "value": "Little Green Man"
          },
          {
            "trait_type": "Communications",
            "value": "Ignorance Is Bliss"
          }
        ]
      }}/>
      <div className="ShipMinter">
        <h1>Slig's New and Used Salvage Ships</h1>
        <p>Catering to the Galaxy's top Salvage Professionals, we guarantee top quality equipment and white glove service.</p>
        <p>Ship minting has not yet begun.</p>
        <p>In the meantime, <Link href="/app/galaxy">explore</Link> the Galaxy.</p>
        <EmailSignup/>
      </div>
    </>
  );
}