import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles'
import {theme} from "./themes/galaxyHustle";

import App from './App';
import Home from './Home'
import HomeContainer from './HomeContainer'
import Container from './Container'
import Planet from './Planet'
import PlanetGenesis from './PlanetGenesis'
import NoSignal from './NoSignal'
import PlanetEditor from './PlanetEditor'
import PlanetPanel from './PlanetPanel'
import SolarSystem from './SolarSystem'
import {GalaxyMap} from './GalaxyMap'
import Team from './Team'
import ShipMinter from './ShipMinter'
import PlanetMinter from './PlanetMinter'
import Pilot from './Pilot'
import Ship from './Ship'
import Web3Container from './Web3Container'

ReactDOM.render(
<React.StrictMode>
  <ThemeProvider theme={theme}>

    <BrowserRouter>

      <Routes>


        <Route exact path="/" element={<Container />} >
          <Route index element={<Navigate to="/home/planets/3b6d2d5b-29d9-4456-a9b8-2552c853ac0c" state={{ isFirstLoad: true}}/>} />
          <Route path="home/planets/:id" element={<Planet/>}/>
          <Route path="home/solarsystems/:id" element={<SolarSystem />}/>
          <Route path="home/galaxy" element={<GalaxyMap />}/>
          <Route  path="team" element={<Team/>} />
          <Route  path="mintplanets" element={<PlanetMinter/>} />
          <Route  path="mintships" element={<ShipMinter/>} />
        </Route>

        <Route path="/app" element={<Web3Container><Container/></Web3Container>}>
          <Route index element={<App/>} />
          <Route path="solarsystems/:id" element={<SolarSystem />}/>
          <Route path="planets/:id" element={<Planet />}>
            <Route index element={<PlanetPanel/>} />
            <Route path="genesis" element={<PlanetGenesis/>}/>
            <Route path="ships" element={<NoSignal/>}/>
            <Route path="terraform" element={<PlanetEditor/>}/>
          </Route>
          <Route path="galaxy" element={<GalaxyMap />}/>
        </Route>

        <Route path="/pilots/:species/:id" element={<Pilot width="50%"/>}/>
        <Route path="/ships/:id" element={<Web3Container><Ship/></Web3Container>}/>
      </Routes>

    </BrowserRouter>

  </ThemeProvider>
</React.StrictMode>,
document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
