import * as THREE from "three";

export function Interactable(canvas, orientation, cameraPosition, mouse, onClick, onZoom, onMove) {
  var lastPinchDistance = false;
  var mousePos = [0,0];

  var isDragging = false;


   function calculatePinchDistance(touch1, touch2){
      const point1 = new THREE.Vector2(touch1.clientX, touch1.clientY);
      const point2 = new THREE.Vector2(touch2.clientX, touch2.clientY);

      return point1.distanceTo(point2);
   }

  function handleTouchStart(event){
    if(event.touches.length == 1){
      mousePos = [event.touches[0].clientX, event.touches[0].clientY];
      isDragging = onClick(mousePos);
      updateMouse(mousePos);
    } else if(event.touches.length == 2){
      lastPinchDistance = calculatePinchDistance(event.touches[0], event.touches[1]);
    }
  }

  function handleTouchEnd(event){
    if(event.touches.length == 1){
      isDragging = false;

    } else if(event.touches.length == 2){
      lastPinchDistance = null;
    }
  }

  function handleTouchMove(event){
    if(event.touches.length == 1){
      if(isDragging){
        var theta = 0.002 * Math.PI;
        const delta = [theta * (mousePos[0] - event.touches[0].clientX), theta *(mousePos[1] - event.touches[0].clientY)];

        mousePos = [event.touches[0].clientX, event.touches[0].clientY];
        onMove && onMove(mousePos, isDragging);
        var upVector = new THREE.Vector3(0,1,0);
        var rightVector = new THREE.Vector3(1,0,0).applyQuaternion(orientation);

        var rotatePitch = new THREE.Quaternion().setFromAxisAngle(rightVector, delta[1] );
        var rotateYaw = new THREE.Quaternion().setFromAxisAngle(upVector, delta[0] );

        orientation.copy(rotatePitch.multiply(orientation).multiply(rotateYaw));
      }

    } else if(event.touches.length == 2){
      var dist = calculatePinchDistance(event.touches[0], event.touches[1]);
      var ratio = lastPinchDistance/dist;
      onZoom(event, ratio);

      lastPinchDistance = dist;
    }
  }

  function handleMouseDown(event){
    event.preventDefault();
    mousePos = [event.clientX, event.clientY];
    isDragging = onClick(mousePos)
  }

  function handleMouseUp(event){

    isDragging = false;
  }

  function handleMouseMove(event){

    if(isDragging){
      event.preventDefault();

      var theta = 0.002 * Math.PI;
      const delta = [theta * (mousePos[0] - event.clientX), theta *(mousePos[1] - event.clientY)];

      mousePos = [event.clientX, event.clientY];
      onMove && onMove(mousePos, isDragging);
      var upVector = new THREE.Vector3(0,1,0);
      var rightVector = new THREE.Vector3(1,0,0).applyQuaternion(orientation);

      var rotatePitch = new THREE.Quaternion().setFromAxisAngle(rightVector, delta[1] );
      var rotateYaw = new THREE.Quaternion().setFromAxisAngle(upVector, delta[0] );

      orientation.copy(rotatePitch.multiply(orientation).multiply(rotateYaw));

    } else {
      onMove && onMove(mousePos, isDragging)
    }

    updateMouse([event.clientX, event.clientY]);
  }

  function updateMouse(pos){
    var rect = canvas.getBoundingClientRect();
    var x = pos[0] - rect.left; //x position within the element.
    var y = pos[1] - rect.top;
    mouse.x = ( x / canvas.clientWidth ) * 2 - 1;
    mouse.y = - ( y / canvas.clientHeight ) * 2 + 1;
  }

  function handleZoom(event){
      var bottom = document.body.scrollHeight - window.scrollY === window.innerHeight;
      if(!bottom){
        return;
      }
      onZoom(event, (100 + (event.deltaY > 0 ? 4 : -4))/100.0);

  }

  function registerCallbacks(){
    canvas.addEventListener("touchstart", handleTouchStart);
    canvas.addEventListener("mousedown", handleMouseDown);

    document.addEventListener("mouseleave", handleMouseUp);
    document.documentElement.addEventListener("mouseup", handleMouseUp);
    document.documentElement.addEventListener("touchend", handleTouchEnd);


    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("wheel", handleZoom);
  }

  function removeCallbacks(){
    canvas.removeEventListener("touchstart", handleTouchStart);
    canvas.removeEventListener("mousedown", handleMouseDown);

    document.removeEventListener("mouseleave", handleMouseUp);
    document.documentElement.removeEventListener("mouseup", handleMouseUp);
    document.documentElement.removeEventListener("touchend", handleTouchEnd);


    document.removeEventListener("touchmove", handleTouchMove);
    document.removeEventListener("mousemove", handleMouseMove);
    canvas.removeEventListener("wheel", handleZoom);
  }

  registerCallbacks();
  return removeCallbacks;
}