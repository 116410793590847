import {createContext} from 'react';

export const UniverseContext = createContext({
  universe: null,
  galaxy: null,
  solarSystem: null,
  localAreas: null,
  planet: null,
  selectedShip: null,
  setSelectedShip: () => {},
});