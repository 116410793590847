import './Speech.css'
import {useState, useEffect} from 'react';
import {Button, Paper} from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import HeadShot from './HeadShot'
import Panel from './Panel'

export default function Speech(props){

  const headshots = props.headshots;
  const paragraphs = props.paragraphs.map(x => x.text ? x : {text: x});
  const src = props.src;
  const name = props.name;
  const onComplete = props.onComplete;
  const onDismiss = props.onDismiss;


  const [speechDisplay, setSpeechDisplay] = useState(true);

  const [charIdx, setCharIdx] = useState(0);
  const [paragraphIdx, setParagraphIdx] = useState(0);
  const [currentHeadshot, setCurrentHeadshot] = useState(headshots ? headshots[paragraphs[0]?.headshot] : null);
  const [currentParagraph, setCurrentParagraph] = useState("");
  const [paragraphComplete, setParagraphComplete] = useState(false);
  var typeWriterTimeoutId = null;
  const typeWriter = () => {


    var paragraph = paragraphs[paragraphIdx].text;
    if (charIdx < paragraph.length) {
      var char = paragraph[charIdx];
      setCurrentParagraph(currentParagraph + char);
      setCharIdx(charIdx+1);


    } else {
      setParagraphComplete(true);
    }
  }


  const skipParagraph = (e) =>{
    if(e){
      e.preventDefault();
    }
    if(paragraphComplete){
      if(paragraphIdx < paragraphs.length-1){
        setCharIdx(0);
        const idx = paragraphIdx+1;
        setParagraphIdx(idx);
        setCurrentHeadshot(headshots ? headshots[paragraphs[idx].headshot] : null)
        setCurrentParagraph("");
        setParagraphComplete(false);
      } else {
        setSpeechDisplay(false);
        onDismiss && onDismiss();
      }
    } else {
      if(typeWriterTimeoutId){
        clearTimeout(typeWriterTimeoutId);
        typeWriterTimeoutId = null;
      }
      setCurrentParagraph(paragraphs[paragraphIdx].text);
      setCurrentHeadshot(headshots ? headshots[paragraphs[paragraphIdx].headshot] : null)
      setCharIdx(paragraphs[paragraphIdx].length);
    }
  }



  useEffect(()=>{
    typeWriterTimeoutId = setTimeout(typeWriter, 35);
    var element = document.getElementById("speechParagraph");
    if(element){
      element.scrollTop = element.scrollHeight;
    }
  }, [charIdx, paragraphIdx]);



  return (
    <Dialog open={speechDisplay} className="Speech" PaperComponent="div" fullWidth={true} onClick={skipParagraph}>
        <DialogContent >
          <div className="content">
          {
            currentHeadshot ?
            (<Panel className="speaker"><HeadShot metadata={currentHeadshot} name={currentHeadshot.name} animated={true} showName={true}/></Panel>) :
            (<Panel className="speaker">

                <img src={src} />
              <h2 className="speakerName">{name}</h2>
            </Panel>
            )
          }



            <div className="paragraphWrapper glitchPanel">
              <div id="speechParagraph" className="paragraph">
                {currentParagraph}

              </div>
              <div className="pressAnyKey">Click to continue</div>
            </div>

          </div>

      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={()=>{setSpeechDisplay(false); onDismiss && onDismiss();}} color="info">Dismiss</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};