import './Planet.css'
import { useParams, useOutletContext, Outlet, useSearchParams, useNavigate} from "react-router-dom";
import * as proto from './protos/universe_pb'
import {useApi} from './apis/api'
import {useState, useEffect, useRef, useContext, useCallback} from 'react';
import * as THREE from "three";
import {SkyTexture} from './libs/skyTexture'
import {Interactable} from './Interactable'
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {Button} from '@mui/material';
import {EditTool} from './libs/editTool'

import { Pass } from 'three/examples/jsm/postprocessing/Pass.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import {Character} from './Character'
import {Asteroid} from './Asteroid'
import {Satellite} from './Satellite'
import {StarFragment} from './StarFragment'
import {OrbitingEntity} from './OrbitingEntity'
import Speech from './Speech'

import { drizzleReactHooks } from '@drizzle/react-plugin';
import { useSessionStorage, AUTH_KEY } from './hooks/useSessionStorage';
import DisplayItem from './DisplayItem'
import {LocalArea} from './LocalArea'
import {fetchFile, disposeObject} from './libs/utils'
import {AuthContext} from './Web3Container'
import {idToLocalAreaToken} from './constants/metadataMap'
import {LOCAL_CHARACTERS} from './constants/localContent'
import {Fleet} from './Fleet'
import TravelDialog from './TravelDialog'
import GateTravelDialog from './GateTravelDialog'
import {UniverseContext} from './context/UniverseContext'


const raycaster = new THREE.Raycaster();

const buildLaserSprite = async () => {

  const axisAlignedSpriteVertexShader = await fetchFile("/shaders/axisAlignedSprite.vertex");
  const axisAlignedSpriteFragmentShader = await fetchFile("/shaders/axisAlignedSprite.fragment");
  const laserTexture = new THREE.TextureLoader().load( '/laser.png' );
  laserTexture.wrapS = THREE.RepeatWrapping;
  laserTexture.wrapT = THREE.RepeatWrapping;
  laserTexture.transparent = true;
  const laserShader = new THREE.ShaderMaterial( {
    uniforms: {
        inputTexture: {value: laserTexture},
        time: {value: 0.0},
    },
    vertexShader: axisAlignedSpriteVertexShader,
    fragmentShader: axisAlignedSpriteFragmentShader
  });
  laserShader.blending = THREE.CustomBlending;
  laserShader.blendEquation = THREE.AddEquation;
  laserShader.blendSrc = THREE.SrcAlphaFactor;
  laserShader.blendDst = THREE.OneMinusSrcAlphaFactor;
  laserShader.depthTest = false;
  const laserSprite = new THREE.Mesh( new THREE.PlaneGeometry( 2, 0.1 ), laserShader);
  laserSprite.position.set(0, 0, -1)
  laserSprite.rotateY(2)
  laserSprite.renderOrder = 4;
  return laserSprite;
};

export default function Planet() {
  const {web3OptedIn} = useContext(AuthContext);
  const api = useApi();
  const params = useParams();
  const outletContext = useOutletContext();
  const {
      universe,
      solarSystem,
      localAreas,
      skyTexture,
      scene,
      sky,
      sunSphere,
      camera,
      canvas,
      mouse,
      planet: localArea,
      galaxy,
      gates,
      renderer,
      particleSystem,
      composer,
    } = useOutletContext();

  const [planet, setPlanet] = useState();
  const { drizzle, useCacheCall } = web3OptedIn ? drizzleReactHooks.useDrizzle() : {drizzle:null, useCacheCall:(()=>{})};
  const [characters, setCharacters] = useState();
  const [artifacts, setArtifacts] = useState();
  const {selectedShip} = useContext(UniverseContext);

  const frameId = useRef();
  const prepareFrame = useRef();
  const orientation = useRef(new THREE.Quaternion());
  const approaching = useRef(true);
  const desiredPosition = useRef();

  const [searchParams, setSearchParams] = useSearchParams();

  const editTool = useRef();

  const [characterDisplay, setCharacterDisplay] = useState();
  const [currentGate, setCurrentGate] = useState();
  const [navigationGate, setNavigationGate] = useState();
  const [laserSprite, setLaserSprite] = useState();

  const [prospectiveDestination, setProspectiveDestination] = useState();
  const friendlyFleet = parseInt(useCacheCall("Galaxy", "getLocalCensus", [1,idToLocalAreaToken[localArea?._id]]));
  const neutralFleet = parseInt(useCacheCall("Galaxy", "getLocalCensus", [2,idToLocalAreaToken[localArea?._id]]));
  const hostileFleet = parseInt(useCacheCall("Galaxy", "getLocalCensus", [3,idToLocalAreaToken[localArea?._id]]));
  const [census, setCensus] = useState([friendlyFleet, neutralFleet, hostileFleet]);

  const navigate = useNavigate();

  useEffect(()=>{
    console.log("SELECTED SHIP = ", selectedShip)
  },[selectedShip])
  useEffect(()=>{
    if(!isNaN(friendlyFleet) && !isNaN(neutralFleet) && !isNaN(hostileFleet)){
      setCensus([friendlyFleet, neutralFleet, hostileFleet])
    }
  }, [friendlyFleet, neutralFleet, hostileFleet]);

  useEffect(()=>{console.log("CHARACTER DISPLAY", characterDisplay)}, [characterDisplay]);

  const sunPosition = new THREE.Vector3( -1, 0, 0 );
  const sunDirection = new THREE.Vector3( 0, 0, 0 ).add( sunPosition.multiplyScalar(-1.0));
  const up = new THREE.Vector3( 0, 1, 0 );
  const cameraPosition = new THREE.Vector3(0,0,1);
  const cameraStartDistance = cameraPosition.length();

  const initiateTransportShip = (ship, localArea) => {
    setProspectiveDestination(localArea);
    //const {signature, price} = await api.trustedSigner.getShipTransportSignature(shipToken, level);
        //const response = await drizzle.contracts.Fleet.methods.upgrade.cacheSend(shipToken, price, signature);
  }


  useEffect(()=>{
    if(canvas && raycaster && localArea && mouse && camera){
      console.log("SETTING EDIT TOOL ", localArea._id)
      editTool.current = new EditTool(canvas, raycaster, localArea, mouse, camera);
      editTool.current.registerCallbacks(canvas, document);
      console.log("EDIT TOOL", editTool.current.localArea._id)
    }

    return () => {
      console.log("KILL EDIT TOOL")
      editTool.current?.removeCallbacks(canvas, document);
      editTool.current = null;
    }
  }, [canvas, raycaster, localArea, mouse, camera]);

  useEffect(()=> {

    if(!particleSystem || !localArea){
      return;
    }

    if(frameId.current){
      cancelAnimationFrame(frameId.current);
      frameId.current = null;
    }
    approaching.current = localArea._id;
    console.log("1 APPROACHING = ", approaching.current, localArea._id);
    var isCanceled = false;
    var newCharacters;
    const newOrbitingEntities = [];

    const run = async () => {

      if(web3OptedIn){
        const result = await api.localAreas.getCharacters(localArea._id);
        newCharacters = await Promise.all(result.characters.map(async (c) => {
          var character = await Character.buildJson(c, {particleSystem, universe});
          character.renderOrder = 10;
          character.orbitObject = localArea;
          scene.add(character);
          return character
        }))
        !isCanceled &&  setCharacters(newCharacters);

        const artifactResult = await api.localAreas.getArtifacts(localArea._id);
        const newArtifacts = await Promise.all(artifactResult.artifacts.map(async (c) => {
          var artifact = await OrbitingEntity.buildJson(c, {particleSystem, universe});

          artifact.orbitObject = localArea;
          artifact.tumbleQuaternion = new THREE.Quaternion();
          const axis = new THREE.Vector3(Math.random()-0.5,Math.random()-0.5,Math.random()-0.5);
          axis.normalize();
          artifact.tumbleQuaternion.setFromAxisAngle(axis, Math.random() * 0.1);
          newOrbitingEntities.push(artifact);
          scene.add(artifact);
          return artifact;
        }))
        !isCanceled &&  setArtifacts(newArtifacts);
      } else if(localArea._id in LOCAL_CHARACTERS){
        newCharacters = await Promise.all(LOCAL_CHARACTERS[localArea._id].characters.map(async (c) => {
          var character = await Character.buildJson(c, {particleSystem, universe});
          character.renderOrder = 10;
          character.orbitObject = localArea;
          scene.add(character);
          return character
        }))
        !isCanceled &&  setCharacters(newCharacters);

      }


      const spaceJunk = localArea.proto.getEntitypointersList();
      for(var entityPointer of spaceJunk){
        const entityId = entityPointer.getId();
        const entity = universe.getEntitymapMap().get(entityId);

        var orbitingObject;
        switch(entity.getEntityCase()){

          case proto.Entity.EntityCase.STARFRAGMENT:
            console.log("Starfragment = ", entity.getStarfragment())
            orbitingObject = await StarFragment.build(entity.getStarfragment(), {particleSystem});
          break;
          case proto.Entity.EntityCase.ASTEROID:
            orbitingObject = await Asteroid.build(entity.getAsteroid(), {particleSystem});
          break;
          case proto.Entity.EntityCase.SATELLITE:
            orbitingObject = await Satellite.build(entity.getSatellite(), {particleSystem});
          break;
        }
        if(orbitingObject){
          orbitingObject.orbitObject = localArea;
          newOrbitingEntities.push(orbitingObject);
          scene.add(orbitingObject);
        }
      }

       const laserSprite = await buildLaserSprite();
       scene.add(laserSprite);
       !isCanceled && setLaserSprite(laserSprite);

    };



    setPlanet(localArea.proto);
    run();

    return () => {
      isCanceled = true;
      if(newCharacters){
        for(var character of newCharacters){
          console.log("DISPOSING CHARACTER", character)
          scene.remove(character);
          disposeObject(character);
        }
      }

      for(var entity of newOrbitingEntities){
        scene.remove(entity);
        disposeObject(entity);
      }

      if(laserSprite){
        scene.remove(laserSprite);
        disposeObject(laserSprite);
      }

    }

  }, [localArea, particleSystem]);

  useEffect(()=>{
    console.log("CENSUS", census)
    if(!census){
      return;
    }


    var fleets = [];
    Promise.all(census.map(async (count, idx) => {
      return Fleet.build(idx, count);//Math.floor(Math.random() * 10000));
    })).then(results => {
      fleets = results;
      for(const fleet of fleets){
        if(fleet){
          localArea.volumeObject.add(fleet);
        }
      }
    })

    return () => {
      for(const fleet of fleets){
        if(fleet){
          localArea.volumeObject.remove(fleet);
          disposeObject(fleet);
        }
      }
    };

  }, [census]);


  const simulate = (isPaused, sunPosition, intersection, target_intersection, orientation, simulationRenderTarget,
                    simulationPlane,
                    simulationScene,
                    simulationCamera,
                    renderer,
                    currentStateTexture) => {

    var cmdType = 0;
    var commandPos;
    var commandDir;
    if(intersection?.length > 0 && editTool){
      cmdType = editTool.current.commandType;

      const localAreaPosition = new THREE.Vector3();
      localArea.volumeObject.getWorldPosition(localAreaPosition);
      const intersectionPoint = intersection[0].point.clone().sub(localAreaPosition).divide(localArea.volumeObject.scale);
      const raycasterPoint = raycaster.ray.direction.clone().multiplyScalar(32.0).divide(localArea.volumeObject.scale);//.applyQuaternion(localArea.volumeObject.quaternion.clone().invert());
      commandPos = intersectionPoint.clone().sub(raycasterPoint);
      commandPos.applyQuaternion(localArea.volumeObject.quaternion.clone().invert())
      commandPos.applyQuaternion(localArea.quaternion.clone().invert())

      commandPos.x = (commandPos.x + 0.5) * 256;// * 1.0/localArea.volumeObject.scale.x;
      commandPos.y = (commandPos.y + 0.5) * 256;// * 1.0/localArea.volumeObject.scale.y;
      commandPos.z = (commandPos.z + 0.5) * 256 ;//* 1.0/localArea.volumeObject.scale.z;


      var commandDirection = raycaster.ray.direction.clone().applyQuaternion(localArea.volumeObject.quaternion.clone().invert()).applyQuaternion(localArea.quaternion.clone().invert());
      commandDir = new THREE.Vector3(commandDirection.x, commandDirection.y, commandDirection.z);
    }

    simulationPlane.material.uniforms.lightViewPosition.value = sunPosition;
    simulationPlane.material.uniforms.isPaused.value = isPaused;
    simulationPlane.material.uniforms.currentStateTexture.value = currentStateTexture;

    simulationPlane.material.uniforms.commandType.value = cmdType;
    simulationPlane.material.uniforms.commandArg1.value = editTool.current.commandArg1;
    simulationPlane.material.uniforms.commandArg2.value = editTool.current.commandArg2;
    simulationPlane.material.uniforms.commandArg3.value = editTool.current.commandArg3;
    if(cmdType > 0){
      simulationPlane.material.uniforms.commandPosition.value = commandPos;
      simulationPlane.material.uniforms.commandDirection.value = commandDir;
    }

    for(let layer = 0; layer < 256; layer ++){
      simulationPlane.material.uniforms.layer.value = layer;
      renderer.setRenderTarget(simulationRenderTarget, layer);
      renderer.clear();
      renderer.render(simulationScene, simulationCamera);

    }
    //simulationRenderTarget.texture.needsUpdate = true;
    //currentStateTexture.needsUpdate = true;
  }

  const transportOrNavigate = useCallback((hoverItem, url)=>{
    if(selectedShip){
      if(hoverItem.name === "Gate"){
        setNavigationGate(hoverItem);
      }else {
        initiateTransportShip(selectedShip, hoverItem);

      }
    } else {
      if(web3OptedIn){
        navigate(url)
      } else {
        navigate(url.replace("app", "home"))
      }
    }
  }, [selectedShip]);

  useEffect(()=>{
    var isCanceled = false;
    if( !canvas || !renderer || !composer || !particleSystem || !sky || !localArea || !sunSphere || !laserSprite){
      return;
    }
    var hasHaloState = {value: false};

    console.log("PLANET biggie")

    var mousePos = [0,0];
    var isDragging = false;

    var cameraFocusObject = null;

    var cancelInteractable = Interactable(canvas, orientation.current, cameraPosition, mouse, function(mousePos){
      if(isCanceled){
        return;
      }
      if(editTool?.current?.commandType <= 0 || !editTool.current.isDragging){
        const hoverList = scene.children.filter(x => x.isHovering);
        if(hoverList.length > 0){
          hoverList[0].click(mousePos, selectedShip, api).then(interactionResult => {
            if( interactionResult?.displayItem ){
              console.log("SETTING CHARACTER DISPLAY!!")
              setCharacterDisplay(interactionResult.displayItem);
              cameraFocusObject = hoverList[0];
            } else if(interactionResult?.navigateItem){
                transportOrNavigate(hoverList[0], interactionResult.navigateItem.url)

            }
          });
        }

      }
      return !editTool.current.isDragging;
    }, function(event, ratio){

      cameraPosition.z = cameraPosition.z * ratio;
      if(cameraPosition.z < 0.5){
          cameraPosition.z = 0.5;
      }
      else if(cameraPosition.z > 100){
          cameraPosition.z = 100;
      } else {
        event.preventDefault();
      }
    });


    var lightPosition = sunPosition.clone().multiplyScalar(localArea.proto.getDistancefromsun() * 4);
    lightPosition.add(new THREE.Vector3(128,128,128));
    var postProcessShader = null;
    var addedPasses = [];
    const setUpComposer = async () => {
      const postprocessVertexShader = await fetchFile("/shaders/postprocess.vertex");
      const postprocessFragmentShader = await fetchFile("/shaders/postprocess.fragment");
      postProcessShader = new THREE.ShaderMaterial( {
        uniforms: {
            inputTexture: {value: null},
            mousePosition: {value: mouse},
            rayRadius: {value: 0.0},
            screenDimensions: {value: [canvas.clientWidth * window.devicePixelRatio, canvas.clientHeight * window.devicePixelRatio]},

        },
        vertexShader: postprocessVertexShader,
        fragmentShader: postprocessFragmentShader
      });
      const renderPass = new RenderPass( scene, camera );
      composer.addPass( renderPass );
      addedPasses.push(renderPass);

      //const particlePass = new ParticleRenderPass(particleScene, camera);
      //composer.addPass(particlePass);


      const effectPass = new ShaderPass(postProcessShader, "inputTexture");
      composer.addPass( effectPass )
      addedPasses.push(effectPass);
    };



    async function drawPlanet(canvas, localArea){

      var volumeTextures = [];
      var volumeTextureIndex = 0;
      var volumeTexture = localArea.material.uniforms.volumeTexture.value;
      volumeTextures.push(volumeTexture);

      var nextTexture = new THREE.DataTexture3D(null,
          localArea.proto.getVolume().getWidth(),
          localArea.proto.getVolume().getHeight(),
          localArea.proto.getVolume().getLength()
      );
      nextTexture.minFilter = THREE.NearestFilter;
      nextTexture.magFilter = THREE.NearestFilter;
      nextTexture.needsUpdate = true;
      volumeTexture.transparent = true;
      volumeTextures.push(nextTexture)

      const updateVolumeStateVertexShader = await fetchFile("/shaders/updateVolumeState.vertex");
      const updateVolumeStateFragmentShader = await fetchFile("/shaders/updateVolumeState.fragment");

      const simulationPlane = new THREE.Mesh( new THREE.PlaneGeometry( 2, 2 ),
                                    new THREE.ShaderMaterial( {
                                       uniforms: {
                                           currentStateTexture: {value: volumeTexture},
                                           commandType: {value: 6.0},
                                           commandPosition: {value: new THREE.Vector3(0,0,0)},
                                           commandDirection: {value: new THREE.Vector3(0,0,0)},
                                           commandArg1: {value: 10.0},
                                           commandArg2: {value: 0.0},
                                           commandArg3: {value: 128.0},
                                           layer: {value: 0},
                                           lightViewPosition: {value: new THREE.Vector3(-4000,0,0)},
                                           isPaused: {value: true},

                                       },
                                       vertexShader: updateVolumeStateVertexShader,
                                       fragmentShader: updateVolumeStateFragmentShader
                                    } ) );
      const simulationScene = new THREE.Scene();
      simulationScene.add(simulationPlane);
      const simulationCamera = new THREE.Camera();

      const simulationRenderTarget = new THREE.WebGL3DRenderTarget(256, 256, 256);
      simulationRenderTarget.texture = nextTexture;


      const clock = new THREE.Clock();

      var animate = function (isPaused, characterDisplay) {

        var deltaTime = clock.getDelta();
        var currentTime = clock.getElapsedTime();
        simulationRenderTarget.texture = volumeTextures[(volumeTextureIndex + 1) % 2];

        if(editTool?.current?.commandType > 0){
          simulate(isPaused, lightPosition, editTool.current.intersection, editTool.current.target_intersection, orientation.current, simulationRenderTarget, simulationPlane, simulationScene, simulationCamera, renderer, volumeTextures[volumeTextureIndex]);
          volumeTextureIndex = (volumeTextureIndex + 1) % 2;
          localArea.material.uniforms.volumeTexture.value = simulationRenderTarget.texture;


          hasHaloState.value = true;
        }
        if(editTool?.current){
          editTool.current.intersection = null;
        }


        if(hasHaloState.value){
          const zoom = (cameraStartDistance/cameraPosition.length()) * window.devicePixelRatio * 3.3/2.0;// * canvas.clientWidth/canvas.clientHeight;
          postProcessShader.uniforms.rayRadius.value = editTool?.current?.commandArg1 * zoom;
        } else {
          postProcessShader.uniforms.rayRadius.value = 0;
        }
        postProcessShader.uniforms.mousePosition.value = [(mouse.x+1.0)*0.5, (mouse.y+1.0)*0.5];
        postProcessShader.uniforms.screenDimensions.value = [canvas.clientWidth * window.devicePixelRatio, canvas.clientHeight * window.devicePixelRatio];



        // calculate objects intersecting the picking ray

        raycaster.setFromCamera( mouse, camera );
        const interactable = scene.children.filter(x => x.isInteractable && x._id != localArea._id);
        const hoverList = raycaster.intersectObjects( interactable );

        for(let obj of interactable){
          obj.isHovering = false;
        }
        localArea.isHovering = false;
        !isCanceled && setCurrentGate(null);

        var interactableHovering = false;
        if(hoverList.length > 0){
          document.body.style.cursor = "pointer";

          if(hoverList[0].object.isInteractable){
            hoverList[0].object.isHovering = true;
            if(hoverList[0].object.name == "Gate"){
              !isCanceled && setCurrentGate(hoverList[0].object);
            }
          } else if(hoverList[0].object.parent.isInteractable){
            hoverList[0].object.parent.isHovering = true;
          }

          interactableHovering = true;
        } else {
          document.body.style.cursor = "default";
        }

        for(let i = 0; i < scene.children.length; i ++ ){
          const child = scene.children[i];
          child.think && child.think(deltaTime, currentTime, camera);
        }

        //volumeSphere.rotation.y += 0.005;
        //sunDirection.applyAxisAngle(up, -0.005);
        if(cameraFocusObject && !characterDisplay){
          approaching.current = localArea._id;
          console.log("2 APPROACHING = ", approaching.current, localArea._id);
        }
        cameraFocusObject = characterDisplay && cameraFocusObject;
        if(cameraFocusObject){
          if(cameraFocusObject.tumbleQuaternion){
            const toPlanet = localArea.volumeObject.position.clone().sub(cameraFocusObject.position).normalize();
            camera.position.copy(cameraFocusObject.position.clone().add(toPlanet.multiplyScalar(-0.3)));
          }else{
            const focusUp = cameraFocusObject.up.clone().applyQuaternion(cameraFocusObject.quaternion).normalize();
            const focusForward = new THREE.Vector3();
            cameraFocusObject.getWorldDirection(focusForward);
            const focusRight = focusForward.clone().cross(focusUp);
            camera.position.copy(cameraFocusObject.position.clone().add(focusRight.multiplyScalar(1.2)))
          }

        } else if(approaching.current === localArea._id) {
          if(deltaTime > 0){
            const rotatedCameraPosition = cameraPosition.clone().applyQuaternion(orientation.current).applyQuaternion(localArea.volumeObject.quaternion);
            desiredPosition.current = rotatedCameraPosition.clone().add(localArea.volumeObject.position).applyQuaternion(localArea.quaternion.clone());

            const toDesiredPosition = desiredPosition.current.clone().sub(camera.position);


            const positionDelta = toDesiredPosition.clone().multiplyScalar(0.1);

            if(toDesiredPosition.length() < 0.004){
              approaching.current = false;
              console.log("3 APPROACHING = ", approaching.current, localArea._id);
              camera.position.copy(desiredPosition.current);
              camera.setRotationFromQuaternion(localArea.quaternion.clone().multiply(localArea.volumeObject.quaternion).multiply(orientation.current));
            } else {

              if(positionDelta.length() < 0.004){
                console.log("APPROACHING SMALL")
                positionDelta.normalize().multiplyScalar(0.004);
              }

              camera.position.add(positionDelta);

              camera.quaternion.slerp(localArea.quaternion.clone().multiply(localArea.volumeObject.quaternion).multiply(orientation.current), 0.07);
              /*const localAreaPosition = new THREE.Vector3();
              localArea.volumeObject.getWorldPosition(localAreaPosition);
              camera.lookAt(localAreaPosition)*/
            }
          }

        }else{

          const rotatedCameraPosition = cameraPosition.clone().applyQuaternion(orientation.current).applyQuaternion(localArea.volumeObject.quaternion);
          camera.position.copy(rotatedCameraPosition.clone().add(localArea.volumeObject.position).applyQuaternion(localArea.quaternion.clone()))//.clone().add(localArea.volumeObject.position.clone().applyQuaternion()));

          camera.setRotationFromQuaternion(localArea.quaternion.clone().multiply(localArea.volumeObject.quaternion).multiply(orientation.current));
        }
        sunSphere.quaternion.copy( camera.quaternion );

        if(cameraFocusObject){

          if(!cameraFocusObject.tumbleQuaternion){
            camera.up.copy(cameraFocusObject.up.clone().applyQuaternion(cameraFocusObject.quaternion))
          }
          camera.lookAt(cameraFocusObject.position.clone().add(camera.up.clone().multiplyScalar(0.1)))

        }

        if(sky){
          sky.material.uniforms.time.value = currentTime;
          sky.position.copy(camera.position);
        }

        if(editTool?.current?.beamIsFiring){
          laserSprite.visible = true;
          var forwardVector = new THREE.Vector3();
          camera.getWorldDirection(forwardVector);

          var shift = new THREE.Vector3(-1,1,0);
          shift.applyQuaternion(orientation.current).normalize();
          var startPoint = camera.position.clone().add(shift.multiplyScalar(0.3))

          var endPoint = startPoint.clone();
          endPoint.add(raycaster.ray.direction.clone().multiplyScalar(1.0));
          //endPoint.add(new THREE.Vector3(0,-1,0))
          var midPoint = startPoint.clone();
          //midPoint.add(new THREE.Vector3(0,-0.5,0))
          midPoint.add(raycaster.ray.direction.clone().multiplyScalar(0.5));
          var axis = endPoint.sub(startPoint);
          var unitAxis = axis.clone().normalize();

          var spriteUp = new THREE.Vector3(1,0,0);
          var a = unitAxis.clone().cross(spriteUp).normalize();

          var spriteAngle = Math.acos(unitAxis.clone().dot(spriteUp));

          if(a.dot(spriteUp) < 0){
              spriteAngle *= -1.0;
          }

          var spriteOrientation = new THREE.Quaternion();
          spriteOrientation.setFromAxisAngle(a, -spriteAngle);


          laserSprite.scale.set(10.0, editTool.current.commandArg1/10.0, 1.0);
          laserSprite.position.copy(midPoint);

          var laserCenter = midPoint.clone();
          var look = camera.position.clone().sub(laserCenter);
          var laserRight = unitAxis.clone().cross(look);
          var orthogonalLook = laserRight.clone().cross(unitAxis).normalize();


          var spriteForward = new THREE.Vector3(0,0,1).applyQuaternion(spriteOrientation);
          var cosTheta = orthogonalLook.clone().dot(spriteForward);
          var t = orthogonalLook.clone().cross(spriteForward);
          var angle = Math.acos(cosTheta);

          var dp_unitAxis = t.dot(unitAxis)


          if( dp_unitAxis > 0.0){
              angle *= -1.0;
              console.log("reverse reverse")
          } else {
            console.log("FORWARD");
          }
          console.log("angle = ", angle);


          var axialBillboardOrientation = new THREE.Quaternion();
          axialBillboardOrientation.setFromAxisAngle(unitAxis, angle);


          laserSprite.setRotationFromQuaternion(axialBillboardOrientation.multiply(spriteOrientation));
          laserSprite.material.uniforms.time.value = currentTime;
        } else {
          laserSprite.visible = false;
        }

        particleSystem.think(deltaTime, camera);

        renderer.setRenderTarget(null);
        renderer.clear();
        composer.render();

        frameId.current = requestAnimationFrame( animate.bind(null, isPaused, characterDisplay) );

      };



      return animate;
    };

    const run = async () => {
      if(localArea){
        console.log("biggie DRAW PLANET")
        prepareFrame.current = await drawPlanet(canvas, localArea);
      }

      if(prepareFrame.current){
        console.log("biggie REQUEST FRAME")
        if(frameId.current){
          cancelAnimationFrame(frameId.current);
          frameId.current = null;
        }
        frameId.current = requestAnimationFrame(prepareFrame.current.bind(null, true, characterDisplay));
      }
    };
    setUpComposer().then(c =>{
      run();
    });

    return () => {
      isCanceled = true;
      console.log("Cleaning up Planet!");
      if(frameId.current){
        console.log("Cancel animation frame!")
        cancelAnimationFrame(frameId.current);
      }

      cancelInteractable && cancelInteractable();
      for(var pass of addedPasses){
        composer.removePass(pass);
      }
    };
  }, [renderer, composer, particleSystem, localArea, universe, sky, sunSphere, laserSprite, selectedShip]);

  useEffect(()=>{
    var isCanceled = false;
    if(prepareFrame.current){
      if(frameId.current){
        cancelAnimationFrame(frameId.current);
        frameId.current = null;
      }
      frameId.current = requestAnimationFrame(prepareFrame.current.bind(null, true, characterDisplay));
    }
    return () => {
      isCanceled = true;
      console.log("Cleaning up Planet!");
      if(frameId){
        console.log("Cancel animation frame!")
        cancelAnimationFrame(frameId.current);
      }
    };
  },[characterDisplay]);


  const dismiss = useCallback(()=>{console.log("CLEARING CHARACTER DISPLAY!!");setCharacterDisplay(null);},[])
  return (
    <div className="Planet">

        <DisplayItem {...characterDisplay} selectedShip={selectedShip} onDismiss={dismiss}/>
        {localArea && <Outlet context={{planet: localArea?.proto, editTool, localArea, universe, characterDisplay, census, selectedShip}}/>}
        <div className="controls">
          {/*isPaused ? (<Button onClick={toggle}><PlayArrowIcon color="info"/></Button>) : (<Button onClick={toggle}><PauseIcon color="info"/></Button>)*/}
        </div>

        <div id="wrapper">
        <div style={{zIndex:1}}>{currentGate?.solarSystem?.getName()}</div>
          <canvas id="planetCanvas" />
        </div>
        {navigationGate && <GateTravelDialog gate={navigationGate} onClick={(l)=>{setProspectiveDestination(l)}}/>}
        {prospectiveDestination && <TravelDialog ship={selectedShip} destination={prospectiveDestination} onClose={()=>{setProspectiveDestination(null)}}/>}
    </div>
  );
}