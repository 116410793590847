import './GalaxyMap3d.css'
import {useState, useEffect, useRef, useContext} from 'react';
import { useNavigate, useOutletContext } from "react-router-dom";
import * as proto from './protos/universe_pb'
import {api} from './apis/api'
import * as THREE from "three";
import {webgl2Support} from "./libs/webglSupport"
import {SkyTexture} from './libs/skyTexture'
import {Interactable} from './Interactable'
import {fetchFile, disposeObject} from './libs/utils'
import {AuthContext} from './Web3Container'
const raycaster = new THREE.Raycaster();



export function GalaxyMap3d() {
  const {web3OptedIn} = useContext(AuthContext);
  const navigate = useNavigate();
  const {galaxy, universe, mouse, scene} = useOutletContext();
  const frameId = useRef();

  var hoverId = null;
  var sky = null;

  useEffect(() => {
    const container = document.getElementById('galaxyMap');
    if(container){
      const header = document.getElementById('header');
      if(header){
        container.style.height = `${window.innerHeight - header.clientHeight}px`;
      } else {
        container.style.height = `${window.innerHeight}px`;
      }
    }
    return () => {
      console.log("GALAXY UNMOUNTED")
    }
  }, []);


  useEffect( () => {
    var added = [];
    const run = async () => {
      if( galaxy){
        var canvas = document.getElementById('spaceCanvas');
        if(!canvas){
          canvas = document.getElementById('galaxyCanvas');
        }
        const gl = canvas.getContext("webgl2");

        if(gl){
          added = await drawGalaxy(canvas, gl);
        } else {
           //WebGL2 is required
        }
      }
    };
    run();

    return () => {
      console.log("Disposing galaxy")
      if(frameId){
        console.log("GALAXY Cancel animation frame!")
        cancelAnimationFrame(frameId.current);
      }
      for(let item of added){

        scene.remove(item);
        disposeObject(item);
      }
    }
  }, [galaxy]);

  function getSolarSystemWidth(solarSystem){

    const localAreas = solarSystem.getLocalareasList();
    const distances = localAreas.map(l=>{return universe.getLocalareamapMap().get(l.getId()).getDistancefromsun()});
    const maxDistance = Math.max.apply(Math, distances);
    const minDistance = Math.min.apply(Math,distances);
    return maxDistance - minDistance;
  }

  async function drawGalaxy(canvas, gl){
    const added = [];
    const skyboxVertexShader = await fetchFile("/shaders/skybox.vertex");
    const skyboxFragmentShader = await fetchFile("/shaders/skybox.fragment");

    var skyboxConfig = new proto.SkyboxConfig();
    var noiseConfig = new proto.NoiseConfig();
    noiseConfig.setOctave(1.4);
    noiseConfig.setPersistence(0.35);
    noiseConfig.setScale(0.0007);
    noiseConfig.setDecay(0.547);
    skyboxConfig.setRed(noiseConfig);
    /*noiseConfig = new proto.NoiseConfig();
    noiseConfig.setOctave(2.0);
    noiseConfig.setPersistence(0.2);
    noiseConfig.setScale(0.00005);
    noiseConfig.setDecay(0.2);*/
    skyboxConfig.setGreen(noiseConfig);
    /*noiseConfig = new proto.NoiseConfig();
    noiseConfig.setOctave(4.0);
    noiseConfig.setPersistence(0.3);
    noiseConfig.setScale(0.0009);
    noiseConfig.setDecay(0.15)*/
    skyboxConfig.setBlue(noiseConfig);

    skyboxConfig.setHue(180);
    skyboxConfig.setTheta(120);
    const skyTextureGenerator = new SkyTexture(skyboxConfig, true);
    const newSkyTexture =  skyTextureGenerator&&skyTextureGenerator.generateCube(64, 64);
    newSkyTexture.needsUpdate = true;
    newSkyTexture.format = THREE.RGBAFormat;
    newSkyTexture.type = THREE.UnsignedByteType;
    newSkyTexture.minFilter = THREE.LinearFilter;
    newSkyTexture.magFilter = THREE.LinearFilter;

    var skySphere = new THREE.SphereGeometry(15, 32, 32);


    const skyMaterial = new THREE.ShaderMaterial({
      uniforms:{
        lightPos: {value:[0,0,0]},
        lightColor: {value: [0,0,0,0]},
        screenWidth: {value:canvas.clientWidth},
        screenHeight: {value:canvas.clientHeight},
        size: {value:0.0},
        skyTexture: {value: newSkyTexture},
        hover: {value: false}
      },
      vertexShader: skyboxVertexShader,
      fragmentShader: skyboxFragmentShader
    });
    skyMaterial.blending = THREE.CustomBlending;
    skyMaterial.blendEquation = THREE.AddEquation;
    skyMaterial.blendSrc = THREE.SrcAlphaFactor;
    skyMaterial.blendDst = THREE.OneMinusSrcAlphaFactor;
    skyMaterial.side = THREE.BackSide;

    var newSky = new THREE.Mesh(skySphere, skyMaterial);

    sky = newSky;
    scene.add(sky);
    added.push(sky);

    const sunVertexShader = await fetchFile("/shaders/sun.vertex");
    const sunFragmentShader = await fetchFile("/shaders/sun.fragment");

    const solarSystems = galaxy.getSolarsystemsList();
    const positions = solarSystems.map(s=>{return universe.getSolarsystemmapMap().get(s.getId()).getPosition()});
    const maxX = Math.max.apply(Math, positions.map(x => {return x.getX()}));
    const maxY = Math.max.apply(Math, positions.map(x => {return x.getY()}));
    const minX = Math.min.apply(Math, positions.map(x => {return x.getX()}));
    const minY = Math.min.apply(Math, positions.map(x => {return x.getY()}));


    for(let idx in solarSystems){

       const s = solarSystems[idx];
       const solarSystem = universe.getSolarsystemmapMap().get(s.getId());
       const sunColor = solarSystem.getSuncolor();

       var geometry = new THREE.PlaneGeometry( 2, 2 );
       var material = new THREE.ShaderMaterial( {
            uniforms: {
                lightColor: {value: [sunColor.getR(),sunColor.getG(),sunColor.getB(),1]},
                screenWidth: {value: canvas.clientWidth},
                screenHeight: {value: canvas.clientHeight},
                hover: {value: false}
            },
            vertexShader: sunVertexShader,
            fragmentShader: sunFragmentShader
        } );
       material.blending = THREE.AdditiveBlending;
       material.depthTest = false;


       var sun = new THREE.Mesh( geometry, material );
       var scale = getSolarSystemWidth(solarSystem)/1000;
       const position = new THREE.Vector3(((solarSystem.getPosition().getX() - minX)/(maxX-minX) - 0.5) * 20,
                                          0,
                                          ((solarSystem.getPosition().getY()-minY)/(maxY-minY) - 0.5) * 20);
       if(position.length() > 56.569){
        console.log(position.length())
       }
       const randLength = (position.length()/56.569 * 0.25  + (1.0-sunColor.getR()*sunColor.getG()*sunColor.getB()) * 0.75);
       console.log("length = ", randLength);
       position.y = (randLength - 0.5) * 10;


       //const position = new THREE.Vector3((solarSystem.getPosition().getX()/100, 0, solarSystem.getPosition().getY()/100));
       sun.name = solarSystem.getId();
       sun.position.copy(position);


       sun.scale.x = scale;
       sun.scale.y = scale;
       sun.scale.z = scale;
       scene.add( sun );
       added.push(sun);
    }


    var cameraPosition = new THREE.Vector3(0,0, 30);
    var camera = new THREE.PerspectiveCamera( 60, canvas.clientWidth/canvas.clientHeight, 0.1, 1000 );

    camera.position.copy(cameraPosition);

    var renderer = new THREE.WebGLRenderer({canvas:canvas, context: gl});
    renderer.setSize( canvas.clientWidth, canvas.clientHeight );

    const onWindowResize = () => {
      const wrapper = document.getElementById("galaxyMap");
      renderer.setSize( wrapper.clientWidth, wrapper.clientHeight );
      camera.aspect = wrapper.clientWidth/wrapper.clientHeight;
      camera.updateProjectionMatrix();
    };
    window.addEventListener('resize', onWindowResize, false);

    var touchStart = false;
    var orientation = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1,0,0), Math.PI * 0.5 );
    Interactable(canvas, orientation, cameraPosition, mouse, function(mousePos){
      console.log("Starting touch");
      touchStart = true;
      return true;
    }, function(event, ratio){

       cameraPosition.z = cameraPosition.z * ratio;
       if(cameraPosition.z < 10){
           cameraPosition.z = 10;
       }
       else if(cameraPosition.z > 50){
           cameraPosition.z = 50;
       } else {
         event.preventDefault();
       }
     });





    var animate = function () {
      frameId.current = requestAnimationFrame( animate );
      //sky.rotation.y += 0.005;
      camera.position.copy(cameraPosition);
      camera.position.applyQuaternion(orientation);
      camera.setRotationFromQuaternion(orientation);

      //sky.setRotationFromQuaternion(orientation.clone());
      //scene.setRotationFromQuaternion( orientation );
      for(let i = 0; i < scene.children.length; i ++ ){
        scene.children[i].material.uniforms.hover.value = false;
        if(scene.children[i].name){
          scene.children[i].setRotationFromQuaternion( orientation );
          }

      }

      //

      raycaster.setFromCamera( mouse, camera );

      // calculate objects intersecting the picking ray
      const intersects = raycaster.intersectObjects( scene.children ).filter(x => {
        return x.object.name != ""
      });


      if(intersects.length > 0){
        document.body.style.cursor = "pointer";
        const uvCenter = new THREE.Vector2(0.5, 0.5);
        var minCenterDist = intersects[0].uv.distanceTo(uvCenter);
        var hoverObj = intersects[0];
        for(let i = 1; i < intersects.length; i++){
          const obj = intersects[i];
          const centerDist = obj.uv.distanceTo(uvCenter);
          if(centerDist < minCenterDist){
            hoverObj = obj;
            minCenterDist = centerDist;
          }

        }
        if(touchStart && hoverId == hoverObj.object.name){
          navigate(web3OptedIn ? `/app/solarsystems/${hoverId}` : `/home/solarsystems/${hoverId}`)
        }

        hoverId = hoverObj.object.name;
        hoverObj.object.material.uniforms.hover.value =  true ;

      } else {
        document.body.style.cursor = "default";
        hoverId = null;
      }


      renderer.render( scene, camera );
      touchStart = false;
    };
    animate();
    return added;
  }




  if(!universe || !galaxy){
    return null;
  }



  return (
    <div id="galaxyMap" className="GalaxyMap3d" >
      <canvas id="galaxyCanvas" />
    </div>
  );
}