import axios from 'axios'
import {LOCAL_INTERACTIONS} from '../constants/localContent'
const base_url = `${process.env.REACT_APP_API_BASE_URL}/characters`

export class Characters {

  constructor(authContext){
    this.authHeaders = {
      'Authorization': authContext?.auth?.signature,
      'X-Signature-Timestamp': authContext?.auth?.timestamp,
      'X-Signature-Address': authContext?.account,
    };
    this.invalidateAuth = authContext?.invalidateAuth;
    this.web3OptedIn = authContext?.web3OptedIn;
  }

  async getInteraction(characterId, selectedShip) {

    if(!this.web3OptedIn && characterId in LOCAL_INTERACTIONS){
      return LOCAL_INTERACTIONS[characterId];
    }
    const config = {
      headers: this.authHeaders,
      params: {shipToken: selectedShip?.token},
    };

    try {
      const {data} = await axios.get(`${base_url}/${characterId}/interaction`, config)
      return data;
    } catch (e) {
      if(e?.response?.status === 401){
        this.invalidateAuth();
      } else {
        throw e;
      }
    }
  }
};

