import * as THREE from "three";

export const formatBalance = (balance, drizzle) => balance ? Math.trunc(drizzle.web3.utils.fromWei(balance) * 1000)/1000 : '-';

export function fetchFile(path) {
   return fetch(path)
               .then((r) => {return r.text()})
               .then(t  => {
                 return t;
               });
 }

export const loadSkyTexture = async (solarSystemId) =>{
 return new Promise((resolve,reject) => {
   new THREE.CubeTextureLoader()
            .setPath( `/solarSystems/${solarSystemId}/` )
            .load( [
                     'skybox_0.png',
                     'skybox_1.png',
                     'skybox_2.png',
                     'skybox_3.png',
                     'skybox_4.png',
                     'skybox_5.png'
                    ] , function(texture){
                          resolve(texture);
                       }, function(){}, function(err){reject(err);});
   });

 };

export const cleanMaterial = material => {
  console.log('dispose material!')
  material.dispose()

  // dispose textures
  for (const key of Object.keys(material)) {
    const value = material[key]
    if (value && typeof value === 'object' && 'minFilter' in value) {
      console.log('dispose texture!')
      value.dispose()
    }
  }
};

export const disposeObject = (obj) => {

  if(obj?.children){
    for(var child of obj.children){
      disposeObject(child);
    }
  }
  if(obj.geometry){
    obj.geometry.dispose();
  }
  if(obj.material){
    if (obj.material.length) {
        for (let i = 0; i < obj.material.length; ++i) {
            cleanMaterial(obj.material[i])
        }
    }
    else {
      cleanMaterial(obj.material)
    }
  }
 };

export const displayTime = (seconds) => {
  var minutes = seconds / 60;
  var hours = minutes / 60;
  var parts = [];

  hours = Math.trunc(hours);
  if (hours >= 1.0 || parts.length > 0){
    parts.push(`0${hours}h`.slice(-3));
  }

  minutes = Math.trunc(minutes - hours * 60)
  if (minutes >= 1 || parts.length > 0){
    parts.push(`0${minutes}m`.slice(-3))
  }

  seconds = Math.trunc(seconds - minutes * 60 - hours * 60 * 60)
  if (seconds >= 1 || parts.length > 0){
    parts.push(`0${seconds}s`.slice(-3))
  }
  return parts.join(" ")
}