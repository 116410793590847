import styled from 'styled-components';

export const ArtifactStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  padding: 3vh;

  .artifact-image {
    max-height: min(50vmin, 512px);
    max-width: min(50vmin, 512px);
    aspect-ratio: 1;
    border-radius: 10px;
    border: 2px solid #0f09;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position:relative;
    flex-shrink: 1;
    overflow:hidden;
    box-shadow: 0px 0px 20px 0px #0f08;
  }

  .artifact-description {
    margin-top: 50px;
    padding: 10px;
    max-width: min(65vmin, 512px);
    max-height: 40vmin;
    border-radius: 10px;
    border: 2px solid #0f09;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position:relative;
    flex-shrink: 1;
    overflow:auto;
    box-shadow: 0px 0px 20px 0px #0f08;
    background-color: #000;
  }


  .artifact-salvage{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 300px;
    width: fit-content;
    .salvage-value {
      display: flex;
      align-items: center;
      .vox-icon {
        height: 2em;
        margin-right: 1em;
      }
      margin-bottom: 1em;

    }
    .local-ships {
      display: flex;
      flex-direction: row;
    }

  }


`;