import './PlanetGenesis.css'
import {useState} from 'react';
import { useOutletContext, useNavigate, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';


export default function PlanetGenesis(props) {
  const [isGenesisVisible, setIsGenesisVisible] = useState(false);

  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const location = useLocation();
  const planet = outletContext.planet;

  if(!planet){
    return null;
  }
  return (
    <>
     <Helmet>
         <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="twitter:card" content="player" />
          <meta name="twitter:site" content="@whistlemangames" />
          <meta name="twitter:title" content={planet.getName()} />
          <meta name="twitter:description"
            content={`Genesis of Planet ${planet.getName()} in Galaxy Hustle.`} />
          <meta name="twitter:player" content={location.pathname} />
          <meta name="twitter:player:width" content="480" />
          <meta name="twitter:player:height" content="480" />
     </Helmet>
    <Dialog className="PlanetGenesis" onClose={()=>{navigate(`/app/planets/${planet.getId()}`)}} open={Boolean(planet)}>
      <div className="glitchPanel genesisDialog">
        <DialogTitle className="displayPanel">Planet {planet.getName()} Genesis</DialogTitle>
        <video className="displayPanel" style={{borderTop:"none"}} controls autoPlay loop muted name="media">
          <source src={`/universe/localAreas/local_area_${planet.getId()}.mp4`} type="video/mp4" />
        </video>
      </div>
    </Dialog>
    </>
  );
}