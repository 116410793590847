import styled from 'styled-components';

export const MintedDialogStyled = styled.div`

  padding: 10px;

  width:80vmin;
  height: 90%;
  overflow-y: auto;
  border-radius: 10px 10px 10px 10px;
  border: 2px solid #0f0;
  background-color: #000;
  display: flex;
  flex-direction: column;
  

  .vox-icon {
    width: 1rem;
  }
  .matic-icon {
    width: 1rem;
  }

  .pilot-container {
    background-color: #0f0;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
  }
  .free-pilot-text{
    flex-grow:3;
  }
  .pilot-avatar {
    border-radius: 50%;
    height: 6em;
    background-color: #f0f;
    flex-grow:0;
  }
`;