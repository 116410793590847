import * as proto from './protos/universe_pb'
import * as THREE from "three";
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader'
import {OrbitingEntity} from './OrbitingEntity'

const protoToVector3 = (protobuf) => {
  return new THREE.Vector3(protobuf.getX(), protobuf.getY(), protobuf.getZ());
}

export class Character extends OrbitingEntity {
  constructor(object, protobuf, params) {
    super(object, protobuf, params);
    this.rocketNozzles = protobuf.getNozzlesList();
    this.task = protobuf.getTask();
    this._isHailing = false;
  };

  get isHailing(){
    return this._isHailing;
  }

  set isHailing(val){
    this._isHailing = val;

    if(!val){
      this.traverse((child) => {
        if( child instanceof THREE.Mesh ){
          child.material.emissive = new THREE.Color(1,1,1,1);
        }
      });
    }
  }
  async click(mousePos, selectedShip, api){
    if( this.isHailing){
      return null;
    }

    this.isHailing = true;
    try{
      const result = await api.characters.getInteraction(this._id, selectedShip)
      return result;
    } finally {
      this.isHailing = false;
    }
  }

  emitParticles(elapsedTime, fwd, up, right, orbitQuat){
    if(this._particleSystem){
      for(var nozzle of this.rocketNozzles){

        var pos = protoToVector3(nozzle.getPosition()).multiplyScalar(0.05);
        var vel = protoToVector3(nozzle.getVelocity()).multiplyScalar(0.25);
        vel = fwd.clone().multiplyScalar(vel.y).add(right.clone().multiplyScalar(vel.x)).add(up.clone().multiplyScalar(vel.z));

        var posShift = fwd.clone().multiplyScalar(pos.y).add(right.clone().multiplyScalar(pos.x)).add(up.clone().multiplyScalar(pos.z));
        pos = this.position.clone().add(posShift);
        var r = 255 * nozzle.getColor().getR();
        var g = 255 * nozzle.getColor().getG();
        var b = 255 * nozzle.getColor().getB();
        this._particleSystem.add({
          position: pos,
          velocity: vel,
          lifetime: nozzle.getLifetime(),
          size: nozzle.getSize(),
          type: 1.0,
          color: [r + Math.random()*(255-r), g + Math.random()* (255-g), b + Math.random()*(255-b), 255 * nozzle.getColor().getA()],
        });
      }

    }
  }

  think(elapsedTime, currentTime){
    if(this.isHailing){
      this.traverse((child) => {
        if( child instanceof THREE.Mesh ){
          let value = currentTime % 1.0;
          value = (Math.cos(value * Math.PI * 2.0) + 1.0) / 4.0;
          child.material.emissive = new THREE.Color(value,value,value);
        }
      });
    }
    super.think(elapsedTime);
  }
  static async build(proto, params={}){

    console.log("Building Character");
    const object = await this.buildObject(proto);
    const result = new Character(object, proto, params);

    return result;
  }

  static async buildJson(defn, params={}){

    var characterProto = new proto.Character();
    characterProto.setId(defn.id);
    /*const taskProto = new proto.Task();
    const displayItem = new proto.DisplayItem();
    const speech = new proto.Speech();
    const speechParagraphs = ["Go away.", "Now!"].map(p => {
      const paragraph = new proto.SpeechParagraph();
      paragraph.setSpeech(p);
      return paragraph;
    });
    speech.setSpeechparagraphsList(speechParagraphs);
    displayItem.setSpeech(speech);
    taskProto.setIntrodisplayitemsList([displayItem]);
    characterProto.setTask(taskProto);*/
    characterProto.setModelfilename(defn.modelFilename);
    characterProto.setTexturefilename(defn.textureFilename);
    var orbit = new proto.Orbit();
    var orbitCenter = new proto.Vector3d();
    orbitCenter.setX(defn.orbit.center[0]);
    orbitCenter.setY(defn.orbit.center[1]);
    orbitCenter.setZ(defn.orbit.center[2]);
    orbit.setCenter(orbitCenter);
    var orbitSpeed = new proto.Vector3d();
    orbitSpeed.setX(defn.orbit.speed[0]);
    orbitSpeed.setY(defn.orbit.speed[1]);
    orbitSpeed.setZ(defn.orbit.speed[2]);
    orbit.setSpeed(orbitSpeed);
    var orbitOffset = new proto.Vector3d();
    orbitOffset.setX(defn.orbit.offset[0]);
    orbitOffset.setY(defn.orbit.offset[1]);
    orbitOffset.setZ(defn.orbit.offset[2]);
    orbit.setOffset(orbitOffset);
    var orbitSize = new proto.Vector3d();
    orbitSize.setX(defn.orbit.size[0]);
    orbitSize.setY(defn.orbit.size[1]);
    orbitSize.setZ(defn.orbit.size[2]);
    orbit.setSize(orbitSize);

    characterProto.setOrbit(orbit);

    var nozzles = defn.nozzles.map(nozzleDefn => {
       var nozzle = new proto.RocketNozzle();
       var nozPos = new proto.Vector3d();
       nozzle.setPosition(nozPos);
       nozPos.setX(nozzleDefn.position[0]);
       nozPos.setY(nozzleDefn.position[1]);
       nozPos.setZ(nozzleDefn.position[2]);

       var nozVel = new proto.Vector3d();
       nozzle.setVelocity(nozVel);
       nozVel.setX(nozzleDefn.velocity[0]);
       nozVel.setY(nozzleDefn.velocity[1]);
       nozVel.setZ(nozzleDefn.velocity[2]);

       var nozCol = new proto.Color();
       nozzle.setColor(nozCol);
       nozCol.setR(nozzleDefn.color[0]);
       nozCol.setG(nozzleDefn.color[1]);
       nozCol.setB(nozzleDefn.color[2]);
       nozCol.setA(nozzleDefn.color[3]);
       nozzle.setLifetime(nozzleDefn.lifetime);
       nozzle.setSize(nozzleDefn.size);
       nozzle.setPeriod(nozzleDefn.period);
       return nozzle;
    });

    characterProto.setNozzlesList(nozzles);

    return Character.build(characterProto, params);
  }

}