import { ethers } from "ethers";

const getAuthMessage = timestamp => {
  return  `Galaxy Hustle Authentication:

Please sign this message to verify your account.
Valid as of this time: ${timestamp}`;
};

const sign = async ( provider, message ) => {
  const signer = new ethers.providers.Web3Provider(provider).getSigner();
  return signer.signMessage(message);
};

export const signAuthMessage = async ( provider, timestamp ) => {
  return sign( provider, getAuthMessage(timestamp) );
};



