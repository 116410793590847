import styled from 'styled-components';

export const PanelStyled = styled.div`
  width: ${props => props.width || "100%"};
  height: ${props => props.height || "100%"};
  .panel {
    border: 2px solid #0f09;
    border-radius: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    position:relative;
    flex-shrink: 1;

    .panel-wrapper {
      display:flex;
      overflow: hidden;
      width: 100%;
      height: 100%;
      position:absolute;
      border-radius: inherit;
    }
    .glitch-effect {
      border: 1px solid #0f09;



      border-radius: 10px;
      width: 100%;
      height: 100%;
      flex-grow: 1;
      position:absolute;
    }

    .glitch-effect::after {
        animation: 2.0s cubic-bezier(0.22, 0.61, 0.36, 1) 0.25s infinite normal none running glitch;

    }

    .glitch-effect::before {
        animation: 1.0s cubic-bezier(0.22, 0.61, 0.36, 1) 0.5s infinite reverse none running glitch;

    }

    .glitch-effect:after, .glitch-effect:before{
        border: 2px solid #0f09;
        border-radius: 10px;
        content: "";
        height: 100%;
        width: 100%;

        position: absolute;
        top: -1px;
        left: -1px;

        z-index: 10;
    }

    @keyframes glitch {
      0% {
          opacity: 1;
          border: 1px solid #0f0;
          -webkit-transform: scale(100%);
          -ms-transform: scale(100%);
          transform: scale(100%) translate(2px, -3px);
          z-index: -1;
      }

      17% {
          opacity: 0.5;
          border: 2px solid #f0f;
          -webkit-transform: rotate(-0.5deg) scale(98%);
          -ms-transform: rotate(-0.5deg) scale(98%);
          transform: rotate(-0.5deg) scale(100%) translate(-2px, 2px);

      }


      33% {
          opacity: 0.3;
          border: 1px solid #0ff;
          -webkit-transform: rotate(-0.5deg);
          -ms-transform: rotate(-0.5deg);
          transform: rotate(-0.5deg) translate(3px, 3px);
      }

      50% {
            opacity: 0.8;
            border: 3px solid #f0f;
            -webkit-transform: rotate(0.5deg) scale(100%);
            -ms-transform: rotate(0.5deg) scale(100%);
            transform: rotate(0.5deg) scale(100%) translate(-2px, -2px);
        }
      60% {
              opacity: 0.5;
              border: 1px solid #0f0;
              -webkit-transform: rotate(-0.5deg);
              -ms-transform: rotate(-0.5deg);
              transform: rotate(-0.5deg) translate(3px, 3px);
          }

      100% {
          opacity: 0.5;
          border: 3px solid #0ff;
          -webkit-transform: rotate(0.5deg) scale(100%);
          -ms-transform: rotate(0.5deg) scale(100%);
          transform: rotate(0.5deg) scale(100%) translate(-2px, 2px);
          z-index: -1;
      }
    }
  }
`;