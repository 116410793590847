export function webglSupport () {
   try {
    var canvas = document.createElement('canvas');
    return !!window.WebGLRenderingContext &&
      (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'));
   } catch(e) {
     return false;
   }
 };

 export function webgl2Support () {
    try {
     var canvas = document.createElement('canvas');
     return !!window.WebGLRenderingContext &&
       canvas.getContext('webgl2');
    } catch(e) {
      return false;
    }
  };