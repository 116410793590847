import * as proto from './protos/universe_pb'
import * as THREE from "three";
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader'
import {disposeObject} from './libs/utils'


const loadObject = async (filename) => {
  filename = `/${filename}`
  console.log("loading Object = ", filename)

  return new Promise((resolve,reject) => {
    new OBJLoader().load(
      filename,
      (object) => {
        resolve(object);
      },
      null,
      (error) => {
        reject(error);
      }
    );
  });
}

const protoToVector3 = (proto) => {
  return new THREE.Vector3(proto.getX(), proto.getY(), proto.getZ());
}

export class OrbitingEntity extends THREE.Object3D {

  constructor(object, proto, params) {
    super();
    this.copy(object);
    disposeObject(object);
    this._id = proto.getId();
    this.universe = params.universe;
    this._particleSystem = params.particleSystem;
    this.totalTime = 0.0;
    this.orbitCenter = protoToVector3(proto.getOrbit().getCenter()).multiplyScalar(0.05);
    this.orbitSpeed = protoToVector3(proto.getOrbit().getSpeed());
    this.orbitOffset = protoToVector3(proto.getOrbit().getOffset());
    this.orbitSize = protoToVector3(proto.getOrbit().getSize()).multiplyScalar(0.05);
    this.velocity = new THREE.Vector3(0,0,0);
    this.scale.set(0.05,0.05,0.05)
    this.position.set(0,0.5,0)
    this.isHovering = false;
    this.hoverActive = false;
    this.isInteractable = true;
    this.orbitObject = null;
    this.tumbleQuaternion = null;
  }

  emitParticles(elapsedTime, fwd, up, right){

  }

  async click(mousePos, selectedShip, api){
    if( this.isHailing){
      return null;
    }

    this.isHailing = true;
    try{
      const result = await api.artifacts.getInteraction(this._id)
      return result;
    } finally {
      this.isHailing = false;
    }
  }

  think(elapsedTime) {
    elapsedTime = elapsedTime || 0.001
    if(this.isHovering && !this.hoverActive){
      this.traverse((child) => {
        if( child instanceof THREE.Mesh ){
          child.material.emissive = new THREE.Color(0x883388);
        }
      });
      this.hoverActive = true;
    } else if(!this.isHovering && this.hoverActive){
      this.traverse((child) => {
        if( child instanceof THREE.Mesh ){
          child.material.emissive = new THREE.Color(0x000000);
        }
      });
      this.hoverActive = false;
    }
    this.totalTime += elapsedTime;

    var orbitObjectPosition = new THREE.Vector3();
    var orbitObjectQuaternion = new THREE.Quaternion();
    if(this.orbitObject){
      orbitObjectPosition = this.orbitObject.volumeObject.position;
      orbitObjectQuaternion = this.orbitObject.quaternion;
    }
    var newPosition = this.orbitCenter.clone().add(new THREE.Vector3(
      Math.cos(this.totalTime * this.orbitSpeed.x + this.orbitOffset.x) * this.orbitSize.x,
      Math.sin(this.totalTime * this.orbitSpeed.y + this.orbitOffset.y) * this.orbitSize.y,
      Math.sin(this.totalTime *this.orbitSpeed.z + this.orbitOffset.z) * this.orbitSize.z
    ));

    var lastPosition = this.orbitCenter.clone().add(new THREE.Vector3(
     Math.cos((this.totalTime-elapsedTime) * this.orbitSpeed.x + this.orbitOffset.x) * this.orbitSize.x,
     Math.sin((this.totalTime-elapsedTime) * this.orbitSpeed.y + this.orbitOffset.y) * this.orbitSize.y,
     Math.sin((this.totalTime-elapsedTime) *this.orbitSpeed.z + this.orbitOffset.z) * this.orbitSize.z
   ));

    this.velocity = newPosition.clone().sub(lastPosition).multiplyScalar(1.0 / elapsedTime);
    var fwd = this.velocity.clone().normalize();
    this.position.copy(orbitObjectPosition.clone().applyQuaternion(orbitObjectQuaternion).add(newPosition));

    var up = newPosition.clone().normalize();
    var right = up.clone().cross(fwd);

    if(this.tumbleQuaternion){
      this.applyQuaternion(this.tumbleQuaternion);
    }else{
      var orthonormalBasis = new THREE.Matrix4();
      orthonormalBasis.lookAt(new THREE.Vector3(), fwd, up);

      var q = new THREE.Quaternion();
      q.setFromRotationMatrix(orthonormalBasis);
      this.setRotationFromQuaternion(q);
    }

    this.emitParticles(elapsedTime, fwd, up, right, orbitObjectQuaternion);
  }

  static async buildJson(defn, params={}){
    var entityProto = new proto.Asteroid();
    entityProto.setId(defn.id);

    entityProto.setModelfilename(defn.modelFilename);
    entityProto.setTexturefilename(defn.textureFilename);
    var orbit = new proto.Orbit();
    var orbitCenter = new proto.Vector3d();
    orbitCenter.setX(defn.orbit.center[0]);
    orbitCenter.setY(defn.orbit.center[1]);
    orbitCenter.setZ(defn.orbit.center[2]);
    orbit.setCenter(orbitCenter);
    var orbitSpeed = new proto.Vector3d();
    orbitSpeed.setX(defn.orbit.speed[0]);
    orbitSpeed.setY(defn.orbit.speed[1]);
    orbitSpeed.setZ(defn.orbit.speed[2]);
    orbit.setSpeed(orbitSpeed);
    var orbitOffset = new proto.Vector3d();
    orbitOffset.setX(defn.orbit.offset[0]);
    orbitOffset.setY(defn.orbit.offset[1]);
    orbitOffset.setZ(defn.orbit.offset[2]);
    orbit.setOffset(orbitOffset);
    var orbitSize = new proto.Vector3d();
    orbitSize.setX(defn.orbit.size[0]);
    orbitSize.setY(defn.orbit.size[1]);
    orbitSize.setZ(defn.orbit.size[2]);
    orbit.setSize(orbitSize);

    entityProto.setOrbit(orbit);

    return OrbitingEntity.build(entityProto, params);
  }
  static async buildObject(proto){
    console.log("loading texture = ", proto.getTexturefilename())
    const texture = new THREE.TextureLoader().load(`/${proto.getTexturefilename()}`);
    var material = new THREE.MeshStandardMaterial({
      map: texture,
    });

    var object = await loadObject(proto.getModelfilename());

    object.traverse((child) => {
      if( child instanceof THREE.Mesh ){
        child.material = material;
      }
    })
    return object;
  }
  static async build(proto, params={}){

    console.log("Building OrbitingEntity");
    const object = await this.buildObject(proto);
    const result = new OrbitingEntity(object, proto, params);

    return result;
  }


}