import * as proto from './protos/universe_pb'
import * as THREE from "three";
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader'
import {OrbitingEntity} from './OrbitingEntity'


const loadObject = async (filename) => {
  filename = `/${filename}`
  console.log("loading Object = ", filename)

  return new Promise((resolve,reject) => {
    new OBJLoader().load(
      filename,
      (object) => {
        resolve(object);
      },
      null,
      (error) => {
        reject(error);
      }
    );
  });
}
export class StarFragment extends OrbitingEntity {

  emitParticles(elapsedTime, fwd, up, right){
    if(this._particleSystem){
      var backward = fwd.clone().multiplyScalar(-1.0 * elapsedTime);
      for(let i = 0; i < 10; i++){
      var displacement = new THREE.Vector3(Math.random()-0.5,Math.random()-0.5, Math.random()-0.5).normalize();
              displacement.multiplyScalar(Math.random()*0.08);

        var vel = backward.clone().multiplyScalar(Math.random() * 2.0).add(displacement);

        var pos = this.position.clone().add(displacement);
        var colorVar = Math.random();
        var color;
        if(colorVar < 0.33){
          color = [200 + Math.random()*55, Math.random()*64.0, Math.random()*255, 255.0]
        } else if(colorVar < 0.66){
          color = [Math.random()*64.0,Math.random()*255, 200 + Math.random()*55, 255.0]
        } else {
          color = [Math.random()*64.0, 200 + Math.random()*55, Math.random()*64.0, 255.0]
        }
        this._particleSystem.add({
          position: pos,
          velocity: vel,
          lifetime: 0.3 + Math.random() * 0.7,
          size: 0.4 + Math.random() * 0.4,
          type: 1.0,
          color: color,
        });
      }


    }
  }

  static async buildObject(p){
    const texture = new THREE.TextureLoader().load("/star.003.png");
    var material = new THREE.MeshStandardMaterial({
      map: texture,

    });

    var object = await loadObject("star.obj");

    object.traverse((child) => {
      if( child instanceof THREE.Mesh ){
        child.material = material;
      }
    })
    return object;
  }
  static async build(p, params={}){

    console.log("Building StarFragment");
    const object = await this.buildObject(p);
    const result = new StarFragment(object, p, params);

    return result;
  }

}