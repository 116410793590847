import {PlanetPanelStyled} from './PlanetPanelStyled'
import {useState, useEffect, useContext} from 'react';
import {useOutletContext} from 'react-router-dom'
import {Button, Paper, Link, Tooltip} from '@mui/material'
import {api} from './apis/api'
import { drizzleReactHooks } from '@drizzle/react-plugin';
import {ReactComponent as VoxIcon} from './assets/vox_crystal_icon.svg'
import { useTheme } from '@mui/material/styles';
import {AuthContext} from './Web3Container'
import ShipMini from './ShipMini'
import NoSignal from './NoSignal'
import Operator from './Operator'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {UniverseContext} from './context/UniverseContext'
import {displayTime} from './libs/utils'

export const ALIGNMENT_FRIENDLY = 1;
export const ALIGNMENT_NEUTRAL = 2;
export const ALIGNMENT_HOSTILE = 3;

const ShipControl = (props) => {
  const {
    level,
    metadata,
    operator,
    location,
    token: shipToken,
  } = props.ship;
  const onBack = props.onBack || (()=>{});
  const theme = useTheme();
  const { drizzle, useCacheCall, useCacheSend } = drizzleReactHooks.useDrizzle();
  const nextSalvageTime = useCacheCall("Fleet", "getNextSalvageTime", shipToken);
  const [timeToNextSalvage, setTimeToNextSalvage] = useState();
  const { send: sendSalvage, TXObjects: salvageTx, status: transportShipStatus} = useCacheSend('Fleet', 'salvage');


  useEffect(()=>{
    setTimeToNextSalvage(nextSalvageTime - Math.trunc((new Date().getTime())/1000));
  },[nextSalvageTime]);

  useEffect(() => {
    var interval = null;
    if (timeToNextSalvage > 0){

      interval = setInterval(() => {
        setTimeToNextSalvage(timeToNextSalvage - 1);
      }, 1000);

    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timeToNextSalvage]);

  const alignmentString = (alignment) => {
    switch(parseInt(alignment)){
      case ALIGNMENT_FRIENDLY:
      return "Friendly";
      case ALIGNMENT_NEUTRAL:
      return "Neutral";
      case ALIGNMENT_HOSTILE:
      return "Hostile";
      default:
      return "Unknown";

    }
  }

  const salvage = async () => {
    sendSalvage(shipToken);
  };

  return (
    <div className="ship-container">

      <div className={"ship-operator " + "alignment-" +location.alignment}>
        <img className="ship-avatar" src={metadata.image}/>
        {operator ?(
         <Operator className="operator-avatar" operator={operator} animated="hovering" showName={false}/>
        ) : null}
        <Button className="back-button" color='info' onClick={onBack}><ArrowBackIcon color='info'/> Disengage</Button>
      </div>
      <div className="control-container">
        <div className="ship-control">
          <div className="label">Level:</div><span className="value">{level}</span>
          <div className="label">Alignment:</div><span className="value">{alignmentString(location.alignment)}</span>

        </div>
        <div className="salvage">
          {
            timeToNextSalvage <= 0 ?
            <Button className="salvage-button" variant="contained" color="info" onClick={salvage}>Salvage</Button> :
            <Button className="salvage-button cooling" variant="contained" disabled={true}><Tooltip title="Cooling down. Time to next salvage"><div>{displayTime(timeToNextSalvage)}</div></Tooltip></Button>
          }
        </div>
      </div>

    </div>
  )
};

export default function PlanetPanel(props){

  const { drizzle, useCacheCall } = drizzleReactHooks.useDrizzle();
  const {universe, localArea, characterDisplay, census} = useOutletContext();
  const {account} = useContext(AuthContext);
  const {selectedShip, setSelectedShip} = useContext(UniverseContext);

  const [localShips, setLocalShips] = useState([]);
  const availableVox = useCacheCall("Galaxy", "getAvailableVox", localArea.tokenId);
  const shipCount = parseInt(useCacheCall("Fleet", "balanceOf", account));
  const theme = useTheme();


  useEffect(()=>{
    if(!account || !shipCount || shipCount <= 0){
      return;
    }

    var isCanceled = false;
    const run = async () => {
      var shipToken = -1;
      const tokens = [];
      for(var i = 0; (i < shipCount); i++){
        try{
          var arg = parseInt(shipToken)+1;
          var nextToken = await drizzle.contracts.Fleet.methods.tokenOfOwnerInLocalAreaAfterId(account, localArea.tokenId, arg).call();
          if(nextToken){
            tokens.push(nextToken);
            shipToken = nextToken;
          }


        } catch (err) {
          console.log(err)
          break;
        }
      }
      !isCanceled && setLocalShips(tokens)
    };

    run();

    return () => {
      isCanceled = true;
    }
  }, [shipCount, account, localArea]);

  const formatBalance = balance => balance ? Math.trunc(drizzle.web3.utils.fromWei(balance) * 1000)/1000 : '-';

  const selectShip = (event, ship) => {
    setSelectedShip(ship);
  };



  const planetAlignment = census && (census[0] - census[2]);
  const classFromAlignment = (alignment) => {
    if(alignment === null){
      return null;
    }
    if(alignment < 0){
      return "hostile";
    }
    if(alignment === 0){
      return "neutral";
    }
    return "friendly";
  };
  const alignmentClass = classFromAlignment(planetAlignment);

  return (
    <PlanetPanelStyled characterDisplay={characterDisplay}>
      <div className={"planet-info " + alignmentClass}>
        <Tooltip title="Local VOX available to be salvaged">
        <div className="vox-available">
          <VoxIcon className="vox-icon" fill={theme.palette.info.main}/>{formatBalance(availableVox)}

        </div>
        </Tooltip>
        {
          census &&
          <Tooltip title="Local balance of power">
            <div className="local-census">
              <span className={"ship-alignment " + alignmentClass}>{alignmentClass}: {planetAlignment === 0 ? census[1] : Math.abs(planetAlignment)}</span>
            </div>
          </Tooltip>
        }
      </div>
      {
        localShips == 0 ?
          <NoSignal/> :
          (
            selectedShip ?
              <ShipControl ship={selectedShip} onBack={()=>{
                setSelectedShip(null);
              }}/> :
              <div className="local-ships">
                {localShips.map(token => <ShipMini id={token} universe={universe} onClick={selectShip}/>)}
              </div>
          )
      }
    </PlanetPanelStyled>
  );

}