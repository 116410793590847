import axios from 'axios'
const base_url = `${process.env.REACT_APP_API_BASE_URL}/solarSystems`

export class SolarSystems {

  constructor(authContext){
    this.authHeaders = {
      'Authorization': authContext?.auth?.signature,
      'X-Signature-Timestamp': authContext?.auth?.timestamp,
      'X-Signature-Address': authContext?.account,
    };
    this.invalidateAuth = authContext?.invalidateAuth;
  }

  async getGates(solarSystemId) {
    const config = {
      headers: this.authHeaders,
    };

    try {
      const {data} = await axios.get(`${base_url}/${solarSystemId}/gates`, config)
      return data;
    } catch (e) {
      if(e?.response?.status === 401){
        this.invalidateAuth();
      } else {
        throw e;
      }
    }
  }
};

