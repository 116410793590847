import {Noise} from './noise'
import * as THREE from "three";
import * as proto from '../protos/universe_pb'
import rgb from 'hsv-rgb';
/*
message SkyboxConfig {
  optional NoiseConfig red = 1;
  optional NoiseConfig green = 2;
  optional NoiseConfig blue = 3;
  optional float hue = 4;
  optional float theta = 5;
}
*/
export class SkyTexture{

    constructor(skyboxConfig, hasNoStars){

        this.skyboxConfig = skyboxConfig;
        this.channel1Noise = new Noise(skyboxConfig.getRed());
        this.channel2Noise = new Noise(skyboxConfig.getGreen());
        this.channel3Noise = new Noise(skyboxConfig.getBlue());
        this.hasNoStars = hasNoStars;

        let hue = skyboxConfig.getHue();
        let saturation = 100.0;
        let brightness = 100.0;


        let theta = skyboxConfig.getTheta();
        if (theta == 0.0) {
            theta = 120.0;
        }

        let epsilon = 360.0 - 2.0 * theta;
        let hsv = [hue, saturation, brightness];
        let c = rgb(hsv[0], hsv[1], hsv[2]);
        this.cyan = new THREE.Vector3(c[0]/255, c[1]/255, c[2]/255);

        hsv[0] = (hsv[0] + theta) % 360.0;
        c = rgb(hsv[0], hsv[1], hsv[2]);
        this.magenta = new THREE.Vector3(c[0]/255, c[1]/255, c[2]/255);

        hsv[0] = (hsv[0] + theta) % 360.0;
        c = rgb(hsv[0], hsv[1], hsv[2]);
        this.yellow = new THREE.Vector3(c[0]/255, c[1]/255, c[2]/255);


        let noiseConfig = new proto.NoiseConfig();
        noiseConfig.setOctave(Math.random() * 2.0);
        noiseConfig.setPersistence(Math.random() * 0.5);
        noiseConfig.setScale((Math.random() * 0.009) + 0.001);
        noiseConfig.setDecay((Math.random() * 0.4) + 0.4);
        this.cloudNoiseConfig = noiseConfig;
        this.cloudNoise = new Noise(noiseConfig);

        noiseConfig.setOctave(Math.random() * 2.0 + 3.0);
        noiseConfig.setPersistence(Math.random() * 0.5 + 0.5);
        noiseConfig.setScale((Math.random() * 0.008) + 0.002);
        noiseConfig.setDecay((Math.random() * 0.2) + 0.3);

        this.cloudDetailNoise = new Noise(noiseConfig);

        noiseConfig.setOctave(Math.random() * 2.0 + 3.0);
        noiseConfig.setPersistence(Math.random() * 0.5 + 0.5);
        noiseConfig.setScale((Math.random() * 0.008) + 0.002);
        noiseConfig.setDecay((Math.random() * 0.2) + 0.3);
        this.channel1DetailNoise = new Noise(noiseConfig);

        noiseConfig.setOctave(Math.random() * 2.0 + 3.0);
        noiseConfig.setPersistence(Math.random() * 0.5 + 0.5);
        noiseConfig.setScale((Math.random() * 0.008) + 0.002);
        noiseConfig.setDecay((Math.random() * 0.2) + 0.3);
        this.channel2DetailNoise = new Noise(noiseConfig);

        noiseConfig.setOctave(Math.random() * 2.0 + 3.0);
        noiseConfig.setPersistence(Math.random() * 0.5 + 0.5);
        noiseConfig.setScale((Math.random() * 0.008) + 0.002);
        noiseConfig.setDecay((Math.random() * 0.2) + 0.3);
        this.channel3DetailNoise = new Noise(noiseConfig);

        noiseConfig.setOctave(Math.random() * 3.0 + 2.0);
        noiseConfig.setPersistence(Math.random() * 0.5);
        noiseConfig.setScale((Math.random() * 0.4) + 0.6);
        noiseConfig.setDecay((Math.random() * 0.2) + 0.5);

        this.starNoise = new Noise(noiseConfig);

    }

    generate(width, height, face) {

        const size = width * height;
        const data = new Uint8Array( 4 * size );

        for ( let j = 0; j < size; j ++ ) {
            let ix = j % width;
            let iy = Math.floor(j / width);

            let x = (ix)/(width - 1);
            let y = (iy)/(height -1);
            let cube = null;
            switch(face){
                case 0:
                    cube = new THREE.Vector3(
                        1.0,
                        y * 2.0 - 1.0,
                        1.0 - (x * 2.0)
                    );
                    break;
                case 1:
                    cube = new THREE.Vector3(
                        -1.0,
                        (y * 2.0) - 1.0,
                        (x * 2.0) - 1.0
                    );
                    break;
                case 2:
                    cube = new THREE.Vector3(
                        (x * 2.0) - 1.0,
                        -1.0,
                        (y * 2.0) - 1.0
                    );
                    break;
                case 3:
                    cube = new THREE.Vector3(
                        (x * 2.0) - 1.0,
                        1.0,
                        1.0 - (y * 2.0)
                    );

                    break;
                case 4:
                    cube = new THREE.Vector3(
                        (x * 2.0) - 1.0,
                        (y * 2.0) - 1.0,
                        1.0
                    );
                    break;
                case 5:
                    cube = new THREE.Vector3(
                        1.0 - (x * 2.0),
                        (y * 2.0) - 1.0,
                        -1.0
                    );

                    break;
            }
            let sphere = cube.normalize().multiplyScalar(1024);

            let channel1 = this.channel1Noise.get3d(sphere.x, sphere.y, sphere.z);
            let channel1Detail = this.channel1DetailNoise.get3d(sphere.x, sphere.y, sphere.z);

            let channel2 = this.channel2Noise.get3d(sphere.x, sphere.y, sphere.z);
            let channel2Detail = this.channel2DetailNoise.get3d(sphere.x, sphere.y, sphere.z);

            let channel3 = this.channel3Noise.get3d(sphere.x, sphere.y, sphere.z);
            let channel3Detail = this.channel3DetailNoise.get3d(sphere.x, sphere.y, sphere.z);

            let cloud = this.cloudNoise.get3d(sphere.x, sphere.y, sphere.z);
            let cloudDetail = this.cloudDetailNoise.get3d(sphere.x, sphere.y, sphere.z);

            let star = this.starNoise.get3d(sphere.x, sphere.y, sphere.z);



            var color;
            const starThreshold = Math.random();
            if( !this.hasNoStars && Math.random() < star * 0.005 ){
                color = new THREE.Color( Math.random() * (1.0-star) + star, Math.random() * (1.0-star) + star, Math.random() * (1.0-star) + star );
            } else {
                if(channel1 > this.skyboxConfig.getRed().getDecay()){
                    channel1 = (channel1-this.skyboxConfig.getRed().getDecay())/(1.0-this.skyboxConfig.getRed().getDecay());
                    channel1 *= channel1Detail;
                } else {
                    channel1 = 0.0;
                }

                if(channel2 > this.skyboxConfig.getGreen().getDecay()){
                    channel2 = (channel2-this.skyboxConfig.getGreen().getDecay())/(1.0-this.skyboxConfig.getGreen().getDecay());
                    channel2 *= channel2Detail;
                } else {
                    channel2 = 0.0;
                }

                if(channel3 > this.skyboxConfig.getBlue().getDecay()){
                    channel3 = (channel3-this.skyboxConfig.getBlue().getDecay())/(1.0-this.skyboxConfig.getBlue().getDecay());
                    channel3 *= channel3Detail;
                } else {
                    channel3 = 0.0;
                }


                let threshold = this.cloudNoiseConfig.getDecay();
                if(cloud > threshold){
                    let clouds = (cloud-threshold)/(1-threshold);
                    clouds *= cloudDetail * 0.1;
                    channel1 = channel1 * (1 - clouds) + clouds;
                    channel2 = channel2 * (1 - clouds) + clouds;
                    channel3 = channel3 * (1 - clouds) + clouds;
                }

                channel1 *= this.skyboxConfig.getRed().getDecay();
                channel2 *= this.skyboxConfig.getGreen().getDecay();
                channel3 *= this.skyboxConfig.getBlue().getDecay();

                let c = this.cyan.clone().multiplyScalar(channel1).add(this.magenta.clone().multiplyScalar(channel2)).add(this.yellow.clone().multiplyScalar(channel3));

                color = new THREE.Color( c.x, c.y, c.z );
            }

            const stride = j * 4;
            data[ stride ] = color.r * 255;
            data[ stride + 1 ] = color.g * 255;
            data[ stride + 2 ] = color.b * 255;
            data[stride + 3] = 255;


        }

        // used the buffer to create a DataTexture2DArray

        const skyTexture = new THREE.DataTexture( data, width, height );
        skyTexture.format = THREE.RGBAFormat;
        skyTexture.type = THREE.UnsignedByteType;

        skyTexture.minFilter = THREE.NearestFilter;
        skyTexture.magFilter = THREE.NearestFilter;
        return skyTexture;
    }

    generateCube(width, height){
        const skyTextures = [];
        for(var i = 0; i < 6; i++){
            skyTextures.push(this.generate(width, height, i));
        }

        return  new THREE.CubeTexture( skyTextures );
    }
}
