import axios from 'axios'
import {useContext} from 'react';

const base_url = `${process.env.REACT_APP_API_BASE_URL}/localAreas`

export class LocalAreas {

  constructor(authContext){
    this.authHeaders = {
      'Authorization': authContext?.auth?.signature,
      'X-Signature-Timestamp': authContext?.auth?.timestamp,
      'X-Signature-Address': authContext?.account,
    };
    this.invalidateAuth = authContext?.invalidateAuth;
  }

  async getCharacters(localAreaId){
    const config = {
      headers: this.authHeaders,
    };

    try{
      const {data} = await axios.get(`${base_url}/${localAreaId}/characters`, config)
      return data;
    } catch (e) {
      if(e?.response?.status === 401){
        this.invalidateAuth();
      } else {
        throw e;
      }
    }
  }

  async getArtifacts(localAreaId){
    const config = {
      headers: this.authHeaders,
    };

    try{
      const {data} = await axios.get(`${base_url}/${localAreaId}/artifacts`, config)
      return data;
    } catch (e) {
      if(e?.response?.status === 401){
        this.invalidateAuth();
      } else {
        throw e;
      }
    }
  }

  async getGates (localAreaId, address, auth) {
    const config = {
      headers: this.authHeaders,
    };

    try{
      const {data} = await axios.get(`${base_url}/${localAreaId}/gates`, config)
      return data;
    } catch (e) {
      if(e?.response?.status === 401){
        this.invalidateAuth();
      } else {
        throw e;
      }
    }
  }
};