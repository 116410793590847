import {VoxBalanceRowStyled} from './VoxBalanceRowStyled'
import {useState, useEffect} from 'react';
import {Button, Paper, Link, Tooltip} from '@mui/material'
import {api} from './apis/api'
import { drizzleReactHooks } from '@drizzle/react-plugin';
import {ReactComponent as VoxIcon} from './assets/vox_crystal_icon.svg'
import { useTheme } from '@mui/material/styles';


export default function VoxBalanceRow(props){

  const { drizzle, useCacheCall } = drizzleReactHooks.useDrizzle();
  const voxBalance = useCacheCall("VOXToken", "balanceOf", drizzle?.store?.getState?.()?.accounts?.[0]);
  const theme = useTheme();


  const formatBalance = balance => voxBalance ? Math.trunc(drizzle.web3.utils.fromWei(voxBalance) * 1000)/1000 : '-';

  return (

    <VoxBalanceRowStyled>
      <Tooltip title="Your VOX balance"><div className="vox-row"><VoxIcon className="vox-icon" fill={theme.palette.info.main}/>{formatBalance(voxBalance)}</div></Tooltip>
    </VoxBalanceRowStyled>

  );

}