import './NoSignal.css'
import {useState, useEffect, createRef, useCallback} from 'react';
import { useNavigate, useLocation} from "react-router-dom";
import {fetchFile} from "./libs/utils"
import * as THREE from "three";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {NoiseTexture} from './libs/noiseTexture'




export default function NoSignal(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [scene, setScene] = useState(new THREE.Scene());


  const textures = [
    new NoiseTexture().generate(128, 128),
    new NoiseTexture().generate(128, 128),
    new NoiseTexture().generate(128, 128),
  ]
  textures[0].needsUpdate = true;
  textures[1].needsUpdate = true;
  textures[2].needsUpdate = true;

  const canvasRef = useCallback(node => {
      if (node !== null) {
          const drawNoise = async (canvas, gl) =>{

          const noiseVertexShader = await fetchFile("/shaders/noise.vertex");
          const noiseFragmentShader = await fetchFile("/shaders/noise.fragment");
          const geometry = new THREE.PlaneGeometry( 1, 1 );
          const material = new THREE.ShaderMaterial( {
                uniforms: {
                    noiseTexture: {value:textures[0]},
                    screenWidth: {value: canvas.clientWidth},
                    screenHeight: {value: canvas.clientHeight},
                    time: {value:0}
                },
                vertexShader: noiseVertexShader,
                fragmentShader: noiseFragmentShader
            } );
          const plane = new THREE.Mesh( geometry, material );
          scene.add( plane );

          var cameraPosition = new THREE.Vector3(0,0,0.3);
          var camera = new THREE.PerspectiveCamera( 60, canvas.clientWidth/canvas.clientHeight, 0.1, 1000 );

          camera.position.copy(cameraPosition);
          var renderer = new THREE.WebGLRenderer({canvas:canvas, context: gl});
          renderer.setSize( canvas.clientWidth, canvas.clientHeight );

          const onWindowResize = () => {
            const wrapper = document.getElementById("wrapper");
            renderer.setSize( wrapper.clientWidth, wrapper.clientHeight );
            camera.aspect = wrapper.clientWidth/wrapper.clientHeight;
            camera.updateProjectionMatrix();
          };
          window.addEventListener('resize', onWindowResize, false);

          const clock = new THREE.Clock();
          clock.start();
          var noiseFlip = 0.1;
          var idx = 0;
          var animate = function () {
            requestAnimationFrame( animate );
            plane.material.uniforms.time.value =  clock.elapsedTime;
            noiseFlip -= clock.getDelta();
            if(noiseFlip <= 0.0){
              idx = (idx+1)%3;
              plane.material.uniforms.noiseTexture.value.dispose();
              plane.material.uniforms.noiseTexture.value = textures[idx]
              noiseFlip = 0.1;
            }
            renderer.render( scene, camera );
          };

          animate();
        }


        console.log("running with canvasRef = ", node)
        if(node){
          const gl = node.getContext("webgl2")
          drawNoise(node, gl);
        }
      }
  }, []);

  useEffect(()=>{



  }, []);

  return (
    <canvas id="noSignalCanvas" ref={canvasRef}/>
  );
}