// source: universe.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.planet_tycoon.Artifact', null, global);
goog.exportSymbol('proto.planet_tycoon.ArtifactVolume', null, global);
goog.exportSymbol('proto.planet_tycoon.Asteroid', null, global);
goog.exportSymbol('proto.planet_tycoon.Capacitor', null, global);
goog.exportSymbol('proto.planet_tycoon.Character', null, global);
goog.exportSymbol('proto.planet_tycoon.Color', null, global);
goog.exportSymbol('proto.planet_tycoon.DisplayItem', null, global);
goog.exportSymbol('proto.planet_tycoon.DisplayItem.DisplayitemCase', null, global);
goog.exportSymbol('proto.planet_tycoon.Entity', null, global);
goog.exportSymbol('proto.planet_tycoon.Entity.EntityCase', null, global);
goog.exportSymbol('proto.planet_tycoon.EntityGenerator', null, global);
goog.exportSymbol('proto.planet_tycoon.EntityPointer', null, global);
goog.exportSymbol('proto.planet_tycoon.Equipment', null, global);
goog.exportSymbol('proto.planet_tycoon.Equipment.TypeCase', null, global);
goog.exportSymbol('proto.planet_tycoon.EquipmentStock', null, global);
goog.exportSymbol('proto.planet_tycoon.EventTask', null, global);
goog.exportSymbol('proto.planet_tycoon.EventTask.Object', null, global);
goog.exportSymbol('proto.planet_tycoon.EventTask.Verb', null, global);
goog.exportSymbol('proto.planet_tycoon.Galaxy', null, global);
goog.exportSymbol('proto.planet_tycoon.HeatRay', null, global);
goog.exportSymbol('proto.planet_tycoon.Info', null, global);
goog.exportSymbol('proto.planet_tycoon.Island', null, global);
goog.exportSymbol('proto.planet_tycoon.LightSpeedEngine', null, global);
goog.exportSymbol('proto.planet_tycoon.LocalArea', null, global);
goog.exportSymbol('proto.planet_tycoon.ModifyVolumeTask', null, global);
goog.exportSymbol('proto.planet_tycoon.NoiseConfig', null, global);
goog.exportSymbol('proto.planet_tycoon.Orbit', null, global);
goog.exportSymbol('proto.planet_tycoon.PlanetConfig', null, global);
goog.exportSymbol('proto.planet_tycoon.PropertyTask', null, global);
goog.exportSymbol('proto.planet_tycoon.PropertyThreshold', null, global);
goog.exportSymbol('proto.planet_tycoon.PropertyThreshold.ObjectType', null, global);
goog.exportSymbol('proto.planet_tycoon.PropertyThreshold.Property', null, global);
goog.exportSymbol('proto.planet_tycoon.Resource', null, global);
goog.exportSymbol('proto.planet_tycoon.Resource.Type', null, global);
goog.exportSymbol('proto.planet_tycoon.Reward', null, global);
goog.exportSymbol('proto.planet_tycoon.Reward.RewardCase', null, global);
goog.exportSymbol('proto.planet_tycoon.RocketNozzle', null, global);
goog.exportSymbol('proto.planet_tycoon.SalvageRay', null, global);
goog.exportSymbol('proto.planet_tycoon.Satellite', null, global);
goog.exportSymbol('proto.planet_tycoon.Ship', null, global);
goog.exportSymbol('proto.planet_tycoon.ShipConfig', null, global);
goog.exportSymbol('proto.planet_tycoon.SkyboxConfig', null, global);
goog.exportSymbol('proto.planet_tycoon.SolarSystem', null, global);
goog.exportSymbol('proto.planet_tycoon.Speech', null, global);
goog.exportSymbol('proto.planet_tycoon.SpeechParagraph', null, global);
goog.exportSymbol('proto.planet_tycoon.SpotlightSensor', null, global);
goog.exportSymbol('proto.planet_tycoon.StarFragment', null, global);
goog.exportSymbol('proto.planet_tycoon.StationConfig', null, global);
goog.exportSymbol('proto.planet_tycoon.Tank', null, global);
goog.exportSymbol('proto.planet_tycoon.Task', null, global);
goog.exportSymbol('proto.planet_tycoon.Task.TaskCase', null, global);
goog.exportSymbol('proto.planet_tycoon.Task.TaskState', null, global);
goog.exportSymbol('proto.planet_tycoon.Texture', null, global);
goog.exportSymbol('proto.planet_tycoon.ThermalSensor', null, global);
goog.exportSymbol('proto.planet_tycoon.Threshold', null, global);
goog.exportSymbol('proto.planet_tycoon.Threshold.Operator', null, global);
goog.exportSymbol('proto.planet_tycoon.Universe', null, global);
goog.exportSymbol('proto.planet_tycoon.VaporizerRay', null, global);
goog.exportSymbol('proto.planet_tycoon.Vector2d', null, global);
goog.exportSymbol('proto.planet_tycoon.Vector2i', null, global);
goog.exportSymbol('proto.planet_tycoon.Vector3d', null, global);
goog.exportSymbol('proto.planet_tycoon.Volume', null, global);
goog.exportSymbol('proto.planet_tycoon.VolumeCommand', null, global);
goog.exportSymbol('proto.planet_tycoon.VolumeCommand.Type', null, global);
goog.exportSymbol('proto.planet_tycoon.VolumeConfig', null, global);
goog.exportSymbol('proto.planet_tycoon.VolumeConfig.VolumeconfigCase', null, global);
goog.exportSymbol('proto.planet_tycoon.Voxel', null, global);
goog.exportSymbol('proto.planet_tycoon.Voxel.Type', null, global);
goog.exportSymbol('proto.planet_tycoon.VoxelCount', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Island = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.Island, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Island.displayName = 'proto.planet_tycoon.Island';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Texture = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.Texture, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Texture.displayName = 'proto.planet_tycoon.Texture';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.LocalArea = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.planet_tycoon.LocalArea.repeatedFields_, null);
};
goog.inherits(proto.planet_tycoon.LocalArea, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.LocalArea.displayName = 'proto.planet_tycoon.LocalArea';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.VolumeConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.planet_tycoon.VolumeConfig.oneofGroups_);
};
goog.inherits(proto.planet_tycoon.VolumeConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.VolumeConfig.displayName = 'proto.planet_tycoon.VolumeConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.StationConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.StationConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.StationConfig.displayName = 'proto.planet_tycoon.StationConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.PlanetConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.planet_tycoon.PlanetConfig.repeatedFields_, null);
};
goog.inherits(proto.planet_tycoon.PlanetConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.PlanetConfig.displayName = 'proto.planet_tycoon.PlanetConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.ArtifactVolume = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.ArtifactVolume, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.ArtifactVolume.displayName = 'proto.planet_tycoon.ArtifactVolume';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.ShipConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.ShipConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.ShipConfig.displayName = 'proto.planet_tycoon.ShipConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.EntityPointer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.EntityPointer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.EntityPointer.displayName = 'proto.planet_tycoon.EntityPointer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Entity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.planet_tycoon.Entity.oneofGroups_);
};
goog.inherits(proto.planet_tycoon.Entity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Entity.displayName = 'proto.planet_tycoon.Entity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.EntityGenerator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.EntityGenerator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.EntityGenerator.displayName = 'proto.planet_tycoon.EntityGenerator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Orbit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.Orbit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Orbit.displayName = 'proto.planet_tycoon.Orbit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Satellite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.Satellite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Satellite.displayName = 'proto.planet_tycoon.Satellite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.VolumeCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.VolumeCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.VolumeCommand.displayName = 'proto.planet_tycoon.VolumeCommand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.StarFragment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.StarFragment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.StarFragment.displayName = 'proto.planet_tycoon.StarFragment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Asteroid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.Asteroid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Asteroid.displayName = 'proto.planet_tycoon.Asteroid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Character = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.planet_tycoon.Character.repeatedFields_, null);
};
goog.inherits(proto.planet_tycoon.Character, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Character.displayName = 'proto.planet_tycoon.Character';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.RocketNozzle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.RocketNozzle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.RocketNozzle.displayName = 'proto.planet_tycoon.RocketNozzle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Info = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.Info, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Info.displayName = 'proto.planet_tycoon.Info';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Task = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.planet_tycoon.Task.repeatedFields_, proto.planet_tycoon.Task.oneofGroups_);
};
goog.inherits(proto.planet_tycoon.Task, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Task.displayName = 'proto.planet_tycoon.Task';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.DisplayItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.planet_tycoon.DisplayItem.oneofGroups_);
};
goog.inherits(proto.planet_tycoon.DisplayItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.DisplayItem.displayName = 'proto.planet_tycoon.DisplayItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Reward = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.planet_tycoon.Reward.oneofGroups_);
};
goog.inherits(proto.planet_tycoon.Reward, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Reward.displayName = 'proto.planet_tycoon.Reward';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Resource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.Resource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Resource.displayName = 'proto.planet_tycoon.Resource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Artifact = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.Artifact, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Artifact.displayName = 'proto.planet_tycoon.Artifact';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.EventTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.EventTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.EventTask.displayName = 'proto.planet_tycoon.EventTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Threshold = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.Threshold, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Threshold.displayName = 'proto.planet_tycoon.Threshold';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.PropertyThreshold = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.PropertyThreshold, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.PropertyThreshold.displayName = 'proto.planet_tycoon.PropertyThreshold';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.PropertyTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.planet_tycoon.PropertyTask.repeatedFields_, null);
};
goog.inherits(proto.planet_tycoon.PropertyTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.PropertyTask.displayName = 'proto.planet_tycoon.PropertyTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.ModifyVolumeTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.ModifyVolumeTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.ModifyVolumeTask.displayName = 'proto.planet_tycoon.ModifyVolumeTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Speech = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.planet_tycoon.Speech.repeatedFields_, null);
};
goog.inherits(proto.planet_tycoon.Speech, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Speech.displayName = 'proto.planet_tycoon.Speech';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.SpeechParagraph = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.SpeechParagraph, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.SpeechParagraph.displayName = 'proto.planet_tycoon.SpeechParagraph';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Vector2i = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.Vector2i, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Vector2i.displayName = 'proto.planet_tycoon.Vector2i';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Vector2d = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.Vector2d, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Vector2d.displayName = 'proto.planet_tycoon.Vector2d';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Vector3d = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.Vector3d, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Vector3d.displayName = 'proto.planet_tycoon.Vector3d';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Color = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.Color, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Color.displayName = 'proto.planet_tycoon.Color';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Voxel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.Voxel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Voxel.displayName = 'proto.planet_tycoon.Voxel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Volume = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.Volume, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Volume.displayName = 'proto.planet_tycoon.Volume';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.EquipmentStock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.EquipmentStock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.EquipmentStock.displayName = 'proto.planet_tycoon.EquipmentStock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Equipment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.planet_tycoon.Equipment.oneofGroups_);
};
goog.inherits(proto.planet_tycoon.Equipment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Equipment.displayName = 'proto.planet_tycoon.Equipment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Capacitor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.Capacitor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Capacitor.displayName = 'proto.planet_tycoon.Capacitor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Tank = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.Tank, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Tank.displayName = 'proto.planet_tycoon.Tank';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.SalvageRay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.SalvageRay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.SalvageRay.displayName = 'proto.planet_tycoon.SalvageRay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.VaporizerRay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.VaporizerRay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.VaporizerRay.displayName = 'proto.planet_tycoon.VaporizerRay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.HeatRay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.HeatRay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.HeatRay.displayName = 'proto.planet_tycoon.HeatRay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.SpotlightSensor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.SpotlightSensor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.SpotlightSensor.displayName = 'proto.planet_tycoon.SpotlightSensor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.ThermalSensor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.ThermalSensor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.ThermalSensor.displayName = 'proto.planet_tycoon.ThermalSensor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.LightSpeedEngine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.LightSpeedEngine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.LightSpeedEngine.displayName = 'proto.planet_tycoon.LightSpeedEngine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.VoxelCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.VoxelCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.VoxelCount.displayName = 'proto.planet_tycoon.VoxelCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Ship = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.planet_tycoon.Ship.repeatedFields_, null);
};
goog.inherits(proto.planet_tycoon.Ship, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Ship.displayName = 'proto.planet_tycoon.Ship';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.NoiseConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.NoiseConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.NoiseConfig.displayName = 'proto.planet_tycoon.NoiseConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.SkyboxConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.SkyboxConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.SkyboxConfig.displayName = 'proto.planet_tycoon.SkyboxConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.SolarSystem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.planet_tycoon.SolarSystem.repeatedFields_, null);
};
goog.inherits(proto.planet_tycoon.SolarSystem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.SolarSystem.displayName = 'proto.planet_tycoon.SolarSystem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Galaxy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.planet_tycoon.Galaxy.repeatedFields_, null);
};
goog.inherits(proto.planet_tycoon.Galaxy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Galaxy.displayName = 'proto.planet_tycoon.Galaxy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.planet_tycoon.Universe = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.planet_tycoon.Universe, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.planet_tycoon.Universe.displayName = 'proto.planet_tycoon.Universe';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Island.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Island.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Island} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Island.toObject = function(includeInstance, msg) {
  var f, obj = {
    height: (f = jspb.Message.getOptionalFloatingPointField(msg, 1)) == null ? undefined : f,
    width: (f = jspb.Message.getOptionalFloatingPointField(msg, 2)) == null ? undefined : f,
    latitude: (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null ? undefined : f,
    longitude: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Island}
 */
proto.planet_tycoon.Island.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Island;
  return proto.planet_tycoon.Island.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Island} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Island}
 */
proto.planet_tycoon.Island.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHeight(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWidth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLatitude(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Island.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Island.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Island} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Island.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional float height = 1;
 * @return {number}
 */
proto.planet_tycoon.Island.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Island} returns this
 */
proto.planet_tycoon.Island.prototype.setHeight = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Island} returns this
 */
proto.planet_tycoon.Island.prototype.clearHeight = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Island.prototype.hasHeight = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float width = 2;
 * @return {number}
 */
proto.planet_tycoon.Island.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Island} returns this
 */
proto.planet_tycoon.Island.prototype.setWidth = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Island} returns this
 */
proto.planet_tycoon.Island.prototype.clearWidth = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Island.prototype.hasWidth = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float latitude = 3;
 * @return {number}
 */
proto.planet_tycoon.Island.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Island} returns this
 */
proto.planet_tycoon.Island.prototype.setLatitude = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Island} returns this
 */
proto.planet_tycoon.Island.prototype.clearLatitude = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Island.prototype.hasLatitude = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float longitude = 4;
 * @return {number}
 */
proto.planet_tycoon.Island.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Island} returns this
 */
proto.planet_tycoon.Island.prototype.setLongitude = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Island} returns this
 */
proto.planet_tycoon.Island.prototype.clearLongitude = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Island.prototype.hasLongitude = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Texture.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Texture.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Texture} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Texture.toObject = function(includeInstance, msg) {
  var f, obj = {
    width: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    height: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Texture}
 */
proto.planet_tycoon.Texture.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Texture;
  return proto.planet_tycoon.Texture.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Texture} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Texture}
 */
proto.planet_tycoon.Texture.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Texture.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Texture.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Texture} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Texture.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * required int32 width = 1;
 * @return {number}
 */
proto.planet_tycoon.Texture.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Texture} returns this
 */
proto.planet_tycoon.Texture.prototype.setWidth = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Texture} returns this
 */
proto.planet_tycoon.Texture.prototype.clearWidth = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Texture.prototype.hasWidth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required int32 height = 2;
 * @return {number}
 */
proto.planet_tycoon.Texture.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Texture} returns this
 */
proto.planet_tycoon.Texture.prototype.setHeight = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Texture} returns this
 */
proto.planet_tycoon.Texture.prototype.clearHeight = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Texture.prototype.hasHeight = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required bytes data = 3;
 * @return {!(string|Uint8Array)}
 */
proto.planet_tycoon.Texture.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * required bytes data = 3;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.planet_tycoon.Texture.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * required bytes data = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Texture.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.planet_tycoon.Texture} returns this
 */
proto.planet_tycoon.Texture.prototype.setData = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Texture} returns this
 */
proto.planet_tycoon.Texture.prototype.clearData = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Texture.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.planet_tycoon.LocalArea.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.LocalArea.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.LocalArea.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.LocalArea} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.LocalArea.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    parent: (f = msg.getParent()) && proto.planet_tycoon.SolarSystem.toObject(includeInstance, f),
    name: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    description: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    distancefromsun: (f = jspb.Message.getOptionalFloatingPointField(msg, 5)) == null ? undefined : f,
    rotationangle: (f = jspb.Message.getOptionalFloatingPointField(msg, 6)) == null ? undefined : f,
    volumeconfig: (f = msg.getVolumeconfig()) && proto.planet_tycoon.VolumeConfig.toObject(includeInstance, f),
    entitypointersList: jspb.Message.toObjectList(msg.getEntitypointersList(),
    proto.planet_tycoon.EntityPointer.toObject, includeInstance),
    volume: (f = msg.getVolume()) && proto.planet_tycoon.Volume.toObject(includeInstance, f),
    visited: (f = jspb.Message.getBooleanField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.LocalArea}
 */
proto.planet_tycoon.LocalArea.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.LocalArea;
  return proto.planet_tycoon.LocalArea.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.LocalArea} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.LocalArea}
 */
proto.planet_tycoon.LocalArea.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.SolarSystem;
      reader.readMessage(value,proto.planet_tycoon.SolarSystem.deserializeBinaryFromReader);
      msg.setParent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistancefromsun(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRotationangle(value);
      break;
    case 7:
      var value = new proto.planet_tycoon.VolumeConfig;
      reader.readMessage(value,proto.planet_tycoon.VolumeConfig.deserializeBinaryFromReader);
      msg.setVolumeconfig(value);
      break;
    case 8:
      var value = new proto.planet_tycoon.EntityPointer;
      reader.readMessage(value,proto.planet_tycoon.EntityPointer.deserializeBinaryFromReader);
      msg.addEntitypointers(value);
      break;
    case 9:
      var value = new proto.planet_tycoon.Volume;
      reader.readMessage(value,proto.planet_tycoon.Volume.deserializeBinaryFromReader);
      msg.setVolume(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisited(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.LocalArea.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.LocalArea.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.LocalArea} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.LocalArea.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.SolarSystem.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getVolumeconfig();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.planet_tycoon.VolumeConfig.serializeBinaryToWriter
    );
  }
  f = message.getEntitypointersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.planet_tycoon.EntityPointer.serializeBinaryToWriter
    );
  }
  f = message.getVolume();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.planet_tycoon.Volume.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * required string id = 1;
 * @return {string}
 */
proto.planet_tycoon.LocalArea.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.LocalArea} returns this
 */
proto.planet_tycoon.LocalArea.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.LocalArea} returns this
 */
proto.planet_tycoon.LocalArea.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.LocalArea.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SolarSystem parent = 2;
 * @return {?proto.planet_tycoon.SolarSystem}
 */
proto.planet_tycoon.LocalArea.prototype.getParent = function() {
  return /** @type{?proto.planet_tycoon.SolarSystem} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.SolarSystem, 2));
};


/**
 * @param {?proto.planet_tycoon.SolarSystem|undefined} value
 * @return {!proto.planet_tycoon.LocalArea} returns this
*/
proto.planet_tycoon.LocalArea.prototype.setParent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.LocalArea} returns this
 */
proto.planet_tycoon.LocalArea.prototype.clearParent = function() {
  return this.setParent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.LocalArea.prototype.hasParent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.planet_tycoon.LocalArea.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.LocalArea} returns this
 */
proto.planet_tycoon.LocalArea.prototype.setName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.LocalArea} returns this
 */
proto.planet_tycoon.LocalArea.prototype.clearName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.LocalArea.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.planet_tycoon.LocalArea.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.LocalArea} returns this
 */
proto.planet_tycoon.LocalArea.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.LocalArea} returns this
 */
proto.planet_tycoon.LocalArea.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.LocalArea.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional float distanceFromSun = 5;
 * @return {number}
 */
proto.planet_tycoon.LocalArea.prototype.getDistancefromsun = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.LocalArea} returns this
 */
proto.planet_tycoon.LocalArea.prototype.setDistancefromsun = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.LocalArea} returns this
 */
proto.planet_tycoon.LocalArea.prototype.clearDistancefromsun = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.LocalArea.prototype.hasDistancefromsun = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional float rotationAngle = 6;
 * @return {number}
 */
proto.planet_tycoon.LocalArea.prototype.getRotationangle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.LocalArea} returns this
 */
proto.planet_tycoon.LocalArea.prototype.setRotationangle = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.LocalArea} returns this
 */
proto.planet_tycoon.LocalArea.prototype.clearRotationangle = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.LocalArea.prototype.hasRotationangle = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional VolumeConfig volumeConfig = 7;
 * @return {?proto.planet_tycoon.VolumeConfig}
 */
proto.planet_tycoon.LocalArea.prototype.getVolumeconfig = function() {
  return /** @type{?proto.planet_tycoon.VolumeConfig} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.VolumeConfig, 7));
};


/**
 * @param {?proto.planet_tycoon.VolumeConfig|undefined} value
 * @return {!proto.planet_tycoon.LocalArea} returns this
*/
proto.planet_tycoon.LocalArea.prototype.setVolumeconfig = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.LocalArea} returns this
 */
proto.planet_tycoon.LocalArea.prototype.clearVolumeconfig = function() {
  return this.setVolumeconfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.LocalArea.prototype.hasVolumeconfig = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated EntityPointer entityPointers = 8;
 * @return {!Array<!proto.planet_tycoon.EntityPointer>}
 */
proto.planet_tycoon.LocalArea.prototype.getEntitypointersList = function() {
  return /** @type{!Array<!proto.planet_tycoon.EntityPointer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.planet_tycoon.EntityPointer, 8));
};


/**
 * @param {!Array<!proto.planet_tycoon.EntityPointer>} value
 * @return {!proto.planet_tycoon.LocalArea} returns this
*/
proto.planet_tycoon.LocalArea.prototype.setEntitypointersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.planet_tycoon.EntityPointer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.planet_tycoon.EntityPointer}
 */
proto.planet_tycoon.LocalArea.prototype.addEntitypointers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.planet_tycoon.EntityPointer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.planet_tycoon.LocalArea} returns this
 */
proto.planet_tycoon.LocalArea.prototype.clearEntitypointersList = function() {
  return this.setEntitypointersList([]);
};


/**
 * optional Volume volume = 9;
 * @return {?proto.planet_tycoon.Volume}
 */
proto.planet_tycoon.LocalArea.prototype.getVolume = function() {
  return /** @type{?proto.planet_tycoon.Volume} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Volume, 9));
};


/**
 * @param {?proto.planet_tycoon.Volume|undefined} value
 * @return {!proto.planet_tycoon.LocalArea} returns this
*/
proto.planet_tycoon.LocalArea.prototype.setVolume = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.LocalArea} returns this
 */
proto.planet_tycoon.LocalArea.prototype.clearVolume = function() {
  return this.setVolume(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.LocalArea.prototype.hasVolume = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool visited = 10;
 * @return {boolean}
 */
proto.planet_tycoon.LocalArea.prototype.getVisited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.planet_tycoon.LocalArea} returns this
 */
proto.planet_tycoon.LocalArea.prototype.setVisited = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.LocalArea} returns this
 */
proto.planet_tycoon.LocalArea.prototype.clearVisited = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.LocalArea.prototype.hasVisited = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.planet_tycoon.VolumeConfig.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.planet_tycoon.VolumeConfig.VolumeconfigCase = {
  VOLUMECONFIG_NOT_SET: 0,
  PLANET: 1,
  SHIP: 2,
  STATION: 3
};

/**
 * @return {proto.planet_tycoon.VolumeConfig.VolumeconfigCase}
 */
proto.planet_tycoon.VolumeConfig.prototype.getVolumeconfigCase = function() {
  return /** @type {proto.planet_tycoon.VolumeConfig.VolumeconfigCase} */(jspb.Message.computeOneofCase(this, proto.planet_tycoon.VolumeConfig.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.VolumeConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.VolumeConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.VolumeConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.VolumeConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    planet: (f = msg.getPlanet()) && proto.planet_tycoon.PlanetConfig.toObject(includeInstance, f),
    ship: (f = msg.getShip()) && proto.planet_tycoon.ShipConfig.toObject(includeInstance, f),
    station: (f = msg.getStation()) && proto.planet_tycoon.StationConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.VolumeConfig}
 */
proto.planet_tycoon.VolumeConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.VolumeConfig;
  return proto.planet_tycoon.VolumeConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.VolumeConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.VolumeConfig}
 */
proto.planet_tycoon.VolumeConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.planet_tycoon.PlanetConfig;
      reader.readMessage(value,proto.planet_tycoon.PlanetConfig.deserializeBinaryFromReader);
      msg.setPlanet(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.ShipConfig;
      reader.readMessage(value,proto.planet_tycoon.ShipConfig.deserializeBinaryFromReader);
      msg.setShip(value);
      break;
    case 3:
      var value = new proto.planet_tycoon.StationConfig;
      reader.readMessage(value,proto.planet_tycoon.StationConfig.deserializeBinaryFromReader);
      msg.setStation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.VolumeConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.VolumeConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.VolumeConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.VolumeConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.planet_tycoon.PlanetConfig.serializeBinaryToWriter
    );
  }
  f = message.getShip();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.ShipConfig.serializeBinaryToWriter
    );
  }
  f = message.getStation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.planet_tycoon.StationConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional PlanetConfig planet = 1;
 * @return {?proto.planet_tycoon.PlanetConfig}
 */
proto.planet_tycoon.VolumeConfig.prototype.getPlanet = function() {
  return /** @type{?proto.planet_tycoon.PlanetConfig} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.PlanetConfig, 1));
};


/**
 * @param {?proto.planet_tycoon.PlanetConfig|undefined} value
 * @return {!proto.planet_tycoon.VolumeConfig} returns this
*/
proto.planet_tycoon.VolumeConfig.prototype.setPlanet = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.planet_tycoon.VolumeConfig.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.VolumeConfig} returns this
 */
proto.planet_tycoon.VolumeConfig.prototype.clearPlanet = function() {
  return this.setPlanet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.VolumeConfig.prototype.hasPlanet = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ShipConfig ship = 2;
 * @return {?proto.planet_tycoon.ShipConfig}
 */
proto.planet_tycoon.VolumeConfig.prototype.getShip = function() {
  return /** @type{?proto.planet_tycoon.ShipConfig} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.ShipConfig, 2));
};


/**
 * @param {?proto.planet_tycoon.ShipConfig|undefined} value
 * @return {!proto.planet_tycoon.VolumeConfig} returns this
*/
proto.planet_tycoon.VolumeConfig.prototype.setShip = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.planet_tycoon.VolumeConfig.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.VolumeConfig} returns this
 */
proto.planet_tycoon.VolumeConfig.prototype.clearShip = function() {
  return this.setShip(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.VolumeConfig.prototype.hasShip = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StationConfig station = 3;
 * @return {?proto.planet_tycoon.StationConfig}
 */
proto.planet_tycoon.VolumeConfig.prototype.getStation = function() {
  return /** @type{?proto.planet_tycoon.StationConfig} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.StationConfig, 3));
};


/**
 * @param {?proto.planet_tycoon.StationConfig|undefined} value
 * @return {!proto.planet_tycoon.VolumeConfig} returns this
*/
proto.planet_tycoon.VolumeConfig.prototype.setStation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.planet_tycoon.VolumeConfig.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.VolumeConfig} returns this
 */
proto.planet_tycoon.VolumeConfig.prototype.clearStation = function() {
  return this.setStation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.VolumeConfig.prototype.hasStation = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.StationConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.StationConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.StationConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.StationConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    numwheels: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    numspokes: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    wheelradius: (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null ? undefined : f,
    wheelwidth: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f,
    hubradius: (f = jspb.Message.getOptionalFloatingPointField(msg, 5)) == null ? undefined : f,
    axlelength: (f = jspb.Message.getOptionalFloatingPointField(msg, 6)) == null ? undefined : f,
    axleradius: (f = jspb.Message.getOptionalFloatingPointField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.StationConfig}
 */
proto.planet_tycoon.StationConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.StationConfig;
  return proto.planet_tycoon.StationConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.StationConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.StationConfig}
 */
proto.planet_tycoon.StationConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumwheels(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumspokes(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWheelradius(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWheelwidth(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHubradius(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAxlelength(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAxleradius(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.StationConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.StationConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.StationConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.StationConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional int32 numWheels = 1;
 * @return {number}
 */
proto.planet_tycoon.StationConfig.prototype.getNumwheels = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.StationConfig} returns this
 */
proto.planet_tycoon.StationConfig.prototype.setNumwheels = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.StationConfig} returns this
 */
proto.planet_tycoon.StationConfig.prototype.clearNumwheels = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.StationConfig.prototype.hasNumwheels = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 numSpokes = 2;
 * @return {number}
 */
proto.planet_tycoon.StationConfig.prototype.getNumspokes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.StationConfig} returns this
 */
proto.planet_tycoon.StationConfig.prototype.setNumspokes = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.StationConfig} returns this
 */
proto.planet_tycoon.StationConfig.prototype.clearNumspokes = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.StationConfig.prototype.hasNumspokes = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float wheelRadius = 3;
 * @return {number}
 */
proto.planet_tycoon.StationConfig.prototype.getWheelradius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.StationConfig} returns this
 */
proto.planet_tycoon.StationConfig.prototype.setWheelradius = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.StationConfig} returns this
 */
proto.planet_tycoon.StationConfig.prototype.clearWheelradius = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.StationConfig.prototype.hasWheelradius = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float wheelWidth = 4;
 * @return {number}
 */
proto.planet_tycoon.StationConfig.prototype.getWheelwidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.StationConfig} returns this
 */
proto.planet_tycoon.StationConfig.prototype.setWheelwidth = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.StationConfig} returns this
 */
proto.planet_tycoon.StationConfig.prototype.clearWheelwidth = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.StationConfig.prototype.hasWheelwidth = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional float hubRadius = 5;
 * @return {number}
 */
proto.planet_tycoon.StationConfig.prototype.getHubradius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.StationConfig} returns this
 */
proto.planet_tycoon.StationConfig.prototype.setHubradius = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.StationConfig} returns this
 */
proto.planet_tycoon.StationConfig.prototype.clearHubradius = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.StationConfig.prototype.hasHubradius = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional float axleLength = 6;
 * @return {number}
 */
proto.planet_tycoon.StationConfig.prototype.getAxlelength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.StationConfig} returns this
 */
proto.planet_tycoon.StationConfig.prototype.setAxlelength = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.StationConfig} returns this
 */
proto.planet_tycoon.StationConfig.prototype.clearAxlelength = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.StationConfig.prototype.hasAxlelength = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional float axleRadius = 7;
 * @return {number}
 */
proto.planet_tycoon.StationConfig.prototype.getAxleradius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.StationConfig} returns this
 */
proto.planet_tycoon.StationConfig.prototype.setAxleradius = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.StationConfig} returns this
 */
proto.planet_tycoon.StationConfig.prototype.clearAxleradius = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.StationConfig.prototype.hasAxleradius = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.planet_tycoon.PlanetConfig.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.PlanetConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.PlanetConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.PlanetConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.PlanetConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    averagesurfacealtitude: (f = jspb.Message.getOptionalFloatingPointField(msg, 1)) == null ? undefined : f,
    maxpeakaltitude: (f = jspb.Message.getOptionalFloatingPointField(msg, 2)) == null ? undefined : f,
    averagecorealtitude: (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null ? undefined : f,
    averageoceanaltitude: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f,
    noiseconfig: (f = msg.getNoiseconfig()) && proto.planet_tycoon.NoiseConfig.toObject(includeInstance, f),
    densitybase: (f = jspb.Message.getOptionalFloatingPointField(msg, 6)) == null ? undefined : f,
    artifactsList: jspb.Message.toObjectList(msg.getArtifactsList(),
    proto.planet_tycoon.ArtifactVolume.toObject, includeInstance),
    maxcontinentaltitude: (f = jspb.Message.getOptionalFloatingPointField(msg, 8)) == null ? undefined : f,
    continentnoiseconfig: (f = msg.getContinentnoiseconfig()) && proto.planet_tycoon.NoiseConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.PlanetConfig}
 */
proto.planet_tycoon.PlanetConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.PlanetConfig;
  return proto.planet_tycoon.PlanetConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.PlanetConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.PlanetConfig}
 */
proto.planet_tycoon.PlanetConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAveragesurfacealtitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxpeakaltitude(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAveragecorealtitude(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAverageoceanaltitude(value);
      break;
    case 5:
      var value = new proto.planet_tycoon.NoiseConfig;
      reader.readMessage(value,proto.planet_tycoon.NoiseConfig.deserializeBinaryFromReader);
      msg.setNoiseconfig(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDensitybase(value);
      break;
    case 7:
      var value = new proto.planet_tycoon.ArtifactVolume;
      reader.readMessage(value,proto.planet_tycoon.ArtifactVolume.deserializeBinaryFromReader);
      msg.addArtifacts(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxcontinentaltitude(value);
      break;
    case 9:
      var value = new proto.planet_tycoon.NoiseConfig;
      reader.readMessage(value,proto.planet_tycoon.NoiseConfig.deserializeBinaryFromReader);
      msg.setContinentnoiseconfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.PlanetConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.PlanetConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.PlanetConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.PlanetConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getNoiseconfig();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.planet_tycoon.NoiseConfig.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getArtifactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.planet_tycoon.ArtifactVolume.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getContinentnoiseconfig();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.planet_tycoon.NoiseConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional float averageSurfaceAltitude = 1;
 * @return {number}
 */
proto.planet_tycoon.PlanetConfig.prototype.getAveragesurfacealtitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.PlanetConfig} returns this
 */
proto.planet_tycoon.PlanetConfig.prototype.setAveragesurfacealtitude = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.PlanetConfig} returns this
 */
proto.planet_tycoon.PlanetConfig.prototype.clearAveragesurfacealtitude = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.PlanetConfig.prototype.hasAveragesurfacealtitude = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float maxPeakAltitude = 2;
 * @return {number}
 */
proto.planet_tycoon.PlanetConfig.prototype.getMaxpeakaltitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.PlanetConfig} returns this
 */
proto.planet_tycoon.PlanetConfig.prototype.setMaxpeakaltitude = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.PlanetConfig} returns this
 */
proto.planet_tycoon.PlanetConfig.prototype.clearMaxpeakaltitude = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.PlanetConfig.prototype.hasMaxpeakaltitude = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float averageCoreAltitude = 3;
 * @return {number}
 */
proto.planet_tycoon.PlanetConfig.prototype.getAveragecorealtitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.PlanetConfig} returns this
 */
proto.planet_tycoon.PlanetConfig.prototype.setAveragecorealtitude = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.PlanetConfig} returns this
 */
proto.planet_tycoon.PlanetConfig.prototype.clearAveragecorealtitude = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.PlanetConfig.prototype.hasAveragecorealtitude = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float averageOceanAltitude = 4;
 * @return {number}
 */
proto.planet_tycoon.PlanetConfig.prototype.getAverageoceanaltitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.PlanetConfig} returns this
 */
proto.planet_tycoon.PlanetConfig.prototype.setAverageoceanaltitude = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.PlanetConfig} returns this
 */
proto.planet_tycoon.PlanetConfig.prototype.clearAverageoceanaltitude = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.PlanetConfig.prototype.hasAverageoceanaltitude = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NoiseConfig noiseConfig = 5;
 * @return {?proto.planet_tycoon.NoiseConfig}
 */
proto.planet_tycoon.PlanetConfig.prototype.getNoiseconfig = function() {
  return /** @type{?proto.planet_tycoon.NoiseConfig} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.NoiseConfig, 5));
};


/**
 * @param {?proto.planet_tycoon.NoiseConfig|undefined} value
 * @return {!proto.planet_tycoon.PlanetConfig} returns this
*/
proto.planet_tycoon.PlanetConfig.prototype.setNoiseconfig = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.PlanetConfig} returns this
 */
proto.planet_tycoon.PlanetConfig.prototype.clearNoiseconfig = function() {
  return this.setNoiseconfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.PlanetConfig.prototype.hasNoiseconfig = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional float densityBase = 6;
 * @return {number}
 */
proto.planet_tycoon.PlanetConfig.prototype.getDensitybase = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.PlanetConfig} returns this
 */
proto.planet_tycoon.PlanetConfig.prototype.setDensitybase = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.PlanetConfig} returns this
 */
proto.planet_tycoon.PlanetConfig.prototype.clearDensitybase = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.PlanetConfig.prototype.hasDensitybase = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated ArtifactVolume artifacts = 7;
 * @return {!Array<!proto.planet_tycoon.ArtifactVolume>}
 */
proto.planet_tycoon.PlanetConfig.prototype.getArtifactsList = function() {
  return /** @type{!Array<!proto.planet_tycoon.ArtifactVolume>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.planet_tycoon.ArtifactVolume, 7));
};


/**
 * @param {!Array<!proto.planet_tycoon.ArtifactVolume>} value
 * @return {!proto.planet_tycoon.PlanetConfig} returns this
*/
proto.planet_tycoon.PlanetConfig.prototype.setArtifactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.planet_tycoon.ArtifactVolume=} opt_value
 * @param {number=} opt_index
 * @return {!proto.planet_tycoon.ArtifactVolume}
 */
proto.planet_tycoon.PlanetConfig.prototype.addArtifacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.planet_tycoon.ArtifactVolume, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.planet_tycoon.PlanetConfig} returns this
 */
proto.planet_tycoon.PlanetConfig.prototype.clearArtifactsList = function() {
  return this.setArtifactsList([]);
};


/**
 * optional float maxContinentAltitude = 8;
 * @return {number}
 */
proto.planet_tycoon.PlanetConfig.prototype.getMaxcontinentaltitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.PlanetConfig} returns this
 */
proto.planet_tycoon.PlanetConfig.prototype.setMaxcontinentaltitude = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.PlanetConfig} returns this
 */
proto.planet_tycoon.PlanetConfig.prototype.clearMaxcontinentaltitude = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.PlanetConfig.prototype.hasMaxcontinentaltitude = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional NoiseConfig continentNoiseConfig = 9;
 * @return {?proto.planet_tycoon.NoiseConfig}
 */
proto.planet_tycoon.PlanetConfig.prototype.getContinentnoiseconfig = function() {
  return /** @type{?proto.planet_tycoon.NoiseConfig} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.NoiseConfig, 9));
};


/**
 * @param {?proto.planet_tycoon.NoiseConfig|undefined} value
 * @return {!proto.planet_tycoon.PlanetConfig} returns this
*/
proto.planet_tycoon.PlanetConfig.prototype.setContinentnoiseconfig = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.PlanetConfig} returns this
 */
proto.planet_tycoon.PlanetConfig.prototype.clearContinentnoiseconfig = function() {
  return this.setContinentnoiseconfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.PlanetConfig.prototype.hasContinentnoiseconfig = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.ArtifactVolume.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.ArtifactVolume.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.ArtifactVolume} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.ArtifactVolume.toObject = function(includeInstance, msg) {
  var f, obj = {
    artifact: (f = msg.getArtifact()) && proto.planet_tycoon.Artifact.toObject(includeInstance, f),
    position: (f = msg.getPosition()) && proto.planet_tycoon.Vector3d.toObject(includeInstance, f),
    size: (f = msg.getSize()) && proto.planet_tycoon.Vector3d.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.ArtifactVolume}
 */
proto.planet_tycoon.ArtifactVolume.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.ArtifactVolume;
  return proto.planet_tycoon.ArtifactVolume.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.ArtifactVolume} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.ArtifactVolume}
 */
proto.planet_tycoon.ArtifactVolume.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.planet_tycoon.Artifact;
      reader.readMessage(value,proto.planet_tycoon.Artifact.deserializeBinaryFromReader);
      msg.setArtifact(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.Vector3d;
      reader.readMessage(value,proto.planet_tycoon.Vector3d.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 3:
      var value = new proto.planet_tycoon.Vector3d;
      reader.readMessage(value,proto.planet_tycoon.Vector3d.deserializeBinaryFromReader);
      msg.setSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.ArtifactVolume.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.ArtifactVolume.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.ArtifactVolume} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.ArtifactVolume.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArtifact();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.planet_tycoon.Artifact.serializeBinaryToWriter
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.Vector3d.serializeBinaryToWriter
    );
  }
  f = message.getSize();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.planet_tycoon.Vector3d.serializeBinaryToWriter
    );
  }
};


/**
 * optional Artifact artifact = 1;
 * @return {?proto.planet_tycoon.Artifact}
 */
proto.planet_tycoon.ArtifactVolume.prototype.getArtifact = function() {
  return /** @type{?proto.planet_tycoon.Artifact} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Artifact, 1));
};


/**
 * @param {?proto.planet_tycoon.Artifact|undefined} value
 * @return {!proto.planet_tycoon.ArtifactVolume} returns this
*/
proto.planet_tycoon.ArtifactVolume.prototype.setArtifact = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.ArtifactVolume} returns this
 */
proto.planet_tycoon.ArtifactVolume.prototype.clearArtifact = function() {
  return this.setArtifact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.ArtifactVolume.prototype.hasArtifact = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Vector3d position = 2;
 * @return {?proto.planet_tycoon.Vector3d}
 */
proto.planet_tycoon.ArtifactVolume.prototype.getPosition = function() {
  return /** @type{?proto.planet_tycoon.Vector3d} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Vector3d, 2));
};


/**
 * @param {?proto.planet_tycoon.Vector3d|undefined} value
 * @return {!proto.planet_tycoon.ArtifactVolume} returns this
*/
proto.planet_tycoon.ArtifactVolume.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.ArtifactVolume} returns this
 */
proto.planet_tycoon.ArtifactVolume.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.ArtifactVolume.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Vector3d size = 3;
 * @return {?proto.planet_tycoon.Vector3d}
 */
proto.planet_tycoon.ArtifactVolume.prototype.getSize = function() {
  return /** @type{?proto.planet_tycoon.Vector3d} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Vector3d, 3));
};


/**
 * @param {?proto.planet_tycoon.Vector3d|undefined} value
 * @return {!proto.planet_tycoon.ArtifactVolume} returns this
*/
proto.planet_tycoon.ArtifactVolume.prototype.setSize = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.ArtifactVolume} returns this
 */
proto.planet_tycoon.ArtifactVolume.prototype.clearSize = function() {
  return this.setSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.ArtifactVolume.prototype.hasSize = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.ShipConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.ShipConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.ShipConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.ShipConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    cockpitsize: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    numhulls: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    hullwidth: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    hulllength: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    shieldlevel: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.ShipConfig}
 */
proto.planet_tycoon.ShipConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.ShipConfig;
  return proto.planet_tycoon.ShipConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.ShipConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.ShipConfig}
 */
proto.planet_tycoon.ShipConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCockpitsize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumhulls(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHullwidth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHulllength(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setShieldlevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.ShipConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.ShipConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.ShipConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.ShipConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 cockpitSize = 1;
 * @return {number}
 */
proto.planet_tycoon.ShipConfig.prototype.getCockpitsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.ShipConfig} returns this
 */
proto.planet_tycoon.ShipConfig.prototype.setCockpitsize = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.ShipConfig} returns this
 */
proto.planet_tycoon.ShipConfig.prototype.clearCockpitsize = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.ShipConfig.prototype.hasCockpitsize = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 numHulls = 2;
 * @return {number}
 */
proto.planet_tycoon.ShipConfig.prototype.getNumhulls = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.ShipConfig} returns this
 */
proto.planet_tycoon.ShipConfig.prototype.setNumhulls = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.ShipConfig} returns this
 */
proto.planet_tycoon.ShipConfig.prototype.clearNumhulls = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.ShipConfig.prototype.hasNumhulls = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 hullWidth = 3;
 * @return {number}
 */
proto.planet_tycoon.ShipConfig.prototype.getHullwidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.ShipConfig} returns this
 */
proto.planet_tycoon.ShipConfig.prototype.setHullwidth = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.ShipConfig} returns this
 */
proto.planet_tycoon.ShipConfig.prototype.clearHullwidth = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.ShipConfig.prototype.hasHullwidth = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 hullLength = 4;
 * @return {number}
 */
proto.planet_tycoon.ShipConfig.prototype.getHulllength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.ShipConfig} returns this
 */
proto.planet_tycoon.ShipConfig.prototype.setHulllength = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.ShipConfig} returns this
 */
proto.planet_tycoon.ShipConfig.prototype.clearHulllength = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.ShipConfig.prototype.hasHulllength = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 shieldLevel = 5;
 * @return {number}
 */
proto.planet_tycoon.ShipConfig.prototype.getShieldlevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.ShipConfig} returns this
 */
proto.planet_tycoon.ShipConfig.prototype.setShieldlevel = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.ShipConfig} returns this
 */
proto.planet_tycoon.ShipConfig.prototype.clearShieldlevel = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.ShipConfig.prototype.hasShieldlevel = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.EntityPointer.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.EntityPointer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.EntityPointer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.EntityPointer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    entity: (f = msg.getEntity()) && proto.planet_tycoon.Entity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.EntityPointer}
 */
proto.planet_tycoon.EntityPointer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.EntityPointer;
  return proto.planet_tycoon.EntityPointer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.EntityPointer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.EntityPointer}
 */
proto.planet_tycoon.EntityPointer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.Entity;
      reader.readMessage(value,proto.planet_tycoon.Entity.deserializeBinaryFromReader);
      msg.setEntity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.EntityPointer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.EntityPointer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.EntityPointer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.EntityPointer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.Entity.serializeBinaryToWriter
    );
  }
};


/**
 * required string id = 1;
 * @return {string}
 */
proto.planet_tycoon.EntityPointer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.EntityPointer} returns this
 */
proto.planet_tycoon.EntityPointer.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.EntityPointer} returns this
 */
proto.planet_tycoon.EntityPointer.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.EntityPointer.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Entity entity = 2;
 * @return {?proto.planet_tycoon.Entity}
 */
proto.planet_tycoon.EntityPointer.prototype.getEntity = function() {
  return /** @type{?proto.planet_tycoon.Entity} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Entity, 2));
};


/**
 * @param {?proto.planet_tycoon.Entity|undefined} value
 * @return {!proto.planet_tycoon.EntityPointer} returns this
*/
proto.planet_tycoon.EntityPointer.prototype.setEntity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.EntityPointer} returns this
 */
proto.planet_tycoon.EntityPointer.prototype.clearEntity = function() {
  return this.setEntity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.EntityPointer.prototype.hasEntity = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.planet_tycoon.Entity.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.planet_tycoon.Entity.EntityCase = {
  ENTITY_NOT_SET: 0,
  STARFRAGMENT: 1,
  CHARACTER: 2,
  SATELLITE: 3,
  ASTEROID: 4,
  ENTITYGENERATOR: 5
};

/**
 * @return {proto.planet_tycoon.Entity.EntityCase}
 */
proto.planet_tycoon.Entity.prototype.getEntityCase = function() {
  return /** @type {proto.planet_tycoon.Entity.EntityCase} */(jspb.Message.computeOneofCase(this, proto.planet_tycoon.Entity.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Entity.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Entity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Entity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Entity.toObject = function(includeInstance, msg) {
  var f, obj = {
    starfragment: (f = msg.getStarfragment()) && proto.planet_tycoon.StarFragment.toObject(includeInstance, f),
    character: (f = msg.getCharacter()) && proto.planet_tycoon.Character.toObject(includeInstance, f),
    satellite: (f = msg.getSatellite()) && proto.planet_tycoon.Satellite.toObject(includeInstance, f),
    asteroid: (f = msg.getAsteroid()) && proto.planet_tycoon.Asteroid.toObject(includeInstance, f),
    entitygenerator: (f = msg.getEntitygenerator()) && proto.planet_tycoon.EntityGenerator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Entity}
 */
proto.planet_tycoon.Entity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Entity;
  return proto.planet_tycoon.Entity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Entity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Entity}
 */
proto.planet_tycoon.Entity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.planet_tycoon.StarFragment;
      reader.readMessage(value,proto.planet_tycoon.StarFragment.deserializeBinaryFromReader);
      msg.setStarfragment(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.Character;
      reader.readMessage(value,proto.planet_tycoon.Character.deserializeBinaryFromReader);
      msg.setCharacter(value);
      break;
    case 3:
      var value = new proto.planet_tycoon.Satellite;
      reader.readMessage(value,proto.planet_tycoon.Satellite.deserializeBinaryFromReader);
      msg.setSatellite(value);
      break;
    case 4:
      var value = new proto.planet_tycoon.Asteroid;
      reader.readMessage(value,proto.planet_tycoon.Asteroid.deserializeBinaryFromReader);
      msg.setAsteroid(value);
      break;
    case 5:
      var value = new proto.planet_tycoon.EntityGenerator;
      reader.readMessage(value,proto.planet_tycoon.EntityGenerator.deserializeBinaryFromReader);
      msg.setEntitygenerator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Entity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Entity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Entity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Entity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStarfragment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.planet_tycoon.StarFragment.serializeBinaryToWriter
    );
  }
  f = message.getCharacter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.Character.serializeBinaryToWriter
    );
  }
  f = message.getSatellite();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.planet_tycoon.Satellite.serializeBinaryToWriter
    );
  }
  f = message.getAsteroid();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.planet_tycoon.Asteroid.serializeBinaryToWriter
    );
  }
  f = message.getEntitygenerator();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.planet_tycoon.EntityGenerator.serializeBinaryToWriter
    );
  }
};


/**
 * optional StarFragment starFragment = 1;
 * @return {?proto.planet_tycoon.StarFragment}
 */
proto.planet_tycoon.Entity.prototype.getStarfragment = function() {
  return /** @type{?proto.planet_tycoon.StarFragment} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.StarFragment, 1));
};


/**
 * @param {?proto.planet_tycoon.StarFragment|undefined} value
 * @return {!proto.planet_tycoon.Entity} returns this
*/
proto.planet_tycoon.Entity.prototype.setStarfragment = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.planet_tycoon.Entity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Entity} returns this
 */
proto.planet_tycoon.Entity.prototype.clearStarfragment = function() {
  return this.setStarfragment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Entity.prototype.hasStarfragment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Character character = 2;
 * @return {?proto.planet_tycoon.Character}
 */
proto.planet_tycoon.Entity.prototype.getCharacter = function() {
  return /** @type{?proto.planet_tycoon.Character} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Character, 2));
};


/**
 * @param {?proto.planet_tycoon.Character|undefined} value
 * @return {!proto.planet_tycoon.Entity} returns this
*/
proto.planet_tycoon.Entity.prototype.setCharacter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.planet_tycoon.Entity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Entity} returns this
 */
proto.planet_tycoon.Entity.prototype.clearCharacter = function() {
  return this.setCharacter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Entity.prototype.hasCharacter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Satellite satellite = 3;
 * @return {?proto.planet_tycoon.Satellite}
 */
proto.planet_tycoon.Entity.prototype.getSatellite = function() {
  return /** @type{?proto.planet_tycoon.Satellite} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Satellite, 3));
};


/**
 * @param {?proto.planet_tycoon.Satellite|undefined} value
 * @return {!proto.planet_tycoon.Entity} returns this
*/
proto.planet_tycoon.Entity.prototype.setSatellite = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.planet_tycoon.Entity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Entity} returns this
 */
proto.planet_tycoon.Entity.prototype.clearSatellite = function() {
  return this.setSatellite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Entity.prototype.hasSatellite = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Asteroid asteroid = 4;
 * @return {?proto.planet_tycoon.Asteroid}
 */
proto.planet_tycoon.Entity.prototype.getAsteroid = function() {
  return /** @type{?proto.planet_tycoon.Asteroid} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Asteroid, 4));
};


/**
 * @param {?proto.planet_tycoon.Asteroid|undefined} value
 * @return {!proto.planet_tycoon.Entity} returns this
*/
proto.planet_tycoon.Entity.prototype.setAsteroid = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.planet_tycoon.Entity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Entity} returns this
 */
proto.planet_tycoon.Entity.prototype.clearAsteroid = function() {
  return this.setAsteroid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Entity.prototype.hasAsteroid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional EntityGenerator entityGenerator = 5;
 * @return {?proto.planet_tycoon.EntityGenerator}
 */
proto.planet_tycoon.Entity.prototype.getEntitygenerator = function() {
  return /** @type{?proto.planet_tycoon.EntityGenerator} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.EntityGenerator, 5));
};


/**
 * @param {?proto.planet_tycoon.EntityGenerator|undefined} value
 * @return {!proto.planet_tycoon.Entity} returns this
*/
proto.planet_tycoon.Entity.prototype.setEntitygenerator = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.planet_tycoon.Entity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Entity} returns this
 */
proto.planet_tycoon.Entity.prototype.clearEntitygenerator = function() {
  return this.setEntitygenerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Entity.prototype.hasEntitygenerator = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.EntityGenerator.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.EntityGenerator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.EntityGenerator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.EntityGenerator.toObject = function(includeInstance, msg) {
  var f, obj = {
    starcount: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    satellitecount: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    asteroidcount: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    charactercount: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.EntityGenerator}
 */
proto.planet_tycoon.EntityGenerator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.EntityGenerator;
  return proto.planet_tycoon.EntityGenerator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.EntityGenerator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.EntityGenerator}
 */
proto.planet_tycoon.EntityGenerator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStarcount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSatellitecount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAsteroidcount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCharactercount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.EntityGenerator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.EntityGenerator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.EntityGenerator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.EntityGenerator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 starCount = 1;
 * @return {number}
 */
proto.planet_tycoon.EntityGenerator.prototype.getStarcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.EntityGenerator} returns this
 */
proto.planet_tycoon.EntityGenerator.prototype.setStarcount = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.EntityGenerator} returns this
 */
proto.planet_tycoon.EntityGenerator.prototype.clearStarcount = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.EntityGenerator.prototype.hasStarcount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 satelliteCount = 2;
 * @return {number}
 */
proto.planet_tycoon.EntityGenerator.prototype.getSatellitecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.EntityGenerator} returns this
 */
proto.planet_tycoon.EntityGenerator.prototype.setSatellitecount = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.EntityGenerator} returns this
 */
proto.planet_tycoon.EntityGenerator.prototype.clearSatellitecount = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.EntityGenerator.prototype.hasSatellitecount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 asteroidCount = 3;
 * @return {number}
 */
proto.planet_tycoon.EntityGenerator.prototype.getAsteroidcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.EntityGenerator} returns this
 */
proto.planet_tycoon.EntityGenerator.prototype.setAsteroidcount = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.EntityGenerator} returns this
 */
proto.planet_tycoon.EntityGenerator.prototype.clearAsteroidcount = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.EntityGenerator.prototype.hasAsteroidcount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 characterCount = 4;
 * @return {number}
 */
proto.planet_tycoon.EntityGenerator.prototype.getCharactercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.EntityGenerator} returns this
 */
proto.planet_tycoon.EntityGenerator.prototype.setCharactercount = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.EntityGenerator} returns this
 */
proto.planet_tycoon.EntityGenerator.prototype.clearCharactercount = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.EntityGenerator.prototype.hasCharactercount = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Orbit.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Orbit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Orbit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Orbit.toObject = function(includeInstance, msg) {
  var f, obj = {
    center: (f = msg.getCenter()) && proto.planet_tycoon.Vector3d.toObject(includeInstance, f),
    speed: (f = msg.getSpeed()) && proto.planet_tycoon.Vector3d.toObject(includeInstance, f),
    size: (f = msg.getSize()) && proto.planet_tycoon.Vector3d.toObject(includeInstance, f),
    offset: (f = msg.getOffset()) && proto.planet_tycoon.Vector3d.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Orbit}
 */
proto.planet_tycoon.Orbit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Orbit;
  return proto.planet_tycoon.Orbit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Orbit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Orbit}
 */
proto.planet_tycoon.Orbit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.planet_tycoon.Vector3d;
      reader.readMessage(value,proto.planet_tycoon.Vector3d.deserializeBinaryFromReader);
      msg.setCenter(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.Vector3d;
      reader.readMessage(value,proto.planet_tycoon.Vector3d.deserializeBinaryFromReader);
      msg.setSpeed(value);
      break;
    case 3:
      var value = new proto.planet_tycoon.Vector3d;
      reader.readMessage(value,proto.planet_tycoon.Vector3d.deserializeBinaryFromReader);
      msg.setSize(value);
      break;
    case 4:
      var value = new proto.planet_tycoon.Vector3d;
      reader.readMessage(value,proto.planet_tycoon.Vector3d.deserializeBinaryFromReader);
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Orbit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Orbit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Orbit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Orbit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCenter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.planet_tycoon.Vector3d.serializeBinaryToWriter
    );
  }
  f = message.getSpeed();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.Vector3d.serializeBinaryToWriter
    );
  }
  f = message.getSize();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.planet_tycoon.Vector3d.serializeBinaryToWriter
    );
  }
  f = message.getOffset();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.planet_tycoon.Vector3d.serializeBinaryToWriter
    );
  }
};


/**
 * optional Vector3d center = 1;
 * @return {?proto.planet_tycoon.Vector3d}
 */
proto.planet_tycoon.Orbit.prototype.getCenter = function() {
  return /** @type{?proto.planet_tycoon.Vector3d} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Vector3d, 1));
};


/**
 * @param {?proto.planet_tycoon.Vector3d|undefined} value
 * @return {!proto.planet_tycoon.Orbit} returns this
*/
proto.planet_tycoon.Orbit.prototype.setCenter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Orbit} returns this
 */
proto.planet_tycoon.Orbit.prototype.clearCenter = function() {
  return this.setCenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Orbit.prototype.hasCenter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Vector3d speed = 2;
 * @return {?proto.planet_tycoon.Vector3d}
 */
proto.planet_tycoon.Orbit.prototype.getSpeed = function() {
  return /** @type{?proto.planet_tycoon.Vector3d} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Vector3d, 2));
};


/**
 * @param {?proto.planet_tycoon.Vector3d|undefined} value
 * @return {!proto.planet_tycoon.Orbit} returns this
*/
proto.planet_tycoon.Orbit.prototype.setSpeed = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Orbit} returns this
 */
proto.planet_tycoon.Orbit.prototype.clearSpeed = function() {
  return this.setSpeed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Orbit.prototype.hasSpeed = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Vector3d size = 3;
 * @return {?proto.planet_tycoon.Vector3d}
 */
proto.planet_tycoon.Orbit.prototype.getSize = function() {
  return /** @type{?proto.planet_tycoon.Vector3d} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Vector3d, 3));
};


/**
 * @param {?proto.planet_tycoon.Vector3d|undefined} value
 * @return {!proto.planet_tycoon.Orbit} returns this
*/
proto.planet_tycoon.Orbit.prototype.setSize = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Orbit} returns this
 */
proto.planet_tycoon.Orbit.prototype.clearSize = function() {
  return this.setSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Orbit.prototype.hasSize = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Vector3d offset = 4;
 * @return {?proto.planet_tycoon.Vector3d}
 */
proto.planet_tycoon.Orbit.prototype.getOffset = function() {
  return /** @type{?proto.planet_tycoon.Vector3d} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Vector3d, 4));
};


/**
 * @param {?proto.planet_tycoon.Vector3d|undefined} value
 * @return {!proto.planet_tycoon.Orbit} returns this
*/
proto.planet_tycoon.Orbit.prototype.setOffset = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Orbit} returns this
 */
proto.planet_tycoon.Orbit.prototype.clearOffset = function() {
  return this.setOffset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Orbit.prototype.hasOffset = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Satellite.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Satellite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Satellite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Satellite.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    orbit: (f = msg.getOrbit()) && proto.planet_tycoon.Orbit.toObject(includeInstance, f),
    modelfilename: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    texturefilename: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    color: (f = msg.getColor()) && proto.planet_tycoon.Vector3d.toObject(includeInstance, f),
    position: (f = msg.getPosition()) && proto.planet_tycoon.Vector3d.toObject(includeInstance, f),
    command: (f = msg.getCommand()) && proto.planet_tycoon.VolumeCommand.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Satellite}
 */
proto.planet_tycoon.Satellite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Satellite;
  return proto.planet_tycoon.Satellite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Satellite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Satellite}
 */
proto.planet_tycoon.Satellite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.Orbit;
      reader.readMessage(value,proto.planet_tycoon.Orbit.deserializeBinaryFromReader);
      msg.setOrbit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelfilename(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTexturefilename(value);
      break;
    case 5:
      var value = new proto.planet_tycoon.Vector3d;
      reader.readMessage(value,proto.planet_tycoon.Vector3d.deserializeBinaryFromReader);
      msg.setColor(value);
      break;
    case 6:
      var value = new proto.planet_tycoon.Vector3d;
      reader.readMessage(value,proto.planet_tycoon.Vector3d.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 7:
      var value = new proto.planet_tycoon.VolumeCommand;
      reader.readMessage(value,proto.planet_tycoon.VolumeCommand.deserializeBinaryFromReader);
      msg.setCommand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Satellite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Satellite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Satellite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Satellite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrbit();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.Orbit.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getColor();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.planet_tycoon.Vector3d.serializeBinaryToWriter
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.planet_tycoon.Vector3d.serializeBinaryToWriter
    );
  }
  f = message.getCommand();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.planet_tycoon.VolumeCommand.serializeBinaryToWriter
    );
  }
};


/**
 * required string id = 1;
 * @return {string}
 */
proto.planet_tycoon.Satellite.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Satellite} returns this
 */
proto.planet_tycoon.Satellite.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Satellite} returns this
 */
proto.planet_tycoon.Satellite.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Satellite.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Orbit orbit = 2;
 * @return {?proto.planet_tycoon.Orbit}
 */
proto.planet_tycoon.Satellite.prototype.getOrbit = function() {
  return /** @type{?proto.planet_tycoon.Orbit} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Orbit, 2));
};


/**
 * @param {?proto.planet_tycoon.Orbit|undefined} value
 * @return {!proto.planet_tycoon.Satellite} returns this
*/
proto.planet_tycoon.Satellite.prototype.setOrbit = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Satellite} returns this
 */
proto.planet_tycoon.Satellite.prototype.clearOrbit = function() {
  return this.setOrbit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Satellite.prototype.hasOrbit = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string modelFilename = 3;
 * @return {string}
 */
proto.planet_tycoon.Satellite.prototype.getModelfilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Satellite} returns this
 */
proto.planet_tycoon.Satellite.prototype.setModelfilename = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Satellite} returns this
 */
proto.planet_tycoon.Satellite.prototype.clearModelfilename = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Satellite.prototype.hasModelfilename = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string textureFilename = 4;
 * @return {string}
 */
proto.planet_tycoon.Satellite.prototype.getTexturefilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Satellite} returns this
 */
proto.planet_tycoon.Satellite.prototype.setTexturefilename = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Satellite} returns this
 */
proto.planet_tycoon.Satellite.prototype.clearTexturefilename = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Satellite.prototype.hasTexturefilename = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Vector3d color = 5;
 * @return {?proto.planet_tycoon.Vector3d}
 */
proto.planet_tycoon.Satellite.prototype.getColor = function() {
  return /** @type{?proto.planet_tycoon.Vector3d} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Vector3d, 5));
};


/**
 * @param {?proto.planet_tycoon.Vector3d|undefined} value
 * @return {!proto.planet_tycoon.Satellite} returns this
*/
proto.planet_tycoon.Satellite.prototype.setColor = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Satellite} returns this
 */
proto.planet_tycoon.Satellite.prototype.clearColor = function() {
  return this.setColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Satellite.prototype.hasColor = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Vector3d position = 6;
 * @return {?proto.planet_tycoon.Vector3d}
 */
proto.planet_tycoon.Satellite.prototype.getPosition = function() {
  return /** @type{?proto.planet_tycoon.Vector3d} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Vector3d, 6));
};


/**
 * @param {?proto.planet_tycoon.Vector3d|undefined} value
 * @return {!proto.planet_tycoon.Satellite} returns this
*/
proto.planet_tycoon.Satellite.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Satellite} returns this
 */
proto.planet_tycoon.Satellite.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Satellite.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional VolumeCommand command = 7;
 * @return {?proto.planet_tycoon.VolumeCommand}
 */
proto.planet_tycoon.Satellite.prototype.getCommand = function() {
  return /** @type{?proto.planet_tycoon.VolumeCommand} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.VolumeCommand, 7));
};


/**
 * @param {?proto.planet_tycoon.VolumeCommand|undefined} value
 * @return {!proto.planet_tycoon.Satellite} returns this
*/
proto.planet_tycoon.Satellite.prototype.setCommand = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Satellite} returns this
 */
proto.planet_tycoon.Satellite.prototype.clearCommand = function() {
  return this.setCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Satellite.prototype.hasCommand = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.VolumeCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.VolumeCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.VolumeCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.VolumeCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    period: (f = jspb.Message.getOptionalFloatingPointField(msg, 1)) == null ? undefined : f,
    type: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    arg1: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    arg2: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    arg3: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.VolumeCommand}
 */
proto.planet_tycoon.VolumeCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.VolumeCommand;
  return proto.planet_tycoon.VolumeCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.VolumeCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.VolumeCommand}
 */
proto.planet_tycoon.VolumeCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPeriod(value);
      break;
    case 2:
      var value = /** @type {!proto.planet_tycoon.VolumeCommand.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setArg1(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setArg2(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setArg3(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.VolumeCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.VolumeCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.VolumeCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.VolumeCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = /** @type {!proto.planet_tycoon.VolumeCommand.Type} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.planet_tycoon.VolumeCommand.Type = {
  NONE: 0,
  HEAT: 1,
  DIG: 2,
  FILL: 3,
  SALVAGE: 4
};

/**
 * optional float period = 1;
 * @return {number}
 */
proto.planet_tycoon.VolumeCommand.prototype.getPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.VolumeCommand} returns this
 */
proto.planet_tycoon.VolumeCommand.prototype.setPeriod = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.VolumeCommand} returns this
 */
proto.planet_tycoon.VolumeCommand.prototype.clearPeriod = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.VolumeCommand.prototype.hasPeriod = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Type type = 2;
 * @return {!proto.planet_tycoon.VolumeCommand.Type}
 */
proto.planet_tycoon.VolumeCommand.prototype.getType = function() {
  return /** @type {!proto.planet_tycoon.VolumeCommand.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.planet_tycoon.VolumeCommand.Type} value
 * @return {!proto.planet_tycoon.VolumeCommand} returns this
 */
proto.planet_tycoon.VolumeCommand.prototype.setType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.VolumeCommand} returns this
 */
proto.planet_tycoon.VolumeCommand.prototype.clearType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.VolumeCommand.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 arg1 = 3;
 * @return {number}
 */
proto.planet_tycoon.VolumeCommand.prototype.getArg1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.VolumeCommand} returns this
 */
proto.planet_tycoon.VolumeCommand.prototype.setArg1 = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.VolumeCommand} returns this
 */
proto.planet_tycoon.VolumeCommand.prototype.clearArg1 = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.VolumeCommand.prototype.hasArg1 = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 arg2 = 4;
 * @return {number}
 */
proto.planet_tycoon.VolumeCommand.prototype.getArg2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.VolumeCommand} returns this
 */
proto.planet_tycoon.VolumeCommand.prototype.setArg2 = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.VolumeCommand} returns this
 */
proto.planet_tycoon.VolumeCommand.prototype.clearArg2 = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.VolumeCommand.prototype.hasArg2 = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 arg3 = 5;
 * @return {number}
 */
proto.planet_tycoon.VolumeCommand.prototype.getArg3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.VolumeCommand} returns this
 */
proto.planet_tycoon.VolumeCommand.prototype.setArg3 = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.VolumeCommand} returns this
 */
proto.planet_tycoon.VolumeCommand.prototype.clearArg3 = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.VolumeCommand.prototype.hasArg3 = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.StarFragment.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.StarFragment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.StarFragment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.StarFragment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    orbit: (f = msg.getOrbit()) && proto.planet_tycoon.Orbit.toObject(includeInstance, f),
    position: (f = msg.getPosition()) && proto.planet_tycoon.Vector3d.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.StarFragment}
 */
proto.planet_tycoon.StarFragment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.StarFragment;
  return proto.planet_tycoon.StarFragment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.StarFragment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.StarFragment}
 */
proto.planet_tycoon.StarFragment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.Orbit;
      reader.readMessage(value,proto.planet_tycoon.Orbit.deserializeBinaryFromReader);
      msg.setOrbit(value);
      break;
    case 3:
      var value = new proto.planet_tycoon.Vector3d;
      reader.readMessage(value,proto.planet_tycoon.Vector3d.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.StarFragment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.StarFragment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.StarFragment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.StarFragment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrbit();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.Orbit.serializeBinaryToWriter
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.planet_tycoon.Vector3d.serializeBinaryToWriter
    );
  }
};


/**
 * required string id = 1;
 * @return {string}
 */
proto.planet_tycoon.StarFragment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.StarFragment} returns this
 */
proto.planet_tycoon.StarFragment.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.StarFragment} returns this
 */
proto.planet_tycoon.StarFragment.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.StarFragment.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Orbit orbit = 2;
 * @return {?proto.planet_tycoon.Orbit}
 */
proto.planet_tycoon.StarFragment.prototype.getOrbit = function() {
  return /** @type{?proto.planet_tycoon.Orbit} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Orbit, 2));
};


/**
 * @param {?proto.planet_tycoon.Orbit|undefined} value
 * @return {!proto.planet_tycoon.StarFragment} returns this
*/
proto.planet_tycoon.StarFragment.prototype.setOrbit = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.StarFragment} returns this
 */
proto.planet_tycoon.StarFragment.prototype.clearOrbit = function() {
  return this.setOrbit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.StarFragment.prototype.hasOrbit = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Vector3d position = 3;
 * @return {?proto.planet_tycoon.Vector3d}
 */
proto.planet_tycoon.StarFragment.prototype.getPosition = function() {
  return /** @type{?proto.planet_tycoon.Vector3d} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Vector3d, 3));
};


/**
 * @param {?proto.planet_tycoon.Vector3d|undefined} value
 * @return {!proto.planet_tycoon.StarFragment} returns this
*/
proto.planet_tycoon.StarFragment.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.StarFragment} returns this
 */
proto.planet_tycoon.StarFragment.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.StarFragment.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Asteroid.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Asteroid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Asteroid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Asteroid.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    orbit: (f = msg.getOrbit()) && proto.planet_tycoon.Orbit.toObject(includeInstance, f),
    position: (f = msg.getPosition()) && proto.planet_tycoon.Vector3d.toObject(includeInstance, f),
    velocity: (f = msg.getVelocity()) && proto.planet_tycoon.Vector3d.toObject(includeInstance, f),
    modelfilename: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    texturefilename: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Asteroid}
 */
proto.planet_tycoon.Asteroid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Asteroid;
  return proto.planet_tycoon.Asteroid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Asteroid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Asteroid}
 */
proto.planet_tycoon.Asteroid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.Orbit;
      reader.readMessage(value,proto.planet_tycoon.Orbit.deserializeBinaryFromReader);
      msg.setOrbit(value);
      break;
    case 3:
      var value = new proto.planet_tycoon.Vector3d;
      reader.readMessage(value,proto.planet_tycoon.Vector3d.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 4:
      var value = new proto.planet_tycoon.Vector3d;
      reader.readMessage(value,proto.planet_tycoon.Vector3d.deserializeBinaryFromReader);
      msg.setVelocity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelfilename(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTexturefilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Asteroid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Asteroid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Asteroid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Asteroid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrbit();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.Orbit.serializeBinaryToWriter
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.planet_tycoon.Vector3d.serializeBinaryToWriter
    );
  }
  f = message.getVelocity();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.planet_tycoon.Vector3d.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * required string id = 1;
 * @return {string}
 */
proto.planet_tycoon.Asteroid.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Asteroid} returns this
 */
proto.planet_tycoon.Asteroid.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Asteroid} returns this
 */
proto.planet_tycoon.Asteroid.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Asteroid.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Orbit orbit = 2;
 * @return {?proto.planet_tycoon.Orbit}
 */
proto.planet_tycoon.Asteroid.prototype.getOrbit = function() {
  return /** @type{?proto.planet_tycoon.Orbit} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Orbit, 2));
};


/**
 * @param {?proto.planet_tycoon.Orbit|undefined} value
 * @return {!proto.planet_tycoon.Asteroid} returns this
*/
proto.planet_tycoon.Asteroid.prototype.setOrbit = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Asteroid} returns this
 */
proto.planet_tycoon.Asteroid.prototype.clearOrbit = function() {
  return this.setOrbit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Asteroid.prototype.hasOrbit = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Vector3d position = 3;
 * @return {?proto.planet_tycoon.Vector3d}
 */
proto.planet_tycoon.Asteroid.prototype.getPosition = function() {
  return /** @type{?proto.planet_tycoon.Vector3d} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Vector3d, 3));
};


/**
 * @param {?proto.planet_tycoon.Vector3d|undefined} value
 * @return {!proto.planet_tycoon.Asteroid} returns this
*/
proto.planet_tycoon.Asteroid.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Asteroid} returns this
 */
proto.planet_tycoon.Asteroid.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Asteroid.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Vector3d velocity = 4;
 * @return {?proto.planet_tycoon.Vector3d}
 */
proto.planet_tycoon.Asteroid.prototype.getVelocity = function() {
  return /** @type{?proto.planet_tycoon.Vector3d} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Vector3d, 4));
};


/**
 * @param {?proto.planet_tycoon.Vector3d|undefined} value
 * @return {!proto.planet_tycoon.Asteroid} returns this
*/
proto.planet_tycoon.Asteroid.prototype.setVelocity = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Asteroid} returns this
 */
proto.planet_tycoon.Asteroid.prototype.clearVelocity = function() {
  return this.setVelocity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Asteroid.prototype.hasVelocity = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string modelFilename = 5;
 * @return {string}
 */
proto.planet_tycoon.Asteroid.prototype.getModelfilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Asteroid} returns this
 */
proto.planet_tycoon.Asteroid.prototype.setModelfilename = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Asteroid} returns this
 */
proto.planet_tycoon.Asteroid.prototype.clearModelfilename = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Asteroid.prototype.hasModelfilename = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string textureFilename = 6;
 * @return {string}
 */
proto.planet_tycoon.Asteroid.prototype.getTexturefilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Asteroid} returns this
 */
proto.planet_tycoon.Asteroid.prototype.setTexturefilename = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Asteroid} returns this
 */
proto.planet_tycoon.Asteroid.prototype.clearTexturefilename = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Asteroid.prototype.hasTexturefilename = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.planet_tycoon.Character.repeatedFields_ = [9,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Character.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Character.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Character} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Character.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    orbit: (f = msg.getOrbit()) && proto.planet_tycoon.Orbit.toObject(includeInstance, f),
    name: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    description: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    portraitfilename: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    modelfilename: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    texturefilename: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    task: (f = msg.getTask()) && proto.planet_tycoon.Task.toObject(includeInstance, f),
    speechesList: jspb.Message.toObjectList(msg.getSpeechesList(),
    proto.planet_tycoon.Speech.toObject, includeInstance),
    position: (f = msg.getPosition()) && proto.planet_tycoon.Vector3d.toObject(includeInstance, f),
    nozzlesList: jspb.Message.toObjectList(msg.getNozzlesList(),
    proto.planet_tycoon.RocketNozzle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Character}
 */
proto.planet_tycoon.Character.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Character;
  return proto.planet_tycoon.Character.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Character} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Character}
 */
proto.planet_tycoon.Character.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.Orbit;
      reader.readMessage(value,proto.planet_tycoon.Orbit.deserializeBinaryFromReader);
      msg.setOrbit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortraitfilename(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelfilename(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTexturefilename(value);
      break;
    case 8:
      var value = new proto.planet_tycoon.Task;
      reader.readMessage(value,proto.planet_tycoon.Task.deserializeBinaryFromReader);
      msg.setTask(value);
      break;
    case 9:
      var value = new proto.planet_tycoon.Speech;
      reader.readMessage(value,proto.planet_tycoon.Speech.deserializeBinaryFromReader);
      msg.addSpeeches(value);
      break;
    case 10:
      var value = new proto.planet_tycoon.Vector3d;
      reader.readMessage(value,proto.planet_tycoon.Vector3d.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 11:
      var value = new proto.planet_tycoon.RocketNozzle;
      reader.readMessage(value,proto.planet_tycoon.RocketNozzle.deserializeBinaryFromReader);
      msg.addNozzles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Character.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Character.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Character} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Character.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrbit();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.Orbit.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTask();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.planet_tycoon.Task.serializeBinaryToWriter
    );
  }
  f = message.getSpeechesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.planet_tycoon.Speech.serializeBinaryToWriter
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.planet_tycoon.Vector3d.serializeBinaryToWriter
    );
  }
  f = message.getNozzlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.planet_tycoon.RocketNozzle.serializeBinaryToWriter
    );
  }
};


/**
 * required string id = 1;
 * @return {string}
 */
proto.planet_tycoon.Character.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Character} returns this
 */
proto.planet_tycoon.Character.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Character} returns this
 */
proto.planet_tycoon.Character.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Character.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Orbit orbit = 2;
 * @return {?proto.planet_tycoon.Orbit}
 */
proto.planet_tycoon.Character.prototype.getOrbit = function() {
  return /** @type{?proto.planet_tycoon.Orbit} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Orbit, 2));
};


/**
 * @param {?proto.planet_tycoon.Orbit|undefined} value
 * @return {!proto.planet_tycoon.Character} returns this
*/
proto.planet_tycoon.Character.prototype.setOrbit = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Character} returns this
 */
proto.planet_tycoon.Character.prototype.clearOrbit = function() {
  return this.setOrbit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Character.prototype.hasOrbit = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.planet_tycoon.Character.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Character} returns this
 */
proto.planet_tycoon.Character.prototype.setName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Character} returns this
 */
proto.planet_tycoon.Character.prototype.clearName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Character.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.planet_tycoon.Character.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Character} returns this
 */
proto.planet_tycoon.Character.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Character} returns this
 */
proto.planet_tycoon.Character.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Character.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string portraitFilename = 5;
 * @return {string}
 */
proto.planet_tycoon.Character.prototype.getPortraitfilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Character} returns this
 */
proto.planet_tycoon.Character.prototype.setPortraitfilename = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Character} returns this
 */
proto.planet_tycoon.Character.prototype.clearPortraitfilename = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Character.prototype.hasPortraitfilename = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string modelFilename = 6;
 * @return {string}
 */
proto.planet_tycoon.Character.prototype.getModelfilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Character} returns this
 */
proto.planet_tycoon.Character.prototype.setModelfilename = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Character} returns this
 */
proto.planet_tycoon.Character.prototype.clearModelfilename = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Character.prototype.hasModelfilename = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string textureFilename = 7;
 * @return {string}
 */
proto.planet_tycoon.Character.prototype.getTexturefilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Character} returns this
 */
proto.planet_tycoon.Character.prototype.setTexturefilename = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Character} returns this
 */
proto.planet_tycoon.Character.prototype.clearTexturefilename = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Character.prototype.hasTexturefilename = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Task task = 8;
 * @return {?proto.planet_tycoon.Task}
 */
proto.planet_tycoon.Character.prototype.getTask = function() {
  return /** @type{?proto.planet_tycoon.Task} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Task, 8));
};


/**
 * @param {?proto.planet_tycoon.Task|undefined} value
 * @return {!proto.planet_tycoon.Character} returns this
*/
proto.planet_tycoon.Character.prototype.setTask = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Character} returns this
 */
proto.planet_tycoon.Character.prototype.clearTask = function() {
  return this.setTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Character.prototype.hasTask = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated Speech speeches = 9;
 * @return {!Array<!proto.planet_tycoon.Speech>}
 */
proto.planet_tycoon.Character.prototype.getSpeechesList = function() {
  return /** @type{!Array<!proto.planet_tycoon.Speech>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.planet_tycoon.Speech, 9));
};


/**
 * @param {!Array<!proto.planet_tycoon.Speech>} value
 * @return {!proto.planet_tycoon.Character} returns this
*/
proto.planet_tycoon.Character.prototype.setSpeechesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.planet_tycoon.Speech=} opt_value
 * @param {number=} opt_index
 * @return {!proto.planet_tycoon.Speech}
 */
proto.planet_tycoon.Character.prototype.addSpeeches = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.planet_tycoon.Speech, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.planet_tycoon.Character} returns this
 */
proto.planet_tycoon.Character.prototype.clearSpeechesList = function() {
  return this.setSpeechesList([]);
};


/**
 * optional Vector3d position = 10;
 * @return {?proto.planet_tycoon.Vector3d}
 */
proto.planet_tycoon.Character.prototype.getPosition = function() {
  return /** @type{?proto.planet_tycoon.Vector3d} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Vector3d, 10));
};


/**
 * @param {?proto.planet_tycoon.Vector3d|undefined} value
 * @return {!proto.planet_tycoon.Character} returns this
*/
proto.planet_tycoon.Character.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Character} returns this
 */
proto.planet_tycoon.Character.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Character.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated RocketNozzle nozzles = 11;
 * @return {!Array<!proto.planet_tycoon.RocketNozzle>}
 */
proto.planet_tycoon.Character.prototype.getNozzlesList = function() {
  return /** @type{!Array<!proto.planet_tycoon.RocketNozzle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.planet_tycoon.RocketNozzle, 11));
};


/**
 * @param {!Array<!proto.planet_tycoon.RocketNozzle>} value
 * @return {!proto.planet_tycoon.Character} returns this
*/
proto.planet_tycoon.Character.prototype.setNozzlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.planet_tycoon.RocketNozzle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.planet_tycoon.RocketNozzle}
 */
proto.planet_tycoon.Character.prototype.addNozzles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.planet_tycoon.RocketNozzle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.planet_tycoon.Character} returns this
 */
proto.planet_tycoon.Character.prototype.clearNozzlesList = function() {
  return this.setNozzlesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.RocketNozzle.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.RocketNozzle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.RocketNozzle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.RocketNozzle.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: (f = msg.getPosition()) && proto.planet_tycoon.Vector3d.toObject(includeInstance, f),
    velocity: (f = msg.getVelocity()) && proto.planet_tycoon.Vector3d.toObject(includeInstance, f),
    color: (f = msg.getColor()) && proto.planet_tycoon.Color.toObject(includeInstance, f),
    lifetime: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f,
    size: (f = jspb.Message.getOptionalFloatingPointField(msg, 5)) == null ? undefined : f,
    period: (f = jspb.Message.getOptionalFloatingPointField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.RocketNozzle}
 */
proto.planet_tycoon.RocketNozzle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.RocketNozzle;
  return proto.planet_tycoon.RocketNozzle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.RocketNozzle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.RocketNozzle}
 */
proto.planet_tycoon.RocketNozzle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.planet_tycoon.Vector3d;
      reader.readMessage(value,proto.planet_tycoon.Vector3d.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.Vector3d;
      reader.readMessage(value,proto.planet_tycoon.Vector3d.deserializeBinaryFromReader);
      msg.setVelocity(value);
      break;
    case 3:
      var value = new proto.planet_tycoon.Color;
      reader.readMessage(value,proto.planet_tycoon.Color.deserializeBinaryFromReader);
      msg.setColor(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLifetime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSize(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.RocketNozzle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.RocketNozzle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.RocketNozzle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.RocketNozzle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.planet_tycoon.Vector3d.serializeBinaryToWriter
    );
  }
  f = message.getVelocity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.Vector3d.serializeBinaryToWriter
    );
  }
  f = message.getColor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.planet_tycoon.Color.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional Vector3d position = 1;
 * @return {?proto.planet_tycoon.Vector3d}
 */
proto.planet_tycoon.RocketNozzle.prototype.getPosition = function() {
  return /** @type{?proto.planet_tycoon.Vector3d} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Vector3d, 1));
};


/**
 * @param {?proto.planet_tycoon.Vector3d|undefined} value
 * @return {!proto.planet_tycoon.RocketNozzle} returns this
*/
proto.planet_tycoon.RocketNozzle.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.RocketNozzle} returns this
 */
proto.planet_tycoon.RocketNozzle.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.RocketNozzle.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Vector3d velocity = 2;
 * @return {?proto.planet_tycoon.Vector3d}
 */
proto.planet_tycoon.RocketNozzle.prototype.getVelocity = function() {
  return /** @type{?proto.planet_tycoon.Vector3d} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Vector3d, 2));
};


/**
 * @param {?proto.planet_tycoon.Vector3d|undefined} value
 * @return {!proto.planet_tycoon.RocketNozzle} returns this
*/
proto.planet_tycoon.RocketNozzle.prototype.setVelocity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.RocketNozzle} returns this
 */
proto.planet_tycoon.RocketNozzle.prototype.clearVelocity = function() {
  return this.setVelocity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.RocketNozzle.prototype.hasVelocity = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Color color = 3;
 * @return {?proto.planet_tycoon.Color}
 */
proto.planet_tycoon.RocketNozzle.prototype.getColor = function() {
  return /** @type{?proto.planet_tycoon.Color} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Color, 3));
};


/**
 * @param {?proto.planet_tycoon.Color|undefined} value
 * @return {!proto.planet_tycoon.RocketNozzle} returns this
*/
proto.planet_tycoon.RocketNozzle.prototype.setColor = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.RocketNozzle} returns this
 */
proto.planet_tycoon.RocketNozzle.prototype.clearColor = function() {
  return this.setColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.RocketNozzle.prototype.hasColor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float lifetime = 4;
 * @return {number}
 */
proto.planet_tycoon.RocketNozzle.prototype.getLifetime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.RocketNozzle} returns this
 */
proto.planet_tycoon.RocketNozzle.prototype.setLifetime = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.RocketNozzle} returns this
 */
proto.planet_tycoon.RocketNozzle.prototype.clearLifetime = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.RocketNozzle.prototype.hasLifetime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional float size = 5;
 * @return {number}
 */
proto.planet_tycoon.RocketNozzle.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.RocketNozzle} returns this
 */
proto.planet_tycoon.RocketNozzle.prototype.setSize = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.RocketNozzle} returns this
 */
proto.planet_tycoon.RocketNozzle.prototype.clearSize = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.RocketNozzle.prototype.hasSize = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional float period = 6;
 * @return {number}
 */
proto.planet_tycoon.RocketNozzle.prototype.getPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.RocketNozzle} returns this
 */
proto.planet_tycoon.RocketNozzle.prototype.setPeriod = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.RocketNozzle} returns this
 */
proto.planet_tycoon.RocketNozzle.prototype.clearPeriod = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.RocketNozzle.prototype.hasPeriod = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Info.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Info.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Info} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Info.toObject = function(includeInstance, msg) {
  var f, obj = {
    contents: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    position: (f = msg.getPosition()) && proto.planet_tycoon.Vector2d.toObject(includeInstance, f),
    size: (f = msg.getSize()) && proto.planet_tycoon.Vector2d.toObject(includeInstance, f),
    duration: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f,
    dismissaltask: (f = msg.getDismissaltask()) && proto.planet_tycoon.Task.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Info}
 */
proto.planet_tycoon.Info.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Info;
  return proto.planet_tycoon.Info.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Info} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Info}
 */
proto.planet_tycoon.Info.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContents(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.Vector2d;
      reader.readMessage(value,proto.planet_tycoon.Vector2d.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 3:
      var value = new proto.planet_tycoon.Vector2d;
      reader.readMessage(value,proto.planet_tycoon.Vector2d.deserializeBinaryFromReader);
      msg.setSize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDuration(value);
      break;
    case 5:
      var value = new proto.planet_tycoon.Task;
      reader.readMessage(value,proto.planet_tycoon.Task.deserializeBinaryFromReader);
      msg.setDismissaltask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Info.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Info.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Info} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Info.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.Vector2d.serializeBinaryToWriter
    );
  }
  f = message.getSize();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.planet_tycoon.Vector2d.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getDismissaltask();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.planet_tycoon.Task.serializeBinaryToWriter
    );
  }
};


/**
 * optional string contents = 1;
 * @return {string}
 */
proto.planet_tycoon.Info.prototype.getContents = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Info} returns this
 */
proto.planet_tycoon.Info.prototype.setContents = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Info} returns this
 */
proto.planet_tycoon.Info.prototype.clearContents = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Info.prototype.hasContents = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Vector2d position = 2;
 * @return {?proto.planet_tycoon.Vector2d}
 */
proto.planet_tycoon.Info.prototype.getPosition = function() {
  return /** @type{?proto.planet_tycoon.Vector2d} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Vector2d, 2));
};


/**
 * @param {?proto.planet_tycoon.Vector2d|undefined} value
 * @return {!proto.planet_tycoon.Info} returns this
*/
proto.planet_tycoon.Info.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Info} returns this
 */
proto.planet_tycoon.Info.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Info.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Vector2d size = 3;
 * @return {?proto.planet_tycoon.Vector2d}
 */
proto.planet_tycoon.Info.prototype.getSize = function() {
  return /** @type{?proto.planet_tycoon.Vector2d} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Vector2d, 3));
};


/**
 * @param {?proto.planet_tycoon.Vector2d|undefined} value
 * @return {!proto.planet_tycoon.Info} returns this
*/
proto.planet_tycoon.Info.prototype.setSize = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Info} returns this
 */
proto.planet_tycoon.Info.prototype.clearSize = function() {
  return this.setSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Info.prototype.hasSize = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float duration = 4;
 * @return {number}
 */
proto.planet_tycoon.Info.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Info} returns this
 */
proto.planet_tycoon.Info.prototype.setDuration = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Info} returns this
 */
proto.planet_tycoon.Info.prototype.clearDuration = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Info.prototype.hasDuration = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Task dismissalTask = 5;
 * @return {?proto.planet_tycoon.Task}
 */
proto.planet_tycoon.Info.prototype.getDismissaltask = function() {
  return /** @type{?proto.planet_tycoon.Task} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Task, 5));
};


/**
 * @param {?proto.planet_tycoon.Task|undefined} value
 * @return {!proto.planet_tycoon.Info} returns this
*/
proto.planet_tycoon.Info.prototype.setDismissaltask = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Info} returns this
 */
proto.planet_tycoon.Info.prototype.clearDismissaltask = function() {
  return this.setDismissaltask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Info.prototype.hasDismissaltask = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.planet_tycoon.Task.repeatedFields_ = [6,8,9,10,12];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.planet_tycoon.Task.oneofGroups_ = [[13,14,15]];

/**
 * @enum {number}
 */
proto.planet_tycoon.Task.TaskCase = {
  TASK_NOT_SET: 0,
  EVENTTASK: 13,
  MODIFYVOLUMETASK: 14,
  PROPERTYTASK: 15
};

/**
 * @return {proto.planet_tycoon.Task.TaskCase}
 */
proto.planet_tycoon.Task.prototype.getTaskCase = function() {
  return /** @type {proto.planet_tycoon.Task.TaskCase} */(jspb.Message.computeOneofCase(this, proto.planet_tycoon.Task.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Task.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Task.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Task} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Task.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    title: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    description: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    state: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    localareaid: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    subtasksList: jspb.Message.toObjectList(msg.getSubtasksList(),
    proto.planet_tycoon.Task.toObject, includeInstance),
    nexttask: (f = msg.getNexttask()) && proto.planet_tycoon.Task.toObject(includeInstance, f),
    introdisplayitemsList: jspb.Message.toObjectList(msg.getIntrodisplayitemsList(),
    proto.planet_tycoon.DisplayItem.toObject, includeInstance),
    activedisplayitemsList: jspb.Message.toObjectList(msg.getActivedisplayitemsList(),
    proto.planet_tycoon.DisplayItem.toObject, includeInstance),
    completiondisplayitemsList: jspb.Message.toObjectList(msg.getCompletiondisplayitemsList(),
    proto.planet_tycoon.DisplayItem.toObject, includeInstance),
    entity: (f = msg.getEntity()) && proto.planet_tycoon.EntityPointer.toObject(includeInstance, f),
    stockList: jspb.Message.toObjectList(msg.getStockList(),
    proto.planet_tycoon.EquipmentStock.toObject, includeInstance),
    eventtask: (f = msg.getEventtask()) && proto.planet_tycoon.EventTask.toObject(includeInstance, f),
    modifyvolumetask: (f = msg.getModifyvolumetask()) && proto.planet_tycoon.ModifyVolumeTask.toObject(includeInstance, f),
    propertytask: (f = msg.getPropertytask()) && proto.planet_tycoon.PropertyTask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Task}
 */
proto.planet_tycoon.Task.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Task;
  return proto.planet_tycoon.Task.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Task} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Task}
 */
proto.planet_tycoon.Task.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.planet_tycoon.Task.TaskState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalareaid(value);
      break;
    case 6:
      var value = new proto.planet_tycoon.Task;
      reader.readMessage(value,proto.planet_tycoon.Task.deserializeBinaryFromReader);
      msg.addSubtasks(value);
      break;
    case 7:
      var value = new proto.planet_tycoon.Task;
      reader.readMessage(value,proto.planet_tycoon.Task.deserializeBinaryFromReader);
      msg.setNexttask(value);
      break;
    case 8:
      var value = new proto.planet_tycoon.DisplayItem;
      reader.readMessage(value,proto.planet_tycoon.DisplayItem.deserializeBinaryFromReader);
      msg.addIntrodisplayitems(value);
      break;
    case 9:
      var value = new proto.planet_tycoon.DisplayItem;
      reader.readMessage(value,proto.planet_tycoon.DisplayItem.deserializeBinaryFromReader);
      msg.addActivedisplayitems(value);
      break;
    case 10:
      var value = new proto.planet_tycoon.DisplayItem;
      reader.readMessage(value,proto.planet_tycoon.DisplayItem.deserializeBinaryFromReader);
      msg.addCompletiondisplayitems(value);
      break;
    case 11:
      var value = new proto.planet_tycoon.EntityPointer;
      reader.readMessage(value,proto.planet_tycoon.EntityPointer.deserializeBinaryFromReader);
      msg.setEntity(value);
      break;
    case 12:
      var value = new proto.planet_tycoon.EquipmentStock;
      reader.readMessage(value,proto.planet_tycoon.EquipmentStock.deserializeBinaryFromReader);
      msg.addStock(value);
      break;
    case 13:
      var value = new proto.planet_tycoon.EventTask;
      reader.readMessage(value,proto.planet_tycoon.EventTask.deserializeBinaryFromReader);
      msg.setEventtask(value);
      break;
    case 14:
      var value = new proto.planet_tycoon.ModifyVolumeTask;
      reader.readMessage(value,proto.planet_tycoon.ModifyVolumeTask.deserializeBinaryFromReader);
      msg.setModifyvolumetask(value);
      break;
    case 15:
      var value = new proto.planet_tycoon.PropertyTask;
      reader.readMessage(value,proto.planet_tycoon.PropertyTask.deserializeBinaryFromReader);
      msg.setPropertytask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Task.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Task.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Task} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Task.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {!proto.planet_tycoon.Task.TaskState} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSubtasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.planet_tycoon.Task.serializeBinaryToWriter
    );
  }
  f = message.getNexttask();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.planet_tycoon.Task.serializeBinaryToWriter
    );
  }
  f = message.getIntrodisplayitemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.planet_tycoon.DisplayItem.serializeBinaryToWriter
    );
  }
  f = message.getActivedisplayitemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.planet_tycoon.DisplayItem.serializeBinaryToWriter
    );
  }
  f = message.getCompletiondisplayitemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.planet_tycoon.DisplayItem.serializeBinaryToWriter
    );
  }
  f = message.getEntity();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.planet_tycoon.EntityPointer.serializeBinaryToWriter
    );
  }
  f = message.getStockList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.planet_tycoon.EquipmentStock.serializeBinaryToWriter
    );
  }
  f = message.getEventtask();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.planet_tycoon.EventTask.serializeBinaryToWriter
    );
  }
  f = message.getModifyvolumetask();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.planet_tycoon.ModifyVolumeTask.serializeBinaryToWriter
    );
  }
  f = message.getPropertytask();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.planet_tycoon.PropertyTask.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.planet_tycoon.Task.TaskState = {
  PENDING: 0,
  ACTIVE: 1,
  COMPLETE: 2
};

/**
 * required string id = 1;
 * @return {string}
 */
proto.planet_tycoon.Task.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Task.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.planet_tycoon.Task.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Task.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.planet_tycoon.Task.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Task.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TaskState state = 4;
 * @return {!proto.planet_tycoon.Task.TaskState}
 */
proto.planet_tycoon.Task.prototype.getState = function() {
  return /** @type {!proto.planet_tycoon.Task.TaskState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.planet_tycoon.Task.TaskState} value
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.setState = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.clearState = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Task.prototype.hasState = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string localAreaId = 5;
 * @return {string}
 */
proto.planet_tycoon.Task.prototype.getLocalareaid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.setLocalareaid = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.clearLocalareaid = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Task.prototype.hasLocalareaid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated Task subTasks = 6;
 * @return {!Array<!proto.planet_tycoon.Task>}
 */
proto.planet_tycoon.Task.prototype.getSubtasksList = function() {
  return /** @type{!Array<!proto.planet_tycoon.Task>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.planet_tycoon.Task, 6));
};


/**
 * @param {!Array<!proto.planet_tycoon.Task>} value
 * @return {!proto.planet_tycoon.Task} returns this
*/
proto.planet_tycoon.Task.prototype.setSubtasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.planet_tycoon.Task=} opt_value
 * @param {number=} opt_index
 * @return {!proto.planet_tycoon.Task}
 */
proto.planet_tycoon.Task.prototype.addSubtasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.planet_tycoon.Task, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.clearSubtasksList = function() {
  return this.setSubtasksList([]);
};


/**
 * optional Task nextTask = 7;
 * @return {?proto.planet_tycoon.Task}
 */
proto.planet_tycoon.Task.prototype.getNexttask = function() {
  return /** @type{?proto.planet_tycoon.Task} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Task, 7));
};


/**
 * @param {?proto.planet_tycoon.Task|undefined} value
 * @return {!proto.planet_tycoon.Task} returns this
*/
proto.planet_tycoon.Task.prototype.setNexttask = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.clearNexttask = function() {
  return this.setNexttask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Task.prototype.hasNexttask = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated DisplayItem introDisplayItems = 8;
 * @return {!Array<!proto.planet_tycoon.DisplayItem>}
 */
proto.planet_tycoon.Task.prototype.getIntrodisplayitemsList = function() {
  return /** @type{!Array<!proto.planet_tycoon.DisplayItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.planet_tycoon.DisplayItem, 8));
};


/**
 * @param {!Array<!proto.planet_tycoon.DisplayItem>} value
 * @return {!proto.planet_tycoon.Task} returns this
*/
proto.planet_tycoon.Task.prototype.setIntrodisplayitemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.planet_tycoon.DisplayItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.planet_tycoon.DisplayItem}
 */
proto.planet_tycoon.Task.prototype.addIntrodisplayitems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.planet_tycoon.DisplayItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.clearIntrodisplayitemsList = function() {
  return this.setIntrodisplayitemsList([]);
};


/**
 * repeated DisplayItem activeDisplayItems = 9;
 * @return {!Array<!proto.planet_tycoon.DisplayItem>}
 */
proto.planet_tycoon.Task.prototype.getActivedisplayitemsList = function() {
  return /** @type{!Array<!proto.planet_tycoon.DisplayItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.planet_tycoon.DisplayItem, 9));
};


/**
 * @param {!Array<!proto.planet_tycoon.DisplayItem>} value
 * @return {!proto.planet_tycoon.Task} returns this
*/
proto.planet_tycoon.Task.prototype.setActivedisplayitemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.planet_tycoon.DisplayItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.planet_tycoon.DisplayItem}
 */
proto.planet_tycoon.Task.prototype.addActivedisplayitems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.planet_tycoon.DisplayItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.clearActivedisplayitemsList = function() {
  return this.setActivedisplayitemsList([]);
};


/**
 * repeated DisplayItem completionDisplayItems = 10;
 * @return {!Array<!proto.planet_tycoon.DisplayItem>}
 */
proto.planet_tycoon.Task.prototype.getCompletiondisplayitemsList = function() {
  return /** @type{!Array<!proto.planet_tycoon.DisplayItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.planet_tycoon.DisplayItem, 10));
};


/**
 * @param {!Array<!proto.planet_tycoon.DisplayItem>} value
 * @return {!proto.planet_tycoon.Task} returns this
*/
proto.planet_tycoon.Task.prototype.setCompletiondisplayitemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.planet_tycoon.DisplayItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.planet_tycoon.DisplayItem}
 */
proto.planet_tycoon.Task.prototype.addCompletiondisplayitems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.planet_tycoon.DisplayItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.clearCompletiondisplayitemsList = function() {
  return this.setCompletiondisplayitemsList([]);
};


/**
 * optional EntityPointer entity = 11;
 * @return {?proto.planet_tycoon.EntityPointer}
 */
proto.planet_tycoon.Task.prototype.getEntity = function() {
  return /** @type{?proto.planet_tycoon.EntityPointer} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.EntityPointer, 11));
};


/**
 * @param {?proto.planet_tycoon.EntityPointer|undefined} value
 * @return {!proto.planet_tycoon.Task} returns this
*/
proto.planet_tycoon.Task.prototype.setEntity = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.clearEntity = function() {
  return this.setEntity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Task.prototype.hasEntity = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated EquipmentStock stock = 12;
 * @return {!Array<!proto.planet_tycoon.EquipmentStock>}
 */
proto.planet_tycoon.Task.prototype.getStockList = function() {
  return /** @type{!Array<!proto.planet_tycoon.EquipmentStock>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.planet_tycoon.EquipmentStock, 12));
};


/**
 * @param {!Array<!proto.planet_tycoon.EquipmentStock>} value
 * @return {!proto.planet_tycoon.Task} returns this
*/
proto.planet_tycoon.Task.prototype.setStockList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.planet_tycoon.EquipmentStock=} opt_value
 * @param {number=} opt_index
 * @return {!proto.planet_tycoon.EquipmentStock}
 */
proto.planet_tycoon.Task.prototype.addStock = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.planet_tycoon.EquipmentStock, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.clearStockList = function() {
  return this.setStockList([]);
};


/**
 * optional EventTask eventTask = 13;
 * @return {?proto.planet_tycoon.EventTask}
 */
proto.planet_tycoon.Task.prototype.getEventtask = function() {
  return /** @type{?proto.planet_tycoon.EventTask} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.EventTask, 13));
};


/**
 * @param {?proto.planet_tycoon.EventTask|undefined} value
 * @return {!proto.planet_tycoon.Task} returns this
*/
proto.planet_tycoon.Task.prototype.setEventtask = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.planet_tycoon.Task.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.clearEventtask = function() {
  return this.setEventtask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Task.prototype.hasEventtask = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional ModifyVolumeTask modifyVolumeTask = 14;
 * @return {?proto.planet_tycoon.ModifyVolumeTask}
 */
proto.planet_tycoon.Task.prototype.getModifyvolumetask = function() {
  return /** @type{?proto.planet_tycoon.ModifyVolumeTask} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.ModifyVolumeTask, 14));
};


/**
 * @param {?proto.planet_tycoon.ModifyVolumeTask|undefined} value
 * @return {!proto.planet_tycoon.Task} returns this
*/
proto.planet_tycoon.Task.prototype.setModifyvolumetask = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.planet_tycoon.Task.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.clearModifyvolumetask = function() {
  return this.setModifyvolumetask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Task.prototype.hasModifyvolumetask = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional PropertyTask propertyTask = 15;
 * @return {?proto.planet_tycoon.PropertyTask}
 */
proto.planet_tycoon.Task.prototype.getPropertytask = function() {
  return /** @type{?proto.planet_tycoon.PropertyTask} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.PropertyTask, 15));
};


/**
 * @param {?proto.planet_tycoon.PropertyTask|undefined} value
 * @return {!proto.planet_tycoon.Task} returns this
*/
proto.planet_tycoon.Task.prototype.setPropertytask = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.planet_tycoon.Task.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Task} returns this
 */
proto.planet_tycoon.Task.prototype.clearPropertytask = function() {
  return this.setPropertytask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Task.prototype.hasPropertytask = function() {
  return jspb.Message.getField(this, 15) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.planet_tycoon.DisplayItem.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.planet_tycoon.DisplayItem.DisplayitemCase = {
  DISPLAYITEM_NOT_SET: 0,
  SPEECH: 1,
  REWARD: 2,
  INFO: 3
};

/**
 * @return {proto.planet_tycoon.DisplayItem.DisplayitemCase}
 */
proto.planet_tycoon.DisplayItem.prototype.getDisplayitemCase = function() {
  return /** @type {proto.planet_tycoon.DisplayItem.DisplayitemCase} */(jspb.Message.computeOneofCase(this, proto.planet_tycoon.DisplayItem.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.DisplayItem.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.DisplayItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.DisplayItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.DisplayItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    speech: (f = msg.getSpeech()) && proto.planet_tycoon.Speech.toObject(includeInstance, f),
    reward: (f = msg.getReward()) && proto.planet_tycoon.Reward.toObject(includeInstance, f),
    info: (f = msg.getInfo()) && proto.planet_tycoon.Info.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.DisplayItem}
 */
proto.planet_tycoon.DisplayItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.DisplayItem;
  return proto.planet_tycoon.DisplayItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.DisplayItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.DisplayItem}
 */
proto.planet_tycoon.DisplayItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.planet_tycoon.Speech;
      reader.readMessage(value,proto.planet_tycoon.Speech.deserializeBinaryFromReader);
      msg.setSpeech(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.Reward;
      reader.readMessage(value,proto.planet_tycoon.Reward.deserializeBinaryFromReader);
      msg.setReward(value);
      break;
    case 3:
      var value = new proto.planet_tycoon.Info;
      reader.readMessage(value,proto.planet_tycoon.Info.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.DisplayItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.DisplayItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.DisplayItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.DisplayItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpeech();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.planet_tycoon.Speech.serializeBinaryToWriter
    );
  }
  f = message.getReward();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.Reward.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.planet_tycoon.Info.serializeBinaryToWriter
    );
  }
};


/**
 * optional Speech speech = 1;
 * @return {?proto.planet_tycoon.Speech}
 */
proto.planet_tycoon.DisplayItem.prototype.getSpeech = function() {
  return /** @type{?proto.planet_tycoon.Speech} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Speech, 1));
};


/**
 * @param {?proto.planet_tycoon.Speech|undefined} value
 * @return {!proto.planet_tycoon.DisplayItem} returns this
*/
proto.planet_tycoon.DisplayItem.prototype.setSpeech = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.planet_tycoon.DisplayItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.DisplayItem} returns this
 */
proto.planet_tycoon.DisplayItem.prototype.clearSpeech = function() {
  return this.setSpeech(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.DisplayItem.prototype.hasSpeech = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Reward reward = 2;
 * @return {?proto.planet_tycoon.Reward}
 */
proto.planet_tycoon.DisplayItem.prototype.getReward = function() {
  return /** @type{?proto.planet_tycoon.Reward} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Reward, 2));
};


/**
 * @param {?proto.planet_tycoon.Reward|undefined} value
 * @return {!proto.planet_tycoon.DisplayItem} returns this
*/
proto.planet_tycoon.DisplayItem.prototype.setReward = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.planet_tycoon.DisplayItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.DisplayItem} returns this
 */
proto.planet_tycoon.DisplayItem.prototype.clearReward = function() {
  return this.setReward(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.DisplayItem.prototype.hasReward = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Info info = 3;
 * @return {?proto.planet_tycoon.Info}
 */
proto.planet_tycoon.DisplayItem.prototype.getInfo = function() {
  return /** @type{?proto.planet_tycoon.Info} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Info, 3));
};


/**
 * @param {?proto.planet_tycoon.Info|undefined} value
 * @return {!proto.planet_tycoon.DisplayItem} returns this
*/
proto.planet_tycoon.DisplayItem.prototype.setInfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.planet_tycoon.DisplayItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.DisplayItem} returns this
 */
proto.planet_tycoon.DisplayItem.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.DisplayItem.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.planet_tycoon.Reward.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.planet_tycoon.Reward.RewardCase = {
  REWARD_NOT_SET: 0,
  RESOURCE: 1,
  ARTIFACT: 2
};

/**
 * @return {proto.planet_tycoon.Reward.RewardCase}
 */
proto.planet_tycoon.Reward.prototype.getRewardCase = function() {
  return /** @type {proto.planet_tycoon.Reward.RewardCase} */(jspb.Message.computeOneofCase(this, proto.planet_tycoon.Reward.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Reward.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Reward.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Reward} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Reward.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: (f = msg.getResource()) && proto.planet_tycoon.Resource.toObject(includeInstance, f),
    artifact: (f = msg.getArtifact()) && proto.planet_tycoon.Artifact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Reward}
 */
proto.planet_tycoon.Reward.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Reward;
  return proto.planet_tycoon.Reward.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Reward} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Reward}
 */
proto.planet_tycoon.Reward.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.planet_tycoon.Resource;
      reader.readMessage(value,proto.planet_tycoon.Resource.deserializeBinaryFromReader);
      msg.setResource(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.Artifact;
      reader.readMessage(value,proto.planet_tycoon.Artifact.deserializeBinaryFromReader);
      msg.setArtifact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Reward.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Reward.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Reward} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Reward.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.planet_tycoon.Resource.serializeBinaryToWriter
    );
  }
  f = message.getArtifact();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.Artifact.serializeBinaryToWriter
    );
  }
};


/**
 * optional Resource resource = 1;
 * @return {?proto.planet_tycoon.Resource}
 */
proto.planet_tycoon.Reward.prototype.getResource = function() {
  return /** @type{?proto.planet_tycoon.Resource} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Resource, 1));
};


/**
 * @param {?proto.planet_tycoon.Resource|undefined} value
 * @return {!proto.planet_tycoon.Reward} returns this
*/
proto.planet_tycoon.Reward.prototype.setResource = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.planet_tycoon.Reward.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Reward} returns this
 */
proto.planet_tycoon.Reward.prototype.clearResource = function() {
  return this.setResource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Reward.prototype.hasResource = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Artifact artifact = 2;
 * @return {?proto.planet_tycoon.Artifact}
 */
proto.planet_tycoon.Reward.prototype.getArtifact = function() {
  return /** @type{?proto.planet_tycoon.Artifact} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Artifact, 2));
};


/**
 * @param {?proto.planet_tycoon.Artifact|undefined} value
 * @return {!proto.planet_tycoon.Reward} returns this
*/
proto.planet_tycoon.Reward.prototype.setArtifact = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.planet_tycoon.Reward.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Reward} returns this
 */
proto.planet_tycoon.Reward.prototype.clearArtifact = function() {
  return this.setArtifact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Reward.prototype.hasArtifact = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Resource.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Resource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Resource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Resource.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    count: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Resource}
 */
proto.planet_tycoon.Resource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Resource;
  return proto.planet_tycoon.Resource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Resource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Resource}
 */
proto.planet_tycoon.Resource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.planet_tycoon.Resource.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Resource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Resource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Resource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Resource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.planet_tycoon.Resource.Type} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.planet_tycoon.Resource.Type = {
  NONE: 0,
  OXYGEN: 1,
  FUEL: 2,
  CRYSTAL: 3,
  METAL: 4
};

/**
 * optional Type type = 1;
 * @return {!proto.planet_tycoon.Resource.Type}
 */
proto.planet_tycoon.Resource.prototype.getType = function() {
  return /** @type {!proto.planet_tycoon.Resource.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.planet_tycoon.Resource.Type} value
 * @return {!proto.planet_tycoon.Resource} returns this
 */
proto.planet_tycoon.Resource.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Resource} returns this
 */
proto.planet_tycoon.Resource.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Resource.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.planet_tycoon.Resource.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Resource} returns this
 */
proto.planet_tycoon.Resource.prototype.setCount = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Resource} returns this
 */
proto.planet_tycoon.Resource.prototype.clearCount = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Resource.prototype.hasCount = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Artifact.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Artifact.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Artifact} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Artifact.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    name: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    description: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    portraitfilename: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Artifact}
 */
proto.planet_tycoon.Artifact.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Artifact;
  return proto.planet_tycoon.Artifact.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Artifact} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Artifact}
 */
proto.planet_tycoon.Artifact.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortraitfilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Artifact.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Artifact.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Artifact} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Artifact.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * required string id = 1;
 * @return {string}
 */
proto.planet_tycoon.Artifact.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Artifact} returns this
 */
proto.planet_tycoon.Artifact.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Artifact} returns this
 */
proto.planet_tycoon.Artifact.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Artifact.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.planet_tycoon.Artifact.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Artifact} returns this
 */
proto.planet_tycoon.Artifact.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Artifact} returns this
 */
proto.planet_tycoon.Artifact.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Artifact.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.planet_tycoon.Artifact.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Artifact} returns this
 */
proto.planet_tycoon.Artifact.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Artifact} returns this
 */
proto.planet_tycoon.Artifact.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Artifact.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string portraitFilename = 4;
 * @return {string}
 */
proto.planet_tycoon.Artifact.prototype.getPortraitfilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Artifact} returns this
 */
proto.planet_tycoon.Artifact.prototype.setPortraitfilename = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Artifact} returns this
 */
proto.planet_tycoon.Artifact.prototype.clearPortraitfilename = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Artifact.prototype.hasPortraitfilename = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.EventTask.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.EventTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.EventTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.EventTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    locationid: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    verb: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    object: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    metadata: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    consumeartifactid: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    withartifactid: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.EventTask}
 */
proto.planet_tycoon.EventTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.EventTask;
  return proto.planet_tycoon.EventTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.EventTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.EventTask}
 */
proto.planet_tycoon.EventTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationid(value);
      break;
    case 2:
      var value = /** @type {!proto.planet_tycoon.EventTask.Verb} */ (reader.readEnum());
      msg.setVerb(value);
      break;
    case 3:
      var value = /** @type {!proto.planet_tycoon.EventTask.Object} */ (reader.readEnum());
      msg.setObject(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetadata(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumeartifactid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setWithartifactid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.EventTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.EventTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.EventTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.EventTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {!proto.planet_tycoon.EventTask.Verb} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {!proto.planet_tycoon.EventTask.Object} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.planet_tycoon.EventTask.Verb = {
  ALL: 0,
  SALVAGE: 1,
  REPAIR: 2,
  LASSO: 3,
  VAPORIZE: 4,
  HEAT: 5,
  FILL_TANK: 6,
  TRAVEL: 7,
  INTERACT: 8,
  ORBIT: 9,
  VIEW: 10,
  HIDE: 11,
  ACTIVATE: 12,
  CHARGE: 13,
  DISCHARGE: 14,
  ACCELERATE: 15,
  DEACTIVATE: 16,
  DISENGAGE: 17,
  DESTROY: 18,
  DAMAGE: 19,
  INSTALL: 20,
  REMOVE: 22
};

/**
 * @enum {number}
 */
proto.planet_tycoon.EventTask.Object = {
  NONE: 0,
  ANY: 1,
  ENTITY: 2,
  STAR_FRAGMENT: 3,
  OXYGEN: 4,
  METAL: 5,
  CRYSTAL: 6,
  CHARACTER: 7,
  SATELLITE: 8,
  ASTEROID: 9,
  SHIP: 10,
  RAY_BEAM: 11,
  PIRATE_SHIP: 12,
  EQUIPMENT: 13,
  CELL: 14
};

/**
 * optional string locationId = 1;
 * @return {string}
 */
proto.planet_tycoon.EventTask.prototype.getLocationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.EventTask} returns this
 */
proto.planet_tycoon.EventTask.prototype.setLocationid = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.EventTask} returns this
 */
proto.planet_tycoon.EventTask.prototype.clearLocationid = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.EventTask.prototype.hasLocationid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Verb verb = 2;
 * @return {!proto.planet_tycoon.EventTask.Verb}
 */
proto.planet_tycoon.EventTask.prototype.getVerb = function() {
  return /** @type {!proto.planet_tycoon.EventTask.Verb} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.planet_tycoon.EventTask.Verb} value
 * @return {!proto.planet_tycoon.EventTask} returns this
 */
proto.planet_tycoon.EventTask.prototype.setVerb = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.EventTask} returns this
 */
proto.planet_tycoon.EventTask.prototype.clearVerb = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.EventTask.prototype.hasVerb = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Object object = 3;
 * @return {!proto.planet_tycoon.EventTask.Object}
 */
proto.planet_tycoon.EventTask.prototype.getObject = function() {
  return /** @type {!proto.planet_tycoon.EventTask.Object} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.planet_tycoon.EventTask.Object} value
 * @return {!proto.planet_tycoon.EventTask} returns this
 */
proto.planet_tycoon.EventTask.prototype.setObject = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.EventTask} returns this
 */
proto.planet_tycoon.EventTask.prototype.clearObject = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.EventTask.prototype.hasObject = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string metadata = 4;
 * @return {string}
 */
proto.planet_tycoon.EventTask.prototype.getMetadata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.EventTask} returns this
 */
proto.planet_tycoon.EventTask.prototype.setMetadata = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.EventTask} returns this
 */
proto.planet_tycoon.EventTask.prototype.clearMetadata = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.EventTask.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string consumeArtifactId = 5;
 * @return {string}
 */
proto.planet_tycoon.EventTask.prototype.getConsumeartifactid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.EventTask} returns this
 */
proto.planet_tycoon.EventTask.prototype.setConsumeartifactid = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.EventTask} returns this
 */
proto.planet_tycoon.EventTask.prototype.clearConsumeartifactid = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.EventTask.prototype.hasConsumeartifactid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string withArtifactId = 6;
 * @return {string}
 */
proto.planet_tycoon.EventTask.prototype.getWithartifactid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.EventTask} returns this
 */
proto.planet_tycoon.EventTask.prototype.setWithartifactid = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.EventTask} returns this
 */
proto.planet_tycoon.EventTask.prototype.clearWithartifactid = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.EventTask.prototype.hasWithartifactid = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Threshold.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Threshold.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Threshold} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Threshold.toObject = function(includeInstance, msg) {
  var f, obj = {
    operator: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    count: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    floatvalue: (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Threshold}
 */
proto.planet_tycoon.Threshold.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Threshold;
  return proto.planet_tycoon.Threshold.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Threshold} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Threshold}
 */
proto.planet_tycoon.Threshold.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.planet_tycoon.Threshold.Operator} */ (reader.readEnum());
      msg.setOperator(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatvalue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Threshold.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Threshold.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Threshold} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Threshold.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.planet_tycoon.Threshold.Operator} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.planet_tycoon.Threshold.Operator = {
  NOT_EQUAL: 0,
  LESS: 1,
  LESS_OR_EQUAL: 2,
  EQUAL: 3,
  GREATER_OR_EQUAL: 4,
  GREATER: 5
};

/**
 * optional Operator operator = 1;
 * @return {!proto.planet_tycoon.Threshold.Operator}
 */
proto.planet_tycoon.Threshold.prototype.getOperator = function() {
  return /** @type {!proto.planet_tycoon.Threshold.Operator} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.planet_tycoon.Threshold.Operator} value
 * @return {!proto.planet_tycoon.Threshold} returns this
 */
proto.planet_tycoon.Threshold.prototype.setOperator = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Threshold} returns this
 */
proto.planet_tycoon.Threshold.prototype.clearOperator = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Threshold.prototype.hasOperator = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.planet_tycoon.Threshold.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Threshold} returns this
 */
proto.planet_tycoon.Threshold.prototype.setCount = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Threshold} returns this
 */
proto.planet_tycoon.Threshold.prototype.clearCount = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Threshold.prototype.hasCount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float floatValue = 3;
 * @return {number}
 */
proto.planet_tycoon.Threshold.prototype.getFloatvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Threshold} returns this
 */
proto.planet_tycoon.Threshold.prototype.setFloatvalue = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Threshold} returns this
 */
proto.planet_tycoon.Threshold.prototype.clearFloatvalue = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Threshold.prototype.hasFloatvalue = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.PropertyThreshold.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.PropertyThreshold.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.PropertyThreshold} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.PropertyThreshold.toObject = function(includeInstance, msg) {
  var f, obj = {
    objecttype: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    objectid: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    property: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    threshold: (f = msg.getThreshold()) && proto.planet_tycoon.Threshold.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.PropertyThreshold}
 */
proto.planet_tycoon.PropertyThreshold.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.PropertyThreshold;
  return proto.planet_tycoon.PropertyThreshold.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.PropertyThreshold} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.PropertyThreshold}
 */
proto.planet_tycoon.PropertyThreshold.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.planet_tycoon.PropertyThreshold.ObjectType} */ (reader.readEnum());
      msg.setObjecttype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectid(value);
      break;
    case 3:
      var value = /** @type {!proto.planet_tycoon.PropertyThreshold.Property} */ (reader.readEnum());
      msg.setProperty(value);
      break;
    case 4:
      var value = new proto.planet_tycoon.Threshold;
      reader.readMessage(value,proto.planet_tycoon.Threshold.deserializeBinaryFromReader);
      msg.setThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.PropertyThreshold.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.PropertyThreshold.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.PropertyThreshold} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.PropertyThreshold.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.planet_tycoon.PropertyThreshold.ObjectType} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {!proto.planet_tycoon.PropertyThreshold.Property} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getThreshold();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.planet_tycoon.Threshold.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.planet_tycoon.PropertyThreshold.ObjectType = {
  NONE: 0,
  SHIP: 1,
  SALVAGE_RAY: 2,
  PIRATE_SHIP: 3,
  CHARACTER: 4
};

/**
 * @enum {number}
 */
proto.planet_tycoon.PropertyThreshold.Property = {
  NA: 0,
  VELOCITY: 1,
  CHARGE: 2,
  IS_ACTIVE: 3,
  LENGTH: 4,
  RELATIVE_SHIELD_LEVEL: 5,
  RELATIVE_AIR_VOXELS: 6,
  IS_INTERACTABLE: 7
};

/**
 * optional ObjectType objectType = 1;
 * @return {!proto.planet_tycoon.PropertyThreshold.ObjectType}
 */
proto.planet_tycoon.PropertyThreshold.prototype.getObjecttype = function() {
  return /** @type {!proto.planet_tycoon.PropertyThreshold.ObjectType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.planet_tycoon.PropertyThreshold.ObjectType} value
 * @return {!proto.planet_tycoon.PropertyThreshold} returns this
 */
proto.planet_tycoon.PropertyThreshold.prototype.setObjecttype = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.PropertyThreshold} returns this
 */
proto.planet_tycoon.PropertyThreshold.prototype.clearObjecttype = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.PropertyThreshold.prototype.hasObjecttype = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string objectId = 2;
 * @return {string}
 */
proto.planet_tycoon.PropertyThreshold.prototype.getObjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.PropertyThreshold} returns this
 */
proto.planet_tycoon.PropertyThreshold.prototype.setObjectid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.PropertyThreshold} returns this
 */
proto.planet_tycoon.PropertyThreshold.prototype.clearObjectid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.PropertyThreshold.prototype.hasObjectid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Property property = 3;
 * @return {!proto.planet_tycoon.PropertyThreshold.Property}
 */
proto.planet_tycoon.PropertyThreshold.prototype.getProperty = function() {
  return /** @type {!proto.planet_tycoon.PropertyThreshold.Property} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.planet_tycoon.PropertyThreshold.Property} value
 * @return {!proto.planet_tycoon.PropertyThreshold} returns this
 */
proto.planet_tycoon.PropertyThreshold.prototype.setProperty = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.PropertyThreshold} returns this
 */
proto.planet_tycoon.PropertyThreshold.prototype.clearProperty = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.PropertyThreshold.prototype.hasProperty = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Threshold threshold = 4;
 * @return {?proto.planet_tycoon.Threshold}
 */
proto.planet_tycoon.PropertyThreshold.prototype.getThreshold = function() {
  return /** @type{?proto.planet_tycoon.Threshold} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Threshold, 4));
};


/**
 * @param {?proto.planet_tycoon.Threshold|undefined} value
 * @return {!proto.planet_tycoon.PropertyThreshold} returns this
*/
proto.planet_tycoon.PropertyThreshold.prototype.setThreshold = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.PropertyThreshold} returns this
 */
proto.planet_tycoon.PropertyThreshold.prototype.clearThreshold = function() {
  return this.setThreshold(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.PropertyThreshold.prototype.hasThreshold = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.planet_tycoon.PropertyTask.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.PropertyTask.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.PropertyTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.PropertyTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.PropertyTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertythresholdsList: jspb.Message.toObjectList(msg.getPropertythresholdsList(),
    proto.planet_tycoon.PropertyThreshold.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.PropertyTask}
 */
proto.planet_tycoon.PropertyTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.PropertyTask;
  return proto.planet_tycoon.PropertyTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.PropertyTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.PropertyTask}
 */
proto.planet_tycoon.PropertyTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.planet_tycoon.PropertyThreshold;
      reader.readMessage(value,proto.planet_tycoon.PropertyThreshold.deserializeBinaryFromReader);
      msg.addPropertythresholds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.PropertyTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.PropertyTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.PropertyTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.PropertyTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertythresholdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.planet_tycoon.PropertyThreshold.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PropertyThreshold propertyThresholds = 1;
 * @return {!Array<!proto.planet_tycoon.PropertyThreshold>}
 */
proto.planet_tycoon.PropertyTask.prototype.getPropertythresholdsList = function() {
  return /** @type{!Array<!proto.planet_tycoon.PropertyThreshold>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.planet_tycoon.PropertyThreshold, 1));
};


/**
 * @param {!Array<!proto.planet_tycoon.PropertyThreshold>} value
 * @return {!proto.planet_tycoon.PropertyTask} returns this
*/
proto.planet_tycoon.PropertyTask.prototype.setPropertythresholdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.planet_tycoon.PropertyThreshold=} opt_value
 * @param {number=} opt_index
 * @return {!proto.planet_tycoon.PropertyThreshold}
 */
proto.planet_tycoon.PropertyTask.prototype.addPropertythresholds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.planet_tycoon.PropertyThreshold, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.planet_tycoon.PropertyTask} returns this
 */
proto.planet_tycoon.PropertyTask.prototype.clearPropertythresholdsList = function() {
  return this.setPropertythresholdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.ModifyVolumeTask.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.ModifyVolumeTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.ModifyVolumeTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.ModifyVolumeTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    localareaid: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    voxelthresholdsMap: (f = msg.getVoxelthresholdsMap()) ? f.toObject(includeInstance, proto.planet_tycoon.Threshold.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.ModifyVolumeTask}
 */
proto.planet_tycoon.ModifyVolumeTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.ModifyVolumeTask;
  return proto.planet_tycoon.ModifyVolumeTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.ModifyVolumeTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.ModifyVolumeTask}
 */
proto.planet_tycoon.ModifyVolumeTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalareaid(value);
      break;
    case 2:
      var value = msg.getVoxelthresholdsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.planet_tycoon.Threshold.deserializeBinaryFromReader, 0, new proto.planet_tycoon.Threshold());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.ModifyVolumeTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.ModifyVolumeTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.ModifyVolumeTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.ModifyVolumeTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVoxelthresholdsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.planet_tycoon.Threshold.serializeBinaryToWriter);
  }
};


/**
 * optional string localAreaId = 1;
 * @return {string}
 */
proto.planet_tycoon.ModifyVolumeTask.prototype.getLocalareaid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.ModifyVolumeTask} returns this
 */
proto.planet_tycoon.ModifyVolumeTask.prototype.setLocalareaid = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.ModifyVolumeTask} returns this
 */
proto.planet_tycoon.ModifyVolumeTask.prototype.clearLocalareaid = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.ModifyVolumeTask.prototype.hasLocalareaid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * map<int32, Threshold> voxelThresholds = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.planet_tycoon.Threshold>}
 */
proto.planet_tycoon.ModifyVolumeTask.prototype.getVoxelthresholdsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.planet_tycoon.Threshold>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.planet_tycoon.Threshold));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.planet_tycoon.ModifyVolumeTask} returns this
 */
proto.planet_tycoon.ModifyVolumeTask.prototype.clearVoxelthresholdsMap = function() {
  this.getVoxelthresholdsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.planet_tycoon.Speech.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Speech.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Speech.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Speech} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Speech.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    speechparagraphsList: jspb.Message.toObjectList(msg.getSpeechparagraphsList(),
    proto.planet_tycoon.SpeechParagraph.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Speech}
 */
proto.planet_tycoon.Speech.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Speech;
  return proto.planet_tycoon.Speech.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Speech} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Speech}
 */
proto.planet_tycoon.Speech.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.SpeechParagraph;
      reader.readMessage(value,proto.planet_tycoon.SpeechParagraph.deserializeBinaryFromReader);
      msg.addSpeechparagraphs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Speech.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Speech.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Speech} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Speech.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpeechparagraphsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.planet_tycoon.SpeechParagraph.serializeBinaryToWriter
    );
  }
};


/**
 * required string id = 1;
 * @return {string}
 */
proto.planet_tycoon.Speech.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Speech} returns this
 */
proto.planet_tycoon.Speech.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Speech} returns this
 */
proto.planet_tycoon.Speech.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Speech.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SpeechParagraph speechParagraphs = 2;
 * @return {!Array<!proto.planet_tycoon.SpeechParagraph>}
 */
proto.planet_tycoon.Speech.prototype.getSpeechparagraphsList = function() {
  return /** @type{!Array<!proto.planet_tycoon.SpeechParagraph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.planet_tycoon.SpeechParagraph, 2));
};


/**
 * @param {!Array<!proto.planet_tycoon.SpeechParagraph>} value
 * @return {!proto.planet_tycoon.Speech} returns this
*/
proto.planet_tycoon.Speech.prototype.setSpeechparagraphsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.planet_tycoon.SpeechParagraph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.planet_tycoon.SpeechParagraph}
 */
proto.planet_tycoon.Speech.prototype.addSpeechparagraphs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.planet_tycoon.SpeechParagraph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.planet_tycoon.Speech} returns this
 */
proto.planet_tycoon.Speech.prototype.clearSpeechparagraphsList = function() {
  return this.setSpeechparagraphsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.SpeechParagraph.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.SpeechParagraph.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.SpeechParagraph} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.SpeechParagraph.toObject = function(includeInstance, msg) {
  var f, obj = {
    portraitfilename: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    speech: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    displayrate: (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.SpeechParagraph}
 */
proto.planet_tycoon.SpeechParagraph.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.SpeechParagraph;
  return proto.planet_tycoon.SpeechParagraph.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.SpeechParagraph} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.SpeechParagraph}
 */
proto.planet_tycoon.SpeechParagraph.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortraitfilename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpeech(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDisplayrate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.SpeechParagraph.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.SpeechParagraph.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.SpeechParagraph} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.SpeechParagraph.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional string portraitFilename = 1;
 * @return {string}
 */
proto.planet_tycoon.SpeechParagraph.prototype.getPortraitfilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.SpeechParagraph} returns this
 */
proto.planet_tycoon.SpeechParagraph.prototype.setPortraitfilename = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.SpeechParagraph} returns this
 */
proto.planet_tycoon.SpeechParagraph.prototype.clearPortraitfilename = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.SpeechParagraph.prototype.hasPortraitfilename = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string speech = 2;
 * @return {string}
 */
proto.planet_tycoon.SpeechParagraph.prototype.getSpeech = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.SpeechParagraph} returns this
 */
proto.planet_tycoon.SpeechParagraph.prototype.setSpeech = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.SpeechParagraph} returns this
 */
proto.planet_tycoon.SpeechParagraph.prototype.clearSpeech = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.SpeechParagraph.prototype.hasSpeech = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float displayRate = 3;
 * @return {number}
 */
proto.planet_tycoon.SpeechParagraph.prototype.getDisplayrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.SpeechParagraph} returns this
 */
proto.planet_tycoon.SpeechParagraph.prototype.setDisplayrate = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.SpeechParagraph} returns this
 */
proto.planet_tycoon.SpeechParagraph.prototype.clearDisplayrate = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.SpeechParagraph.prototype.hasDisplayrate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Vector2i.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Vector2i.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Vector2i} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Vector2i.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    y: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Vector2i}
 */
proto.planet_tycoon.Vector2i.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Vector2i;
  return proto.planet_tycoon.Vector2i.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Vector2i} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Vector2i}
 */
proto.planet_tycoon.Vector2i.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setY(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Vector2i.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Vector2i.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Vector2i} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Vector2i.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * required int32 x = 1;
 * @return {number}
 */
proto.planet_tycoon.Vector2i.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Vector2i} returns this
 */
proto.planet_tycoon.Vector2i.prototype.setX = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Vector2i} returns this
 */
proto.planet_tycoon.Vector2i.prototype.clearX = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Vector2i.prototype.hasX = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required int32 y = 2;
 * @return {number}
 */
proto.planet_tycoon.Vector2i.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Vector2i} returns this
 */
proto.planet_tycoon.Vector2i.prototype.setY = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Vector2i} returns this
 */
proto.planet_tycoon.Vector2i.prototype.clearY = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Vector2i.prototype.hasY = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Vector2d.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Vector2d.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Vector2d} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Vector2d.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: (f = jspb.Message.getOptionalFloatingPointField(msg, 1)) == null ? undefined : f,
    y: (f = jspb.Message.getOptionalFloatingPointField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Vector2d}
 */
proto.planet_tycoon.Vector2d.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Vector2d;
  return proto.planet_tycoon.Vector2d.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Vector2d} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Vector2d}
 */
proto.planet_tycoon.Vector2d.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setY(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Vector2d.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Vector2d.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Vector2d} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Vector2d.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * required float x = 1;
 * @return {number}
 */
proto.planet_tycoon.Vector2d.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Vector2d} returns this
 */
proto.planet_tycoon.Vector2d.prototype.setX = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Vector2d} returns this
 */
proto.planet_tycoon.Vector2d.prototype.clearX = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Vector2d.prototype.hasX = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required float y = 2;
 * @return {number}
 */
proto.planet_tycoon.Vector2d.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Vector2d} returns this
 */
proto.planet_tycoon.Vector2d.prototype.setY = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Vector2d} returns this
 */
proto.planet_tycoon.Vector2d.prototype.clearY = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Vector2d.prototype.hasY = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Vector3d.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Vector3d.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Vector3d} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Vector3d.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: (f = jspb.Message.getOptionalFloatingPointField(msg, 1)) == null ? undefined : f,
    y: (f = jspb.Message.getOptionalFloatingPointField(msg, 2)) == null ? undefined : f,
    z: (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Vector3d}
 */
proto.planet_tycoon.Vector3d.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Vector3d;
  return proto.planet_tycoon.Vector3d.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Vector3d} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Vector3d}
 */
proto.planet_tycoon.Vector3d.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setY(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setZ(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Vector3d.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Vector3d.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Vector3d} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Vector3d.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * required float x = 1;
 * @return {number}
 */
proto.planet_tycoon.Vector3d.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Vector3d} returns this
 */
proto.planet_tycoon.Vector3d.prototype.setX = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Vector3d} returns this
 */
proto.planet_tycoon.Vector3d.prototype.clearX = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Vector3d.prototype.hasX = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required float y = 2;
 * @return {number}
 */
proto.planet_tycoon.Vector3d.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Vector3d} returns this
 */
proto.planet_tycoon.Vector3d.prototype.setY = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Vector3d} returns this
 */
proto.planet_tycoon.Vector3d.prototype.clearY = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Vector3d.prototype.hasY = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required float z = 3;
 * @return {number}
 */
proto.planet_tycoon.Vector3d.prototype.getZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Vector3d} returns this
 */
proto.planet_tycoon.Vector3d.prototype.setZ = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Vector3d} returns this
 */
proto.planet_tycoon.Vector3d.prototype.clearZ = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Vector3d.prototype.hasZ = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Color.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Color.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Color} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Color.toObject = function(includeInstance, msg) {
  var f, obj = {
    r: (f = jspb.Message.getOptionalFloatingPointField(msg, 1)) == null ? undefined : f,
    g: (f = jspb.Message.getOptionalFloatingPointField(msg, 2)) == null ? undefined : f,
    b: (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null ? undefined : f,
    a: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Color}
 */
proto.planet_tycoon.Color.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Color;
  return proto.planet_tycoon.Color.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Color} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Color}
 */
proto.planet_tycoon.Color.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setR(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setG(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setB(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setA(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Color.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Color.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Color} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Color.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * required float r = 1;
 * @return {number}
 */
proto.planet_tycoon.Color.prototype.getR = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Color} returns this
 */
proto.planet_tycoon.Color.prototype.setR = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Color} returns this
 */
proto.planet_tycoon.Color.prototype.clearR = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Color.prototype.hasR = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required float g = 2;
 * @return {number}
 */
proto.planet_tycoon.Color.prototype.getG = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Color} returns this
 */
proto.planet_tycoon.Color.prototype.setG = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Color} returns this
 */
proto.planet_tycoon.Color.prototype.clearG = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Color.prototype.hasG = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required float b = 3;
 * @return {number}
 */
proto.planet_tycoon.Color.prototype.getB = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Color} returns this
 */
proto.planet_tycoon.Color.prototype.setB = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Color} returns this
 */
proto.planet_tycoon.Color.prototype.clearB = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Color.prototype.hasB = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required float a = 4;
 * @return {number}
 */
proto.planet_tycoon.Color.prototype.getA = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Color} returns this
 */
proto.planet_tycoon.Color.prototype.setA = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Color} returns this
 */
proto.planet_tycoon.Color.prototype.clearA = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Color.prototype.hasA = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Voxel.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Voxel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Voxel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Voxel.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    temperature: (f = jspb.Message.getOptionalFloatingPointField(msg, 2)) == null ? undefined : f,
    pressure: (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null ? undefined : f,
    moisture: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Voxel}
 */
proto.planet_tycoon.Voxel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Voxel;
  return proto.planet_tycoon.Voxel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Voxel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Voxel}
 */
proto.planet_tycoon.Voxel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.planet_tycoon.Voxel.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTemperature(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPressure(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMoisture(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Voxel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Voxel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Voxel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Voxel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.planet_tycoon.Voxel.Type} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.planet_tycoon.Voxel.Type = {
  EMPTY: 0,
  WATER: 1,
  ROCK: 2,
  PLANT: 3,
  ALIEN: 4,
  CITY: 5,
  SALVAGE: 6
};

/**
 * optional Type type = 1;
 * @return {!proto.planet_tycoon.Voxel.Type}
 */
proto.planet_tycoon.Voxel.prototype.getType = function() {
  return /** @type {!proto.planet_tycoon.Voxel.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.planet_tycoon.Voxel.Type} value
 * @return {!proto.planet_tycoon.Voxel} returns this
 */
proto.planet_tycoon.Voxel.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Voxel} returns this
 */
proto.planet_tycoon.Voxel.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Voxel.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float temperature = 2;
 * @return {number}
 */
proto.planet_tycoon.Voxel.prototype.getTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Voxel} returns this
 */
proto.planet_tycoon.Voxel.prototype.setTemperature = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Voxel} returns this
 */
proto.planet_tycoon.Voxel.prototype.clearTemperature = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Voxel.prototype.hasTemperature = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float pressure = 3;
 * @return {number}
 */
proto.planet_tycoon.Voxel.prototype.getPressure = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Voxel} returns this
 */
proto.planet_tycoon.Voxel.prototype.setPressure = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Voxel} returns this
 */
proto.planet_tycoon.Voxel.prototype.clearPressure = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Voxel.prototype.hasPressure = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float moisture = 4;
 * @return {number}
 */
proto.planet_tycoon.Voxel.prototype.getMoisture = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Voxel} returns this
 */
proto.planet_tycoon.Voxel.prototype.setMoisture = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Voxel} returns this
 */
proto.planet_tycoon.Voxel.prototype.clearMoisture = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Voxel.prototype.hasMoisture = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Volume.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Volume.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Volume} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Volume.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    length: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    width: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    height: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Volume}
 */
proto.planet_tycoon.Volume.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Volume;
  return proto.planet_tycoon.Volume.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Volume} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Volume}
 */
proto.planet_tycoon.Volume.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLength(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Volume.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Volume.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Volume} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Volume.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * required string id = 1;
 * @return {string}
 */
proto.planet_tycoon.Volume.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Volume} returns this
 */
proto.planet_tycoon.Volume.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Volume} returns this
 */
proto.planet_tycoon.Volume.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Volume.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required int32 length = 2;
 * @return {number}
 */
proto.planet_tycoon.Volume.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Volume} returns this
 */
proto.planet_tycoon.Volume.prototype.setLength = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Volume} returns this
 */
proto.planet_tycoon.Volume.prototype.clearLength = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Volume.prototype.hasLength = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required int32 width = 3;
 * @return {number}
 */
proto.planet_tycoon.Volume.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Volume} returns this
 */
proto.planet_tycoon.Volume.prototype.setWidth = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Volume} returns this
 */
proto.planet_tycoon.Volume.prototype.clearWidth = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Volume.prototype.hasWidth = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required int32 height = 4;
 * @return {number}
 */
proto.planet_tycoon.Volume.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Volume} returns this
 */
proto.planet_tycoon.Volume.prototype.setHeight = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Volume} returns this
 */
proto.planet_tycoon.Volume.prototype.clearHeight = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Volume.prototype.hasHeight = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required bytes data = 5;
 * @return {!(string|Uint8Array)}
 */
proto.planet_tycoon.Volume.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * required bytes data = 5;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.planet_tycoon.Volume.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * required bytes data = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Volume.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.planet_tycoon.Volume} returns this
 */
proto.planet_tycoon.Volume.prototype.setData = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Volume} returns this
 */
proto.planet_tycoon.Volume.prototype.clearData = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Volume.prototype.hasData = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.EquipmentStock.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.EquipmentStock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.EquipmentStock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.EquipmentStock.toObject = function(includeInstance, msg) {
  var f, obj = {
    equipment: (f = msg.getEquipment()) && proto.planet_tycoon.Equipment.toObject(includeInstance, f),
    count: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    cost: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.EquipmentStock}
 */
proto.planet_tycoon.EquipmentStock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.EquipmentStock;
  return proto.planet_tycoon.EquipmentStock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.EquipmentStock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.EquipmentStock}
 */
proto.planet_tycoon.EquipmentStock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.planet_tycoon.Equipment;
      reader.readMessage(value,proto.planet_tycoon.Equipment.deserializeBinaryFromReader);
      msg.setEquipment(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.EquipmentStock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.EquipmentStock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.EquipmentStock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.EquipmentStock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEquipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.planet_tycoon.Equipment.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional Equipment equipment = 1;
 * @return {?proto.planet_tycoon.Equipment}
 */
proto.planet_tycoon.EquipmentStock.prototype.getEquipment = function() {
  return /** @type{?proto.planet_tycoon.Equipment} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Equipment, 1));
};


/**
 * @param {?proto.planet_tycoon.Equipment|undefined} value
 * @return {!proto.planet_tycoon.EquipmentStock} returns this
*/
proto.planet_tycoon.EquipmentStock.prototype.setEquipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.EquipmentStock} returns this
 */
proto.planet_tycoon.EquipmentStock.prototype.clearEquipment = function() {
  return this.setEquipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.EquipmentStock.prototype.hasEquipment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.planet_tycoon.EquipmentStock.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.EquipmentStock} returns this
 */
proto.planet_tycoon.EquipmentStock.prototype.setCount = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.EquipmentStock} returns this
 */
proto.planet_tycoon.EquipmentStock.prototype.clearCount = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.EquipmentStock.prototype.hasCount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 cost = 3;
 * @return {number}
 */
proto.planet_tycoon.EquipmentStock.prototype.getCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.EquipmentStock} returns this
 */
proto.planet_tycoon.EquipmentStock.prototype.setCost = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.EquipmentStock} returns this
 */
proto.planet_tycoon.EquipmentStock.prototype.clearCost = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.EquipmentStock.prototype.hasCost = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.planet_tycoon.Equipment.oneofGroups_ = [[5,6,7,8,9,10,11,12]];

/**
 * @enum {number}
 */
proto.planet_tycoon.Equipment.TypeCase = {
  TYPE_NOT_SET: 0,
  CAPACITOR: 5,
  TANK: 6,
  SALVAGERAY: 7,
  VAPORIZERRAY: 8,
  HEATRAY: 9,
  SPOTLIGHTSENSOR: 10,
  THERMALSENSOR: 11,
  LIGHTSPEEDENGINE: 12
};

/**
 * @return {proto.planet_tycoon.Equipment.TypeCase}
 */
proto.planet_tycoon.Equipment.prototype.getTypeCase = function() {
  return /** @type {proto.planet_tycoon.Equipment.TypeCase} */(jspb.Message.computeOneofCase(this, proto.planet_tycoon.Equipment.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Equipment.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Equipment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Equipment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Equipment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    name: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    description: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    level: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    capacitor: (f = msg.getCapacitor()) && proto.planet_tycoon.Capacitor.toObject(includeInstance, f),
    tank: (f = msg.getTank()) && proto.planet_tycoon.Tank.toObject(includeInstance, f),
    salvageray: (f = msg.getSalvageray()) && proto.planet_tycoon.SalvageRay.toObject(includeInstance, f),
    vaporizerray: (f = msg.getVaporizerray()) && proto.planet_tycoon.VaporizerRay.toObject(includeInstance, f),
    heatray: (f = msg.getHeatray()) && proto.planet_tycoon.HeatRay.toObject(includeInstance, f),
    spotlightsensor: (f = msg.getSpotlightsensor()) && proto.planet_tycoon.SpotlightSensor.toObject(includeInstance, f),
    thermalsensor: (f = msg.getThermalsensor()) && proto.planet_tycoon.ThermalSensor.toObject(includeInstance, f),
    lightspeedengine: (f = msg.getLightspeedengine()) && proto.planet_tycoon.LightSpeedEngine.toObject(includeInstance, f),
    health: (f = jspb.Message.getOptionalFloatingPointField(msg, 13)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Equipment}
 */
proto.planet_tycoon.Equipment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Equipment;
  return proto.planet_tycoon.Equipment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Equipment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Equipment}
 */
proto.planet_tycoon.Equipment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLevel(value);
      break;
    case 5:
      var value = new proto.planet_tycoon.Capacitor;
      reader.readMessage(value,proto.planet_tycoon.Capacitor.deserializeBinaryFromReader);
      msg.setCapacitor(value);
      break;
    case 6:
      var value = new proto.planet_tycoon.Tank;
      reader.readMessage(value,proto.planet_tycoon.Tank.deserializeBinaryFromReader);
      msg.setTank(value);
      break;
    case 7:
      var value = new proto.planet_tycoon.SalvageRay;
      reader.readMessage(value,proto.planet_tycoon.SalvageRay.deserializeBinaryFromReader);
      msg.setSalvageray(value);
      break;
    case 8:
      var value = new proto.planet_tycoon.VaporizerRay;
      reader.readMessage(value,proto.planet_tycoon.VaporizerRay.deserializeBinaryFromReader);
      msg.setVaporizerray(value);
      break;
    case 9:
      var value = new proto.planet_tycoon.HeatRay;
      reader.readMessage(value,proto.planet_tycoon.HeatRay.deserializeBinaryFromReader);
      msg.setHeatray(value);
      break;
    case 10:
      var value = new proto.planet_tycoon.SpotlightSensor;
      reader.readMessage(value,proto.planet_tycoon.SpotlightSensor.deserializeBinaryFromReader);
      msg.setSpotlightsensor(value);
      break;
    case 11:
      var value = new proto.planet_tycoon.ThermalSensor;
      reader.readMessage(value,proto.planet_tycoon.ThermalSensor.deserializeBinaryFromReader);
      msg.setThermalsensor(value);
      break;
    case 12:
      var value = new proto.planet_tycoon.LightSpeedEngine;
      reader.readMessage(value,proto.planet_tycoon.LightSpeedEngine.deserializeBinaryFromReader);
      msg.setLightspeedengine(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHealth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Equipment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Equipment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Equipment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Equipment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCapacitor();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.planet_tycoon.Capacitor.serializeBinaryToWriter
    );
  }
  f = message.getTank();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.planet_tycoon.Tank.serializeBinaryToWriter
    );
  }
  f = message.getSalvageray();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.planet_tycoon.SalvageRay.serializeBinaryToWriter
    );
  }
  f = message.getVaporizerray();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.planet_tycoon.VaporizerRay.serializeBinaryToWriter
    );
  }
  f = message.getHeatray();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.planet_tycoon.HeatRay.serializeBinaryToWriter
    );
  }
  f = message.getSpotlightsensor();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.planet_tycoon.SpotlightSensor.serializeBinaryToWriter
    );
  }
  f = message.getThermalsensor();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.planet_tycoon.ThermalSensor.serializeBinaryToWriter
    );
  }
  f = message.getLightspeedengine();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.planet_tycoon.LightSpeedEngine.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeFloat(
      13,
      f
    );
  }
};


/**
 * required string id = 1;
 * @return {string}
 */
proto.planet_tycoon.Equipment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Equipment} returns this
 */
proto.planet_tycoon.Equipment.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Equipment} returns this
 */
proto.planet_tycoon.Equipment.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Equipment.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.planet_tycoon.Equipment.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Equipment} returns this
 */
proto.planet_tycoon.Equipment.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Equipment} returns this
 */
proto.planet_tycoon.Equipment.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Equipment.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.planet_tycoon.Equipment.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Equipment} returns this
 */
proto.planet_tycoon.Equipment.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Equipment} returns this
 */
proto.planet_tycoon.Equipment.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Equipment.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 level = 4;
 * @return {number}
 */
proto.planet_tycoon.Equipment.prototype.getLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Equipment} returns this
 */
proto.planet_tycoon.Equipment.prototype.setLevel = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Equipment} returns this
 */
proto.planet_tycoon.Equipment.prototype.clearLevel = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Equipment.prototype.hasLevel = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Capacitor capacitor = 5;
 * @return {?proto.planet_tycoon.Capacitor}
 */
proto.planet_tycoon.Equipment.prototype.getCapacitor = function() {
  return /** @type{?proto.planet_tycoon.Capacitor} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Capacitor, 5));
};


/**
 * @param {?proto.planet_tycoon.Capacitor|undefined} value
 * @return {!proto.planet_tycoon.Equipment} returns this
*/
proto.planet_tycoon.Equipment.prototype.setCapacitor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.planet_tycoon.Equipment.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Equipment} returns this
 */
proto.planet_tycoon.Equipment.prototype.clearCapacitor = function() {
  return this.setCapacitor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Equipment.prototype.hasCapacitor = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Tank tank = 6;
 * @return {?proto.planet_tycoon.Tank}
 */
proto.planet_tycoon.Equipment.prototype.getTank = function() {
  return /** @type{?proto.planet_tycoon.Tank} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Tank, 6));
};


/**
 * @param {?proto.planet_tycoon.Tank|undefined} value
 * @return {!proto.planet_tycoon.Equipment} returns this
*/
proto.planet_tycoon.Equipment.prototype.setTank = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.planet_tycoon.Equipment.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Equipment} returns this
 */
proto.planet_tycoon.Equipment.prototype.clearTank = function() {
  return this.setTank(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Equipment.prototype.hasTank = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SalvageRay salvageRay = 7;
 * @return {?proto.planet_tycoon.SalvageRay}
 */
proto.planet_tycoon.Equipment.prototype.getSalvageray = function() {
  return /** @type{?proto.planet_tycoon.SalvageRay} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.SalvageRay, 7));
};


/**
 * @param {?proto.planet_tycoon.SalvageRay|undefined} value
 * @return {!proto.planet_tycoon.Equipment} returns this
*/
proto.planet_tycoon.Equipment.prototype.setSalvageray = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.planet_tycoon.Equipment.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Equipment} returns this
 */
proto.planet_tycoon.Equipment.prototype.clearSalvageray = function() {
  return this.setSalvageray(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Equipment.prototype.hasSalvageray = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional VaporizerRay vaporizerRay = 8;
 * @return {?proto.planet_tycoon.VaporizerRay}
 */
proto.planet_tycoon.Equipment.prototype.getVaporizerray = function() {
  return /** @type{?proto.planet_tycoon.VaporizerRay} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.VaporizerRay, 8));
};


/**
 * @param {?proto.planet_tycoon.VaporizerRay|undefined} value
 * @return {!proto.planet_tycoon.Equipment} returns this
*/
proto.planet_tycoon.Equipment.prototype.setVaporizerray = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.planet_tycoon.Equipment.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Equipment} returns this
 */
proto.planet_tycoon.Equipment.prototype.clearVaporizerray = function() {
  return this.setVaporizerray(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Equipment.prototype.hasVaporizerray = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional HeatRay heatRay = 9;
 * @return {?proto.planet_tycoon.HeatRay}
 */
proto.planet_tycoon.Equipment.prototype.getHeatray = function() {
  return /** @type{?proto.planet_tycoon.HeatRay} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.HeatRay, 9));
};


/**
 * @param {?proto.planet_tycoon.HeatRay|undefined} value
 * @return {!proto.planet_tycoon.Equipment} returns this
*/
proto.planet_tycoon.Equipment.prototype.setHeatray = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.planet_tycoon.Equipment.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Equipment} returns this
 */
proto.planet_tycoon.Equipment.prototype.clearHeatray = function() {
  return this.setHeatray(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Equipment.prototype.hasHeatray = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional SpotlightSensor spotlightSensor = 10;
 * @return {?proto.planet_tycoon.SpotlightSensor}
 */
proto.planet_tycoon.Equipment.prototype.getSpotlightsensor = function() {
  return /** @type{?proto.planet_tycoon.SpotlightSensor} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.SpotlightSensor, 10));
};


/**
 * @param {?proto.planet_tycoon.SpotlightSensor|undefined} value
 * @return {!proto.planet_tycoon.Equipment} returns this
*/
proto.planet_tycoon.Equipment.prototype.setSpotlightsensor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.planet_tycoon.Equipment.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Equipment} returns this
 */
proto.planet_tycoon.Equipment.prototype.clearSpotlightsensor = function() {
  return this.setSpotlightsensor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Equipment.prototype.hasSpotlightsensor = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ThermalSensor thermalSensor = 11;
 * @return {?proto.planet_tycoon.ThermalSensor}
 */
proto.planet_tycoon.Equipment.prototype.getThermalsensor = function() {
  return /** @type{?proto.planet_tycoon.ThermalSensor} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.ThermalSensor, 11));
};


/**
 * @param {?proto.planet_tycoon.ThermalSensor|undefined} value
 * @return {!proto.planet_tycoon.Equipment} returns this
*/
proto.planet_tycoon.Equipment.prototype.setThermalsensor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.planet_tycoon.Equipment.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Equipment} returns this
 */
proto.planet_tycoon.Equipment.prototype.clearThermalsensor = function() {
  return this.setThermalsensor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Equipment.prototype.hasThermalsensor = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional LightSpeedEngine lightSpeedEngine = 12;
 * @return {?proto.planet_tycoon.LightSpeedEngine}
 */
proto.planet_tycoon.Equipment.prototype.getLightspeedengine = function() {
  return /** @type{?proto.planet_tycoon.LightSpeedEngine} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.LightSpeedEngine, 12));
};


/**
 * @param {?proto.planet_tycoon.LightSpeedEngine|undefined} value
 * @return {!proto.planet_tycoon.Equipment} returns this
*/
proto.planet_tycoon.Equipment.prototype.setLightspeedengine = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.planet_tycoon.Equipment.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Equipment} returns this
 */
proto.planet_tycoon.Equipment.prototype.clearLightspeedengine = function() {
  return this.setLightspeedengine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Equipment.prototype.hasLightspeedengine = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional float health = 13;
 * @return {number}
 */
proto.planet_tycoon.Equipment.prototype.getHealth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Equipment} returns this
 */
proto.planet_tycoon.Equipment.prototype.setHealth = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Equipment} returns this
 */
proto.planet_tycoon.Equipment.prototype.clearHealth = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Equipment.prototype.hasHealth = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Capacitor.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Capacitor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Capacitor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Capacitor.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxpower: (f = jspb.Message.getOptionalFloatingPointField(msg, 1)) == null ? undefined : f,
    chargerate: (f = jspb.Message.getOptionalFloatingPointField(msg, 2)) == null ? undefined : f,
    dischargerate: (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Capacitor}
 */
proto.planet_tycoon.Capacitor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Capacitor;
  return proto.planet_tycoon.Capacitor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Capacitor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Capacitor}
 */
proto.planet_tycoon.Capacitor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxpower(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setChargerate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDischargerate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Capacitor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Capacitor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Capacitor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Capacitor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional float maxPower = 1;
 * @return {number}
 */
proto.planet_tycoon.Capacitor.prototype.getMaxpower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Capacitor} returns this
 */
proto.planet_tycoon.Capacitor.prototype.setMaxpower = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Capacitor} returns this
 */
proto.planet_tycoon.Capacitor.prototype.clearMaxpower = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Capacitor.prototype.hasMaxpower = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float chargeRate = 2;
 * @return {number}
 */
proto.planet_tycoon.Capacitor.prototype.getChargerate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Capacitor} returns this
 */
proto.planet_tycoon.Capacitor.prototype.setChargerate = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Capacitor} returns this
 */
proto.planet_tycoon.Capacitor.prototype.clearChargerate = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Capacitor.prototype.hasChargerate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float dischargeRate = 3;
 * @return {number}
 */
proto.planet_tycoon.Capacitor.prototype.getDischargerate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Capacitor} returns this
 */
proto.planet_tycoon.Capacitor.prototype.setDischargerate = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Capacitor} returns this
 */
proto.planet_tycoon.Capacitor.prototype.clearDischargerate = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Capacitor.prototype.hasDischargerate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Tank.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Tank.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Tank} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Tank.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxcapacity: (f = jspb.Message.getOptionalFloatingPointField(msg, 1)) == null ? undefined : f,
    resource: (f = msg.getResource()) && proto.planet_tycoon.Resource.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Tank}
 */
proto.planet_tycoon.Tank.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Tank;
  return proto.planet_tycoon.Tank.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Tank} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Tank}
 */
proto.planet_tycoon.Tank.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxcapacity(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.Resource;
      reader.readMessage(value,proto.planet_tycoon.Resource.deserializeBinaryFromReader);
      msg.setResource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Tank.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Tank.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Tank} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Tank.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getResource();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.Resource.serializeBinaryToWriter
    );
  }
};


/**
 * optional float maxCapacity = 1;
 * @return {number}
 */
proto.planet_tycoon.Tank.prototype.getMaxcapacity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Tank} returns this
 */
proto.planet_tycoon.Tank.prototype.setMaxcapacity = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Tank} returns this
 */
proto.planet_tycoon.Tank.prototype.clearMaxcapacity = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Tank.prototype.hasMaxcapacity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Resource resource = 2;
 * @return {?proto.planet_tycoon.Resource}
 */
proto.planet_tycoon.Tank.prototype.getResource = function() {
  return /** @type{?proto.planet_tycoon.Resource} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Resource, 2));
};


/**
 * @param {?proto.planet_tycoon.Resource|undefined} value
 * @return {!proto.planet_tycoon.Tank} returns this
*/
proto.planet_tycoon.Tank.prototype.setResource = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Tank} returns this
 */
proto.planet_tycoon.Tank.prototype.clearResource = function() {
  return this.setResource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Tank.prototype.hasResource = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.SalvageRay.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.SalvageRay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.SalvageRay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.SalvageRay.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.SalvageRay}
 */
proto.planet_tycoon.SalvageRay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.SalvageRay;
  return proto.planet_tycoon.SalvageRay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.SalvageRay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.SalvageRay}
 */
proto.planet_tycoon.SalvageRay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.SalvageRay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.SalvageRay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.SalvageRay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.SalvageRay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.VaporizerRay.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.VaporizerRay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.VaporizerRay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.VaporizerRay.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.VaporizerRay}
 */
proto.planet_tycoon.VaporizerRay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.VaporizerRay;
  return proto.planet_tycoon.VaporizerRay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.VaporizerRay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.VaporizerRay}
 */
proto.planet_tycoon.VaporizerRay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.VaporizerRay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.VaporizerRay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.VaporizerRay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.VaporizerRay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.HeatRay.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.HeatRay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.HeatRay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.HeatRay.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.HeatRay}
 */
proto.planet_tycoon.HeatRay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.HeatRay;
  return proto.planet_tycoon.HeatRay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.HeatRay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.HeatRay}
 */
proto.planet_tycoon.HeatRay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.HeatRay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.HeatRay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.HeatRay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.HeatRay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.SpotlightSensor.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.SpotlightSensor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.SpotlightSensor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.SpotlightSensor.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.SpotlightSensor}
 */
proto.planet_tycoon.SpotlightSensor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.SpotlightSensor;
  return proto.planet_tycoon.SpotlightSensor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.SpotlightSensor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.SpotlightSensor}
 */
proto.planet_tycoon.SpotlightSensor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.SpotlightSensor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.SpotlightSensor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.SpotlightSensor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.SpotlightSensor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.ThermalSensor.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.ThermalSensor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.ThermalSensor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.ThermalSensor.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.ThermalSensor}
 */
proto.planet_tycoon.ThermalSensor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.ThermalSensor;
  return proto.planet_tycoon.ThermalSensor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.ThermalSensor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.ThermalSensor}
 */
proto.planet_tycoon.ThermalSensor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.ThermalSensor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.ThermalSensor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.ThermalSensor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.ThermalSensor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.LightSpeedEngine.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.LightSpeedEngine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.LightSpeedEngine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.LightSpeedEngine.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.LightSpeedEngine}
 */
proto.planet_tycoon.LightSpeedEngine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.LightSpeedEngine;
  return proto.planet_tycoon.LightSpeedEngine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.LightSpeedEngine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.LightSpeedEngine}
 */
proto.planet_tycoon.LightSpeedEngine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.LightSpeedEngine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.LightSpeedEngine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.LightSpeedEngine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.LightSpeedEngine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.VoxelCount.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.VoxelCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.VoxelCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.VoxelCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    count: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.VoxelCount}
 */
proto.planet_tycoon.VoxelCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.VoxelCount;
  return proto.planet_tycoon.VoxelCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.VoxelCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.VoxelCount}
 */
proto.planet_tycoon.VoxelCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.planet_tycoon.Voxel.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.VoxelCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.VoxelCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.VoxelCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.VoxelCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.planet_tycoon.Voxel.Type} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * required Voxel.Type type = 1;
 * @return {!proto.planet_tycoon.Voxel.Type}
 */
proto.planet_tycoon.VoxelCount.prototype.getType = function() {
  return /** @type {!proto.planet_tycoon.Voxel.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.planet_tycoon.Voxel.Type} value
 * @return {!proto.planet_tycoon.VoxelCount} returns this
 */
proto.planet_tycoon.VoxelCount.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.VoxelCount} returns this
 */
proto.planet_tycoon.VoxelCount.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.VoxelCount.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.planet_tycoon.VoxelCount.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.VoxelCount} returns this
 */
proto.planet_tycoon.VoxelCount.prototype.setCount = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.VoxelCount} returns this
 */
proto.planet_tycoon.VoxelCount.prototype.clearCount = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.VoxelCount.prototype.hasCount = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.planet_tycoon.Ship.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Ship.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Ship.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Ship} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Ship.toObject = function(includeInstance, msg) {
  var f, obj = {
    money: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    equipmentList: jspb.Message.toObjectList(msg.getEquipmentList(),
    proto.planet_tycoon.Equipment.toObject, includeInstance),
    equipmentmapMap: (f = msg.getEquipmentmapMap()) ? f.toObject(includeInstance, proto.planet_tycoon.Equipment.toObject) : [],
    currentlocalarea: (f = msg.getCurrentlocalarea()) && proto.planet_tycoon.LocalArea.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Ship}
 */
proto.planet_tycoon.Ship.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Ship;
  return proto.planet_tycoon.Ship.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Ship} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Ship}
 */
proto.planet_tycoon.Ship.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMoney(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.Equipment;
      reader.readMessage(value,proto.planet_tycoon.Equipment.deserializeBinaryFromReader);
      msg.addEquipment(value);
      break;
    case 3:
      var value = msg.getEquipmentmapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.planet_tycoon.Equipment.deserializeBinaryFromReader, "", new proto.planet_tycoon.Equipment());
         });
      break;
    case 6:
      var value = new proto.planet_tycoon.LocalArea;
      reader.readMessage(value,proto.planet_tycoon.LocalArea.deserializeBinaryFromReader);
      msg.setCurrentlocalarea(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Ship.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Ship.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Ship} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Ship.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEquipmentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.planet_tycoon.Equipment.serializeBinaryToWriter
    );
  }
  f = message.getEquipmentmapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.planet_tycoon.Equipment.serializeBinaryToWriter);
  }
  f = message.getCurrentlocalarea();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.planet_tycoon.LocalArea.serializeBinaryToWriter
    );
  }
};


/**
 * required int32 money = 1;
 * @return {number}
 */
proto.planet_tycoon.Ship.prototype.getMoney = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.Ship} returns this
 */
proto.planet_tycoon.Ship.prototype.setMoney = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Ship} returns this
 */
proto.planet_tycoon.Ship.prototype.clearMoney = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Ship.prototype.hasMoney = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Equipment equipment = 2;
 * @return {!Array<!proto.planet_tycoon.Equipment>}
 */
proto.planet_tycoon.Ship.prototype.getEquipmentList = function() {
  return /** @type{!Array<!proto.planet_tycoon.Equipment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.planet_tycoon.Equipment, 2));
};


/**
 * @param {!Array<!proto.planet_tycoon.Equipment>} value
 * @return {!proto.planet_tycoon.Ship} returns this
*/
proto.planet_tycoon.Ship.prototype.setEquipmentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.planet_tycoon.Equipment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.planet_tycoon.Equipment}
 */
proto.planet_tycoon.Ship.prototype.addEquipment = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.planet_tycoon.Equipment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.planet_tycoon.Ship} returns this
 */
proto.planet_tycoon.Ship.prototype.clearEquipmentList = function() {
  return this.setEquipmentList([]);
};


/**
 * map<string, Equipment> equipmentMap = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.planet_tycoon.Equipment>}
 */
proto.planet_tycoon.Ship.prototype.getEquipmentmapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.planet_tycoon.Equipment>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.planet_tycoon.Equipment));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.planet_tycoon.Ship} returns this
 */
proto.planet_tycoon.Ship.prototype.clearEquipmentmapMap = function() {
  this.getEquipmentmapMap().clear();
  return this;};


/**
 * optional LocalArea currentLocalArea = 6;
 * @return {?proto.planet_tycoon.LocalArea}
 */
proto.planet_tycoon.Ship.prototype.getCurrentlocalarea = function() {
  return /** @type{?proto.planet_tycoon.LocalArea} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.LocalArea, 6));
};


/**
 * @param {?proto.planet_tycoon.LocalArea|undefined} value
 * @return {!proto.planet_tycoon.Ship} returns this
*/
proto.planet_tycoon.Ship.prototype.setCurrentlocalarea = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Ship} returns this
 */
proto.planet_tycoon.Ship.prototype.clearCurrentlocalarea = function() {
  return this.setCurrentlocalarea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Ship.prototype.hasCurrentlocalarea = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.NoiseConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.NoiseConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.NoiseConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.NoiseConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    octave: (f = jspb.Message.getOptionalFloatingPointField(msg, 1)) == null ? undefined : f,
    persistence: (f = jspb.Message.getOptionalFloatingPointField(msg, 2)) == null ? undefined : f,
    scale: (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null ? undefined : f,
    decay: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.NoiseConfig}
 */
proto.planet_tycoon.NoiseConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.NoiseConfig;
  return proto.planet_tycoon.NoiseConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.NoiseConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.NoiseConfig}
 */
proto.planet_tycoon.NoiseConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOctave(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPersistence(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScale(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDecay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.NoiseConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.NoiseConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.NoiseConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.NoiseConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional double octave = 1;
 * @return {number}
 */
proto.planet_tycoon.NoiseConfig.prototype.getOctave = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.NoiseConfig} returns this
 */
proto.planet_tycoon.NoiseConfig.prototype.setOctave = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.NoiseConfig} returns this
 */
proto.planet_tycoon.NoiseConfig.prototype.clearOctave = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.NoiseConfig.prototype.hasOctave = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double persistence = 2;
 * @return {number}
 */
proto.planet_tycoon.NoiseConfig.prototype.getPersistence = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.NoiseConfig} returns this
 */
proto.planet_tycoon.NoiseConfig.prototype.setPersistence = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.NoiseConfig} returns this
 */
proto.planet_tycoon.NoiseConfig.prototype.clearPersistence = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.NoiseConfig.prototype.hasPersistence = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double scale = 3;
 * @return {number}
 */
proto.planet_tycoon.NoiseConfig.prototype.getScale = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.NoiseConfig} returns this
 */
proto.planet_tycoon.NoiseConfig.prototype.setScale = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.NoiseConfig} returns this
 */
proto.planet_tycoon.NoiseConfig.prototype.clearScale = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.NoiseConfig.prototype.hasScale = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double decay = 4;
 * @return {number}
 */
proto.planet_tycoon.NoiseConfig.prototype.getDecay = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.NoiseConfig} returns this
 */
proto.planet_tycoon.NoiseConfig.prototype.setDecay = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.NoiseConfig} returns this
 */
proto.planet_tycoon.NoiseConfig.prototype.clearDecay = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.NoiseConfig.prototype.hasDecay = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.SkyboxConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.SkyboxConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.SkyboxConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.SkyboxConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    red: (f = msg.getRed()) && proto.planet_tycoon.NoiseConfig.toObject(includeInstance, f),
    green: (f = msg.getGreen()) && proto.planet_tycoon.NoiseConfig.toObject(includeInstance, f),
    blue: (f = msg.getBlue()) && proto.planet_tycoon.NoiseConfig.toObject(includeInstance, f),
    hue: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f,
    theta: (f = jspb.Message.getOptionalFloatingPointField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.SkyboxConfig}
 */
proto.planet_tycoon.SkyboxConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.SkyboxConfig;
  return proto.planet_tycoon.SkyboxConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.SkyboxConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.SkyboxConfig}
 */
proto.planet_tycoon.SkyboxConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.planet_tycoon.NoiseConfig;
      reader.readMessage(value,proto.planet_tycoon.NoiseConfig.deserializeBinaryFromReader);
      msg.setRed(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.NoiseConfig;
      reader.readMessage(value,proto.planet_tycoon.NoiseConfig.deserializeBinaryFromReader);
      msg.setGreen(value);
      break;
    case 3:
      var value = new proto.planet_tycoon.NoiseConfig;
      reader.readMessage(value,proto.planet_tycoon.NoiseConfig.deserializeBinaryFromReader);
      msg.setBlue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTheta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.SkyboxConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.SkyboxConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.SkyboxConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.SkyboxConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRed();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.planet_tycoon.NoiseConfig.serializeBinaryToWriter
    );
  }
  f = message.getGreen();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.NoiseConfig.serializeBinaryToWriter
    );
  }
  f = message.getBlue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.planet_tycoon.NoiseConfig.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional NoiseConfig red = 1;
 * @return {?proto.planet_tycoon.NoiseConfig}
 */
proto.planet_tycoon.SkyboxConfig.prototype.getRed = function() {
  return /** @type{?proto.planet_tycoon.NoiseConfig} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.NoiseConfig, 1));
};


/**
 * @param {?proto.planet_tycoon.NoiseConfig|undefined} value
 * @return {!proto.planet_tycoon.SkyboxConfig} returns this
*/
proto.planet_tycoon.SkyboxConfig.prototype.setRed = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.SkyboxConfig} returns this
 */
proto.planet_tycoon.SkyboxConfig.prototype.clearRed = function() {
  return this.setRed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.SkyboxConfig.prototype.hasRed = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NoiseConfig green = 2;
 * @return {?proto.planet_tycoon.NoiseConfig}
 */
proto.planet_tycoon.SkyboxConfig.prototype.getGreen = function() {
  return /** @type{?proto.planet_tycoon.NoiseConfig} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.NoiseConfig, 2));
};


/**
 * @param {?proto.planet_tycoon.NoiseConfig|undefined} value
 * @return {!proto.planet_tycoon.SkyboxConfig} returns this
*/
proto.planet_tycoon.SkyboxConfig.prototype.setGreen = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.SkyboxConfig} returns this
 */
proto.planet_tycoon.SkyboxConfig.prototype.clearGreen = function() {
  return this.setGreen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.SkyboxConfig.prototype.hasGreen = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NoiseConfig blue = 3;
 * @return {?proto.planet_tycoon.NoiseConfig}
 */
proto.planet_tycoon.SkyboxConfig.prototype.getBlue = function() {
  return /** @type{?proto.planet_tycoon.NoiseConfig} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.NoiseConfig, 3));
};


/**
 * @param {?proto.planet_tycoon.NoiseConfig|undefined} value
 * @return {!proto.planet_tycoon.SkyboxConfig} returns this
*/
proto.planet_tycoon.SkyboxConfig.prototype.setBlue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.SkyboxConfig} returns this
 */
proto.planet_tycoon.SkyboxConfig.prototype.clearBlue = function() {
  return this.setBlue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.SkyboxConfig.prototype.hasBlue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float hue = 4;
 * @return {number}
 */
proto.planet_tycoon.SkyboxConfig.prototype.getHue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.SkyboxConfig} returns this
 */
proto.planet_tycoon.SkyboxConfig.prototype.setHue = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.SkyboxConfig} returns this
 */
proto.planet_tycoon.SkyboxConfig.prototype.clearHue = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.SkyboxConfig.prototype.hasHue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional float theta = 5;
 * @return {number}
 */
proto.planet_tycoon.SkyboxConfig.prototype.getTheta = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.planet_tycoon.SkyboxConfig} returns this
 */
proto.planet_tycoon.SkyboxConfig.prototype.setTheta = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.SkyboxConfig} returns this
 */
proto.planet_tycoon.SkyboxConfig.prototype.clearTheta = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.SkyboxConfig.prototype.hasTheta = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.planet_tycoon.SolarSystem.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.SolarSystem.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.SolarSystem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.SolarSystem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.SolarSystem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    parent: (f = msg.getParent()) && proto.planet_tycoon.Galaxy.toObject(includeInstance, f),
    name: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    position: (f = msg.getPosition()) && proto.planet_tycoon.Vector2i.toObject(includeInstance, f),
    skyboxconfig: (f = msg.getSkyboxconfig()) && proto.planet_tycoon.SkyboxConfig.toObject(includeInstance, f),
    localareasList: jspb.Message.toObjectList(msg.getLocalareasList(),
    proto.planet_tycoon.LocalArea.toObject, includeInstance),
    description: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    visited: (f = jspb.Message.getBooleanField(msg, 8)) == null ? undefined : f,
    suncolor: (f = msg.getSuncolor()) && proto.planet_tycoon.Color.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.SolarSystem}
 */
proto.planet_tycoon.SolarSystem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.SolarSystem;
  return proto.planet_tycoon.SolarSystem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.SolarSystem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.SolarSystem}
 */
proto.planet_tycoon.SolarSystem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.Galaxy;
      reader.readMessage(value,proto.planet_tycoon.Galaxy.deserializeBinaryFromReader);
      msg.setParent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.planet_tycoon.Vector2i;
      reader.readMessage(value,proto.planet_tycoon.Vector2i.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 5:
      var value = new proto.planet_tycoon.SkyboxConfig;
      reader.readMessage(value,proto.planet_tycoon.SkyboxConfig.deserializeBinaryFromReader);
      msg.setSkyboxconfig(value);
      break;
    case 6:
      var value = new proto.planet_tycoon.LocalArea;
      reader.readMessage(value,proto.planet_tycoon.LocalArea.deserializeBinaryFromReader);
      msg.addLocalareas(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisited(value);
      break;
    case 9:
      var value = new proto.planet_tycoon.Color;
      reader.readMessage(value,proto.planet_tycoon.Color.deserializeBinaryFromReader);
      msg.setSuncolor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.SolarSystem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.SolarSystem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.SolarSystem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.SolarSystem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.Galaxy.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.planet_tycoon.Vector2i.serializeBinaryToWriter
    );
  }
  f = message.getSkyboxconfig();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.planet_tycoon.SkyboxConfig.serializeBinaryToWriter
    );
  }
  f = message.getLocalareasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.planet_tycoon.LocalArea.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSuncolor();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.planet_tycoon.Color.serializeBinaryToWriter
    );
  }
};


/**
 * required string id = 1;
 * @return {string}
 */
proto.planet_tycoon.SolarSystem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.SolarSystem} returns this
 */
proto.planet_tycoon.SolarSystem.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.SolarSystem} returns this
 */
proto.planet_tycoon.SolarSystem.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.SolarSystem.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Galaxy parent = 2;
 * @return {?proto.planet_tycoon.Galaxy}
 */
proto.planet_tycoon.SolarSystem.prototype.getParent = function() {
  return /** @type{?proto.planet_tycoon.Galaxy} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Galaxy, 2));
};


/**
 * @param {?proto.planet_tycoon.Galaxy|undefined} value
 * @return {!proto.planet_tycoon.SolarSystem} returns this
*/
proto.planet_tycoon.SolarSystem.prototype.setParent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.SolarSystem} returns this
 */
proto.planet_tycoon.SolarSystem.prototype.clearParent = function() {
  return this.setParent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.SolarSystem.prototype.hasParent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.planet_tycoon.SolarSystem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.SolarSystem} returns this
 */
proto.planet_tycoon.SolarSystem.prototype.setName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.SolarSystem} returns this
 */
proto.planet_tycoon.SolarSystem.prototype.clearName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.SolarSystem.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Vector2i position = 4;
 * @return {?proto.planet_tycoon.Vector2i}
 */
proto.planet_tycoon.SolarSystem.prototype.getPosition = function() {
  return /** @type{?proto.planet_tycoon.Vector2i} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Vector2i, 4));
};


/**
 * @param {?proto.planet_tycoon.Vector2i|undefined} value
 * @return {!proto.planet_tycoon.SolarSystem} returns this
*/
proto.planet_tycoon.SolarSystem.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.SolarSystem} returns this
 */
proto.planet_tycoon.SolarSystem.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.SolarSystem.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SkyboxConfig skyboxConfig = 5;
 * @return {?proto.planet_tycoon.SkyboxConfig}
 */
proto.planet_tycoon.SolarSystem.prototype.getSkyboxconfig = function() {
  return /** @type{?proto.planet_tycoon.SkyboxConfig} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.SkyboxConfig, 5));
};


/**
 * @param {?proto.planet_tycoon.SkyboxConfig|undefined} value
 * @return {!proto.planet_tycoon.SolarSystem} returns this
*/
proto.planet_tycoon.SolarSystem.prototype.setSkyboxconfig = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.SolarSystem} returns this
 */
proto.planet_tycoon.SolarSystem.prototype.clearSkyboxconfig = function() {
  return this.setSkyboxconfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.SolarSystem.prototype.hasSkyboxconfig = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated LocalArea localAreas = 6;
 * @return {!Array<!proto.planet_tycoon.LocalArea>}
 */
proto.planet_tycoon.SolarSystem.prototype.getLocalareasList = function() {
  return /** @type{!Array<!proto.planet_tycoon.LocalArea>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.planet_tycoon.LocalArea, 6));
};


/**
 * @param {!Array<!proto.planet_tycoon.LocalArea>} value
 * @return {!proto.planet_tycoon.SolarSystem} returns this
*/
proto.planet_tycoon.SolarSystem.prototype.setLocalareasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.planet_tycoon.LocalArea=} opt_value
 * @param {number=} opt_index
 * @return {!proto.planet_tycoon.LocalArea}
 */
proto.planet_tycoon.SolarSystem.prototype.addLocalareas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.planet_tycoon.LocalArea, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.planet_tycoon.SolarSystem} returns this
 */
proto.planet_tycoon.SolarSystem.prototype.clearLocalareasList = function() {
  return this.setLocalareasList([]);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.planet_tycoon.SolarSystem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.SolarSystem} returns this
 */
proto.planet_tycoon.SolarSystem.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.SolarSystem} returns this
 */
proto.planet_tycoon.SolarSystem.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.SolarSystem.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool visited = 8;
 * @return {boolean}
 */
proto.planet_tycoon.SolarSystem.prototype.getVisited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.planet_tycoon.SolarSystem} returns this
 */
proto.planet_tycoon.SolarSystem.prototype.setVisited = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.SolarSystem} returns this
 */
proto.planet_tycoon.SolarSystem.prototype.clearVisited = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.SolarSystem.prototype.hasVisited = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Color sunColor = 9;
 * @return {?proto.planet_tycoon.Color}
 */
proto.planet_tycoon.SolarSystem.prototype.getSuncolor = function() {
  return /** @type{?proto.planet_tycoon.Color} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Color, 9));
};


/**
 * @param {?proto.planet_tycoon.Color|undefined} value
 * @return {!proto.planet_tycoon.SolarSystem} returns this
*/
proto.planet_tycoon.SolarSystem.prototype.setSuncolor = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.SolarSystem} returns this
 */
proto.planet_tycoon.SolarSystem.prototype.clearSuncolor = function() {
  return this.setSuncolor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.SolarSystem.prototype.hasSuncolor = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.planet_tycoon.Galaxy.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Galaxy.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Galaxy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Galaxy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Galaxy.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    position: (f = msg.getPosition()) && proto.planet_tycoon.Vector2i.toObject(includeInstance, f),
    solarsystemsList: jspb.Message.toObjectList(msg.getSolarsystemsList(),
    proto.planet_tycoon.SolarSystem.toObject, includeInstance),
    name: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    description: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    visited: (f = jspb.Message.getBooleanField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Galaxy}
 */
proto.planet_tycoon.Galaxy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Galaxy;
  return proto.planet_tycoon.Galaxy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Galaxy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Galaxy}
 */
proto.planet_tycoon.Galaxy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.Vector2i;
      reader.readMessage(value,proto.planet_tycoon.Vector2i.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 3:
      var value = new proto.planet_tycoon.SolarSystem;
      reader.readMessage(value,proto.planet_tycoon.SolarSystem.deserializeBinaryFromReader);
      msg.addSolarsystems(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisited(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Galaxy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Galaxy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Galaxy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Galaxy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.Vector2i.serializeBinaryToWriter
    );
  }
  f = message.getSolarsystemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.planet_tycoon.SolarSystem.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * required string id = 1;
 * @return {string}
 */
proto.planet_tycoon.Galaxy.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Galaxy} returns this
 */
proto.planet_tycoon.Galaxy.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Galaxy} returns this
 */
proto.planet_tycoon.Galaxy.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Galaxy.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Vector2i position = 2;
 * @return {?proto.planet_tycoon.Vector2i}
 */
proto.planet_tycoon.Galaxy.prototype.getPosition = function() {
  return /** @type{?proto.planet_tycoon.Vector2i} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Vector2i, 2));
};


/**
 * @param {?proto.planet_tycoon.Vector2i|undefined} value
 * @return {!proto.planet_tycoon.Galaxy} returns this
*/
proto.planet_tycoon.Galaxy.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.planet_tycoon.Galaxy} returns this
 */
proto.planet_tycoon.Galaxy.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Galaxy.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SolarSystem solarSystems = 3;
 * @return {!Array<!proto.planet_tycoon.SolarSystem>}
 */
proto.planet_tycoon.Galaxy.prototype.getSolarsystemsList = function() {
  return /** @type{!Array<!proto.planet_tycoon.SolarSystem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.planet_tycoon.SolarSystem, 3));
};


/**
 * @param {!Array<!proto.planet_tycoon.SolarSystem>} value
 * @return {!proto.planet_tycoon.Galaxy} returns this
*/
proto.planet_tycoon.Galaxy.prototype.setSolarsystemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.planet_tycoon.SolarSystem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.planet_tycoon.SolarSystem}
 */
proto.planet_tycoon.Galaxy.prototype.addSolarsystems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.planet_tycoon.SolarSystem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.planet_tycoon.Galaxy} returns this
 */
proto.planet_tycoon.Galaxy.prototype.clearSolarsystemsList = function() {
  return this.setSolarsystemsList([]);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.planet_tycoon.Galaxy.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Galaxy} returns this
 */
proto.planet_tycoon.Galaxy.prototype.setName = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Galaxy} returns this
 */
proto.planet_tycoon.Galaxy.prototype.clearName = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Galaxy.prototype.hasName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.planet_tycoon.Galaxy.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Galaxy} returns this
 */
proto.planet_tycoon.Galaxy.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Galaxy} returns this
 */
proto.planet_tycoon.Galaxy.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Galaxy.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool visited = 6;
 * @return {boolean}
 */
proto.planet_tycoon.Galaxy.prototype.getVisited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.planet_tycoon.Galaxy} returns this
 */
proto.planet_tycoon.Galaxy.prototype.setVisited = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Galaxy} returns this
 */
proto.planet_tycoon.Galaxy.prototype.clearVisited = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Galaxy.prototype.hasVisited = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.planet_tycoon.Universe.prototype.toObject = function(opt_includeInstance) {
  return proto.planet_tycoon.Universe.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.planet_tycoon.Universe} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Universe.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    ship: (f = msg.getShip()) && proto.planet_tycoon.Ship.toObject(includeInstance, f),
    galaxymapMap: (f = msg.getGalaxymapMap()) ? f.toObject(includeInstance, proto.planet_tycoon.Galaxy.toObject) : [],
    solarsystemmapMap: (f = msg.getSolarsystemmapMap()) ? f.toObject(includeInstance, proto.planet_tycoon.SolarSystem.toObject) : [],
    localareamapMap: (f = msg.getLocalareamapMap()) ? f.toObject(includeInstance, proto.planet_tycoon.LocalArea.toObject) : [],
    entitymapMap: (f = msg.getEntitymapMap()) ? f.toObject(includeInstance, proto.planet_tycoon.Entity.toObject) : [],
    taskmapMap: (f = msg.getTaskmapMap()) ? f.toObject(includeInstance, proto.planet_tycoon.Task.toObject) : [],
    speechmapMap: (f = msg.getSpeechmapMap()) ? f.toObject(includeInstance, proto.planet_tycoon.Speech.toObject) : [],
    artifactmapMap: (f = msg.getArtifactmapMap()) ? f.toObject(includeInstance, proto.planet_tycoon.Artifact.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.planet_tycoon.Universe}
 */
proto.planet_tycoon.Universe.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.planet_tycoon.Universe;
  return proto.planet_tycoon.Universe.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.planet_tycoon.Universe} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.planet_tycoon.Universe}
 */
proto.planet_tycoon.Universe.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.planet_tycoon.Ship;
      reader.readMessage(value,proto.planet_tycoon.Ship.deserializeBinaryFromReader);
      msg.setShip(value);
      break;
    case 3:
      var value = msg.getGalaxymapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.planet_tycoon.Galaxy.deserializeBinaryFromReader, "", new proto.planet_tycoon.Galaxy());
         });
      break;
    case 4:
      var value = msg.getSolarsystemmapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.planet_tycoon.SolarSystem.deserializeBinaryFromReader, "", new proto.planet_tycoon.SolarSystem());
         });
      break;
    case 5:
      var value = msg.getLocalareamapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.planet_tycoon.LocalArea.deserializeBinaryFromReader, "", new proto.planet_tycoon.LocalArea());
         });
      break;
    case 6:
      var value = msg.getEntitymapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.planet_tycoon.Entity.deserializeBinaryFromReader, "", new proto.planet_tycoon.Entity());
         });
      break;
    case 7:
      var value = msg.getTaskmapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.planet_tycoon.Task.deserializeBinaryFromReader, "", new proto.planet_tycoon.Task());
         });
      break;
    case 8:
      var value = msg.getSpeechmapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.planet_tycoon.Speech.deserializeBinaryFromReader, "", new proto.planet_tycoon.Speech());
         });
      break;
    case 9:
      var value = msg.getArtifactmapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.planet_tycoon.Artifact.deserializeBinaryFromReader, "", new proto.planet_tycoon.Artifact());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.planet_tycoon.Universe.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.planet_tycoon.Universe.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.planet_tycoon.Universe} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.planet_tycoon.Universe.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShip();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.planet_tycoon.Ship.serializeBinaryToWriter
    );
  }
  f = message.getGalaxymapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.planet_tycoon.Galaxy.serializeBinaryToWriter);
  }
  f = message.getSolarsystemmapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.planet_tycoon.SolarSystem.serializeBinaryToWriter);
  }
  f = message.getLocalareamapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.planet_tycoon.LocalArea.serializeBinaryToWriter);
  }
  f = message.getEntitymapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.planet_tycoon.Entity.serializeBinaryToWriter);
  }
  f = message.getTaskmapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.planet_tycoon.Task.serializeBinaryToWriter);
  }
  f = message.getSpeechmapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.planet_tycoon.Speech.serializeBinaryToWriter);
  }
  f = message.getArtifactmapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.planet_tycoon.Artifact.serializeBinaryToWriter);
  }
};


/**
 * required string id = 1;
 * @return {string}
 */
proto.planet_tycoon.Universe.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.planet_tycoon.Universe} returns this
 */
proto.planet_tycoon.Universe.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Universe} returns this
 */
proto.planet_tycoon.Universe.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Universe.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required Ship ship = 2;
 * @return {!proto.planet_tycoon.Ship}
 */
proto.planet_tycoon.Universe.prototype.getShip = function() {
  return /** @type{!proto.planet_tycoon.Ship} */ (
    jspb.Message.getWrapperField(this, proto.planet_tycoon.Ship, 2, 1));
};


/**
 * @param {!proto.planet_tycoon.Ship} value
 * @return {!proto.planet_tycoon.Universe} returns this
*/
proto.planet_tycoon.Universe.prototype.setShip = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.planet_tycoon.Universe} returns this
 */
proto.planet_tycoon.Universe.prototype.clearShip = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.planet_tycoon.Universe.prototype.hasShip = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * map<string, Galaxy> galaxyMap = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.planet_tycoon.Galaxy>}
 */
proto.planet_tycoon.Universe.prototype.getGalaxymapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.planet_tycoon.Galaxy>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.planet_tycoon.Galaxy));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.planet_tycoon.Universe} returns this
 */
proto.planet_tycoon.Universe.prototype.clearGalaxymapMap = function() {
  this.getGalaxymapMap().clear();
  return this;};


/**
 * map<string, SolarSystem> solarSystemMap = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.planet_tycoon.SolarSystem>}
 */
proto.planet_tycoon.Universe.prototype.getSolarsystemmapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.planet_tycoon.SolarSystem>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.planet_tycoon.SolarSystem));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.planet_tycoon.Universe} returns this
 */
proto.planet_tycoon.Universe.prototype.clearSolarsystemmapMap = function() {
  this.getSolarsystemmapMap().clear();
  return this;};


/**
 * map<string, LocalArea> localAreaMap = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.planet_tycoon.LocalArea>}
 */
proto.planet_tycoon.Universe.prototype.getLocalareamapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.planet_tycoon.LocalArea>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.planet_tycoon.LocalArea));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.planet_tycoon.Universe} returns this
 */
proto.planet_tycoon.Universe.prototype.clearLocalareamapMap = function() {
  this.getLocalareamapMap().clear();
  return this;};


/**
 * map<string, Entity> entityMap = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.planet_tycoon.Entity>}
 */
proto.planet_tycoon.Universe.prototype.getEntitymapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.planet_tycoon.Entity>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      proto.planet_tycoon.Entity));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.planet_tycoon.Universe} returns this
 */
proto.planet_tycoon.Universe.prototype.clearEntitymapMap = function() {
  this.getEntitymapMap().clear();
  return this;};


/**
 * map<string, Task> taskMap = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.planet_tycoon.Task>}
 */
proto.planet_tycoon.Universe.prototype.getTaskmapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.planet_tycoon.Task>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      proto.planet_tycoon.Task));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.planet_tycoon.Universe} returns this
 */
proto.planet_tycoon.Universe.prototype.clearTaskmapMap = function() {
  this.getTaskmapMap().clear();
  return this;};


/**
 * map<string, Speech> speechMap = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.planet_tycoon.Speech>}
 */
proto.planet_tycoon.Universe.prototype.getSpeechmapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.planet_tycoon.Speech>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      proto.planet_tycoon.Speech));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.planet_tycoon.Universe} returns this
 */
proto.planet_tycoon.Universe.prototype.clearSpeechmapMap = function() {
  this.getSpeechmapMap().clear();
  return this;};


/**
 * map<string, Artifact> artifactMap = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.planet_tycoon.Artifact>}
 */
proto.planet_tycoon.Universe.prototype.getArtifactmapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.planet_tycoon.Artifact>} */ (
      jspb.Message.getMapField(this, 9, opt_noLazyCreate,
      proto.planet_tycoon.Artifact));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.planet_tycoon.Universe} returns this
 */
proto.planet_tycoon.Universe.prototype.clearArtifactmapMap = function() {
  this.getArtifactmapMap().clear();
  return this;};


goog.object.extend(exports, proto.planet_tycoon);
