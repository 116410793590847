import './HeadShot.css'
import {useState, useEffect, createRef} from 'react';
import {Button, Paper} from '@mui/material'
import NoSignal from './NoSignal.js'

export default function HeadShot(props){

  const metadata = props.metadata;
  const name = props.showName && (props.name || metadata.name);
  const animated = props.animated === undefined ? true : props.animated;
  const layerRef = createRef();

  /*
    {
      "description": "Salvage professionals are pilots who scour the Galaxy for VOX crystals, artifacts, and good times. In the quiet emptiness of space, it's hard to tell friend from enemy, so you gotta keep hustling.",
      "external_url": "https://galaxyhustle.xyz/pilots/eyeguy/2",
      "image": "https://nft.galaxyhustle.xyz/eyeguy/images/2_800x800.png",
      "attributes": [
        {
          "trait_type": "Species",
          "value": "Eyeguy"
        },
        {
          "trait_type": "Optical Sensors",
          "value": "Brown Eyed"
        },
        {
          "trait_type": "Locomotion",
          "value": "Never Skip Leg Day"
        },
        {
          "trait_type": "Manipulators",
          "value": "Poser"
        },
        {
          "trait_type": "Bioreactor",
          "value": "Pickle"
        },
        {
          "trait_type": "Processing Unit",
          "value": "Chelsea"
        },
        {
          "trait_type": "Communications",
          "value": "My What Big Teeth You Have"
        }
      ]
    }
    */




  const speciesTrait = metadata.attributes.find(x => x.trait_type == "Species");
  const hue = metadata.attributes.find(x => x.trait_type == "Aural Hue")["value"];

  const colorString = hue === 'noise' ? '#000' : `hsl(${Math.floor(hue)},85%,5%)`;
  const [hovering, setHovering] = useState(false);

  const onEnter = (event)=>{
    if(layerRef && layerRef.current){

      var x = 2.0*(((event.clientX-layerRef.current.getBoundingClientRect().left)/layerRef.current.getBoundingClientRect().width) - 0.5)

      var translation = `rotate(${x*1.0}deg)`
      layerRef.current.style["transform"] = translation
    }

    setHovering(animated === "hovering");
  }

  const onLeave = (event) =>{
    if(layerRef && layerRef.current){
        //layerRef.current.style["transform"] = ""
      }

    setHovering(false);
  }
  return (
    <div className={"headShot " + props.className || ""} style={{backgroundColor: colorString}} onMouseMove={onEnter} onMouseLeave={onLeave}>
      <div className="layers">
        {
          hue === 'noise' ?
          <NoSignal/> :
          metadata.attributes
                  .filter(x => {return x.trait_type != "Species" && x.trait_type != "Aural Hue"})
                  .map(x => {

                    return (
                      <div className="imgcontainer" key={x.trait_type} ref={x.trait_type=="Optical Sensors" ? layerRef : null}>
                        <img key={x.trait_type} className={
                          (animated === true || hovering ? 'animated ' : '') +
                          x.trait_type.replace(" ", "_")} src={`/aliens/${speciesTrait.value}/${x.trait_type}/${x.value}.png`
                        }/>
                      </div>
                    );
                  })

        }
      </div>

      {name && <h2 className="speakerName">{name}</h2>}

    </div>
  )
}
