import {ShipMiniStyled} from './ShipMiniStyled'
import {useState, useEffect, useContext} from 'react';
import {Button, Paper, Link, Tooltip} from '@mui/material'
import {useApi} from './apis/api'
import Pilot from './Pilot'
import Contract from 'web3-eth-contract'
import ERC721 from'./contracts/ERC721.json';
import { drizzleReactHooks } from '@drizzle/react-plugin';
import networkMap from './constants/networkMap'
import {metadataMap} from './constants/metadataMap'
import axios from 'axios'
import Web3 from 'web3'
import {ipfsToGateway} from './libs/uriTools'
import * as proto from './protos/universe_pb'
import Operator from './Operator'
import Panel from './Panel'
import UpgradeIcon from '@mui/icons-material/Upgrade';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import {useCallback} from 'react';
import {UniverseContext} from './context/UniverseContext.js'

export default function ShipMini(props){
  const {universe, planet} = useContext(UniverseContext);
  const shipToken = props.id;
  const api = useApi();
  const { drizzle, useCacheCall } = drizzleReactHooks.useDrizzle();
  const [metadata, setMetadata] = useState();
  const shipLocation = useCacheCall("Fleet", "getShipLocation", shipToken);
  const level = useCacheCall("Fleet", "getLevel", shipToken);
  const operator = useCacheCall("Fleet", "getOperator", shipToken);
  const parentOnClick = props.onClick || (() => {});
  const isSalvage = props.isSalvage;

  useEffect(() => {

    let mounted = true;
    const run = async () => {

      if(shipToken){
        const meta = await api.ships.get(shipToken);
        if(!mounted){
          return;
        }
        console.log("SETTING METADATA")
        setMetadata(meta);
      }
    }

    run();

    return () => {
      mounted = false;
    }
  }, [shipToken]);

  const onClick = (event) => {
    parentOnClick(event, {token: shipToken, metadata, location: shipLocation, operator, level})
  }


  const SkeletonRow = ()=>{
    return (<ShipMiniStyled >

      <div className="ship-operator">
        <div className="ship-level">-</div>
        <div className="ship-avatar"/>
      </div>
    </ShipMiniStyled>);

  }


  if(!metadata){
     return <SkeletonRow/>;
  }

  const Alignment = (props) => {
    const {value} = props;
    const getDisplayName = (alignment) => {
      switch(alignment){
        case "1": return "Friendly";
        case "2": return "Neutral";
        case "3": return "Hostile";
        default: return "UNINITIALIZED";
      }
    };

    return (<div>{getDisplayName(value)}</div>);
  };


  if(!shipLocation){
    return <SkeletonRow/>
  }


  return (
    <ShipMiniStyled className={"alignment-" + shipLocation.alignment}onClick={onClick} >

      <div className="ship-operator">
        <Tooltip title="Ship level"><div className="ship-level">{level}</div></Tooltip>
        <img className="ship-avatar" src={metadata.image}/>
        {operator ?(
         <Operator className="operator-avatar" operator={operator} animated="hovering" showName={false}/>
        ) : null}

      </div>
      {isSalvage && <h3>Salvage</h3>}

    </ShipMiniStyled>
  )
};
