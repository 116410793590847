import {
  Button,
  Dialog,
  DialogContent,
} from '@mui/material';

import {TravelDialogStyled} from './TravelDialogStyled';
import {useApi} from './apis/api';
import { drizzleReactHooks } from '@drizzle/react-plugin';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {useEffect, useState} from 'react';
import {formatBalance} from './libs/utils';
import {ReactComponent as VoxIcon} from './assets/vox_crystal_icon.svg';
import {useNavigate} from 'react-router-dom';


export default function TravelDialog(props) {

  const {
    ship,
    destination,
    onClose,
  } = props;

  const api = useApi();
  const { drizzle, useCacheCall, useCacheSend } = drizzleReactHooks.useDrizzle();
  const isMinted = useCacheCall("Galaxy", "isLocalAreaMinted", destination.tokenId);
  const salvageTaxBP = parseInt(useCacheCall("Galaxy", "getLocalSalvageTax", destination.tokenId));
  const { send: transportShip, TXObjects: transportShipTx, status: transportShipStatus} = useCacheSend('Fleet', 'transportShip');

  const [travelData, setTravelData] = useState();
  const navigate = useNavigate();

  const isOpen = Boolean(ship && destination);

  useEffect(()=>{
    console.log("IS OPEN", isOpen)
    console.log("SHIP", ship)
    console.log("DESTINATION", destination)
  },[isOpen]);

  useEffect(()=>{
    var isCancelled = false;
    const run = async () => {


      const friendlyTravelData = await api.trustedSigner.getShipTravelSignature(ship.token, ship.location, {localAreaToken: destination.tokenId, alignment: 1});
      const neutralTravelData = await api.trustedSigner.getShipTravelSignature(ship.token, ship.location, {localAreaToken: destination.tokenId, alignment: 2});
      const hostileTravelData = await api.trustedSigner.getShipTravelSignature(ship.token, ship.location, {localAreaToken: destination.tokenId, alignment: 3});
      if(isCancelled){
        return;
      }
      setTravelData({
        1: friendlyTravelData,
        2: neutralTravelData,
        3: hostileTravelData,
      });
    }
    run();
    return () => {isCancelled = true;}
  },[]);

  useEffect(()=>{
    console.log("TRANSPORT SHIP TX", transportShipTx)
    if(transportShipTx.length > 0){
      const txResult = transportShipTx[transportShipTx.length - 1];
      if(txResult){
        console.log("transportShipTx", txResult);
        if(txResult.status === "success" ){
          console.log(txResult.receipt);
          onClose();
          navigate(`/app/planets/${destination._id}`)
        }
      }
    }
  },[transportShipTx]);

  const transport = async (alignment) => {
    const {signature, price} = travelData[alignment]
    transportShip(ship.token, [alignment, destination.tokenId], price, signature);


  };

  const transportFriendly = async () => {
     transport(1);
  };

  const transportNeutral = async () => {
    transport(2);
  };

  const transportHostile = async () => {
    transport(3);
  };

  const Cost = (props) => {
    if(!travelData){
      return null;
    }
    return <span className="travel-price">{formatBalance(travelData[props.alignment].price.hex, drizzle)}<VoxIcon className="vox-icon"/></span>
  }

  return (
    <Dialog open={isOpen} className="travel-dialog" PaperComponent="div" scroll="paper" fullWidth={true} onBackdropClick={onClose}>
      <DialogContent style={{justifyContent: "center", display: "flex"}}>
        <TravelDialogStyled>
          <h1 className="destination-info">
            Travel to {destination && destination.proto.getName()}
          </h1>
          {!isMinted && <span className="travel-warning"><WarningAmberIcon color="secondary" style={{marginRight:'1rem'}}/>No active VOX detected.</span>}
          <span className="travel-info"><HelpOutlineIcon color="primary" style={{marginRight:'1rem'}}/>{salvageTaxBP/100}% Salvage Tax</span>
          <h2>Select your ship's posture.</h2>
          <ul className="posture-description">
            <li><span><b>Friendly</b> ships agree to comply with all local salvage taxes.</span></li>
            <li><span><b>Neutral</b> ships are restricted from any salvage activities but enjoy certain traveler perks.</span></li>
            <li><span><b>Hostile</b> ships pay no salvage-taxes if they amass superior numbers. Otherwise, they are restricted from salvage activities.</span></li>
          </ul>
          <div className="button-container">
            <Button variant='contained' disabled={!travelData} color="info" onClick={transportFriendly}>Friendly <Cost alignment={1}/> </Button>
            <Button variant='contained' disabled={!travelData} onClick={transportNeutral}>Neutral <Cost alignment={2}/></Button>
            <Button variant='contained' disabled={!travelData} color="secondary" onClick={transportHostile}>Hostile <Cost alignment={3}/></Button>
          </div>
        </TravelDialogStyled>
      </DialogContent>
    </Dialog>
    )
}