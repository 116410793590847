import './Pilot.css'
import {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import {Button, Paper} from '@mui/material'
import HeadShot from './HeadShot'
import {api} from './apis/api'
import Panel from './Panel'

export default function Pilot(props){
  const params = useParams();
  var {showName, id} = props;
  const [metadata, setMetadata] = useState();

  if(showName === undefined){
    showName = true;
  }
  if(id === undefined){
    id = params.id;
  }
  const species = props.species || params.species;

  useEffect(() => {
    const run = async () => {
      const meta = await api.pilots.get(species, id);
      if(!showName){
        meta.name = null;
      }
      setMetadata(meta);
    }

    run();

  }, []);

  if(!metadata){
    return null;
  }

  return (
    <HeadShot className={props.className} metadata={metadata} animated={props.animated} showName={props.showName}/>
  )
}
