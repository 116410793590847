import axios from 'axios'
import {description} from '../constants/localContent'

const base_url = `${process.env.REACT_APP_API_BASE_URL}/artifacts`

export class Artifacts {

  constructor(authContext){
    this.authHeaders = {
      'Authorization': authContext?.auth?.signature,
      'X-Signature-Timestamp': authContext?.auth?.timestamp,
      'X-Signature-Address': authContext?.account,
    };
    this.invalidateAuth = authContext?.invalidateAuth;
    this.web3OptedIn = authContext?.web3OptedIn;
  }

  async getInteraction(artifactId, address, auth) {

    if(!this.web3OptedIn){
      return {
        displayItem:{
          information: {
            description
          }
        }
      };
    }
    const config = {
      headers: this.authHeaders,
    };

    try {
      const {data} = await axios.get(`${base_url}/${artifactId}/interaction`, config)
      return data;
    } catch (e) {
      if(e?.response?.status === 401){
        this.invalidateAuth();
      } else {
        throw e;
      }
    }
  }
};

