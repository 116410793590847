const networkMap = {
  1: {
    providerUrls: {
      http: "https://mainnet.infura.io/v3/f963eeed487143f39b967914c620e46c",
    }
  },
  137: null, //polygon
  80001: null, //polygon-mumbai
  1337: null, //truffle-development
};

export default networkMap;