import axios from 'axios'
const base_url = `${process.env.REACT_APP_NFT_BASE_URL}`

const params = {
    responseType: "text/json",
    headers:{

    }
}

export const get = async (id: string) => {
  try {
    const { data } = await axios.get(`${base_url}/ships/metadata/${id}`, params)
    return data
  } catch (error) {
    throw error;
  }
}