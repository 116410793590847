import styled from 'styled-components';

export const TravelDialogStyled = styled.div`
  padding: 20px;

  width:80vmin;
  height: 90%;
  overflow-y: auto;
  border-radius: 10px 10px 10px 10px;
  border: 2px solid #0f0;
  background-color: #000;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px 0px #0a0f;

  .travel-warning, .travel-info {
    display:flex;
    align-items: center;
  }
  .destination-info {
    margin-top: 0;
  }
  .vox-icon {
    width: 1rem;
    color: #0f0;
  }
  .matic-icon {
    width: 1rem;
  }

  .travel-price {
    margin-left: 10px;
    align-items: center;
    display: flex;

    .vox-icon{
      margin-left: 5px;
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 150px;
  }
`;