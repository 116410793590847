import "./galaxyHustle.css";
import {createTheme} from "@mui/material/styles";

let theme = createTheme({
    palette: {
        mode:"dark",
        background: {
            paper: '#040718',
            default: '#000000',
        },
        primary: {
            main: "#00ffff",
        },
        secondary: {
            main: "#ff00ff"
        },
        info: {
            main: "#00ff00"
        }
    },
    typography: {
        fontFamily: "LazenbyCompLiquid",
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold: 700,
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    fontWeight: 600,
                    textTransform: "capitalize",
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    margin: "2rem 0",
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: "#040718",
                    fontWeight: 800,
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontSize: "2rem",
                    fontWeight: 700,
                },
                h2: {
                    fontSize: "1.75rem",
                    fontWeight: 600,
                },
                h3: {
                    fontSize: "1.25rem",
                    fontWeight: 600
                },
                h4: {
                    fontSize: "1rem",
                    fontWeight: 600,
                },
                h5: {
                    fontSize: "0.75rem",
                    fontWeight: 200,
                    fontStyle: "italic"
                },
                p: {
                  fontFamily: "Poppins"
                },
                span: {
                  fontFamily: "Poppins"
                },
                li: {
                  fontFamily: "Poppins"
                }

            }
        }
    }
});

export {theme};