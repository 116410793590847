import * as proto from './protos/universe_pb';
import {api} from './apis/api';
import * as THREE from "three";
import {fetchFile} from './libs/utils'
import {idToLocalAreaToken} from './constants/metadataMap'

const protoToVector3 = (proto) => {
  return new THREE.Vector3(proto.getX(), proto.getY(), proto.getZ());
}

export class LocalArea extends THREE.Object3D {

  constructor(pivotObject, volumeObject, material, localAreaProto, params) {

    super();
    this.copy(pivotObject);
    this._id = localAreaProto.getId();
    this.tokenId = idToLocalAreaToken[this._id];
    this.traverse((child) => {
      if( child instanceof THREE.Mesh && child.name){
        this.volumeObject = child
      }
    });

    this.material = material;
    this.proto = localAreaProto;
    this.up = params.up;

    this.isHovering = false;
    this.hoverActive = false;
    this.isInteractable = true;
    this.rotationDelta = this.proto.getDistancefromsun()/(5000000 + 5000000 * parseInt(this._id.slice(0,2),16)/255.0 );
  }

  think(elapsedTime, currentTime){


    this.material.uniforms.hover.value = false;
    this.rotation.y += this.rotationDelta;

    if(this.isHovering){
      this.material.uniforms.hover.value =  true ;
    }

    this.volumeObject.rotateOnAxis(this.up, 0.001);
    this.material.uniforms.sunDirection.value.applyAxisAngle( this.up,-0.001)

    for(const child of this.volumeObject.children){
      if(child.think){
        child.think(elapsedTime, currentTime);
      }
    }

  }

  async click(mouse, api){
    this.isHovering = false;
    return {
      navigateItem: {
        url: `/app/planets/${this._id}`
      }
    };
  }
  static async build(localAreaProto, params={}){

    const volumeVertexShader = await fetchFile("/shaders/volume.vertex");
    const volumeFragmentShader = await fetchFile("/shaders/volume.fragment");

    console.log("Building LocalArea");
    const p = await api.planets.get(localAreaProto.getId());
    console.log("Loading " + localAreaProto.getId())
    const planet = proto.LocalArea.deserializeBinary(p);
    var volumeTexture = new THREE.DataTexture3D(planet.getVolume().getData(),
      planet.getVolume().getWidth(),
      planet.getVolume().getHeight(),
      planet.getVolume().getLength()
    );
    volumeTexture.minFilter = THREE.NearestFilter;
    volumeTexture.magFilter = THREE.NearestFilter;
    volumeTexture.needsUpdate = true;

    var geometry = new THREE.SphereGeometry( 0.5, 16, 16 );
    const y = Math.sin(localAreaProto.getRotationangle());
    const x = Math.cos(localAreaProto.getRotationangle());
    const position = new THREE.Vector3(x, 0, y);

    var sunDirection = position.clone().multiplyScalar(-1.0).normalize();
    var material = new THREE.ShaderMaterial( {
       uniforms: {
           volumeTexture: {value: volumeTexture},
           ambientLight: {value: 0.25},
           sunDirection: {value:sunDirection},
           lightColor: {value: [params.sunColor.getR(),params.sunColor.getG(),params.sunColor.getB(),1]},
           hover: {value: false},
       },
       vertexShader: volumeVertexShader,
       fragmentShader: volumeFragmentShader
    } );
    material.blending = THREE.CustomBlending;
    material.blendEquation = THREE.AddEquation;
    material.blendSrc = THREE.SrcAlphaFactor;
    material.blendDst = THREE.OneMinusSrcAlphaFactor;
    var volumeSphere = new THREE.Mesh( geometry, material );
    volumeSphere.name = localAreaProto.getId();
    volumeSphere.translateOnAxis(position, localAreaProto.getDistancefromsun()/250 );
    volumeSphere.renderOrder = params.renderOrder;

    const pivot = new THREE.Group();
    pivot.position.set(0,0,0);
    pivot.add(volumeSphere);

    if(planet.getName().includes("Station")){
      console.log("STATION!")
      var scale = 0.4;
      volumeSphere.rotateZ(0.5*Math.PI);
      params.up = new THREE.Vector3( 1, 0, 0 );

      sunDirection.applyAxisAngle( new THREE.Vector3( 0, 0, 1 ),-0.5*Math.PI)

    }else{
      var scale = localAreaProto.getVolumeconfig().getPlanet().getAveragesurfacealtitude()/48;
      params.up = new THREE.Vector3( 0, 1, 0 );
    }


    volumeSphere.scale.x = scale;
    volumeSphere.scale.y = scale;
    volumeSphere.scale.z = scale;

    const result = new LocalArea(pivot, volumeSphere, material, planet, params);

    return result;

  }
}






