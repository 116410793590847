import styled from 'styled-components';

export const OperatorStyled = styled.div`
  & {
    flex-grow: 1;

    height:100%;
    .pilotImage {
      width: 105%;
      height: 105%;
      position: absolute;
      top: -5%;
      left: -5%;

      .animated {
        animation: 2s ease 0.25s infinite reverse none running bounce_body;
      }
    }
  }
`;