import './WalletDialog.css'

import {useEffect, useState, memo, useRef, useCallback, useMemo, useContext} from 'react'
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar
} from '@mui/material'
import { drizzleReactHooks } from '@drizzle/react-plugin';
import ShipRow from './ShipRow.js'
import VoxBalanceRow from './VoxBalanceRow.js'
import Panel from './Panel'
import {AuthContext} from './Web3Container'
import { useInView } from 'react-intersection-observer';

const ShipListItem = memo((props) => {
  const shipToken = props.shipToken;
  const universe = props.universe;
  console.log("RENDERING shipToken = ", shipToken)
  return  <ListItem key={shipToken} disablePadding={true}><ShipRow id={shipToken} universe={universe}/></ListItem>;
});

const LoadingItem = (props) =>{
  const isLoading = props.isLoading;

  if(!isLoading){
    return null;
  }

  return (<div>LOADING...</div>);
};

const WalletList =(props) => {

  const shipTokens = props.shipTokens;
  const universe = props.universe;
  console.log("RENDERING WALLET LIST")
  return (
    <List>
      {
       shipTokens.map(x=><ShipListItem key={x} shipToken={x} universe={universe} />)
      }
    </List>
  );
};

function WalletDialog(props) {
  const {web3OptedIn} = useContext(AuthContext);
  const {open, onClose} = props;
  const { drizzle, useCacheCall } = web3OptedIn ? drizzleReactHooks.useDrizzle() : {drizzle: null, useCacheCall: (()=>{})};
  const [shipTokens,setShipTokens] = useState([]);
  const universe = props.universe;
  const balance = parseInt(useCacheCall("Fleet", "balanceOf", drizzle?.store?.getState?.()?.accounts?.[0]));
  const pageSize = 7;
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const fetchMoreRows = useCallback(async () => {

    const run = async () => {
      const currentCount = shipTokens.length;
      if(currentCount < balance && !isLoading){
        setIsLoading(true);
        var shipToken = -1;
        if(shipTokens.length > 0){
          shipToken = shipTokens[currentCount - 1];
        }

        const tokens = [];
        for(var i = currentCount; (i < currentCount + pageSize) && (i < balance); i++){
          try{
            var arg = parseInt(shipToken)+1;

            shipToken = await drizzle.contracts.Fleet.methods.tokenOfOwnerAfterId(drizzle.store.getState().accounts[0], arg).call();

            tokens.push(shipToken);

          } catch (err) {
            console.log(err)
            break;
          }
        }
        setShipTokens(shipTokens.concat(tokens))
        console.log("TOTAL ON PAGE= ", shipTokens.length);
        setIsLoading(false);
      }
    };
    await run();
  }, [drizzle, balance, shipTokens, isLoading]);

  const EndItem = (props) => {
    const { ref, inView } = useInView({root: null});

    const isLoading = props.isLoading;
    const fetchMoreRows = props.fetchMoreRows;
    const balance = props.balance;

    useEffect(()=>{
      if(inView && !isLoading){
        fetchMoreRows();
      }
    }, [inView, isLoading, balance, fetchMoreRows]);

    if(isLoading){
      return null;
    }
    return (<div ref={ref} style={{minHeight: "1px"}}></div>);
  };

  return (
    <Dialog className="WalletDialog" open={open} onClose={handleClose} >
        <VoxBalanceRow />
        <WalletList shipTokens={shipTokens} universe={universe}/>
        <LoadingItem key="loading-item" isLoading={isLoading}/>
        <EndItem key="end-item" isLoading={isLoading} fetchMoreRows={fetchMoreRows} balance={balance} />
    </Dialog>
  );
}

export default WalletDialog;