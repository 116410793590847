import Fleet from'../contracts/Fleet.json';
import Eyeguy from'../contracts/Eyeguy.json';
import Galaxy from'../contracts/Galaxy.json';
import VOXToken from'../contracts/VOXToken.json';

const drizzleOptions = {
  contracts: [
    VOXToken,
    Galaxy,
    Fleet,
    Eyeguy,
  ],
  //syncAlways: true,
  web3: {
    fallback:{
      type: "ws",
      url: 'ws://127.0.0.1:9545'
    }
  }
};

export default drizzleOptions;
