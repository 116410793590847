import axios from 'axios'
const base_url = process.env.REACT_APP_API_BASE_URL;

export class TrustedSigner {

  constructor(authContext){
    this.authHeaders = {
      'Authorization': authContext?.auth?.signature,
      'X-Signature-Timestamp': authContext?.auth?.timestamp,
      'X-Signature-Address': authContext?.account,
    };
    this.invalidateAuth = authContext?.invalidateAuth;
  }

  async getShipUpgradeSignature(shipTokenId, level) {

    const config = {
      params: {
        level
      },
      headers: this.authHeaders,
    };

    try {
      console.log(base_url);
      try {
        const { data } = await axios.get(`${base_url}/ships/${shipTokenId}/upgrade-signature`, config);
        return data
      } catch (e) {
        if(e?.response?.status === 401){
          this.invalidateAuth();
        } else {
          throw e;
        }
      }
    } catch (error) {
      throw error;
    }
  }

  async getShipTravelSignature(shipTokenId, departure, arrival) {

    const config = {
      params: {
        departureLocalAreaToken: departure.localAreaToken,
        departureAlignment: departure.alignment,
        arrivalLocalAreaToken: arrival.localAreaToken,
        arrivalAlignment: arrival.alignment,
      },
      headers: this.authHeaders,
    };

    try {
      console.log(base_url);
      try {
        const { data } = await axios.get(`${base_url}/ships/${shipTokenId}/transport-signature`, config);
        return data
      } catch (e) {
        if(e?.response?.status === 401){
          this.invalidateAuth();
        } else {
          throw e;
        }
      }
    } catch (error) {
      throw error;
    }
  }

  async getSalvageSignature(artifactId, shipTokenId) {

      const config = {
        headers: this.authHeaders,
        params: {
          shipTokenId
        },
      };

      try {
        console.log(base_url);
        try {
          const { data } = await axios.get(`${base_url}/artifacts/${artifactId}/salvage-signature`, config);
          return data
        } catch (e) {
          if(e?.response?.status === 401){
            this.invalidateAuth();
          } else {
            throw e;
          }
        }
      } catch (error) {
        throw error;
      }
    }
};


