import styled from 'styled-components';

export const PlanetPanelStyled = styled.div`

  ${props => props.characterDisplay ? `
    border: none;
    box-shadow: none;
    flex-direction: row;
    height: 75px;
    background-color: none;
    z-index: 2000;
    .planet-info {
      display: none;
    }
    .local-ships {
      display: none;
    }
    .ship-control {
      display: none;
    }
    .back-button {
      display: none;
    }
    #noSignalCanvas {
      display: none;
    }
  ` : `
    border: 2px solid #0f0;
    box-shadow: 0px 0px 20px 0px #0f08;
    flex-direction: column;
    height: 300px;
    background-color: #0f01;
    z-index: 1;
    .planet-info {
      display: flex;
    }
    .local-ships {
      display: flex;
    }

  `}

  position:absolute;
  bottom: 15px;
  left: 15px;
  display: flex;

  border-radius: 10px 3rem 10px 10px;
  display: flex;

  overflow:hidden;

  width: 300px;
  max-width: 90vw;

  max-height: 30vh;

  .planet-info {

    align-items: center;
    padding: 0.5rem 0 0.5rem 0;
    border-bottom: 2px solid #0f0;

    justify-content: space-between;
    &.friendly {
      background-color: #0a0a;
      box-shadow: 0px 0px 10px 3px #080d;
    }

    &.neutral {
      background-color: #0aaa;
      box-shadow: 0px 0px 10px 3px #088d;
    }

    &.hostile {
      background-color: #a0aa;
      box-shadow: 0px 0px 10px 3px #808d;
    }
    .vox-available {
      display: flex;
      align-items: center;
      .vox-icon {
        height: 2rem;
        margin: 0 1rem 0 1rem;
      }
    }
    .local-census {
      margin-right: 24px;
      display: flex;
      justify-content: space-around;

      .ship-alignment {
        margin-right: 10px;

        &.friendly {
          color: #0f0;
        }

        &.neutral {
          color: #0ff;
        }

        &.hostile {
          color: #f0f;
        }
      }

    }
  }

  .local-ships {

    height: 100%;
    padding-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    overflow-y: auto;
    flex-direction: row;
    align-content: flex-start;
    flex-wrap: wrap;

  }


  .ship-container {
    padding: 10px;
    .control-container {
      display: flex;
    }
    .ship-operator {
      display: flex;
      position:relative;
      padding: 2px 10px 2px 0;
      border-radius: 2em 2em 2em 2em;
      margin: 10px;

      &.alignment-1 {
        background-color: #0a0a;
        box-shadow: 0px 0px 10px 0px #080d;
        .ship-avatar, .operator-avatar {
          border: 2px solid #0f0;
        }
      }
      &.alignment-2 {
        background-color: #0aaa;
        box-shadow: 0px 0px 10px 0px #088d;
        .ship-avatar, .operator-avatar {
          border: 2px solid #0ff;
        }
      }
      &.alignment-3 {
        background-color: #a0aa;
        box-shadow: 0px 0px 10px 0px #808d;
        .ship-avatar, .operator-avatar {
          border: 2px solid #f0f;
        }
      }

      .ship-avatar, .operator-avatar, .ship-level {
        box-shadow: 0px 0px 3px 2px #0005;
       }

      .ship-avatar {
        border-radius: 50%;
        height: 50px;


      }

      .operator-avatar {
        border-radius: 50%;
        overflow:hidden;
        height: 50px;
        width: 50px;
        position: relative;
        left: -5px;

      }
    }
    .ship-control{
      width: 100%;
      padding: 10px;
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-gap: 10px;

      .value {

      }

    }
  }

`;