import axios from 'axios'
const base_url = `${process.env.REACT_APP_BASE_URL}/universe/localAreas`

const params = {
    responseType: "arraybuffer",
    headers:{

    }
}

export const get = async (id: string) => {
  try {
    console.log(base_url);
    const { data } = await axios.get(`${base_url}/local_area_${id}.pb.gz`, params)
    return data
  } catch (error) {
    throw error;
  }
}
