import styled from 'styled-components';

export const ShipMiniStyled = styled.div`
  & {



    display: flex;
    width: fit-content;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-right: 10px;
    border-radius: 50vw;
    box-shadow: 0px 0px 5px #000a;
    cursor: pointer;

    &.alignment-1 {
      background-color: #0f0;
      .ship-avatar, .operator-avatar {
        border: 2px solid #0f0;
      }
    }
    &.alignment-2 {
      background-color: #0aa;
      .ship-avatar, .operator-avatar {
        border: 2px solid #0ff;
      }
    }
    &.alignment-3 {
      background-color: #a0a;
      .ship-avatar, .operator-avatar {
        border: 2px solid #f0f;
      }
    }

    &:hover{
       background-color: #0aa;
       box-shadow: 0px 0px 0px 2px #0aaf;
       .ship-avatar, .operator-avatar, .ship-level {
        box-shadow: 0px 0px 3px 2px #0008;
       }
       .ship-level{
        border: 2px solid #0aa;
       }
    }
    .ship-avatar, .operator-avatar, .ship-level {
      box-shadow: 0px 0px 3px 2px #0005;
     }
    .ship-operator {
      display: flex;

      position:relative;
    }
    .ship-level {
      position:absolute;
      border: 2px solid #0f0;
      border-radius: 100%;
      width: 1.2rem;
      aspect-ratio: 1;
      justify-contents: center;
      text-align: center;
      top: -8px;
      left: -8px;
      background-color: #000;


    }
    .ship-avatar {
      border-radius: 50%;
      height: 50px;
      border: 2px solid #0f00;

    }


    .operator-avatar {
      border: 2px solid #f0ff;
      border-radius: 50%;
      overflow:hidden;
      height: 1.5em;
      width: 1.5em;
      position: relative;
      top:0.75em;
      left:-1em;

    }



  }

`;