import * as proto from './protos/universe_pb'
import * as THREE from "three";
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader'
import {OrbitingEntity} from './OrbitingEntity'



export class Satellite extends OrbitingEntity {

  static async build(proto, params={}){

    console.log("Building Satellite");
    const object = await this.buildObject(proto);
    const result = new Satellite(object, proto, params);

    return result;
  }

}