import {MarketStyled} from './MarketStyled'
import VoxBalanceRow from './VoxBalanceRow'
import { drizzleReactHooks } from '@drizzle/react-plugin';
import Shipyard from './Shipyard'
import Transport from './Transport'

const MarketFromType = (props) => {
  const type = props.type;
  switch(type){
    case 'SHIPYARD':
      return (<Shipyard {...props?.opts} />);
    case 'TRANSPORT':
      return <Transport {...props?.opts} />;
    default:
      console.log("DISPLAY NULL")
      return null;
  }
}

export default function Market(props){

  const { drizzle, useCacheCall } = drizzleReactHooks.useDrizzle();

  return (
    <MarketStyled>
      <VoxBalanceRow />
      <MarketFromType {...props}/>
    </MarketStyled>
  );

}