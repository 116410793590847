import './Container.css'
import {Outlet, useLocation} from 'react-router-dom'
import {useState, useEffect, useContext} from 'react'
import * as proto from './protos/universe_pb'
import {useApi} from './apis/api'
import Header from './Header'
import {LocalArea} from './LocalArea'
import {fetchFile, loadSkyTexture, disposeObject} from './libs/utils'
import * as THREE from "three";
import {Interactable} from './Interactable'
import {Gate} from './Gate'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import {ParticleSystem} from './ParticleSystem'
import {UniverseContext} from './context/UniverseContext'
import {AuthContext} from './Web3Container'
import {LinearProgress} from '@mui/material'

const mouse = new THREE.Vector2();

function onMouseMove( event ) {

	// calculate mouse position in normalized device coordinates
	// (-1 to +1) for both components

	mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
	mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

}
window.addEventListener( 'pointermove', onMouseMove, false );

const loadSky = async (canvas, solarSystem, gates) => {
  var newSkyTexture;
  try{
    newSkyTexture = await loadSkyTexture(solarSystem.getId());

    newSkyTexture.needsUpdate = true;
    newSkyTexture.format = THREE.RGBAFormat;
    newSkyTexture.type = THREE.UnsignedByteType;
    newSkyTexture.minFilter = THREE.LinearFilter;
    newSkyTexture.magFilter = THREE.LinearFilter;
  } catch (error){
    console.error(error);
  }


  const skyboxVertexShader = await fetchFile("/shaders/skybox.vertex");
  const skyboxFragmentShader = await fetchFile("/shaders/skybox.fragment");

  var skySphere = new THREE.SphereGeometry(100, 16, 16);

  var sunColor = solarSystem.getSuncolor();
  const skyMaterial = new THREE.ShaderMaterial({
    uniforms:{
      lightPos: {value:[0,0,0]},
      lightColor: {value: [0,0,0,0]},
      screenWidth: {value:canvas.clientWidth},
      screenHeight: {value:canvas.clientHeight},
      size: {value:0.0},
      skyTexture: {value: newSkyTexture},
      time: {value: 0.0}
    },
    vertexShader: skyboxVertexShader,
    fragmentShader: skyboxFragmentShader
  });
  skyMaterial.blending = THREE.CustomBlending;
  skyMaterial.blendEquation = THREE.AddEquation;
  skyMaterial.blendSrc = THREE.SrcAlphaFactor;
  skyMaterial.blendDst = THREE.OneMinusSrcAlphaFactor;

  var newSky = new THREE.Mesh(skySphere, skyMaterial);
  newSky.material.side = THREE.BackSide;
  newSky.renderOrder = 0;
  return newSky;
};

const loadSun = async (canvas, sunColor, renderOrder) => {
  const sunVertexShader = await fetchFile("/shaders/sun.vertex");
  const sunFragmentShader = await fetchFile("/shaders/sun.fragment");
  var geometry = new THREE.PlaneGeometry( 2, 2 );
  var material = new THREE.ShaderMaterial( {
       uniforms: {
           lightColor: {value: [sunColor.getR(),sunColor.getG(),sunColor.getB(),1]},
           screenWidth: {value: canvas.clientWidth},
           screenHeight: {value: canvas.clientHeight},
       },
       vertexShader: sunVertexShader,
       fragmentShader: sunFragmentShader
   } );
  material.blending = THREE.CustomBlending;
  material.blendEquation = THREE.AddEquation; //default
  material.blendSrc = THREE.SrcAlphaFactor; //default
  material.blendDst = THREE.OneMinusSrcAlphaFactor;
  material.depthWrite = false;
  material.transparent = true;
  var sunSphere = new THREE.Mesh( geometry, material );
  sunSphere.renderOrder = renderOrder;
  return sunSphere;
};

export default function Container(props) {
  const {web3OptedIn, auth} = useContext(AuthContext);
  const api = useApi();
  const location = useLocation();
  const isFirstLoad = location?.state?.isFirstLoad;
  const [universe, setUniverse] = useState();
  const [galaxy, setGalaxy] = useState();
  const [solarSystem, setSolarSystem] = useState();
  const [localAreas, setLocalAreas] = useState();
  const [planet, setPlanet] = useState();
  const [selectedShip, setSelectedShip] = useState();

  const [skyTexture, setSkyTexture] = useState();
  const [scene, setScene] = useState(new THREE.Scene());
  const [camera, setCamera] = useState();
  const [sky, setSky] = useState();
  const [canvas, setCanvas] = useState();
  const [sunSphere, setSunSphere] = useState();
  const [gates, setGates] = useState([]);
  const [renderer, setRenderer] = useState();
  const [particleSystem, setParticleSystem] = useState();
  const [composer, setComposer] = useState();
  const [hasLogo, setHasLogo] = useState(true);
  const [removeLogo, setRemoveLogo] = useState(false);
  const [isLoadingUniverse, setIsLoadingUniverse] = useState(true);
  const [isLoadingSolarSystem, setIsLoadingSolarSystem] = useState(false);
  const [isBuildingSolarSystem, setIsBuildingSolarSystem] = useState(false);
  const [localAreaCount, setLocalAreaCount] = useState(0);

  const cameraPosition = new THREE.Vector3(0,0,5);


  const sunPosition = new THREE.Vector3( 0, 0, 0 );
  const up = new THREE.Vector3( 0, 1, 0 );

  useEffect( ()=> {
    var isCancelled = false;
    console.log("CONTAINER init")
    const run = async () => {
      setIsLoadingUniverse(true);
      try{
        const u = await api.universe.get();
        const universe = proto.Universe.deserializeBinary(u);
        !isCancelled && setUniverse(universe);
      } finally {
        !isCancelled && setIsLoadingUniverse(false);
      }
    };

    run();
    return ()=>{isCancelled = true};
  },[]);

  useEffect(() => {
    var isCancelled = false;
    console.log("CONTAINER universe location")
    if(!universe){
      return;
    }

    if(location.pathname.includes("galaxy")){
      const id = universe.getGalaxymapMap().keys().next().value;
      setGalaxy(universe.getGalaxymapMap().get(id));
      setSolarSystem(null);
      setPlanet(null);
      return;
    }

    if(location.pathname.includes("solarsystems")){
      const solarSystemId = location.pathname.split("/")[3];
      const solarSystem = universe.getSolarsystemmapMap().get(solarSystemId);
      setSolarSystem(solarSystem);
      setPlanet(null);
      return;

    }

    if(location.pathname.includes("planets")){
      const planetId = location.pathname.split("/")[3];
      console.log("Planet ID = ", planetId);
      if(!planet || (planetId !== planet._id)){
        const planetProto = universe.getLocalareamapMap().get(planetId);

        const solarSystem = universe.getSolarsystemmapMap().get(planetProto.getParent().getId());
        setSolarSystem(solarSystem);

        return;
      }
    }

    return () => {isCancelled = true};
  }, [universe, location, auth]);

  useEffect(() => {
    var isCancelled = false;
    console.log("CONTAINER solarSystem")
    if(solarSystem){
      setIsLoadingSolarSystem(true);
      const sunColor = solarSystem.getSuncolor();
      console.log("TOTAL LocalAreas count = ", solarSystem.getLocalareasList().length);
      const localAreaPromises = solarSystem.getLocalareasList()
                                          .map( localArea => universe.getLocalareamapMap().get(localArea.getId()))
                                          .map( (localAreaProto, idx) => {
                                            return LocalArea.build(localAreaProto, {sunColor, renderOrder: idx + 1})
                                                            .then(la => {
                                                              setLocalAreaCount(prev => prev + 1)
                                                              return la;
                                                            });
                                          })

      Promise.all(localAreaPromises).then(localAreas => {
        !isCancelled && setLocalAreas(localAreas);
        !isCancelled && setIsBuildingSolarSystem(true);
        !isCancelled && setIsLoadingSolarSystem(false);
      }).catch(err => {
        console.error("Failed to load solar system")
        !isCancelled && setIsLoadingSolarSystem(false);
      });

    }

    return () => {
      isCancelled = true;
    }

  }, [solarSystem]);


  useEffect(()=>{
    console.log("CONTAINER localAreas location")
    if(localAreas && location.pathname.includes("planets")){
      const planetId = location.pathname.split("/")[3];
      setPlanet(localAreas.find(x => x._id == planetId));
    }
  }, [localAreas, location]);

  useEffect( () => {
    console.log("CONTAINER solarSystem localAreas")
    var addedObjects = [];
    var sky;
    var newGates = [];
    var isCancelled = false;
    if(solarSystem && localAreas){
      setIsBuildingSolarSystem(true);
      const run = async () => {
        console.log("GETTING CANVAS")
        var canvas = document.getElementById('spaceCanvas');
        console.log("GOT CANVAS", canvas)
        setCanvas(canvas);

        sky = await loadSky(canvas, solarSystem, newGates);
        scene.add(sky)
        setSky(sky);

        if(web3OptedIn){
          const gateResult = await api.solarSystems.getGates(solarSystem.getId());
          newGates = await Promise.all(gateResult.gates.map(x => Gate.buildFromJson(x, {
            canvas,
            destination: universe.getSolarsystemmapMap().get(x.destination.id),
            skyTexture: sky.material.uniforms.skyTexture.value,
          })));

          for(let g of newGates){
            scene.add(g);
          }
          setGates(newGates);
        }

        addedObjects = await buildSolarSystem(canvas);

        var camera = new THREE.PerspectiveCamera( 60, canvas.clientWidth/canvas.clientHeight, 0.1, 1000 );

        camera.position.copy(cameraPosition);
        setCamera(camera);
      };

      run().then(r=>{
        !isCancelled && setIsBuildingSolarSystem(false)
      }).catch(err=>{
        console.error("Failed to build solar system", err)
        !isCancelled && setIsBuildingSolarSystem(false);
      });
    }

    return () => {
      isCancelled = true;
      console.log("CLEANING CONTAINER solarsystem localareas")
      if(addedObjects){
        for(var obj of addedObjects){
          scene.remove(obj);
          disposeObject(obj);
        }
      }

      if(sky){
        scene.remove(sky);
        disposeObject(sky);
      }

      if(newGates){
        for(var obj of newGates){
          scene.remove(obj);
          disposeObject(obj);
        }
      }

    }
  }, [solarSystem, localAreas, web3OptedIn, auth]);

  const buildSolarSystem = async (canvas) => {
    console.log("CONTAINER buildSolarSystem")
    const addedObjects = [];
    const volumeVertexShader = await fetchFile("/shaders/volume.vertex");
    const volumeFragmentShader = await fetchFile("/shaders/volume.fragment");

    console.log(canvas);

    const sunColor = solarSystem.getSuncolor();
    if(solarSystem && localAreas){
      localAreas.map((localArea) => {
        scene.add( localArea );
        addedObjects.push(localArea);
      });
    }

    var sunSphere = await loadSun(canvas, sunColor, localAreas.length + 1);
    setSunSphere(sunSphere);
    scene.add( sunSphere );
    addedObjects.push(sunSphere);


    const sunLightColor = new THREE.Color(sunColor.getR(), sunColor.getG(), sunColor.getB());
    const light = new THREE.PointLight( sunLightColor, 1, 0 );
    scene.add( light );
    addedObjects.push(light);

    const ambientLight = new THREE.AmbientLight(sunLightColor, 0.5);
    scene.add(ambientLight);
    addedObjects.push(ambientLight);

    const gl = canvas.getContext("webgl2");
    var renderer = new THREE.WebGLRenderer({canvas:canvas, context: gl});
    renderer.setSize( canvas.clientWidth, canvas.clientHeight );
    renderer.setPixelRatio(window.devicePixelRatio);

    const parameters = {
      minFilter: THREE.LinearFilter,
      magFilter: THREE.LinearFilter,
      format: THREE.RGBAFormat
    };
    const size = renderer.getSize( new THREE.Vector2() );
    var pixelRatio = renderer.getPixelRatio();
    var width = size.width;
    var height = size.height;
    var composerRenderTarget = new THREE.WebGLRenderTarget( width * pixelRatio, height * pixelRatio, parameters );
    composerRenderTarget.texture.name = 'EffectComposer.rt1';
    composerRenderTarget.depthTexture = new THREE.DepthTexture(width * pixelRatio, height * pixelRatio);

    const composer = new EffectComposer( renderer, composerRenderTarget );
    setRenderer(renderer);
    setComposer(composer);

    var particleSystem = await ParticleSystem.build(renderer, 10000);
    setParticleSystem(particleSystem);
    var particleScene = new THREE.Scene();
    scene.add(particleSystem.particleRenderObject);
    addedObjects.push(particleSystem.particleRenderObject);
    return addedObjects;
  }

  const handleRemoveLogo = () => {
    if(isLoadingUniverse || isLoadingSolarSystem || isBuildingSolarSystem){
      return;
    }
    setHasLogo(false);
    setTimeout(()=>{
      setRemoveLogo(true);
    },1000)
  }
  return (
      <div className="Container">
        <UniverseContext.Provider value={{
              universe,
              galaxy,
              solarSystem,
              localAreas,
              planet,
              selectedShip,
              setSelectedShip,
        }}>
          {
            (isLoadingUniverse || isLoadingSolarSystem || isBuildingSolarSystem) &&
             <>
              <LinearProgress className="universe-loading thin"/>
               <LinearProgress
                color="info"
                className="universe-loading"
                variant="determinate"
                value={ isLoadingUniverse ? 0 : (isLoadingSolarSystem ? (100 * localAreaCount / (solarSystem.getLocalareasList().length+1)) : (isBuildingSolarSystem ? 95 : 100))}/>
              </>
          }
          <Header showLogo={web3OptedIn || removeLogo || !isFirstLoad} solarSystem={solarSystem} planet={planet} galaxy={galaxy} universe={universe}/>
          {
            !web3OptedIn && !removeLogo && isFirstLoad &&
            <div onClick={handleRemoveLogo} className={"logo-container" + (hasLogo ? " fade-in" : " fade-out")}>
              <img src="/logo700.png"/>
              {!isLoadingUniverse && !isLoadingSolarSystem && !isBuildingSolarSystem && <>Click to Continue</>}
            </div>
          }
          <div className="content">

            {props.children || <Outlet context={{
              universe,
              galaxy,
              galaxy,
              solarSystem,
              planet,
              localAreas,
              sky,
              sunSphere,
              camera,
              canvas,
              mouse,
              scene,
              gates,
              renderer,
              composer,
              particleSystem,
            }}/>}
            <div id="wrapper">
              <canvas id="spaceCanvas" />
            </div>
          </div>
        </UniverseContext.Provider>
      </div>
  );
}