import axios from 'axios'
const base_url = `${process.env.REACT_APP_BASE_URL}/universe`

const params = {
    responseType: "arraybuffer",
    headers:{

    }
}

export const get = async () => {
  try {
    console.log(base_url);
    const response = await axios.get(`${base_url}/nft_universe.pb.gz`, params)


    return response.data
  } catch (error) {
    throw error;
  }
}
