import {makeNoise2D, makeNoise3D} from 'fast-simplex-noise';

export class Noise{

    constructor(noiseConfig){
        this.octaves = noiseConfig.getOctave();
        this.persistence = noiseConfig.getPersistence();
        this.scale = noiseConfig.getScale();
        this.noise = makeNoise2D();
        this.noise3d = makeNoise3D();
        this.max =0;
        this.min = 1.0;
    }

    get2d(x, y){
       var total = 0;
       var frequency = this.scale;
       var amplitude = 1;

       // We have to keep track of the largest possible amplitude,
       // because each octave adds more, and we need a value in [-1, 1].
       var maxAmplitude = 0;

       for( let i=0; i < this.octaves; i++ ) {
           var n = this.noise( x * frequency, y * frequency ) + 0.5;
           //console.log(n)
           total += n * amplitude;
           frequency *= 2;
           maxAmplitude += amplitude;
           amplitude *= this.persistence;
       }


       return total / maxAmplitude;
    }

    getScaled2d(x, y, loBound, hiBound){
        var res = this.get2d(x,y) * (hiBound - loBound) / 2 + (hiBound + loBound) / 2;
    }

    get3d(x,y,z){
        var total = 0;
       var frequency = this.scale;
       var amplitude = 1;

       // We have to keep track of the largest possible amplitude,
       // because each octave adds more, and we need a value in [-1, 1].
       var maxAmplitude = 0;

       for( let i=0; i < this.octaves; i++ ) {
           var n = (this.noise3d( x * frequency, y * frequency, z * frequency ) + 1.0) * 0.5;
           n = Math.min(Math.max(n, 0.0), 1.0);

           total += n * amplitude;
           frequency *= 2;
           maxAmplitude += amplitude;
           amplitude *= this.persistence;
       }


       return total / maxAmplitude;
    }


}