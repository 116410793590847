import styled from 'styled-components';

export const InformationStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 3vh);
  justify-content: space-between;
  padding: 3vh;


  .information-description {
    overflow-y:auto;
    text-align: justify;
    padding: 10px;
    width: 50vw;
    min-width: 250px;
    background-color: #00000055;
  }
`;