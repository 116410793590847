import './Ship.css'
import {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import {Button, Paper, Link} from '@mui/material'
import {api} from './apis/api'
import Pilot from './Pilot'
import Contract from 'web3-eth-contract'
import ERC721 from'./contracts/ERC721.json';
import { drizzleReactHooks } from '@drizzle/react-plugin';
import networkMap from './constants/networkMap'
import {metadataMap} from './constants/metadataMap'
import axios from 'axios'
import Web3 from 'web3'
import {ipfsToGateway} from './libs/uriTools'
import * as proto from './protos/universe_pb'
import Panel from './Panel'
import Operator from './Operator'
import AccountLink from './AccountLink'



export default function Ship(props){
  const params = useParams();

  const id = props.id || params.id;
  const [metadata, setMetadata] = useState();

  const { drizzle, useCacheCall } = drizzleReactHooks.useDrizzle();

  const [operator, setOperator] = useState();
  const [spaceColor, setSpaceColor] = useState("rgb(0,0,0)")
  const shipLocation = useCacheCall("Fleet", "getShipLocation", id)
  const nextSalvageTime = useCacheCall("Fleet", "getNextSalvageTime", id)
  const owner = useCacheCall("Fleet", "ownerOf", id)

  useEffect(() => {
    const run = async () => {
      const meta = await api.ships.get(id);
      const hue = meta.attributes.find((x) => {return x.trait_type == "Space Hue"});
      setSpaceColor(`hsl(${Math.floor(hue.value)},100%,50%)`)
      setMetadata(meta);

      if(owner){


        try{

          var op = await drizzle.contracts.Fleet.methods.getOperator(id).call();
          setOperator(op)
        } catch(err) {
          const contractErr = err.message.match("VM Exception while processing transaction: (.*)\"")[1]
          console.error("Can't get operator", contractErr)
          setOperator(null)
        }
      }

    }

    run();

  }, [owner]);

  if(!metadata){
    return null;
  }

  if(!operator){
    if(operator === null){
      return (<div>This ship doesn't exist</div>)
    }
  }

  const Alignment = (props) => {
    const {value} = props;
    const getDisplayName = (alignment) => {
      switch(alignment){
        case "1": return "Friendly";
        case "2": return "Neutral";
        case "3": return "Hostile";
        default: return "UNINITIALIZED";
      }
    };

    return (<div>{getDisplayName(value)}</div>);

  }

  const Location = (props) => {
    const [universe, setUniverse] = useState();
    const [planet, setPlanet] = useState();
    const localAreaToken = props.shipLocation.localAreaToken;
    const id = metadataMap[localAreaToken];

    useEffect(() => {
      const run = async () => {
        const u = await api.universe.get();
        const universe = proto.Universe.deserializeBinary(u);
        setUniverse(universe);
      };

      run();

    }, []);

    useEffect(() => {
      if(!universe){
        return;
      }
      const planet = universe.getLocalareamapMap().get(id);
      setPlanet(planet);
    }, [id, universe]);

    if(!planet){
      return <div><Link href={`/app/planets/${id}`}>{id}</Link></div>;
    }
    return (<div><Link href={`/app/planets/${id}`}>{planet.getName()}</Link></div>)
  }

  const crewAppreciation = () => {
    const statements = [
      'This crew is ready to rock. As long as the mission is trivial.',
      'Check out that totally qualified Salvage Ship Operator.',
      'What a fine pilot we have here.',
      "I'd put your life in this pilot's hands any day.",
      'Where\'d you find this one?',
      'Nine out of ten Salvage Professionals prefer an alternative crewmember.'
    ]

    return statements[Math.floor(Math.random() * statements.length)]

  }
  return (
    <div className="Ship">
     <div className="shipFrame" >
         <img src={metadata.image.replace("https://nft.galaxyhustle.xyz/", "http://localhost:9001/")}/>
     </div>
     <div className="description">
       {
         shipLocation ?
         ( <><div>Location</div>
           <Location shipLocation={shipLocation}/>
           <div>Alignment</div>
           <Alignment value={shipLocation.alignment}/>
           <div>Next Salvage Time</div>
           <div>{nextSalvageTime}</div>
           <div>Owner</div>
            <div><AccountLink address={owner}/></div></>) : null
       }
     </div>
     {operator ?(
       <>
       <div className="operatorFrame"><Operator operator={operator} animated={true} showName={true}/></div>
       <div className="description">
         {crewAppreciation()}
       </div>
       </>
      ) : null}
    </div>
  )
}

