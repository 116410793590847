import {ShipRowStyled} from './ShipRowStyled'
import {useState, useEffect} from 'react';
import {Button, Paper, Link, Tooltip} from '@mui/material'
import {useApi} from './apis/api'
import Pilot from './Pilot'
import Contract from 'web3-eth-contract'
import ERC721 from'./contracts/ERC721.json';
import { drizzleReactHooks } from '@drizzle/react-plugin';
import networkMap from './constants/networkMap'
import {metadataMap} from './constants/metadataMap'
import axios from 'axios'
import Web3 from 'web3'
import {ipfsToGateway} from './libs/uriTools'
import * as proto from './protos/universe_pb'
import Operator from './Operator'
import Panel from './Panel'
import UpgradeIcon from '@mui/icons-material/Upgrade';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import {useCallback} from 'react';
import {displayTime} from './libs/utils'


export default function ShipRow(props){

  const shipToken = props.id;


  const api = useApi();
  const { drizzle, useCacheCall } = drizzleReactHooks.useDrizzle();
  const [metadata, setMetadata] = useState();
  const [animated, setAnimated] = useState(false);
  const [hideControls, setHideControls] = useState(true);
  const universe = props.universe;
  const shipLocation = useCacheCall("Fleet", "getShipLocation", shipToken);
  const nextSalvageTime = useCacheCall("Fleet", "getNextSalvageTime", shipToken);
  const cooldownTime = useCacheCall("Fleet", "getSalvageCooldownTime", shipToken);
  const level = useCacheCall("Fleet", "getLevel", shipToken);
  const operator = useCacheCall("Fleet", "getOperator", shipToken);
  const timeToNextSalvage = nextSalvageTime - Math.trunc((new Date().getTime())/1000);

  useEffect(() => {

    let mounted = true;
    const run = async () => {

      if(shipToken){
        const meta = await api.ships.get(shipToken);
        if(!mounted){
          return;
        }
        console.log("SETTING METADATA")
        setMetadata(meta);
      }
    }

    run();

    return () => {
      mounted = false;
    }
  }, [shipToken]);

  const salvage = async () => {
    drizzle.contracts.Fleet.methods.salvage.cacheSend(shipToken);
  };

  const SkeletonRow = ()=>{
    return (<ShipRowStyled >

      <div className="ship-operator">
        <div className="ship-level">-</div>
        <div className="ship-avatar"/>
      </div>



    </ShipRowStyled>);

  }

  const upgrade = async () => {

    const {signature, price} = await api.trustedSigner.getShipUpgradeSignature(shipToken, level);
    const response = await drizzle.contracts.Fleet.methods.upgrade.cacheSend(shipToken, price, signature);
  };

  if(!metadata){
     return <SkeletonRow/>;
  }

  const Alignment = (props) => {
    const {value} = props;
    const getDisplayName = (alignment) => {
      switch(alignment){
        case "1": return "Friendly";
        case "2": return "Neutral";
        case "3": return "Hostile";
        default: return "UNINITIALIZED";
      }
    };

    return (<div>{getDisplayName(value)}</div>);
  };

  const ShipDetails = (props) => {
    const localAreaToken = props?.shipLocation?.localAreaToken;
    const alignment = props?.shipLocation?.alignment;
    const id = localAreaToken && metadataMap[localAreaToken];
    const [timeToNextSalvage, setTimeToNextSalvage] = useState(props.timeToNextSalvage);
    const planet = universe.getLocalareamapMap().get(id);


    useEffect(() => {
      var interval = null;
      if (timeToNextSalvage > 0){

        interval = setInterval(() => {
          setTimeToNextSalvage(timeToNextSalvage - 1);
        }, 1000);

      } else {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }, [timeToNextSalvage]);


    if(!id){
      return <SkeletonRow/>;
    }


    const PlanetLink = (props) => {

      const {id, planet} = props;
      return (<Link href={`/app/planets/${id}`}>{planet && planet.getName()}</Link>);
    };

    return (
      <div className={props.className}>
        <div className="location">
          <PlanetLink id={id} planet={planet}/>
          <Alignment value={alignment}/>
        </div>
        <div className="salvage">
          {
            timeToNextSalvage <= 0 ?
            <Button className="salvage-button" variant="contained" color="info" onClick={salvage}>Salvage</Button> :
            <Button className="salvage-button cooling" variant="contained" disabled={true}><Tooltip title="Cooling down. Time to next salvage"><div>{displayTime(timeToNextSalvage)}</div></Tooltip></Button>
          }
        </div>
      </div>
    );
  };

  const ShipControls = (props) => {
    const { hidden } = props;
    return (
      <div className={"ship-controls " + (hidden ? "hidden" : "")}>
        <Button color="info" className="ship-control upgrade" variant="outlined" onClick={upgrade}><UpgradeIcon/></Button>
        <Button color="info" className="ship-control warp" variant="outlined" ><RocketLaunchIcon/></Button>

      </div>
    )
  };

  if(!shipLocation){
    return <SkeletonRow/>
  }

  return (
    <ShipRowStyled onClick={() => setHideControls(!hideControls)} onMouseEnter={() => setHideControls(false)} onMouseLeave={() => setHideControls(true)}>

          <div className="ship-operator">
            <Tooltip title="Ship level"><div className="ship-level">{level}</div></Tooltip>
            <img className="ship-avatar" src={metadata.image}/>
            {operator ?(
             <div style={{width:"5em", position:"relative"}}>
             <Operator className="operator-avatar" operator={operator} animated="hovering" showName={false}/>
             </div>
            ) : null}
          </div>

          <ShipDetails className="ship-details" shipLocation={shipLocation} timeToNextSalvage={timeToNextSalvage} />




    </ShipRowStyled>
  )
};
