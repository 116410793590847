import {GalaxyMap2d} from './GalaxyMap2d'
import {GalaxyMap3d} from './GalaxyMap3d'
import {webgl2Support} from "./libs/webglSupport"

export function GalaxyMap() {

  if(webgl2Support()){
    return (<GalaxyMap3d />);
  }
  return (<GalaxyMap2d />);

}