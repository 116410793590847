import {Noise} from './noise'
import * as THREE from "three";
import rgb from 'hsv-rgb';

export class NoiseTexture{

    constructor(){


        let hue = Math.random() * 360;
        let saturation = 100.0;
        let brightness = 100.0;

        let theta = 120;

        if (theta == 0.0) {
            theta = 120.0;
        }

        let epsilon = 360.0 - 2.0 * theta;
        let hsv = [hue, saturation, brightness];
        let c = rgb(hsv[0], hsv[1], hsv[2]);
        this.cyan = new THREE.Vector3(c[0]/255, c[1]/255, c[2]/255);

        hsv[0] = (hsv[0] + theta) % 360.0;
        c = rgb(hsv[0], hsv[1], hsv[2]);
        this.magenta = new THREE.Vector3(c[0]/255, c[1]/255, c[2]/255);

        hsv[0] = (hsv[0] + theta) % 360.0;
        c = rgb(hsv[0], hsv[1], hsv[2]);
        this.yellow = new THREE.Vector3(c[0]/255, c[1]/255, c[2]/255);


    }

        generate(width, height) {

          const size = width * height;
          const data = new Uint8Array( 4 * size );

          for ( let j = 0; j < size; j ++ ) {
              let ix = j % width;
              let iy = Math.floor(j / width);

              let x = (ix)/(width - 1);
              let y = (iy)/(height -1);



              let channel1 = Math.random();//this.channel1Noise.get3d(x,y,0);
              let channel2 = Math.random();//this.channel2Noise.get3d(x,y,0);
              let channel3 = Math.random();//this.channel3Noise.get3d(x,y,0);

              var color;
              let c = this.cyan.clone().multiplyScalar(channel1).add(this.magenta.clone().multiplyScalar(channel2)).add(this.yellow.clone().multiplyScalar(channel3));
              color = new THREE.Color( c.x, c.y, c.z );


              const stride = j * 4;
              data[ stride ] = color.r * 255;
              data[ stride + 1 ] = color.g * 255;
              data[ stride + 2 ] = color.b * 255;
              data [stride + 3] = 255;

          }

        // used the buffer to create a DataTexture2DArray

        const skyTexture = new THREE.DataTexture( data, width, height );
        skyTexture.format = THREE.RGBAFormat;
        skyTexture.type = THREE.UnsignedByteType;

        skyTexture.minFilter = THREE.NearestFilter;
        skyTexture.magFilter = THREE.NearestFilter;
        return skyTexture;
    }

}
