import {useEffect, useState, useContext} from 'react';
import {ArtifactStyled} from './ArtifactStyled'
import { drizzleReactHooks } from '@drizzle/react-plugin';
import {useApi} from './apis/api'
import LocalShips from './LocalShips'
import {ReactComponent as VoxIcon} from './assets/vox_crystal_icon.svg'
import {Button} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import {UniverseContext} from './context/UniverseContext'
import {AuthContext} from './Web3Container'
import {formatBalance, displayTime} from './libs/utils'

//salvageArtifact(uint256 shipTokenId, address account, uint256 amount, bytes memory signature)
export default function SalvageArtifactButton(props) {

  const shipTokenId = props.shipTokenId;
  const {
    amount,
    signature
  } = props.trustedSalvage;
  const {account} = useContext(AuthContext);

  const { drizzle, useCacheCall, useCacheSend } = drizzleReactHooks.useDrizzle();
  const { send: salvageArtifact, TXObjects: salvageArtifactTx} = useCacheSend('Fleet', 'salvageArtifact');
  const nextSalvageTime = useCacheCall("Fleet", "getNextSalvageTime", shipTokenId);
  const cooldownTime = useCacheCall("Fleet", "getSalvageCooldownTime", shipTokenId);
  const [timeToNextSalvage, setTimeToNextSalvage] = useState(nextSalvageTime - Math.trunc((new Date().getTime())/1000));

  useEffect(()=>{
    setTimeToNextSalvage(nextSalvageTime - Math.trunc((new Date().getTime())/1000));
  }, [nextSalvageTime]);

  useEffect(() => {
    var interval = null;
    if (timeToNextSalvage > 0){

      interval = setInterval(() => {
        setTimeToNextSalvage(timeToNextSalvage - 1);
      }, 1000);

    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timeToNextSalvage]);

  useEffect(()=>{

    if(salvageArtifactTx.length > 0){
      const txResult = salvageArtifactTx[salvageArtifactTx.length - 1];
      if(txResult){
        console.log("salvageArtifactTx", txResult);
        if(txResult.status === "success" ){
          console.log("Salvage tx receipt", txResult.receipt);
        }
      }
    }
  },[salvageArtifactTx]);

  const salvage = () =>{
    salvageArtifact(shipTokenId, account, amount, signature);
  };

  return (
    <div className="salvage-button">
      {timeToNextSalvage <= 0 ?
        <Button className="salvage-button" variant="contained" color="info" onClick={salvage}>Salvage</Button> :
        <Button className="salvage-button cooling" variant="contained" disabled={true}>{displayTime(timeToNextSalvage)}</Button>
      }
    </div>
  )
}