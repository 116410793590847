import {TransportStyled} from './TransportStyled'
import {UniverseContext} from './context/UniverseContext'
import {useContext, useState, useEffect} from 'react'
import { drizzleReactHooks } from '@drizzle/react-plugin';
import {AuthContext} from './Web3Container'
import ShipMini from './ShipMini'

export default function Transport(props){
  const { drizzle, useCacheCall, useCacheSend } = drizzleReactHooks.useDrizzle();
  const {account} = useContext(AuthContext);
  const shipCount = parseInt(useCacheCall("Fleet", "balanceOf", account));

  const [selectedShip, setSelectedShip] = useState();
  const [localShips, setLocalShips] = useState([]);
  const {universe, planet} = useContext(UniverseContext);

  const destinations = props?.destinations?.map( x => {
    const proto = universe.getLocalareamapMap().get(x.id);
    x.name = proto.getName();
    return x;
  });

  useEffect(()=>{
    if(!account || !shipCount || shipCount <= 0){
      return;
    }

    var isCanceled = false;
    const run = async () => {
      var shipToken = -1;
      const tokens = [];
      for(var i = 0; (i < shipCount); i++){
        try{
          var arg = parseInt(shipToken)+1;
          var nextToken = await drizzle.contracts.Fleet.methods.tokenOfOwnerInLocalAreaAfterId(account, planet.tokenId, arg).call();
          if(nextToken){
            tokens.push(nextToken);
            shipToken = nextToken;
          }


        } catch (err) {
          console.log(err)
          break;
        }
      }
      !isCanceled && setLocalShips(tokens)
    };

    run();
  }, [shipCount, account, planet]);

  const selectShip = ()=>{

  };
  return (
    <TransportStyled>
      {destinations.map(x => <span>{x.name}</span>)}
      {localShips.map(token => <ShipMini id={token} universe={universe} onClick={selectShip}/>)}
    </TransportStyled>
  );
}