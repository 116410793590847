import './DisplayItem.css'
import Speech from './Speech'
import Market from './Market'
import Artifact from './Artifact'
import Information from './Information'
import {Button, Dialog, DialogContent, DialogActions} from '@mui/material';
import {useEffect} from 'react';

export default function DisplayItem(props) {
  const onDismiss = props.onDismiss;
  const speech = props.speech;
  const market = props.market;
  const artifact = props.artifact;
  const information = props.information;
  const selectedShip = props.selectedShip;
  const open = Boolean(speech || market || artifact || information) || false;

  useEffect(()=>{
    console.log("LOADING CHARACTER DISPLAY ITEM")
  },[])

  useEffect(()=>{
    console.log("LOADING CHARACTER DISPLAY ITEM SPEECH", speech)
  },[speech])

  useEffect(()=>{
      console.log("LOADING CHARACTER DISPLAY ITEM onDismiss", onDismiss)
    },[onDismiss])

  useEffect(()=>{
      console.log("LOADING CHARACTER DISPLAY ITEM MARKET", market)
    },[market])

  useEffect(()=>{
        console.log("LOADING CHARACTER DISPLAY ITEM ARTIFACT", artifact)
      },[artifact])

  useEffect(()=>{
        console.log("LOADING CHARACTER DISPLAY ITEM open", open)
      },[open])

  return (

    <Dialog open={open} className="DisplayItem" PaperComponent="div" fullWidth={true} scroll="paper" BackdropProps={{invisible:false}} PaperProps={{
      style: {
        width: '80%',
        maxWidth: '1080px',
        position: 'absolute',
        top: 0,
        height: '100%',
      },
    }}>
      <DialogContent >
        <div className="displayItemContent">
          {information && <Information onDismiss={onDismiss} {...information} />}
          {artifact && <Artifact onDismiss={onDismiss} selectedShip={selectedShip} {...artifact} />}
          {speech && <Speech onDismiss={onDismiss} {...speech} dismissOnComplete={!market}/>}
          {market && <Market {...market} />}
        </div>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={()=>{onDismiss && onDismiss();}} color="info">Dismiss</Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}